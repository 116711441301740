const SecondaryFeatureTextEnums = new Map();

SecondaryFeatureTextEnums.set(1, 'smsFeature');
SecondaryFeatureTextEnums.set(2, 'sellCropPrimaryHeading'); //
SecondaryFeatureTextEnums.set(3, 'bahiKhata');
SecondaryFeatureTextEnums.set(4, 'cropAdvisory');
SecondaryFeatureTextEnums.set(5, 'rake');
SecondaryFeatureTextEnums.set(6, 'expenseManagerPrimaryHeading'); //
SecondaryFeatureTextEnums.set(7, 'mandiRatePrimaryHeading'); //
SecondaryFeatureTextEnums.set(8, 'distributorList');

export default SecondaryFeatureTextEnums;
