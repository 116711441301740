export function encodeParams(params) {
  params = { params: JSON.stringify(params) };
  let encodedParams = Object.keys(params)
    .map(
      (key) => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`,
    )
    .join('&');
  return encodedParams;
}

export function decodeParams(encodedParams) {
  let decodedParams: any = {};
  // Check if encodedParams is not null or undefined
  if (encodedParams) {
    const queryParams = new URLSearchParams(encodedParams);
    // Iterate over each query parameter
    queryParams.forEach((value, key) => {
      // Decode the value and assign it to the decodedParams object
      decodedParams[key] = decodeURIComponent(value);
    });
    // Check if decodedParams has a 'params' key
    if ('params' in decodedParams) {
      // Return the value of 'params'
      decodedParams = JSON.parse(decodedParams.params);
    }
  }
  // Return the entire decodedParams object if 'params' key is not found or encodedParams is null/undefined
  return decodedParams;
}
