/**
 * The above code defines a React context and provider for managing platform data in a TypeScript React
 * application.
 * @returns The code snippet provided defines a React context named `PlatformContext` along with a
 * custom hook `usePlatformData` and a context provider component `PlatformContextProvider`.
 */
import React, {
  createContext,
  useContext,
  useEffect,
  useState,
  ReactNode,
} from 'react';
import { AuthParamsFromMobileApp } from './types';
import { decodeParams } from '../../utils/handleUrlParams';

// Define a type for the context data
interface PlatformContextData {
  platform: string;
  data: AuthParamsFromMobileApp | null;
}

// Create a context

const PlatformContext = createContext<PlatformContextData | undefined>(
  undefined,
);
// Define the props for the PlatformContextProvider component
interface PlatformContextProviderProps {
  children: ReactNode;
}

export function usePlatfromData() {
  return useContext(PlatformContext);
}

function isAndroid() {
  try {
    return typeof (window as any)?.ReactNativeWebView !== 'undefined';
  } catch (e) {
    return false;
  }
}

export function PlatformContextProvider({
  children,
}: PlatformContextProviderProps) {
  const [platformData, setPlatformData] = useState<PlatformContextData>({
    platform: isAndroid() ? 'APP' : 'WEB',
    data: null,
  });

  useEffect(() => {
    // params in string format
    const paramsString = decodeParams(window?.location?.search);

    let params;

    if (paramsString && Object.keys(paramsString).length) {
      params = paramsString;
    }
    setPlatformData({
      platform: isAndroid() ? 'APP' : 'WEB',
      data: params || null,
    });
  }, []);

  return (
    <PlatformContext.Provider value={platformData}>
      {children}
    </PlatformContext.Provider>
  );
}
