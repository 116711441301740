/* export { default as getCommunityFeed } from "./models/getCommunityFeed"; */
//use above as example

/* export * from "./types"; */
import fetchTradePriceBreakup from '../../../apis/Trader/fetchTradePriceBreakup';

import { TradePriceBreakupData } from '../types';

interface PayloadType {
  trade_price_id: string;
  supplier_state_id: number;
  supplier_district_id: number;
}

export default async function getTradeList(
  payload: PayloadType,
): Promise<TradePriceBreakupData> {
  const body = {
    ...payload,
  };
  try {
    const result = await fetchTradePriceBreakup(body);

    if (!result.data.status) {
      throw result;
    }

    return result.data.data;
  } catch (error) {
    if (!error.message) {
      error.message = 'Something Went Wrong';
    }

    throw error;
  }
}
