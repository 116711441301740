import * as AppLanguages from '../constant/appLanguages';
//language will be added here
const languages = {
  [AppLanguages.appLanguagesIndex.hindi]: {
    help: 'सहायता',
    heading: 'किसान जोड़ें',
    searchPlaceHolder: 'किसान को नाम या नंबर से खोजें',
    contactAlert: 'चेक बॉक्स पर क्लिक करके एक या अधिक किसान जोड़ें',
    manualFarmerButton: 'नया किसान जोड़ें',
    deniedPermissionAlertHeading: 'कांटेक्ट बुक अनुमति',
    deniedPermissionAlertSubHeading:
      'फ़ोन बुक से किसान जोड़ने के लिए फ़ोन बुक की परमिशन दें',
    emptyState: 'आपने अभी तक कोई किसान नहीं जोड़ा है।',
    manualModalHeading: 'किसान की जानकारी भरें',
    farmerNameInputTitle: 'किसान का नाम',
    farmerNameInputPlaceHolder: 'किसान का नाम भरें',
    farmerNumberInputTitle: 'मोबाइल नंबर',
    farmerNumberInputPlaceHolder: 'किसान का मोबाइल नंबर भरें',
    manualSubmitButton: 'आगे बढ़े',
    addFarmerButton: 'किसान जोड़ें {(variable)}',
    webAlertText:
      'To add farmers directly from the your Contact Book, please download our app. ',
    downloadAppButton: 'Download FarMart app',
    apiError: 'असुविधा के लिए क्षमा करें! कृपया बाद में फिर से प्रयास करें।',
  },
  [AppLanguages.appLanguagesIndex.english]: {
    help: 'Help',
    heading: 'Add Farmer',
    searchPlaceHolder: 'Search by name or number',
    emptyState: 'You have not added any farmers till now',
    contactAlert: 'Click the check box to add one or more farmers',
    manualFarmerButton: 'Add New Farmer',
    deniedPermissionAlertHeading: 'Contact Book permission',
    deniedPermissionAlertSubHeading:
      'To add farmer please give permission of your contact book',
    manualModalHeading: "Enter farmer's information",
    farmerNameInputTitle: 'Farmer Name',
    farmerNameInputPlaceHolder: 'Enter Farmer Name',
    farmerNumberInputTitle: 'Farmer Number',
    farmerNumberInputPlaceHolder: 'Enter Farmer Number',
    manualSubmitButton: 'Proceed',
    addFarmerButton: 'Add farmer {(variable)}',
    webAlertText:
      'To add farmers directly from the your Contact Book, please download our app. ',
    downloadAppButton: 'Download FarMart app',
    apiError: 'Sorry for the inconvenience! Please try again later.',
  },
};

export default (language) => languages[language];
