import getLang from '../../languages/SmsAcknowledgement.js'; // Importing language translation function
import { useLocation, useNavigate } from 'react-router-dom';
import { appLanguages } from '../../constant/appLanguages';
import { SmsAcknowledgementProps } from './types';

export default function SmsAcknowledgementHooks(): SmsAcknowledgementProps {
  const { state } = useLocation();
  const { smsSent, smsBalance, smsId } = state;

  const langId = JSON.parse(localStorage.getItem('userLanguage'));
  const activeSystemLang = Intl.DateTimeFormat().resolvedOptions().locale;
  const systemLanguage = activeSystemLang?.includes('en')
    ? appLanguages[1].langCode
    : appLanguages[0].langCode;

  const language = getLang(langId?.langCode || systemLanguage);

  const navigate = useNavigate();

  // Function to handle back button press
  /**
   * The onPressBack function navigates to the "/myFarmers" route with state information indicating it
   * was triggered from the "addFarmer" action.
   */
  function onPressSendNewSms() {
    navigate('/shopPromotion');
  }

  /**
   * The function `onContactUs` navigates to the "/faq/questions" page.
   */
  /**
   * The `onContactUs` function navigates to the "/faq/questions" page.
   */
  function onContactUs() {
    navigate('/faq/questions');
  }

  // Returning necessary functions and states
  return {
    onPressSendNewSms,
    language,
    onContactUs,
    smsSent,
    smsBalance,
    smsId,
  };
}
