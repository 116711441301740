import React from 'react';

const navigateBack = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M11.175 19.475L4.225 12.525C4.14167 12.4417 4.08333 12.3583 4.05 12.275C4.01667 12.1917 4 12.1 4 12C4 11.9 4.01667 11.8083 4.05 11.725C4.08333 11.6417 4.14167 11.5583 4.225 11.475L11.2 4.5C11.3333 4.36667 11.5 4.3 11.7 4.3C11.9 4.3 12.075 4.375 12.225 4.525C12.375 4.675 12.45 4.85 12.45 5.05C12.45 5.25 12.375 5.425 12.225 5.575L6.55 11.25H18.95C19.1667 11.25 19.3458 11.3208 19.4875 11.4625C19.6292 11.6042 19.7 11.7833 19.7 12C19.7 12.2167 19.6292 12.3958 19.4875 12.5375C19.3458 12.6792 19.1667 12.75 18.95 12.75H6.55L12.25 18.45C12.3833 18.5833 12.45 18.75 12.45 18.95C12.45 19.15 12.375 19.325 12.225 19.475C12.075 19.625 11.9 19.7 11.7 19.7C11.5 19.7 11.325 19.625 11.175 19.475Z"
        fill="#293756"
      />
    </svg>
  );
};

export default navigateBack;
