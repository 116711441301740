import React from 'react';
import Style from './style.module.css';
import FileListCard from '../FileListCard';
import Loading from '../../../ComponentV2/Loading';

interface FileListProps {
  language: { [key: string]: any };
  handleFileChange?: (
    event: React.ChangeEvent<HTMLInputElement>,
    index?: number,
  ) => void;
  handleUploadButtonClick?: (from: string, index?: number) => void;
  fileInputRef: any;
  documents: any;
  preViewDocument: (documents: any, index?: number) => void;
  multiFileUpload: boolean;
  reUploadInputRef: any;
  handleReUploadChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleReUploadButtonClick: (
    from?: string,
    index?: number,
    file?: any,
  ) => void;
  initialPageLoading?: boolean;
  saudaType: number;
}

const FileList = ({
  language,
  handleFileChange,
  handleUploadButtonClick,
  fileInputRef,
  documents,
  preViewDocument,
  multiFileUpload,
  reUploadInputRef,
  handleReUploadChange,
  handleReUploadButtonClick,
  initialPageLoading,
  saudaType,
}: FileListProps) => {
  return (
    <div className={Style.fileList} style={{ paddingBottom: '130px' }}>
      <label className={Style.fileListTitle}>{language?.listOfFiles}</label>
      {documents.map((file, index) => (
        <div className={Style.fileCardWrapper} key={index + 'fileListCard'}>
          <FileListCard
            preViewDocument={preViewDocument}
            file={file}
            handleFileChange={handleFileChange}
            handleUploadButtonClick={handleUploadButtonClick}
            fileInputRef={fileInputRef}
            key={index}
            language={language}
            multiFileUpload={multiFileUpload}
            index={index}
            reUploadInputRef={reUploadInputRef}
            handleReUploadChange={handleReUploadChange}
            handleReUploadButtonClick={handleReUploadButtonClick}
            saudaType={saudaType}
          />
        </div>
      ))}

      {initialPageLoading && (
        <div className={Style.loadingContainer}>
          <Loading size={30} marginTop={20} color="grey" />
        </div>
      )}
    </div>
  );
};

export default FileList;
