import React from 'react';
import Styles from './style.module.css';
import { Crop, CropVariety } from '../../../modal/Traders/types';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';

interface PayloadType {
  handleCropListModal: () => void;
  handleCropVarietyListModal: () => void;
  handleBuyerLocationListModal: () => void;
  activeCrop: Crop;
  activeVariety: CropVariety;
  activeLocations: string[];
  language: { [key: string]: any };
}

const Header = ({
  handleCropListModal,
  handleCropVarietyListModal,
  handleBuyerLocationListModal,
  activeCrop,
  activeVariety,
  activeLocations,
  language,
}: PayloadType) => {
  return (
    <div className={Styles.mainContainer}>
      <label className={Styles.headerTextStyle}>{language?.buyerPrice}</label>
      <div className={Styles.buttonrow}>
        <div
          onClick={() => handleCropListModal()}
          className={Styles.filterButtonContainer}
        >
          <label className={Styles.filterTextStyle}>
            {activeCrop?.crop_name}
          </label>
          <ExpandMoreRoundedIcon />
        </div>
        <div
          onClick={() =>
            activeCrop?.id !== null && handleCropVarietyListModal()
          }
          className={Styles.filterButtonContainer}
        >
          <label
            className={
              activeCrop?.id === null
                ? Styles.deactiveButton
                : Styles.filterTextStyle
            }
          >
            {activeVariety?.crop_variety_name}
          </label>
          {activeCrop?.id !== null ? (
            <ExpandMoreRoundedIcon />
          ) : (
            <ExpandMoreRoundedIcon sx={{ color: '#98A0AE' }} />
          )}
        </div>
        <div
          onClick={() => handleBuyerLocationListModal()}
          className={Styles.filterButtonContainer}
        >
          <label className={Styles.filterTextStyle}>
            {activeLocations.includes(null)
              ? 'Locations'
              : activeLocations?.length + ' Locations'}
          </label>
          <ExpandMoreRoundedIcon />
        </div>
      </div>
    </div>
  );
};

export default Header;
