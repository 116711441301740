import React from 'react';
import Styles from './style.module.css';
import { Box } from '@mui/joy';
import MuiTypography from '../../../ComponentV3/Typography';

interface HeaderProps {
  onLanguageModalToggle: () => void;
  language: { [key: string]: any };
  farmartLogo: string;
  languageSelectIcon: string;
}
const Header = (props: HeaderProps) => {
  const { onLanguageModalToggle, language, farmartLogo, languageSelectIcon } =
    props;
  return (
    <Box className={Styles.mainContainer} bgcolor={'background.surface'}>
      <div className={Styles.locationContainer}>
        <div className={Styles.loactionLabelContainer}>
          <img
            className={Styles.whatsAppIcon}
            src={farmartLogo}
            alt="Farmart Logo"
            height={18}
            width={80}
            loading="eager"
          />
        </div>
      </div>

      <div
        id="loginScreenChangeLangTestId"
        className={Styles.helpButtonContainer}
        onClick={onLanguageModalToggle}
      >
        <img
          className={Styles.languageIconStyle}
          src={languageSelectIcon}
          alt="Language Select Icon"
          height={20}
          width={20}
          loading="eager"
        />
        <MuiTypography
          className={Styles.helpTextStyle}
          level="body-xs"
          color="primary"
        >
          {language?.changeLanguage}
        </MuiTypography>
      </div>
    </Box>
  );
};

export default Header;
