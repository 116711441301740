import React from 'react';
import BuyerDetails from '../../Pages/BuyerDetails/BuyerDetails';
import UseTradePriceBreakup from '../../hooks/Trades/useTradesPriceBreakup';

function BuyerDetail() {
  const {
    PriceBreakup,

    handleChangeTab,

    activeTab,

    brokeragePopup,

    handleBrokeragePopup,

    financingPopup,

    handleFinancingPopup,

    netPopup,

    handleNetPopup,
    numberCopyToast,
    handleNumberCopyToast,
    language,
    currentLanguageId,
    onClickBack,
    showRequestSauda,
    onClickRequestSauda,
    toggleRequestSaudaModal,
    quantity,
    handleQuantityChange,
    paymentTerms,
    paymentTermsType,
    handlePaymentTerms,
    showPaymentTermsModal,
    proceedDisabled,
    grnInfo,
    buyersInfo,
    onClosePaymentTermsModal,
    closePaymentTermsModal,
    saudaRequestToastPopup,
    handleSaudaRequestToastPopup,
    proceedSaudaRequest,
    isFocusedSaudaRequestInput,
    handleFocusSaudaRequestInput,
    handleBlurSaudaRequestInput,
    onKeyDownSaudaRequestInput,
    buyerDetailAssets,
  } = UseTradePriceBreakup();
  return (
    <BuyerDetails
      PriceBreakup={PriceBreakup}
      handleChangeTab={handleChangeTab}
      activeTab={activeTab}
      brokeragePopup={brokeragePopup}
      handleBrokeragePopup={handleBrokeragePopup}
      financingPopup={financingPopup}
      handleFinancingPopup={handleFinancingPopup}
      netPopup={netPopup}
      handleNetPopup={handleNetPopup}
      onClickBack={onClickBack}
      numberCopyToast={numberCopyToast}
      handleNumberCopyToast={handleNumberCopyToast}
      language={language}
      currentLanguageId={currentLanguageId}
      showRequestSauda={showRequestSauda}
      onClickRequestSauda={onClickRequestSauda}
      toggleRequestSaudaModal={toggleRequestSaudaModal}
      quantity={quantity}
      paymentTerms={paymentTerms}
      paymentTermsType={paymentTermsType}
      showPaymentTermsModal={showPaymentTermsModal}
      handleQuantityChange={handleQuantityChange}
      handlePaymentTerms={handlePaymentTerms}
      proceedDisabled={proceedDisabled}
      grnInfo={grnInfo}
      buyersInfo={buyersInfo}
      onClosePaymentTermsModal={onClosePaymentTermsModal}
      closePaymentTermsModal={closePaymentTermsModal}
      saudaRequestToastPopup={saudaRequestToastPopup}
      handleSaudaRequestToastPopup={handleSaudaRequestToastPopup}
      proceedSaudaRequest={proceedSaudaRequest}
      isFocusedSaudaRequestInput={isFocusedSaudaRequestInput}
      handleFocusSaudaRequestInput={handleFocusSaudaRequestInput}
      handleBlurSaudaRequestInput={handleBlurSaudaRequestInput}
      onKeyDownSaudaRequestInput={onKeyDownSaudaRequestInput}
      buyerDetailAssets={buyerDetailAssets}
    />
  );
}
export default BuyerDetail;
