import React from 'react';
import Styles from './style.module.css';

import Typography from '@mui/joy/Typography';
import { Box } from '@mui/material';
import ArrowBackRounded from '@mui/icons-material/ArrowBackRounded';
import SearchBar from './../SearchBar/SearchBar';
import MuiButton from '../../../ComponentsV3/MuiButton';

interface PinCodeHeaderProps {
  onSearchPinCode: (event: React.ChangeEvent<HTMLInputElement>) => void;
  pinCodeSearchValue: string;
  onPressBack: () => void;
  language: { [key: string]: string };
}
export default function Header(props: PinCodeHeaderProps) {
  const { onSearchPinCode, pinCodeSearchValue, onPressBack, language } = props;

  return (
    <Box className={Styles.mainContainer} bgcolor={'primary.500'}>
      <Box className={Styles.locationContainer} id="RakeListScreenBackTestId">
        <Box display={'flex'} gap={'12px'}>
          <ArrowBackRounded htmlColor="#fff" onClick={onPressBack} />
          <Typography level="title-md" textColor={'text.icon-inverse'}>
            {language?.heading}
          </Typography>
        </Box>

        <Box>
          <MuiButton
            size="sm"
            color="warning"
            className={Styles?.helpButtonContainer}
            //onClick={onClickFaq}
            id="helpFaqPwaId"
          >
            {language?.help}
          </MuiButton>
        </Box>
      </Box>
      <SearchBar
        pinCodeSearchValue={pinCodeSearchValue}
        onSearchPinCode={onSearchPinCode}
        language={language}
      />
    </Box>
  );
}
