import React from 'react';
import { Box, Chip } from '@mui/joy';
import MuiTypography from '../../../ComponentV3/Typography';

export default function SMSDetailsCard({
  headerTitle,
  smsDetailText,
  inputValues,
  inputVariables,
}) {
  const replacedText = smsDetailText.map((text, index) => {
    if (text === '${var}') {
      const variable = inputVariables.find(
        (variable) => variable.position === index,
      );
      if (variable) {
        let variableValue = inputValues[variable.name] || variable?.heading;
        if (
          variable?.type === 'date' &&
          inputValues?.hasOwnProperty(variable.name)
        ) {
          const dateValue = inputValues[variable.name];
          if (Date.parse(dateValue)) {
            // Format the date value to DD/MM/YYYY
            variableValue = new Date(dateValue)?.toLocaleDateString('en-GB', {
              day: '2-digit',
              month: '2-digit',
              year: 'numeric',
            });
          } else {
            // If the date string is invalid, use variable.heading
            variableValue = variable.heading;
          }
        }

        // Add prefix and suffix strings to variableValue only if inputValue has a value
        if (
          inputValues[variable.name] &&
          inputValues[variable.name] !== variable.default_value
        ) {
          if (variable.prefix_string) {
            variableValue = variable.prefix_string + ' ' + variableValue;
          }
          if (variable.suffix_string) {
            variableValue = variableValue + ' ' + variable.suffix_string;
          }
        }

        // Determine the color: red for values provided by the user, grey for the heading fallback
        const typographyColor = inputValues[variable.name]
          ? '#019881' // Primary color for user input
          : '#9FA6AD'; // Grey for fallback heading

        return (
          <MuiTypography sx={{ color: typographyColor }}>
            {variableValue}
          </MuiTypography>
        );
      }
    }
    return text;
  });

  return (
    <Box
      margin={4}
      bgcolor={'#F0F4F8'}
      borderRadius={4}
      padding={3}
      display={'flex'}
      flexDirection={'column'}
      justifyContent={'center'}
      alignItems={'center'}
    >
      <Chip
        variant="solid"
        color={'warning'}
        size={'md'}
        sx={{ borderRadius: '4px' }}
      >
        {headerTitle}
      </Chip>
      <Box paddingTop={5}>
        <MuiTypography
          sx={{
            color: '#171A1C',
            wordBreak: 'break-word',
            maxWidth: '100%',
          }}
        >
          {replacedText.map((text, index) => (
            <React.Fragment key={index}>
              {typeof text === 'string' ? text : text}
              {index < replacedText.length - 1 ? ' ' : ''}
            </React.Fragment>
          ))}
        </MuiTypography>
      </Box>
    </Box>
  );
}
