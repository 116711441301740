import React from 'react';
import { Box, Snackbar } from '@mui/joy';

// Interface for props received by the component

interface FAQAlertProps {
  showFaqAlert: boolean; // Boolean flag to determine if the alert should be shown
  setShowFaqAlert: (flag: boolean) => void; // Function to set the showFaqAlert state
  language: { [key: string]: any }; // Language object containing text strings
}

// Functional component definition with destructured props

const FAQAlert = ({
  showFaqAlert,
  setShowFaqAlert,
  language,
}: FAQAlertProps) => {
  return (
    <Box>
      <Snackbar
        open={showFaqAlert}
        autoHideDuration={4000}
        onClose={() => {
          setShowFaqAlert(false);
        }}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        sx={{
          paddingTop: 3,
          paddingBottom: 3,
          paddingLeft: 2,
          paddingRight: 2,
          zIndex: 20000,
          borderRadius: '8px',
          width: '90%',
          marginBottom: '32px',
        }}
        variant="soft"
        color={'danger'}
      >
        {language?.noWhatsAppAccountFound}
      </Snackbar>
    </Box>
  );
};

export default FAQAlert; // Exporting the FAQAlert component as the default export
