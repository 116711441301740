import * as AppLanguages from '../constant/appLanguages';
//language will be added here
const languages = {
  [AppLanguages.appLanguagesIndex.hindi]: {
    retailer: 'खाद, बीज, कीटनाशक बिक्री',
    retailerDescp: 'कृषि विक्रेताओं और कृषि सलाहकारों के लिए',
    distributer: 'फसल व्यापार',
    distributerDescp: 'ट्रेडर, आढ़तियों, और अन्य लोगों के लिए',
    farmer: 'खेती बाड़ी',
    farmerDescp: 'हर एक किसानों के लिए',
    chooseRole: 'अपनी व्यापार चुनें',
    whatYouDo: 'आप क्या करते हैं?',
    applicableProfession: 'जो भी आप पर लागू हो उसे चुनें',
    proceed: 'आगे बढ़ें',
    selectLanguage: 'भाषा चुने',
    changeLanguage: 'भाषा बदलें',
  },
  [AppLanguages.appLanguagesIndex.english]: {
    retailer: 'Agri Input Retailing',
    retailerDescp: 'For Agri retailers or Agri consultants',
    distributer: 'Agri Trading',
    distributerDescp: 'For Traders, Market agents and others',
    farmer: 'Farming',
    farmerDescp: 'For each and every Farmers',
    chooseRole: 'Choose your role',
    whatYouDo: 'What you do?',
    applicableProfession: 'Choose whatever is applicable to you',
    proceed: 'Proceed',
    selectLanguage: 'Select language',
    changeLanguage: 'Change Language',
  },
};

export default (language) => languages[language];
