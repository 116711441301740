import React from 'react';
import { Box } from '@mui/material';
import Styles from './style.module.css';
import SearchRounded from '@mui/icons-material/SearchRounded';

interface SearchProps {
  language: { [key: string]: string }; // Interface for the language object
  searchParam: string; // Current search parameter
  setSearchParam: (val: string) => void; // Function to update the search parameter
  debouncedSetSearchParam: (value: string) => void; // Function to update the search parameter with debounce
  setCurrentPage: (val: number) => void;
}

export default function Search(props: SearchProps) {
  const { language, setSearchParam, setCurrentPage } = props;

  // Event handler for input change
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCurrentPage(1);
    const value = event.target.value;
    setSearchParam(value); // Update the search parameter
  };

  return (
    <Box margin={'16px 16px 0px 16px'} display={'flex'} flex={1}>
      <Box display={'flex'} height={40} flex={1}>
        <Box
          display={'flex'}
          position={'absolute'}
          alignItems={'center'}
          alignSelf={'stretch'}
          height={40}
          justifyContent={'center'}
          marginLeft={'12px'}
        >
          <SearchRounded data-testid="searchIcon" /> {/* Search icon */}
        </Box>

        <input
          id="smsFarmerSearchInput"
          className={Styles.inputBoxContainer} // CSS class for the input box
          placeholder={language?.searchText} // Placeholder text for the input box
          style={{
            fontFamily: 'NotoSansDevanagari',
            fontSize: 14,
            fontWeight: 400,
            lineHeight: 24,
            color: '#9FA6AD',
          }}
          onChange={handleInputChange} // Event handler for input change
          data-testid="searchInput" // Test ID for the input box
        />
      </Box>
    </Box>
  );
}
