const homeScreenDownloadAppModalType = new Map();

homeScreenDownloadAppModalType.set('rake', 1);
homeScreenDownloadAppModalType.set('bahiKhata', 2);
homeScreenDownloadAppModalType.set('distributorList', 3);
homeScreenDownloadAppModalType.set('smsFeature', 4);
homeScreenDownloadAppModalType.set('subscribedCropAdvisoryList', 5);
homeScreenDownloadAppModalType.set('cropAdvisoryList', 6);
homeScreenDownloadAppModalType.set('quiz', 11);
homeScreenDownloadAppModalType.set('addFarmer', 12);
homeScreenDownloadAppModalType.set('myProfile', 13);
homeScreenDownloadAppModalType.set('smsHistory', 14);
homeScreenDownloadAppModalType.set('newsFeed', 15);
homeScreenDownloadAppModalType.set('subScribedCropAdvisorySteps', 16);
homeScreenDownloadAppModalType.set('faq', 17);
homeScreenDownloadAppModalType.set('quizResult', 18);
homeScreenDownloadAppModalType.set('quizWinnerList', 19);
homeScreenDownloadAppModalType.set('mySaudaList', 20);

export default homeScreenDownloadAppModalType;
