import React from 'react';
import { useRive, Layout, Fit, Alignment } from '@rive-app/react-canvas';

interface RiveProps {
  src: string;
  stateMachines?: string;
  autoplay?: boolean;
  styles?: React.CSSProperties;
}

export const RiveComponent: React.FC<RiveProps> = ({
  src,

  autoplay = true,
  styles = {},
}) => {
  const { RiveComponent } = useRive({
    src: src,

    autoplay: autoplay,
    layout: new Layout({
      fit: Fit.FitHeight, // Adjust as per your requirements
      alignment: Alignment.Center,
    }),
  });

  return <RiveComponent style={styles} />;
};

export default RiveComponent;
