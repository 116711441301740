/**
 * config/constants/images.js
 *
 * @format
 * @flow strict-local
 */

import { userType } from './enums/userType';

class ImageAssets {
  get occupationImages() {
    return {
      [userType.retailer]:
        'https://d2n0idf0n5xz1f.cloudfront.net/image/development/1697452534628',
      [userType.trader]:
        'https://d2n0idf0n5xz1f.cloudfront.net/image/development/1697452667517',
      [userType.farmer]:
        'https://d2n0idf0n5xz1f.cloudfront.net/image/development/1697452690791',
    };
  }
}

export default new ImageAssets();
