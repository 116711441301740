import React from 'react';
import Style from './style.module.css';
import NavigateBack from '../../../assets/svgComponet/navigateBack';
import WhatsAppSvg from '../../../Images/Whatsapp.svg';
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';

interface AcknowledgementHeaderProps {
  onClickBackSaudaAcknowledgement: () => void; // Function to handle back click
  language: { [key: string]: any }; // Language settings for localization
  onClickHelpSaudaAcknowledgement: () => void; // Function to handle help click
}

// Header component for Sauda Acknowledgement screen
export default function SaudaAcknowledgementHeader({
  onClickBackSaudaAcknowledgement,
  language,
  onClickHelpSaudaAcknowledgement,
}: AcknowledgementHeaderProps) {
  return (
    <div className={Style.header}>
      <div
        onClick={onClickBackSaudaAcknowledgement}
        className={Style.headerLeft}
        id="onClickBackSaudaAcknowledgementScreenId"
        data-testid="onClickBackSaudaAcknowledgementScreenTestId"
      >
        <ArrowBackRoundedIcon htmlColor="#293756" />
        <div className={Style.headerText}>{language?.confirmRequest}</div>
      </div>
      <div
        onClick={onClickHelpSaudaAcknowledgement}
        className={Style.helpButtonContainer}
        id={'onCickHelpSaudaAcknowledgementId'}
        data-testid="onCickHelpSaudaAcknowledgementTestId"
      >
        <img
          className={Style.whatsAppIcon}
          src={WhatsAppSvg as unknown as string}
          alt="whatsappIcon"
          id="onCickHelpSaudaImageAcknowledgementId"
          loading="eager"
        />
        <label
          className={Style.helpTextStyle}
          id="onCickHelpLabelSaudaAcknowledgementId"
        >
          {language?.help}
        </label>
      </div>
    </div>
  );
}
