import { Box } from '@mui/joy';
import React from 'react';
import Styles from './style.module.css';
import { PersonRounded } from '@mui/icons-material';
import MuiTypography from '../../../ComponentV3/Typography';
import { UserProfile } from '../../../hooks/MyProfile/types';
import SkeletonLoading from '../../../ComponentsV4/SkeletonLoading';
import AddPhotoAlternateRoundedIcon from '@mui/icons-material/AddPhotoAlternateRounded';

/**
 * Renders a user profile section with an image, name, and mobile number.
 * Allows clicking on the profile image section to trigger a custom action.
 *
 * Props:
 * - profile: UserProfile - Contains the user's profile information.
 * - onClickMyProfile: () => void - Function to be called when the profile image section is clicked.
 *
 * The component uses Material UI's Joy UI components for layout and styling,
 * and custom CSS modules for specific styles.
 */
export default function index(props: UserProfile) {
  const { profile, onClickMyProfile, loading } = props;
  // eslint-disable-next-line no-constant-condition
  return loading ? (
    <SkeletonLoading className={Styles?.skeleton} />
  ) : (
    <Box className={Styles?.mainContainer}>
      <Box
        className={Styles?.imageSection}
        onClick={() => onClickMyProfile('profile_card')}
        id="myProfileUserProfileTestId"
        data-testid={'myProfileUserProfileClickTestId'}
      >
        {profile?.profile_pic_url ? (
          // Renders the user's profile picture if available
          <img
            src={profile?.profile_pic_url}
            className={Styles?.proileImage}
            data-testid={'myProfileUseProfileImageTestId'}
            alt="profileImage"
            loading="eager"
          />
        ) : (
          // Renders a default icon if no profile picture is available
          <PersonRounded />
        )}
        <Box className={Styles.addImageSection}>
          {/* SVG icon for adding or editing the profile image */}
          <AddPhotoAlternateRoundedIcon
            sx={{
              fill: '#555E68',
              backgroundColor: 'white',
              height: 24,
              width: 24,
              borderRadius: 16,
              padding: '4px',
            }}
          />
        </Box>
      </Box>
      <Box className={Styles?.userInfoSection}>
        {/* Renders the user's merchant name */}
        <MuiTypography
          level="title-sm"
          textColor={'text.primary'}
          alignSelf={'stretch'}
          data-testid={'myProfileUserNameTestId'}
        >
          {profile?.merchant_name}
        </MuiTypography>
        {/* Renders the user's mobile number */}
        <MuiTypography
          level="body-xxs"
          textColor={'text.secondary'}
          alignSelf={'stretch'}
          data-testid={'myProfileUserMobileNumberTestId'}
        >
          +91 {profile?.mobile_number}
        </MuiTypography>
      </Box>
    </Box>
  );
}
