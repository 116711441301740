function resizeImage(file) {
  return new Promise((resolve, reject) => {
    const maxWidth = 1920;
    const maxHeight = 1080;
    const image = new Image();
    image.onload = function () {
      let width = image.width;
      let height = image.height;
      if (width > maxWidth || height > maxHeight) {
        let scaleFactor = Math.min(maxWidth / width, maxHeight / height);
        width = Math.floor(width * scaleFactor);
        height = Math.floor(height * scaleFactor);
        if (file.size < 10000000) {
          // 1MB
          // Use a faster, simpler resize method for smaller files
          fastResize(file, width, height)
            .then((resizedFile) => resolve(resizedFile))
            .catch((error) => reject(error));
        } else {
          resizeImageWithLanczos3(file, width, height)
            .then((resizedFile) => resolve(resizedFile))
            .catch((error) => reject(error));
        }
      } else {
        resolve(file); // No need to resize, return the original file
      }
    };
    image.onerror = reject;
    image.src = URL.createObjectURL(file);
  });
}
function fastResize(file, width, height) {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = function () {
      const canvas = document.createElement('canvas');
      canvas.width = width;
      canvas.height = height;
      const ctx = canvas.getContext('2d');
      ctx.drawImage(img, 0, 0, width, height);
      canvas.toBlob((blob) => {
        const resizedFile = new File([blob], file.name, { type: file.type });
        resolve(resizedFile);
      }, file.type);
    };
    img.onerror = reject;
    img.src = URL.createObjectURL(file);
  });
}

function prepareInputAndOutputData(inputImage, outputWidth, outputHeight) {
  var sourceWidth = inputImage.width;
  var sourceHeight = inputImage.height;

  var canvas = document.createElement('canvas');
  var ctx = canvas.getContext('2d');

  canvas.width = sourceWidth;
  canvas.height = sourceHeight;
  ctx.drawImage(inputImage, 0, 0, sourceWidth, sourceHeight);
  var src = ctx.getImageData(0, 0, sourceWidth, sourceHeight);
  var dest = new Uint8ClampedArray(outputWidth * outputHeight * 4);
  return [src.data, dest, sourceWidth, sourceHeight];
}

async function resizeImageWithLanczos3(inputFile, outputWidth, outputHeight) {
  return new Promise((resolve, reject) => {
    const image = new Image();
    image.onload = function () {
      const [src, dest, sourceWidth, sourceHeight] = prepareInputAndOutputData(
        image,
        outputWidth,
        outputHeight,
      );

      for (let y = 0; y < outputHeight; y++) {
        for (let x = 0; x < outputWidth; x++) {
          const u = (x * sourceWidth) / outputWidth;
          const v = (y * sourceHeight) / outputHeight;
          const u1 = Math.floor(u);
          const v1 = Math.floor(v);

          let sumR = 0,
            sumG = 0,
            sumB = 0,
            sumA = 0,
            sumWeight = 0;

          for (let j = v1 - 1; j <= v1 + 2; j++) {
            for (let i = u1 - 1; i <= u1 + 2; i++) {
              const weight = lanczos3(u - i) * lanczos3(v - j);
              if (i >= 0 && i < sourceWidth && j >= 0 && j < sourceHeight) {
                const idx = (j * sourceWidth + i) * 4;
                sumR += src[idx] * weight;
                sumG += src[idx + 1] * weight;
                sumB += src[idx + 2] * weight;
                sumA += src[idx + 3] * weight;
                sumWeight += weight;
              }
            }
          }
          const destIdx = (y * outputWidth + x) * 4;
          dest[destIdx] = sumR / sumWeight;
          dest[destIdx + 1] = sumG / sumWeight;
          dest[destIdx + 2] = sumB / sumWeight;
          dest[destIdx + 3] = sumA / sumWeight;
        }
      }
      const imageData = new ImageData(dest, outputWidth, outputHeight);
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      canvas.width = outputWidth;
      canvas.height = outputHeight;
      ctx.putImageData(imageData, 0, 0);
      canvas.toBlob((blob) => {
        // Create a new file with the same name and type as the input file
        const resizedFile = new File([blob], inputFile.name, {
          type: inputFile.type,
        });
        resolve(resizedFile);
      }, 'image/jpeg');
    };
    image.src = URL.createObjectURL(inputFile);
  });
}

function lanczos3(x) {
  if (x === 0) {
    return 1;
  }
  if (Math.abs(x) < 3) {
    return (
      (Math.sin(Math.PI * x) * Math.sin((Math.PI * x) / 3)) /
      ((Math.PI * Math.PI * x * x) / 3)
    );
  }
  return 0;
}

function imageCompress(file) {
  return resizeImage(file);
}

export default imageCompress;
