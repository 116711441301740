import { useState, useEffect } from 'react';

import { AuthParamsFromMobileApp, UseAuthReturnType } from './types';

import {
  loginAsGuest,
  getAuthTokenFromStorage,
  storeAuthInfoToStorage,
  AuthInfoToStorage,
} from '../../modal/auth/auth.model';
import { decodeParams } from '../../utils/handleUrlParams';

export default function useAuth(): UseAuthReturnType {
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
  const [checkingIsLoggedIn, setCheckingIsLoggedIn] = useState<boolean>(true);

  useEffect(function () {
    initAuth();
  }, []);

  async function initAuth() {
    /* 
      check if the user is logged in
      if the user is logged in, set the login status to true
      if the user is not logged in, continue
    */

    const token = getAuthTokenFromStorage();

    /* 
      this app will be launched in two ways:
        1. from the browser
        2. from the mobile app
      if the app is launched from the mobile app, the params will be passed in the url
      if the app is launched from the browser, there will be no params in the url
      so we need to check if the params are present in the url
      if the params are present, we need to parse the params and set the user
      if the params are not present, we need to call annonimous login 
    */

    // params in string format
    const paramsString = decodeParams(window?.location?.search);

    const paramsParseData =
      paramsString && Object.keys(paramsString).length ? paramsString : null;

    if (paramsParseData) {
      // store the params in the local storage
      // remove the params from the url
      //window.history.replaceState({}, document.title, "/");
      // impleament similar using react router if possible

      if (paramsParseData?.token) {
        let params: AuthParamsFromMobileApp;
        params = paramsParseData;
        const paramsToStore: AuthInfoToStorage = {
          token: params.token,
          language: {
            langCode: params?.langCode,
            langId: params?.langId,
          },
          location: {
            state: {
              id: params?.location?.state?.id,
              name: params?.location?.state?.name,
            },
            district: {
              id: params?.location?.district?.id,
              name: params?.location?.district?.name,
            },
          },

          profile: params?.profile,
          appLocationPermission: params?.appLocationPermission,
        };
        storeAuthInfoToStorage(paramsToStore);
      } else {
        await loginAsGuest();
      }
    } else if (!token) {
      //call annonimous login
      await loginAsGuest();
    }

    setIsLoggedIn(true);
    setCheckingIsLoggedIn(false);
  }

  return {
    checkingIsLoggedIn,
    isLoggedIn,
  };
}
