import React, {
  useState,
  useEffect,
  useRef,
  forwardRef,
  useImperativeHandle,
} from 'react';

interface CountdownTimerProps {
  initialTime: number;
  className?: string;
  onTimeChange?: (isInRange: boolean) => void;
}

export interface CountdownTimerRef {
  reset: () => void;
}

const CountdownTimer: React.ForwardRefRenderFunction<
  CountdownTimerRef,
  CountdownTimerProps
> = ({ initialTime, className, onTimeChange }, ref) => {
  const [time, setTime] = useState(initialTime);
  const timerRef = useRef<number | null>(null);

  useEffect(() => {
    timerRef.current = window.setInterval(() => {
      setTime((prevTime) => {
        if (prevTime > 0) {
          const isInRange = prevTime >= 1 && prevTime <= initialTime;
          if (onTimeChange) {
            onTimeChange(isInRange);
          }
          return prevTime - 1;
        } else {
          clearInterval(timerRef.current!);
          if (onTimeChange) {
            onTimeChange(false);
          }
          return 0;
        }
      });
    }, 1000);

    return () => {
      if (timerRef.current) {
        clearInterval(timerRef.current);
      }
    };
  }, [onTimeChange]);

  const formatTime = (time: number): string => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(
      2,
      '0',
    )}`;
  };

  const reset = () => {
    if (timerRef.current) {
      clearInterval(timerRef.current);
    }
    setTime(initialTime);
    timerRef.current = window.setInterval(() => {
      setTime((prevTime) => {
        if (prevTime > 0) {
          const isInRange = prevTime >= 1 && prevTime <= initialTime;
          if (onTimeChange) {
            onTimeChange(isInRange);
          }
          return prevTime - 1;
        } else {
          clearInterval(timerRef.current!);
          if (onTimeChange) {
            onTimeChange(false);
          }
          return 0;
        }
      });
    }, 1000);
  };

  // Forward the reset function to the parent component using useImperativeHandle
  useImperativeHandle(ref, () => ({
    reset,
  }));

  return <div className={className}>({formatTime(time)})</div>;
};

export default forwardRef(CountdownTimer);
