import React from 'react';
import MuiTypography from '../../../ComponentV3/Typography';
import Style from './style.module.css';

interface AcknowledgeCompulsoryCardProps {
  language: { [key: string]: any }; // Language settings for localization
}

// Component to display compulsory documents required for the user
export default function CompulsoryDocCard({
  language,
}: AcknowledgeCompulsoryCardProps) {
  return (
    <div className={Style?.compulsoryDocInfoContainer}>
      <MuiTypography level="label-md">
        {language?.docRequirementTitle}
      </MuiTypography>
      <div className={Style?.requiredDocsContainer}>
        <MuiTypography level="label-md">{language?.gstOrPan}</MuiTypography>
        <MuiTypography level="label-md">
          {language?.canceledCheque}
        </MuiTypography>
        <MuiTypography level="label-md">{language?.tds}</MuiTypography>
        <MuiTypography level="label-md">{language?.aadharCard}</MuiTypography>
      </div>
    </div>
  );
}
