import React from 'react';
import { Box } from '@mui/joy';
import MuiSkeleton from '../../../MuiComponents/Skeleton';
import MuiTypography from '../../../ComponentV3/Typography';

export const postDetailsSkeleton = () => {
  return (
    <Box>
      <Box display={'flex'} flexDirection={'row'} justifyContent={'stretch'}>
        <MuiSkeleton
          sx={{ height: '36px', width: '36px', borderRadius: '8px' }}
        />
        <Box marginLeft={2}>
          <MuiSkeleton
            sx={{ height: '12px', width: '80px', borderRadius: '8px' }}
          />
          <MuiSkeleton
            sx={{
              height: '12px',
              width: '80px',
              marginTop: '8px',
              borderRadius: '8px',
            }}
          />
        </Box>
      </Box>
      <Box marginTop={2}>
        <MuiSkeleton
          sx={{ height: '12px', width: '100%', borderRadius: '8px' }}
        />
        <MuiSkeleton
          sx={{
            height: '12px',
            width: '90%',
            marginTop: '8px',
            borderRadius: '8px',
          }}
        />
      </Box>
    </Box>
  );
};

export const contentSkeleton = () => {
  return (
    <Box margin={4}>
      <MuiSkeleton
        sx={{
          height: '12px',
          borderRadius: '8px',
          width: '100%',
          marginTop: '9px',
        }}
      />
      <MuiSkeleton
        sx={{
          height: '12px',
          borderRadius: '8px',
          width: '90%',
          marginTop: '5px',
        }}
      />
      <MuiSkeleton
        sx={{
          height: '12px',
          borderRadius: '8px',
          width: '80%',
          marginTop: '5px',
        }}
      />
      <MuiSkeleton sx={{ height: '212px', width: '100%', marginTop: '23px' }} />
    </Box>
  );
};

export const actionSkeleton = () => {
  return (
    <Box marginY={2.5}>
      <Box
        padding={1}
        display={'flex'}
        justifyContent={'space-between'}
        alignItems={'center'}
      >
        {Array.from({ length: 3 }).map((_, index) => {
          return (
            <Box
              display={'flex'}
              justifyContent={'center'}
              alignItems={'center'}
            >
              <MuiSkeleton
                key={index} // It's important to add a key prop for each element
                variant="circular"
                sx={{ height: '20px', width: '20px' }}
              />
              <MuiSkeleton
                sx={{
                  height: '12px',
                  width: '32px',
                  marginLeft: '8px',
                  borderRadius: '8px',
                }}
              />
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};

export const postSkeleton = () => {
  return (
    <Box padding={2} borderRadius={8}>
      {postDetailsSkeleton()}
      <MuiSkeleton sx={{ height: '148px', marginTop: '24px' }} />
      <>
        <MuiSkeleton sx={{ height: '1px', width: '100%', marginTop: '12px' }} />
        {actionSkeleton()}
      </>
    </Box>
  );
};

export const commentSkeleton = () => {
  return (
    <Box marginY={5} width={'100%'}>
      <Box>
        <MuiTypography level={'body-sm'} textColor={'common.black'}>
          {'Comments'}
        </MuiTypography>
      </Box>
      {Array.from({ length: 3 }).map((_, index) => (
        <Box display={'flex'} marginTop={3}>
          <MuiSkeleton
            sx={{ height: '36px', width: '36px', borderRadius: '8px' }}
          />
          <Box
            display={'flex'}
            marginLeft={2}
            width={'100%'}
            flexDirection={'column'}
          >
            <MuiSkeleton
              sx={{ height: '12px', width: '80%', borderRadius: '8px' }}
            />
            <MuiSkeleton
              sx={{
                height: '12px',
                width: '100%',
                marginTop: '10px',
                borderRadius: '8px',
              }}
            />
          </Box>
        </Box>
      ))}
    </Box>
  );
};
