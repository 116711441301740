const smsTypeIdEnums = new Map();

smsTypeIdEnums.set('customMessage', 23);
smsTypeIdEnums.set('festivalMessage', 39);
smsTypeIdEnums.set('thankyouMessage', 48);
smsTypeIdEnums.set('discountMessage', 33);
smsTypeIdEnums.set('shopOpenCloseSingleDayMessage', 19);
smsTypeIdEnums.set('shopOpenCloseMultiDayMessage', 20);
smsTypeIdEnums.set('buyCropMessage', 38);
smsTypeIdEnums.set('machinSmsMessage', 42);
smsTypeIdEnums.set('seedSmsId', 28);
smsTypeIdEnums.set('pesticideSmsId', 29);
smsTypeIdEnums.set('fertilizerSmsId', 34);

export default smsTypeIdEnums;
