import React from 'react';
import ButtonV2 from '../../../ComponentV2/ButtonV2';
import Style from './style.module.css';

interface AcknowledgeActionProps {
  language: { [key: string]: any }; // Language settings for localization
  confirmSaudaRequest: () => void; // Function to confirm the Sauda request
  confirmSaudaLoading: boolean; // Loading state for the confirm Sauda request
}

export default function Action({
  language,
  confirmSaudaRequest,
  confirmSaudaLoading,
}: AcknowledgeActionProps) {
  return (
    <div
      className={Style.button}
      data-testid="onClickConfirmSaudaRequestButtonTestId"
    >
      <ButtonV2
        title={language?.confirmSaudaRequest}
        onClick={confirmSaudaRequest}
        loading={confirmSaudaLoading}
        id="onClickConfirmSaudaRequestButtonId"
      />
    </div>
  );
}
