import React, { Dispatch, SetStateAction } from 'react';
import {
  ActiveTrade,
  ExpiredTrade,
  PromotionalMediaList,
} from '../../modal/Traders/types';
import { StateDistrict } from '../../hooks/Trades/types';
import ComingSoon from './comingSoon/ComingSoon';
import NoLocation from './NoLocationPermission/NoLocation';
import Styles from './style.module.css';
import Header from './Header/Header';
import TradeFilter from './TradeFilter/TradeFilter';
import Loading from '../../ComponentV2/Loading';
import LocationModal from './LocationAccess/LocationModal';
import SelectCropModal from './SelectCropModal';
import SelectVarietyModal from './SelectVarietyModal';
import SelectLocationModal from './SelectLocationModal';
import TradeList from './TradeList';
import { Crop, CropVariety, Location } from '../../modal/Traders/types';
import Toast from '../../ComponentV2/Toast';
import MySaudaButton from './MySaudaButton';

interface TradeProps {
  serviciableLoading: boolean;
  serviciability: boolean;
  isLocationModal: boolean;
  onClickAllowLocationModal: () => void;
  otpModalVisible: Boolean;
  onClickLoginProceed: () => void;
  mobileNumberModalVisible: Boolean;
  getMobileNumber: (number: String) => void;
  getUserOtpFromInput: (number: string) => void;
  userOtp: string;
  onClickValidateOtpProceed: (number: Number) => void;
  changeMobileNumber: () => void;
  personaModalVisible: Boolean;
  onClickPersonaCard: (number: String) => () => void;
  userSelectedPersona: String;
  onClickProceedPersona: () => void;
  resendOtp: () => void;
  buyersList: ActiveTrade[];
  totalRecords: number;
  getMoreTradeListData: () => void;
  error: boolean;
  loading: boolean;
  userMobileNumber: string;
  language: { [key: string]: any };
  locationModalLoading: boolean;
  cropList: Crop[];
  cropVarietyList: CropVariety[];
  locationList: Location[];
  cropListSearchParam: string;
  setCropListSearchParam: React.Dispatch<React.SetStateAction<string>>;
  cropListPage: number;
  setCropListPage: React.Dispatch<React.SetStateAction<number>>;
  cropListLimit: number;
  setCropListLimit: React.Dispatch<React.SetStateAction<number>>;
  cropVarietyListCropId: number;
  setCropVarietyListCropId: React.Dispatch<React.SetStateAction<number>>;
  cropVarietyListSearchParam: string;
  setCropVarietyListSearchParam: React.Dispatch<React.SetStateAction<string>>;
  cropVarietyListPage: number;
  setVarietyCropListPage: React.Dispatch<React.SetStateAction<number>>;
  cropVarietyListLimit: number;
  setCropVarietyListLimit: React.Dispatch<React.SetStateAction<number>>;
  buyerLocationListSearchParam: string;
  setBuyerLocationListSearchParam: React.Dispatch<React.SetStateAction<string>>;
  buyerLocationListPage: number;
  setBuyerLocationListPage: React.Dispatch<React.SetStateAction<number>>;
  buyerLocationListLimit: number;
  setBuyerLocationListLimit: React.Dispatch<React.SetStateAction<number>>;
  cropListModal: boolean;
  handleCropListModal: () => void;
  cropVarietyListModal: boolean;
  handleCropVarietyListModal: () => void;
  buyerLocationListModal: boolean;
  handleBuyerLocationListModal: () => void;
  activeVariety: CropVariety;
  activeCrop: Crop;
  handleCropSelection: (cropId: Crop) => void;
  handleVarietySelection: (cropVarietyId: CropVariety) => void;
  handleLocationSelection: (locationId: string) => void;
  cropLoading: boolean;
  activeLocations: string[];
  cropVarietyLoading: boolean;
  locationLoading: boolean;
  handleCropSearch: (event: React.ChangeEvent<HTMLInputElement>) => void;
  locationToastPopup: boolean;
  handleLocationToastPopup: () => void;
  numberCopyToast: boolean;
  handleNumberCopyToast: (
    phoneNumber: number,
    cropName: string,
    buyerName: string,
  ) => void;
  userBlocked: boolean;
  onContactUs: () => void;
  onClickHelp: () => void;
  onClickLocationFilterApply: () => void;
  buyersListLoading: boolean;
  currentLanguageId: string;
  handleCropVarietySearch: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onCLickViewDetail: (
    id: string,
    cropName: string,
    buyerName: string,
    requestSauda: boolean,
  ) => void;
  handleLocationSearch: (event: React.ChangeEvent<HTMLInputElement>) => void;
  loadMoreLocationList: () => void;
  disableResend: boolean;
  setDisableResend: Dispatch<SetStateAction<boolean>>;
  activeTradeList: ActiveTrade[];
  expiredTradeList: ExpiredTrade[];
  onClickMySaudaCard: () => void;
  tradeListPageNumber: number;
  saudaRequestToastPopup: boolean;
  handleSaudaRequestToastPopup: () => void;
  masterStateDistrict: StateDistrict;
  onChangeLocation: () => void;
  isLocationPermission: boolean;
  promotionalMediaList: PromotionalMediaList[];
  tradeScreenAssets: { [key: string]: string };
}

export default function TraderList(props: TradeProps) {
  const {
    serviciableLoading,
    serviciability,
    isLocationModal,
    onClickAllowLocationModal,
    otpModalVisible,
    mobileNumberModalVisible,
    onClickLoginProceed,
    getMobileNumber,
    getUserOtpFromInput,
    userOtp,
    onClickValidateOtpProceed,
    changeMobileNumber,
    personaModalVisible,
    onClickPersonaCard,
    userSelectedPersona,
    onClickProceedPersona,
    resendOtp,
    buyersList,
    totalRecords,
    getMoreTradeListData,
    error,
    loading,
    userMobileNumber,
    language,
    locationModalLoading,
    cropList,
    cropVarietyList,
    locationList,
    cropListSearchParam,
    setCropListSearchParam,
    cropListPage,
    setCropListPage,
    cropListLimit,
    setCropListLimit,
    cropVarietyListCropId,
    setCropVarietyListCropId,
    cropVarietyListSearchParam,
    setCropVarietyListSearchParam,
    cropVarietyListPage,
    setVarietyCropListPage,
    cropVarietyListLimit,
    setCropVarietyListLimit,
    buyerLocationListSearchParam,
    setBuyerLocationListSearchParam,
    buyerLocationListPage,
    setBuyerLocationListPage,
    buyerLocationListLimit,
    setBuyerLocationListLimit,
    cropListModal,
    handleCropListModal,
    cropVarietyListModal,
    handleCropVarietyListModal,
    buyerLocationListModal,
    handleBuyerLocationListModal,
    activeVariety,
    activeCrop,
    handleCropSelection,
    handleVarietySelection,
    cropLoading,
    cropVarietyLoading,
    activeLocations,
    handleLocationSelection,
    locationLoading,
    handleCropSearch,
    locationToastPopup,
    handleLocationToastPopup,
    numberCopyToast,
    handleNumberCopyToast,
    userBlocked,
    onContactUs,
    onClickHelp,
    onClickLocationFilterApply,
    buyersListLoading,
    currentLanguageId,
    handleCropVarietySearch,
    onCLickViewDetail,
    handleLocationSearch,
    loadMoreLocationList,
    disableResend,
    setDisableResend,
    activeTradeList,
    expiredTradeList,
    onClickMySaudaCard,
    tradeListPageNumber,
    saudaRequestToastPopup,
    handleSaudaRequestToastPopup,
    masterStateDistrict,
    onChangeLocation,
    isLocationPermission,
    promotionalMediaList,
    tradeScreenAssets,
  } = props;

  return (
    <div className={Styles.container}>
      <Header
        language={language}
        onClickHelp={onClickHelp}
        masterStateDistrict={masterStateDistrict}
        onChangeLocation={onChangeLocation}
        whatsappIcon={tradeScreenAssets.whatsappIcon}
      />
      <Toast
        isError={true}
        message={language?.locationEnableText}
        isOpen={locationToastPopup}
      />
      <Toast
        isError={false}
        message={language?.mobileNumberCopytext}
        isOpen={numberCopyToast}
      />
      <Toast
        isError={true}
        message={language?.suadaAlreadyRequested}
        isOpen={saudaRequestToastPopup}
      />
      <SelectCropModal
        isOpen={cropListModal}
        handleCropListModal={handleCropListModal}
        cropList={cropList}
        activeCrop={activeCrop}
        handleCropSelection={handleCropSelection}
        cropLoading={cropLoading}
        cropListSearchParam={cropListSearchParam}
        handleCropSearch={handleCropSearch}
        language={language}
        searchIcon={tradeScreenAssets.searchIcon}
      />
      <SelectVarietyModal
        isOpen={cropVarietyListModal}
        handleCropVarietyListModal={handleCropVarietyListModal}
        cropVarietyList={cropVarietyList}
        activeVariety={activeVariety}
        handleVarietySelection={handleVarietySelection}
        cropVarietyLoading={cropVarietyLoading}
        activeCrop={activeCrop}
        handleCropVarietySearch={handleCropVarietySearch}
        cropVarietyListSearchParam={cropVarietyListSearchParam}
        language={language}
        searchIcon={tradeScreenAssets.searchIcon}
      />
      <SelectLocationModal
        isOpen={buyerLocationListModal}
        handleBuyerLocationListModal={handleBuyerLocationListModal}
        locationList={locationList}
        handleLocationSelection={handleLocationSelection}
        activeLocations={activeLocations}
        locationLoading={locationLoading}
        onClickLocationFilterApply={onClickLocationFilterApply}
        buyerLocationListSearchParam={buyerLocationListSearchParam}
        handleLocationSearch={handleLocationSearch}
        loadMoreLocationList={loadMoreLocationList}
        language={language}
        searchIcon={tradeScreenAssets.searchIcon}
        activeSvg={tradeScreenAssets.activeSvg}
        inactiveSvg={tradeScreenAssets.inactiveSvg}
      />
      <MySaudaButton
        onClickMySaudaButton={onClickMySaudaCard}
        title={language?.mySauda}
      />
      {serviciableLoading ? (
        <div className={Styles.loadingContainer}>
          <Loading size={30} color="grey" marginTop={20} />
        </div>
      ) : (
        <>
          {isLocationPermission ? (
            <>
              {!serviciability ? (
                <ComingSoon
                  language={language}
                  onContactUs={onContactUs}
                  tradeComingSoonImage={tradeScreenAssets.tradeComingSoon}
                />
              ) : (
                <>
                  <TradeFilter
                    language={language}
                    handleCropVarietyListModal={handleCropVarietyListModal}
                    handleCropListModal={handleCropListModal}
                    handleBuyerLocationListModal={handleBuyerLocationListModal}
                    activeCrop={activeCrop}
                    activeVariety={activeVariety}
                    activeLocations={activeLocations}
                  />
                  <TradeList
                    onCLickViewDetail={onCLickViewDetail}
                    currentLanguageId={currentLanguageId}
                    language={language}
                    buyersList={buyersList}
                    totalRecords={totalRecords}
                    getMoreTradeListData={getMoreTradeListData}
                    buyersListLoading={buyersListLoading}
                    handleNumberCopyToast={handleNumberCopyToast}
                    activeTradeList={activeTradeList}
                    expiredTradeList={expiredTradeList}
                    serviciability={serviciability}
                    tradeListPageNumber={tradeListPageNumber}
                    saudaRequestToastPopup={saudaRequestToastPopup}
                    handleSaudaRequestToastPopup={handleSaudaRequestToastPopup}
                    promotionalMediaList={promotionalMediaList}
                    noBuyerListImage={tradeScreenAssets.noBuyerList}
                    noExpiredListImage={tradeScreenAssets.noExpiredList}
                    discountCardBgSmall={tradeScreenAssets.discountCardBgSmall}
                  />
                </>
              )}
            </>
          ) : (
            <NoLocation
              language={language}
              onChangeLocation={onChangeLocation}
            />
          )}
        </>
      )}
      <LocationModal
        language={language}
        onClickAllowLocationModal={onClickAllowLocationModal}
        isLocationModal={isLocationModal}
        locationModalLoading={locationModalLoading}
        locationImage={tradeScreenAssets.locationImage}
      />
    </div>
  );
}
