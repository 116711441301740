import getDispatchDetailApi from '../../../apis/MySauda/getDispatchDetails';

import { DispatchDetailsResponse, DispatchDetailspayload } from '../types';

export default async function getDispatchDetails(
  payload: DispatchDetailspayload,
): Promise<DispatchDetailsResponse> {
  const body = {
    ...payload,
  };
  try {
    const result = await getDispatchDetailApi(body);

    if (!result.data.status) {
      throw result;
    }

    return result.data.data;
  } catch (error) {
    if (!error.message) {
      error.message = 'Something Went Wrong';
    }

    throw error;
  }
}
