import React from 'react';
import Modal from '../../../ComponentV2/Modal';
import {
  Box,
  Input,
  ListItem,
  ListItemButton,
  ListItemContent,
  ListItemDecorator,
} from '@mui/joy';
import { SearchRounded } from '@mui/icons-material';
import Typography from '../../../ComponentV3/Typography';
import InfiniteScroll from '../../../Components/InfintyScroll';
import smsTemplateType from '../../../constant/enums/smsTemplateType';

export default function SingleSelectModal({
  showSingleSelectModal,
  smsTypeId,
  singleSelectLoading,
  handleSelectedItem,
  handleModalClose,
  searchTerm,
  setSearchTerm,
  filteredItems,
  language,
  setPage,
}) {
  const getModalHeadings = () => {
    switch (Number(smsTypeId)) {
      case smsTemplateType.get('fertilizerSmsId'):
        return {
          title: language?.fertilizerName,
          searchPlaceholder: language?.searchFertilizer,
        };
      case smsTemplateType.get('pesticideSmsId'):
      case smsTemplateType.get('buyCropSmsId'):
      case smsTemplateType.get('seedSmsId'):
        return {
          title: language?.cropName,
          searchPlaceholder: language?.searchCrop,
        };
      default:
        return {
          title: language?.fertilizerName,
          searchPlaceholder: language?.searchFertilizer,
        };
    }
  };

  const modalHeadings = getModalHeadings();

  const handleSearchTermChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const maxLength = 30;
    const inputValue = e.target.value;
    if (inputValue.length > maxLength) {
      setSearchTerm(inputValue.substring(0, maxLength));
    } else {
      setSearchTerm(inputValue);
    }
  };

  const modalContent = (
    <Box padding={4} sx={{ maxWidth: '100%', overflowX: 'hidden' }}>
      <Box>
        <Input
          type="search"
          value={searchTerm}
          onChange={handleSearchTermChange}
          placeholder={modalHeadings?.searchPlaceholder}
          sx={{
            input: {
              '&:focus': {
                border: 'none',
              },
              //placeholder text style
              '&::placeholder': {
                opacity: 0.64,
                color: '#9FA6AD',
              },
            },
            width: '100%',
            marginBottom: 2,
            borderRadius: 8,
          }}
          startDecorator={<SearchRounded />}
          id={'smsTemplateSearchInputId'}
        />
      </Box>
      <InfiniteScroll
        onScrollToEnd={() => {
          console.log('Load more!====');
          setPage((page) => page + 1);
        }}
        style={{
          width: '100%',
          paddingTop: 3,
          gap: 3,
          maxHeight: 315,
          display: 'flex',
          overflowY: 'auto',
        }}
      >
        {filteredItems.map((item) => (
          <ListItem
            key={item?.id}
            sx={{
              display: 'flex',
              width: '100%',
              paddingBottom: '12px',
            }}
          >
            <ListItemButton
              onClick={() => handleSelectedItem(item)}
              id={`${item.id}ListIemButtonSMSTemplateId`}
            >
              <ListItemDecorator sx={{}}>
                <img
                  src={item?.image_url}
                  alt={item?.label}
                  style={{ height: 52, width: 52, borderRadius: 8 }}
                  loading="lazy"
                />
              </ListItemDecorator>
              <ListItemContent>
                <Typography
                  level={'body-sm'}
                  fontWeight={400}
                  color="neutral"
                  sx={{
                    paddingLeft: 3,
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    maxWidth: 284,
                  }}
                >
                  {item?.label}
                </Typography>
              </ListItemContent>
            </ListItemButton>
          </ListItem>
        ))}
      </InfiniteScroll>
    </Box>
  );

  return (
    <Modal
      isOpen={showSingleSelectModal}
      onClose={handleModalClose}
      children={modalContent}
      title={modalHeadings?.title}
      id={'singleSelectModalSMSTemplateId'}
      isCloseButtonOption={true}
    />
  );
}
