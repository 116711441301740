/**
 * The above TypeScript code defines functions to initialize, check, and interact with the MoEngage SDK
 * for web applications.
 * @param appID - The `appID` parameter in the Moengage functions refers to the unique identifier for
 * your Moengage application. This ID can be found in the settings page of your Moengage Dashboard. It
 * is used to initialize the Moengage SDK and connect your frontend application to your Moengage
 * account.
 * @param [debugMode=1] - The `debugMode` parameter in the `initializeMoengage` function is used to
 * determine whether debug logs should be enabled or not. It is a boolean value where `1` represents
 * `true` (debug logs enabled) and `0` represents `false` (debug logs disabled).
 * @param [moeDataCenter=dc_3] - The `moeDataCenter` parameter in the `initializeMoengage` function is
 * used to specify the data center for MoEngage. It is set to a default value of "dc_3" in case a
 * specific data center is not provided. This parameter is important for loading the MoEng
 */
// moengage/index.js

export const initializeMoengage = (
  appID,
  debugMode = 1,
  moeDataCenter = 'dc_3',
) => {
  //@ts-ignore
  if (!window.Moengage) {
    const scriptTag = document.createElement('script');
    scriptTag.type = 'text/javascript';
    scriptTag.text = `
    var moeDataCenter = "${moeDataCenter}"; // Replace "DC" with the actual Data center value from the above table
    var moeAppID = "${appID}"; // Replace "AppID" available in the settings page of MoEngage Dashboard.
          
    !function(e,n,i,t,a,r,o,d){if(!moeDataCenter||"{DC}"===moeDataCenter)return console.error("Data center has not been passed correctly. Please follow the SDK installation instruction carefully.");var s=e[a]=e[a]||[];if(s.invoked=0,s.initialised>0||s.invoked>0)return console.error("MoEngage Web SDK initialised multiple times. Please integrate the Web SDK only once!"),!1;e.moengage_object=a;var l={},g=function n(i){return function(){for(var n=arguments.length,t=Array(n),a=0;a<n;a++)t[a]=arguments[a];(e.moengage_q=e.moengage_q||[]).push({f:i,a:t})}},u=["track_event","add_user_attribute","add_first_name","add_last_name","add_email","add_mobile","add_user_name","add_gender","add_birthday","destroy_session","add_unique_user_id","update_unique_user_id","moe_events","call_web_push","track","location_type_attribute"],m={onsite:["getData","registerCallback"]};for(var c in u)l[u[c]]=g(u[c]);for(var v in m)for(var f in m[v])null==l[v]&&(l[v]={}),l[v][m[v][f]]=g(v+"."+m[v][f]);r=n.createElement(i),o=n.getElementsByTagName("head")[0],r.async=1,r.src=t,o.appendChild(r),e.moe=e.moe||function(){return(s.invoked=s.invoked+1,s.invoked>1)?(console.error("MoEngage Web SDK initialised multiple times. Please integrate the Web SDK only once!"),!1):(d=arguments.length<=0?void 0:arguments[0],l)},r.addEventListener("load",function(){if(d)return e[a]=e.moe(d),e[a].initialised=e[a].initialised+1||1,!0}),r.addEventListener("error",function(){return console.error("Moengage Web SDK loading failed."),!1})}(window,document,"script","https://cdn.moengage.com/release/"+moeDataCenter+"/moe_webSdk.min.latest.js","Moengage");
    Moengage = moe({
      app_id: '${appID}',
      debug_logs: ${Number(debugMode)}
    });
      `;
    document.head.appendChild(scriptTag);
  }
};

export const checkMoengage = () => {
  try {
    //@ts-ignore
    const MoengageInstance = window.Moengage;

    return MoengageInstance || false;
  } catch (error) {
    console.error('MoEngage not found or not initialized properly');
    console.log(error);
    return false;
  }
};

export const useMoengage = (appID, debugMode = 1, moeDataCenter = 'dc_3') => {
  initializeMoengage(appID, debugMode, moeDataCenter);
  const moengage = checkMoengage();

  return {
    moengage,
  };
};

export const trackEvent = (eventName, attrObj) => {
  const moengage = checkMoengage();
  if (moengage) {
    moengage.track_event(eventName, attrObj);
  }
};

export const addUniqueId = (mobileNumber) => {
  const moengage = checkMoengage();
  if (moengage) {
    moengage.add_unique_user_id(mobileNumber)?.then(() => {
      moengage.add_mobile(mobileNumber);
    });
  }
};

export const updateUniqueUserId = (mobileNumber) => {
  const moengage = checkMoengage();
  if (moengage) {
    moengage.update_unique_user_id(mobileNumber)?.then(() => {
      moengage.add_mobile(mobileNumber);
    });
  }
};

// Other MoEngage-related functions can be added here
