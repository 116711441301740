import React from 'react';
import Styles from './style.module.css';

interface PayloadType {
  isError: boolean;
  message: string;
  isOpen: boolean;
}

const Toast = ({ isError, message, isOpen }: PayloadType) => {
  return (
    <div
      className={`${isError ? Styles.error : Styles.success} ${
        isOpen ? Styles.active : Styles.inactive
      }`}
    >
      {message}
    </div>
  );
};

export default Toast;
