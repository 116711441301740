import { LocationType, StateDistrict } from '../Trades/types';
import { useEffect, useState } from 'react';
import getTradeServicibility from '../../modal/Traders/models/TraderServiceability';
import UpdateUserLocation from '../../modal/Traders/models/UpdateUserLocation';
import GetIpLocation from '../../modal/Traders/models/GetIpLocation';
import SetIpLocationLog from '../../modal/Traders/models/SetIpLocationLog';

import StateDistrictFromLatlong from '../../modal/Traders/models/StateDistrictFromLatlong';
import GetTradeList from '../../modal/Traders/models/TradeList';
import { usePlatfromData } from '../../hooks/CrossPlatformActionHandler/platformChecker';
import { useNavigate } from 'react-router-dom';
import { trackEvent } from '../../utils/moengage';
import Events from '../../constant/MoengageEvents';
import { useAuth } from '../../context/login';
import {
  getUserProfileFromStorage,
  loginAsGuest,
} from '../../modal/auth/auth.model';
import { unVerifiedRoutes } from '../../constant/unVerifiedRoutes';
import GetRequest from '../../utils/apiCaller/getRequest';
import { apiEndPoints } from '../../constant/apiEndPoints';
import PostRequest from '../../utils/apiCaller/postRequest';
import { helpLineNumber } from '../../constant/HelpLineNumber';
import { getAuthTokenFromStorage } from '../../modal/auth/auth.model';
import { decodeParams } from '../../utils/handleUrlParams';
import PlatForms from '../../constant/platFormEnums';
import homeScreenDownloadAppModalType from '../../constant/enums/downloadAppModalType';
import { getTradeScreenAssets } from '../../constant/imageUrls';
/**
 * UseLocationFetch function
 *
 * This function is designed to manage the fetching and updating of location-specific trade data
 * within a trading application. It utilizes various state-setting functions and parameters to
 * dynamically update the UI based on location changes.
 *
 * @param setMobileNumberModalVisible - Function to set the visibility of the mobile number modal.
 * @param setLocationModal - Function to set the visibility of the location modal.
 * @param activeLocations - Array of currently active locations.
 * @param tradeListPageNumber - The current page number for the trade list pagination.
 * @param setTradeListPageNumber - Function to set the current page number for the trade list pagination.
 * @param activeCrop - The currently active crop being viewed or selected.
 * @param activeVariety - The currently active crop variety being viewed or selected.
 * @param setBuyerList - Function to set the list of buyers.
 * @param buyersList - The current list of buyers.
 * @param applyLocationCheck - Boolean indicating if location filter is applied.
 * @param askForPermission - Boolean indicating if location permission is requested.
 * @param setTotalRecords - Function to set the total number of records.
 * @param totalRecords - The current total number of records.
 * @param setActiveTradeList - Function to set the list of active trades.
 * @param activeTradeList - The current list of active trades.
 * @param setExpiredTradeList - Function to set the list of expired trades.
 * @param expiredTradeList - The current list of expired trades.
 * @param setPromotionalMediaList - Function to set the list of promotional media.
 */
export default function UseLocationFetch(
  setMobileNumberModalVisible,
  setLocationModal,
  activeLocations,
  tradeListPageNumber,
  setTradeListPageNumber,
  activeCrop,
  activeVariety,
  setBuyerList,
  buyersList,
  applyLocationCheck,
  askForPermission,
  setTotalRecords,
  totalRecords,
  setActiveTradeList,
  activeTradeList,
  setExpiredTradeList,
  expiredTradeList,
  setPromotionalMediaList,
): LocationType {
  const auth = useAuth();
  const [guestLoginAttempts, setGuestLoginAttempts] = useState<number>(0);
  const platformData = usePlatfromData();
  const userLogedIn = localStorage.getItem('loged');
  const [locationToastPopup, setLocationToastPopup] = useState(false);
  const [numberCopyToast, setNumberCopyToast] = useState(false);
  const [serviciableLoading, setServiciableLoading] = useState<boolean>(true);
  const [serviciability, setServiciability] = useState<boolean>(false);
  const [isLocationPermission, setIsLocationPermission] =
    useState<boolean>(true);
  const [buyersListLoading, setBuyersListLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const startLoadTime = new Date().getTime();
  const utm = getUtm();
  const [firstTimeUser, setFirstTimeUser] = useState<boolean>(false);
  const [masterStateDistrict, setMasterStateDistrict] = useState<StateDistrict>(
    {
      master_state_id: null,
      master_district_id: null,
      master_district_name: '',
      master_state_name: '',
    },
  );
  const [locationModalLoading, setLocationModalLoading] =
    useState<boolean>(false);
  const [saudaRequestToastPopup, setSaudaRequestToastPopup] =
    useState<boolean>(false);
  const token = getAuthTokenFromStorage();
  const userProfile = getUserProfileFromStorage() || null;
  const merchantOccupationId = userProfile?.merchant_occupation_id;

  const langId = JSON.parse(localStorage.getItem('userLanguage'));
  const tradeScreenAssets = getTradeScreenAssets(langId?.langCode);

  function getUtm() {
    if (platformData.platform === 'WEB') {
      const urlSearchParams = new URLSearchParams(window?.location?.search);
      const paramsString = Object.fromEntries(urlSearchParams.entries());
      return paramsString?.utm_source;
    } else {
      const params = decodeParams(window?.location?.search);
      return params?.utm;
    }
  }

  const handleLocationToastPopup = () => {
    setLocationToastPopup(true);
    setTimeout(() => {
      setLocationToastPopup(false);
    }, 5000);
  };

  const handleSaudaRequestToastPopup = () => {
    setSaudaRequestToastPopup(true);
    setTimeout(() => {
      setSaudaRequestToastPopup(false);
    }, 5000);
  };

  const handleNumberCopyToast = (
    phoneNumber: number,
    cropName: string,
    buyerName: string,
  ) => {
    const contactButton = {
      Crop_name: cropName,
      Buyer_name: buyerName,
    };

    trackEvent(Events.onContactToSellButtonClick, contactButton);
    if (window?.innerWidth > 1100) {
      setNumberCopyToast(true);
      navigator.clipboard.writeText(JSON.stringify(phoneNumber));
      setTimeout(() => {
        setNumberCopyToast(false);
      }, 5000);
    } else {
      if (platformData.platform !== 'WEB') {
        (window as any)?.ReactNativeWebView?.postMessage(
          JSON.stringify({ type: 'call', mobileNumber: phoneNumber }),
        );
      } else {
        window.location.href = `tel:${phoneNumber}`;
      }
    }
  };

  async function getIpLocationGlobalFun() {
    try {
      const IpLocationLatLong = await GetIpLocation();
      const ipAddressResp = await SetIpLocationLog({
        lat_long: `${IpLocationLatLong?.location?.lat}, ${IpLocationLatLong?.location?.lng}`,
      });
      const stateDistrict = await StateDistrictFromLatlong({
        lat: JSON.stringify(IpLocationLatLong?.location?.lat),
        long: JSON.stringify(IpLocationLatLong?.location?.lng),
      });
      const locationAttributes = {
        Location: 'yes',
        State: stateDistrict?.reverse_geocode?.state.toLocaleLowerCase(),
        District: stateDistrict?.reverse_geocode?.district.toLocaleLowerCase(),
        userLoggedIn: JSON.parse(userLogedIn) === true ? 'Yes' : 'No',
        locationFrom: 'IP',
        ipAddress: ipAddressResp?.ip_address,
      };
      trackEvent(Events.beforeLocationAccess, locationAttributes);

      localStorage.setItem('ipAddress', ipAddressResp?.ip_address);

      setIsLocationPermission(true);
      setMasterStateDistrict(() => {
        return {
          master_district_id:
            stateDistrict?.reverse_geocode?.master_district_id,
          master_state_id: stateDistrict?.reverse_geocode?.master_state_id,
          master_district_name:
            stateDistrict?.reverse_geocode?.master_district_name,
          master_state_name: stateDistrict?.reverse_geocode?.master_state_name,
        };
      });
      await updateServiceability({
        stateDistrict: {
          stateName: stateDistrict?.reverse_geocode?.master_state_name,
          districtName: stateDistrict?.reverse_geocode?.master_district_name,
          districtId: stateDistrict?.reverse_geocode?.master_district_id,
          stateId: stateDistrict?.reverse_geocode?.master_state_id,
        },
      });
      if (userLogedIn) {
        await UpdateUserLocation({
          feature_id: 13, //mandatory
          state_id: stateDistrict?.reverse_geocode?.master_state_id, //mandatory ,
          district_id: stateDistrict?.reverse_geocode?.master_district_id,
        });
      }

      localStorage.setItem(
        'stateId',
        JSON.stringify(stateDistrict.reverse_geocode.master_state_id),
      );
      localStorage.setItem(
        'districtId',
        JSON.stringify(stateDistrict?.reverse_geocode?.master_district_id),
      );
      localStorage.setItem(
        'stateName',
        JSON.stringify(stateDistrict.reverse_geocode.master_state_name),
      );
      localStorage.setItem(
        'districtName',
        JSON.stringify(stateDistrict?.reverse_geocode?.master_district_name),
      );
      localStorage.setItem(
        'pinCode',
        JSON.stringify(stateDistrict?.reverse_geocode?.zipcode),
      );
      localStorage.setItem(
        'talukId',
        JSON.stringify(stateDistrict?.reverse_geocode?.master_taluk_id),
      );

      if (userLogedIn) {
        const submitLocationParams = {
          pincode: stateDistrict?.reverse_geocode?.zipcode,
          master_taluk_id: stateDistrict?.reverse_geocode?.master_taluk_id,
          master_district_id:
            stateDistrict?.reverse_geocode?.master_district_id,
          master_state_id: stateDistrict?.reverse_geocode?.master_state_id,
        };

        await PostRequest({
          payload: submitLocationParams,
          uri: apiEndPoints?.updateMerchantLocation,
        });
      }
      setGuestLoginAttempts(0);
    } catch (error) {
      if (error?.code === 401 || error?.code === 426) {
        if (platformData.platform === 'WEB') {
          if (unVerifiedRoutes?.guestLogin?.includes(error?.path)) {
            if (guestLoginAttempts < 3) {
              await loginAsGuest();
              setGuestLoginAttempts((lastValue) => {
                return lastValue + 1;
              });
            }
          } else {
            auth.setMobileNumberModalVisible(true);
          }
        }
      }
      console.log(error);
    }
  }

  /**
   * The function `checkWebLocation` handles user location data retrieval and storage based on login
   * status and saved preferences.
   */
  async function checkWebLocation() {
    const stateId = localStorage.getItem('stateId');
    const districtId = localStorage.getItem('districtId');
    const districtName = localStorage.getItem('districtName');
    const stateName = localStorage.getItem('stateName');
    const ipAddress = localStorage.getItem('ipAddress');

    if (stateId && stateName && districtId && districtName) {
      const locationAttributes = {
        Location: 'yes',
        State: stateName?.toLocaleLowerCase(),
        District: districtName.toLocaleLowerCase(),
        userLoggedIn: JSON.parse(userLogedIn) === true ? 'Yes' : 'No',

        locationFrom: 'IP',
        ipAddress: ipAddress,
      };
      trackEvent(Events.beforeLocationAccess, locationAttributes);
      setMasterStateDistrict(() => {
        return {
          master_district_id: JSON.parse(districtId),
          master_state_id: JSON.parse(stateId),
          master_district_name: JSON.parse(districtName),
          master_state_name: JSON.parse(stateName),
        };
      });
      await updateServiceability({
        stateDistrict: {
          districtId: districtId,
          stateId: stateId,
          districtName: districtName,
          stateName: stateName,
        },
      });
      if (userLogedIn) {
        await UpdateUserLocation({
          feature_id: 13, //mandatory
          state_id: JSON.parse(stateId), //mandatory ,
          district_id: JSON.parse(districtId),
        });
      }
    } else {
      if (userLogedIn) {
        const savedLocation = await GetRequest({
          payload: {},
          uri: apiEndPoints?.updateMerchantLocation,
        });

        if (savedLocation?.data?.data?.master_district_id) {
          const locationAttributes = {
            Location: 'yes',
            State:
              savedLocation?.data?.data?.master_state_name.toLocaleLowerCase(),
            District:
              savedLocation?.data?.data?.master_district_name.toLocaleLowerCase(),
            userLoggedIn: JSON.parse(userLogedIn) === true ? 'Yes' : 'No',

            locationFrom: 'DB',
          };
          trackEvent(Events.beforeLocationAccess, locationAttributes);
          localStorage.setItem(
            'ipAddress',
            savedLocation?.data?.data?.ip_address,
          );

          localStorage.setItem(
            'stateId',
            JSON.stringify(savedLocation?.data?.data?.master_state_id),
          );
          localStorage.setItem(
            'districtId',
            JSON.stringify(savedLocation?.data?.data?.master_district_id),
          );
          localStorage.setItem(
            'stateName',
            JSON.stringify(savedLocation?.data?.data?.master_state_name),
          );
          localStorage.setItem(
            'districtName',
            JSON.stringify(savedLocation?.data?.data?.master_district_name),
          );

          localStorage.setItem(
            'lat',
            JSON.stringify(savedLocation?.data?.data?.lat),
          );
          localStorage.setItem(
            'long',
            JSON.stringify(savedLocation?.data?.data?.long),
          );
          localStorage.setItem(
            'pinCode',
            JSON.stringify(savedLocation?.data?.data?.pincode),
          );
          localStorage.setItem(
            'talukId',
            JSON.stringify(savedLocation?.data?.data?.master_taluk_id),
          );

          setMasterStateDistrict(() => {
            return {
              master_district_id: savedLocation?.data?.data?.master_district_id,
              master_state_id: savedLocation?.data?.data?.master_state_id,
              master_district_name:
                savedLocation?.data?.data?.master_district_name,
              master_state_name: savedLocation?.data?.data?.master_state_name,
            };
          });
          await updateServiceability({
            stateDistrict: {
              districtId: savedLocation?.data?.data.master_district_id,
              stateId: savedLocation?.data?.data.master_state_id,
              districtName: savedLocation?.data?.data.master_district_name,
              stateName: savedLocation?.data?.data.master_state_name,
            },
          });
          await UpdateUserLocation({
            feature_id: 13, //mandatory
            state_id: savedLocation?.data?.data.master_state_id, //mandatory ,
            district_id: savedLocation?.data?.data.master_district_id,
          });
          setIsLocationPermission(true);
        } else {
          getIpLocationGlobalFun();
        }
      } else {
        getIpLocationGlobalFun();
      }
    }
  }

  /**
   * The function handles the error or denial of location permission and performs various actions
   * accordingly.
   */

  /**
   * The function clears the last stored location and asks for high accuracy location.
   */
  function onClickAllowLocationModal(): void {
    setMasterStateDistrict(() => {
      return {
        master_district_id: null,
        master_state_id: null,
        master_district_name: '',
        master_state_name: '',
      };
    });
  }

  /**
   * The function `updateServiceability` checks if a given state and district are trade serviceable and
   * updates the serviceability status accordingly.
   * The `stateDistrict` parameter is an object that contains information about
   * the state and district. It has the following properties:
   */
  async function updateServiceability({ stateDistrict }) {
    try {
      if (stateDistrict?.districtId) {
        const serviceResponse = await getTradeServicibility({
          supplier_district_id: stateDistrict?.districtId,
          supplier_state_id: stateDistrict?.stateId,
        });
        if (serviceResponse?.is_trade_serviceable === true) {
          setServiciability(serviceResponse.is_trade_serviceable);
          setFirstTimeUser(true);
        } else if (serviceResponse?.is_trade_serviceable === false) {
          const initailMoProperty = {
            sericeable: false,
            Loading_time: (new Date().getTime() - startLoadTime) / 1000,
            Channel: utm,
            userLoggedIn: JSON.parse(userLogedIn) === true ? 'Yes' : 'No',
          };
          trackEvent(Events.onStartCropViewPage, initailMoProperty);
          const onTradeNonServiceablePageAttributes = {
            LoadingTime: (new Date().getTime() - startLoadTime) / 1000,
            userLoggedIn: JSON.parse(userLogedIn) === true ? 'Yes' : 'No',
          };
          trackEvent(
            Events.ontradenonserviceablepage,
            onTradeNonServiceablePageAttributes,
          );
          setServiciableLoading(false);
          setServiciability(false);
        } else {
          setServiciableLoading(false);
          const onTradeNonServiceablePageAttributes = {
            LoadingTime: (new Date().getTime() - startLoadTime) / 1000,
            userLoggedIn: JSON.parse(userLogedIn) === true ? 'Yes' : 'No',
          };
          trackEvent(
            Events.ontradenonserviceablepage,
            onTradeNonServiceablePageAttributes,
          );
          if (platformData.platform === 'WEB') {
            await loginAsGuest();
          }
          setServiciability(false);
        }
        setServiciableLoading(false);
      }
      setGuestLoginAttempts(0);
    } catch (error) {
      if (error?.code === 401 || error?.code === 426) {
        if (platformData.platform === 'WEB') {
          if (unVerifiedRoutes?.guestLogin?.includes(error?.path)) {
            if (guestLoginAttempts < 3) {
              await loginAsGuest();
              setGuestLoginAttempts((lastValue) => {
                return lastValue + 1;
              });
            }
          } else {
            auth.setMobileNumberModalVisible(true);
          }
        }
      }
      console.log(error);
    }
  }

  const getMoreTradeListData = () => {
    if (totalRecords > buyersList?.length) {
      setTradeListPageNumber((lastvalue) => {
        return lastvalue + 1;
      });
      trackEvent(Events.onScrollCropViewPage, {});
    }
  };

  async function getTradeList() {
    setBuyersListLoading(true);
    try {
      const tradeListResponse = await GetTradeList({
        limit: 10,
        page_no: tradeListPageNumber,
        supplier_district_id: masterStateDistrict?.master_district_id,
        supplier_state_id: masterStateDistrict?.master_state_id,
        crop_id: activeCrop?.id,
        variety_id: activeVariety?.id,
        master_state_district_map: activeLocations?.filter(
          (element) => element !== null,
        ),
      });
      if (firstTimeUser) {
        const initailMoProperty = {
          sericeable: true,
          Loading_time: (new Date().getTime() - startLoadTime) / 1000,
          Num_card_loaded:
            tradeListResponse?.active_trade_list?.length +
            tradeListResponse?.expired_trade_list?.length,
          Channel: utm,
        };
        trackEvent(Events.onStartCropViewPage, initailMoProperty);
        const onTradeServiceablePageAttributes = {
          LoadingTime: (new Date().getTime() - startLoadTime) / 1000,
          userLoggedIn: JSON.parse(userLogedIn) === true ? 'Yes' : 'No',
        };
        trackEvent(
          Events.ontradeserviceableviewpage,
          onTradeServiceablePageAttributes,
        );
        setFirstTimeUser(false);
      }

      setBuyerList([
        ...buyersList,
        ...tradeListResponse.active_trade_list,
        ...tradeListResponse.expired_trade_list,
      ]);
      setActiveTradeList([
        ...activeTradeList,
        ...tradeListResponse.active_trade_list,
      ]);
      setExpiredTradeList([
        ...expiredTradeList,
        ...tradeListResponse.expired_trade_list,
      ]);
      tradeListResponse?.promotional_media &&
        setPromotionalMediaList(tradeListResponse?.promotional_media);
      setTotalRecords(tradeListResponse?.total_records);
      setGuestLoginAttempts(0);
    } catch (error) {
      setBuyersListLoading(false);
      if (error?.code === 401 || error?.code === 426) {
        if (platformData.platform === 'WEB') {
          if (unVerifiedRoutes?.guestLogin?.includes(error?.path)) {
            await loginAsGuest();
            setGuestLoginAttempts((lastValue) => {
              return lastValue + 1;
            });
          } else {
            auth.setMobileNumberModalVisible(true);
          }
        }
      }
      console.log(error);
    } finally {
      setBuyersListLoading(false);
    }
  }

  function userLogs() {
    setServiciableLoading(true);
    checkWebLocation();
  }

  function onContactUs() {
    const helpButtonAttributes = {
      Loading_time: (new Date().getTime() - startLoadTime) / 1000,
      userLoggedIn: JSON.parse(userLogedIn) === true ? 'Yes' : 'No',
    };
    trackEvent(
      Events.ontradenonserviceablehelpbuttonclicked,
      helpButtonAttributes,
    );
    const url = `https://wa.me/${helpLineNumber}?text=hey%2C+i+was+looking+at+the+trade+section+of+your+app.+But+could+not+find+any+potential+buyer%2C+want+to+suggest+a+few+buyers`;
    if (platformData.platform === 'WEB') {
      const win = window?.open(url, '_blank');
      win?.focus();
    } else {
      const text = `Hey, I was looking at the trade section of your app. But could not find any potential buyer, want to suggest a few buyers &phone=${helpLineNumber}`;
      const link = `whatsapp://send?text=${text}`;
      (window as any)?.ReactNativeWebView?.postMessage(
        JSON.stringify({ type: 'share', link: link }),
      );
    }
  }

  function onClickHelp() {
    trackEvent(Events.onHelpButtonClick, {});
    const url = `https://wa.me/${helpLineNumber}?text=Hey%20Farmart%20%0A%0AWas%20checking%20out%20your%20trade%20section%20on%20the%20farmart%20app.%20%0A%0AI%20need%20Help`;
    if (platformData.platform === 'WEB') {
      const win = window?.open(url, '_blank');
      win?.focus();
    } else {
      const text = `Hey Farmart\n I Was checking out your trade section on the farmart app.\n I need Help &phone=${helpLineNumber}`;
      const link = `whatsapp://send?text=${text}`;
      (window as any)?.ReactNativeWebView?.postMessage(
        JSON.stringify({ type: 'share', link: link }),
      );
    }
  }

  function trackClosedScreen() {
    const closedScreenAttributes = {
      Time_Spent: (new Date().getTime() - startLoadTime) / 1000,
    };
    trackEvent(Events.onEndCropViewPage, closedScreenAttributes);
  }

  useEffect(() => {
    userLogs();
    return trackClosedScreen;
  }, [askForPermission, token]);

  useEffect(() => {
    serviciability && getTradeList();
  }, [
    tradeListPageNumber,
    serviciability,
    activeCrop,
    activeVariety,
    applyLocationCheck,
  ]);

  function onCLickViewDetail(
    id: string,
    cropName: string,
    buyerName: string,
    requestSauda: boolean,
  ): void {
    const viewPageAttributes = {
      LoadingTime: (new Date().getTime() - startLoadTime) / 1000,
      Crop_name: cropName,
      Buyer_name: buyerName,
      requestSauda: requestSauda,
    };
    const onClickPriceCardAttributes = {
      LoadingTime: (new Date().getTime() - startLoadTime) / 1000,
    };
    if (requestSauda) {
      trackEvent(Events.onTradeListingRequestSaudaButtonClick, {
        Crop_name: cropName,
        Buyer_name: buyerName,
        userLoggedIn: JSON.parse(userLogedIn) === true ? 'Yes' : 'No',
      });
    }
    trackEvent(Events.onTradeDetailviewpage, viewPageAttributes);
    trackEvent(Events.onclickpricecard, onClickPriceCardAttributes);
    navigate(`/trades/${id}`, { state: { requestSauda: requestSauda } });
  }

  function onClickMySaudaCard() {
    trackEvent(Events?.onMySaudasButtonClick, {});
    if (platformData?.platform === PlatForms.get('website')) {
      navigate('/trades/mySauda');
    } else {
      (window as any)?.ReactNativeWebView?.postMessage(
        JSON.stringify({
          type: 'nativeNavigation',
          navigationConstant: {
            featureId: homeScreenDownloadAppModalType.get('mySaudaList'),
            merchantOccupationId,
          },
        }),
      );
      navigate('/trades/mySauda');
    }
  }

  function onChangeLocation() {
    setLocationModal(true);
    trackEvent(Events.onChangeTradeLocationButton, {});
    trackEvent(Events.onLocationAccessPopup, {});
    setLocationModalLoading(false);
  }

  return {
    onClickAllowLocationModal,
    serviciableLoading,
    serviciability,
    getMoreTradeListData,
    locationModalLoading,
    onCLickViewDetail,
    locationToastPopup,
    handleLocationToastPopup,
    numberCopyToast,
    handleNumberCopyToast,
    onContactUs,
    onClickHelp,
    buyersListLoading,
    saudaRequestToastPopup,
    handleSaudaRequestToastPopup,
    onClickMySaudaCard,
    masterStateDistrict,
    onChangeLocation,
    isLocationPermission,
    tradeScreenAssets,
  };
}
