import React, { Suspense } from 'react';

import Styles from './style.module.css';

import { SaudaDetailHooksTypes } from '../../hooks/Trades/types';
import Toast from '../../ComponentV2/Toast';
import saudaStatusEnums from '../../constant/saudaStatusEnum';
import ErrorMessageToast from '../../ComponentV2/ErrorMessageToast';
import { LoginView, useAuth } from '../../context/login';
import DocumentViewer from '../../ComponentsV3/UploadDocument/viewer/DocumentViewer';
import { DocumentProvider } from '../../ComponentsV3/UploadDocument/DocumentContext';
import { Box } from '@mui/joy';
import MuiSkeleton from '../../MuiComponents/Skeleton';

// Lazy load components
const Header = React.lazy(() => import('./Header'));
const SaudaStatusCard = React.lazy(() => import('./SaudaStatusCard'));
const BuyerInfo = React.lazy(() => import('./BuyerInfo'));
const QualityParameters = React.lazy(() => import('./QualityParameters'));
const OrderFulfilment = React.lazy(() => import('./OrderFulfilment'));
const HelpSection = React.lazy(() => import('./HelpSection'));
const OrderDetails = React.lazy(() => import('./OrderDetails'));
const BottomButton = React.lazy(() => import('./BottomButton'));

const TruckDetailModal = React.lazy(() => import('./TruckDetailModal'));
const RejectPOModal = React.lazy(() => import('./RejectPOModal'));
const AcceptPOModal = React.lazy(() => import('./AcceptPOModal'));
const TruckDetails = React.lazy(() => import('./TruckDetails'));
const CancelSaudaModal = React.lazy(() => import('./CancelSaudaModal'));
const PaymentTermsModal = React.lazy(() => import('./PaymentTermsModal'));

const BuyerDetails = (props: SaudaDetailHooksTypes) => {
  const {
    onClickBack,
    onClickHelp,
    onCallNow,
    numberCopyToast,
    isTruckModalVisible,
    saudaDetailData,
    onClickViewPo,
    truckDetailData,
    onCopyUtr,
    utrCopyToast,
    saudaPageLoading,
    saudaTruckLoading,
    isPaymentTermInfoVisible,
    onClickPaymentTermInfo,
    onDownloadPdf,
    showCancelSaudaModal,
    toggleCancelSaudaModal,
    showAcceptPOModal,
    toggleAcceptPO,
    acceptPODisabled,
    onClickTermsAndCondition,
    showRejectPOModal,
    toggleRejectPOModal,
    updatepoStatus,
    updatePoLoading,
    language,
    brokeragePopup,
    handleBrokeragePopup,
    financingPopup,
    handleFinancingPopup,
    netPopup,
    handleNetPopup,
    onClickPaymentModalGotItButton,
    isError,
    setIsError,
    onClickReUploadTruckDetailsCard,
    onClickTruckNumberTruckDetailsCard,
    onClickCreateDispatch,
    onPressClosePreview,
    preViewDocument,
    downloadPdfModal,
    toggleDownloadPdfModal,
    selectedDocument,
    saudaDetailsAssets,
  } = props;
  const authProps = useAuth();

  return (
    <>
      {selectedDocument ? (
        <DocumentProvider
          initialDocuments={[
            {
              uri: selectedDocument,
              fileName: '',
            },
          ]}
          //onPressClosePreview={onPressClosePreview}
          label={'Deduction Report'}
          deleteVisible={false}
          downloadVisible={true}
          onDownload={onDownloadPdf}
          downloadModal={downloadPdfModal}
          onCloseDownloadModal={toggleDownloadPdfModal}
          onPressClosePreview={onPressClosePreview}
        >
          <DocumentViewer />
        </DocumentProvider>
      ) : (
        <div className={Styles.container}>
          <Suspense
            fallback={
              <MuiSkeleton
                sx={{
                  height: 60,
                  width: '100%',
                  marginBottom: 1,
                }}
              />
            }
          >
            <Header
              onClickBack={onClickBack}
              saudaDetailData={saudaDetailData}
              language={language}
              saudaPageLoading={saudaPageLoading}
            />
          </Suspense>
          <Toast
            isError={false}
            message={language?.mobileNumberCopytext}
            isOpen={numberCopyToast}
          />

          <Toast
            isError={false}
            message={language?.utrCopyPopup}
            isOpen={utrCopyToast}
          />

          {saudaPageLoading ? (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              marginTop={'53px'}
              flexDirection={'column'}
              gap={3}
            >
              <MuiSkeleton sx={{ width: '100%', height: 130 }} itemNumber={5} />
            </Box>
          ) : (
            <div className={Styles.restOfContainer}>
              <Suspense
                fallback={
                  <MuiSkeleton
                    sx={{
                      height: 130,
                      width: '100%',

                      marginBottom: 4,
                    }}
                  />
                }
              >
                <SaudaStatusCard
                  saudaDetailData={saudaDetailData}
                  toggleAcceptPO={toggleAcceptPO}
                  toggleRejectPOModal={toggleRejectPOModal}
                  language={language}
                  onClickCreateDispatch={onClickCreateDispatch}
                  saudaDetailImage={saudaDetailsAssets.saudaDetailsImage}
                  onTimeIcon={saudaDetailsAssets.onTimeIcon}
                />
              </Suspense>
              <Suspense
                fallback={
                  <MuiSkeleton
                    sx={{
                      height: 130,
                      width: '100%',

                      marginBottom: 4,
                    }}
                  />
                }
              >
                <OrderDetails
                  onDownloadPdf={onDownloadPdf}
                  saudaDetailData={saudaDetailData}
                  onClickPaymentTermInfo={onClickPaymentTermInfo}
                  language={language}
                />
              </Suspense>
              <Suspense
                fallback={
                  <MuiSkeleton
                    sx={{
                      height: 130,
                      width: '100%',
                      marginBottom: 4,
                    }}
                  />
                }
              >
                <TruckDetails
                  saudaDetailData={saudaDetailData}
                  onClickViewPo={onClickViewPo}
                  language={language}
                  onClickReUploadTruckDetailsCard={
                    onClickReUploadTruckDetailsCard
                  }
                  onClickTruckNumberTruckDetailsCard={
                    onClickTruckNumberTruckDetailsCard
                  }
                  onClickCreateDispatch={onClickCreateDispatch}
                />
              </Suspense>
              <Suspense
                fallback={
                  <MuiSkeleton
                    sx={{
                      height: 130,
                      width: '100%',
                      marginBottom: 4,
                    }}
                  />
                }
              >
                <BuyerInfo
                  saudaDetailData={saudaDetailData}
                  language={language}
                />
              </Suspense>
              <Suspense
                fallback={
                  <MuiSkeleton
                    sx={{
                      height: 130,
                      width: '100%',
                      marginBottom: 4,
                    }}
                  />
                }
              >
                <HelpSection
                  onClickHelp={onClickHelp}
                  onCallNow={onCallNow}
                  saudaDetailData={saudaDetailData}
                  language={language}
                  whatsappIcon={saudaDetailsAssets.whatsappIcon}
                />
              </Suspense>
              <Suspense
                fallback={
                  <MuiSkeleton
                    sx={{
                      height: 130,
                      width: '100%',
                      marginBottom: 4,
                    }}
                  />
                }
              >
                <QualityParameters
                  saudaDetailData={saudaDetailData}
                  language={language}
                />
              </Suspense>
              <Suspense
                fallback={
                  <MuiSkeleton
                    sx={{
                      height: 130,
                      width: '100%',
                      marginBottom: 4,
                    }}
                  />
                }
              >
                <OrderFulfilment
                  saudaDetailData={saudaDetailData}
                  language={language}
                />
              </Suspense>
              {saudaDetailData?.sauda_status_id ===
                saudaStatusEnums.get('saudaInReview') && (
                <BottomButton
                  toggleCancelSaudaModal={toggleCancelSaudaModal}
                  language={language}
                />
              )}
              <Suspense
                fallback={
                  <MuiSkeleton
                    sx={{
                      height: 130,
                      width: '100%',
                      marginBottom: 4,
                    }}
                  />
                }
              >
                <TruckDetailModal
                  preViewDocument={preViewDocument}
                  onCopyUtr={onCopyUtr}
                  truckDetailData={truckDetailData}
                  onClickViewPo={onClickViewPo}
                  saudaDetailData={saudaDetailData}
                  isTruckModalVisible={isTruckModalVisible}
                  saudaTruckLoading={saudaTruckLoading}
                  language={language}
                  onClickPaymentModalGotItButton={
                    onClickPaymentModalGotItButton
                  }
                  onTimeIcon={saudaDetailsAssets.onTimeIcon}
                  magicIcon={saudaDetailsAssets.magicIcon}
                />
              </Suspense>
              <Suspense
                fallback={
                  <MuiSkeleton
                    sx={{
                      height: 130,
                      width: '100%',
                      marginBottom: 4,
                    }}
                  />
                }
              >
                <PaymentTermsModal
                  brokeragePopup={brokeragePopup}
                  handleBrokeragePopup={handleBrokeragePopup}
                  financingPopup={financingPopup}
                  handleFinancingPopup={handleFinancingPopup}
                  netPopup={netPopup}
                  handleNetPopup={handleNetPopup}
                  saudaDetailData={saudaDetailData}
                  language={language}
                  isPaymentTermInfoVisible={isPaymentTermInfoVisible}
                  onClickPaymentTermInfo={onClickPaymentTermInfo}
                  magicIcon={saudaDetailsAssets.magicIcon}
                />
              </Suspense>
              <Suspense
                fallback={
                  <MuiSkeleton
                    sx={{
                      height: 130,
                      width: '100%',
                      marginBottom: 4,
                    }}
                  />
                }
              >
                <AcceptPOModal
                  showAcceptPOModal={showAcceptPOModal}
                  toggleAcceptPO={toggleAcceptPO}
                  language={language}
                  acceptPODisabled={acceptPODisabled}
                  onClickTermsAndCondition={onClickTermsAndCondition}
                  saudaDetailData={saudaDetailData}
                  onDownloadPdf={onDownloadPdf}
                  updatepoStatus={updatepoStatus}
                  updatePoLoading={updatePoLoading}
                  activeCheckboxIcon={saudaDetailsAssets.activeSvg}
                  inactiveCheckboxIcon={saudaDetailsAssets.inactiveSvg}
                />
              </Suspense>
              <Suspense
                fallback={
                  <MuiSkeleton
                    sx={{
                      height: 130,
                      width: '100%',
                      marginBottom: 4,
                    }}
                  />
                }
              >
                <RejectPOModal
                  saudaDetailData={saudaDetailData}
                  language={language}
                  showRejectPOModal={showRejectPOModal}
                  toggleRejectPOModal={toggleRejectPOModal}
                  onDownloadPdf={onDownloadPdf}
                  onClickPaymentTermInfo={onClickPaymentTermInfo}
                  updatepoStatus={updatepoStatus}
                  updatePoLoading={updatePoLoading}
                />
              </Suspense>
              <Suspense
                fallback={
                  <MuiSkeleton
                    sx={{
                      height: 130,
                      width: '100%',
                      marginBottom: 4,
                    }}
                  />
                }
              >
                <CancelSaudaModal
                  saudaDetailData={saudaDetailData}
                  language={language}
                  updatepoStatus={updatepoStatus}
                  updatePoLoading={updatePoLoading}
                  showCancelSaudaModal={showCancelSaudaModal}
                  toggleCancelSaudaModal={toggleCancelSaudaModal}
                />
              </Suspense>
            </div>
          )}
        </div>
      )}
      <ErrorMessageToast
        message={language?.errorMessage}
        isError={isError}
        setIsError={setIsError}
        ErrorToastType={true}
      />
      <LoginView authProps={{ ...authProps }} />
    </>
  );
};

export default BuyerDetails;
