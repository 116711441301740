import * as AppLanguages from '../constant/appLanguages';
//language will be added here
const languages = {
  [AppLanguages.appLanguagesIndex.hindi]: {
    loginText: 'लॉग इन करें',
    loginHeading: 'अपना 10 अंकों का मोबाइल नंबर दर्ज करें',
    numerIntputTitle: 'यहां नंबर दर्ज करें',
    termsAndCondtions:
      'लॉग इन से आप {(गोपनीयता नीति)}, {(नियमों और शर्तों)} को स्वीकार करते है',
    help: 'सहायता',
    selectLanguageButton: 'आगे बढ़ें',
    errorMobileNumber: 'कृपा सही मोबाइल नंबर डालें',
    privacyPolicy: '(गोपनीयता नीति)',
    termAndCondition: '(नियमों और शर्तों)',
    changeLanguage: 'भाषा बदलें',
    proceed: 'आगे बढ़ें',
    selectLanguage: 'भाषा चुने',
    blockUserMessage:
      'क्षमा करें, आपको वर्तमान में Farmart ऐप्लिकेशन तक पहुँचने का अनुमति प्रदान नहीं की गई है। यदि आपको लगता है कि यह एक त्रुटि है या आपको और सहायता की आवश्यकता है, तो कृपया हमसे संपर्क करें - ',
  },
  [AppLanguages.appLanguagesIndex.english]: {
    loginText: 'Login Now',
    loginHeading: 'Enter your 10 digits mobile number',
    numerIntputTitle: 'Enter number here',
    termsAndCondtions:
      'By Login you accept our {(Privacy policy)}, {(Terms of Conditions)}.',
    help: 'Help',
    selectLanguageButton: 'Proceed',
    errorMobileNumber: 'Please enter correct mobile number',
    privacyPolicy: '(Privacy policy)',
    termAndCondition: '(Terms of Conditions)',
    changeLanguage: 'Change Language',
    proceed: 'Proceed',
    selectLanguage: 'Select language',
    blockUserMessage:
      'Sorry, you are currently blocked from accessing the Farmart app. If you believe this is an error or need further assistance, please contact our support team - .',
  },
};

export default (language) => languages[language];
