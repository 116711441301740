import CommunityHome from '../viewmodal/community/communityViewModel';
import PostDetails from '../viewmodal/postDetails/postDetailsViewModel';
import BuyerDetails from '../viewmodal/buyerdetails/buyerDetails';
import Trader from '../viewmodal/trader/trader';
import SaudaAcknowledgement from '../viewmodal/saudaAcknowledgement/saudaAcknowledgement';
import MySauda from '../viewmodal/mySauda/mySauda';
import SaudaDetail from '../viewmodal/saudaDetails/saudaDetails';
import documentUpload from '../viewmodal/documentUpload/documentUpload'; //ToDo: check and removeimport CreateDispatch from "../viewmodal/createDispatch";
import React from 'react';
import { Route } from 'react-router-dom';
import DocumentUpload from '../viewmodal/documentUpload/documentUpload';
import CreateDispatch from '../viewmodal/createDispatch/createDispatch';
import LoginScreen from '../viewmodal/Login/loginScreen';
import OtpScreen from '../viewmodal/OTPScreen/otpScreen';
import UserRegistration from '../viewmodal/UserRegistration/userRegistration';
import HomeScreen from '../viewmodal/homeScreen/homeScreen';
import FaqQuestions from '../viewmodal/faqQuestions/faqQuestions';
import FaqAnswers from '../viewmodal/faqAnswers/faqAnswers';
import EditProfile from '../viewmodal/editProfile/editProfile';
import RakeList from '../viewmodal/rakeList/rakeList';
import PinCode from '../viewmodal/Pincode/pinCode';
import RakeDetail from '../viewmodal/RakeDetail/RakeDetail';
import AddFarmerScreen from '../viewmodal/AddFarmer/addFarmerScreen';
import AcknowledgeMentScreen from '../viewmodal/AcknowledgeMentScreen/acknowledgeMentScreen';
import MyFarmers from '../viewmodal/MyFarmer/myFarmerScreen';
import MyProfileMain from '../viewmodal/MyProfle/MyProfile';
import SmsHistory from '../viewmodal/SmsHistoryScreen/smsHistoryScreen';
import SmsHistoryScreenDetail from '../viewmodal/SmsHistoryScreenDetailScreen/smsHistoryDetailScreen';
import SmsFarmers from '../viewmodal/SmsFarmers/smsFarmersScreen';
import SmsAcknowledgeMentScreen from '../viewmodal/SmsAcknowledgeMentScreen/smsAcknowledgeMentScreen';
import SMSLandingScreen from '../viewmodal/SMSLandingScreen/SMSLandingScreen';
import SMSTemplate from '../viewmodal/SMSTemplateScreen/SMSTemplateScreen';

interface Route {
  path: string;
  component: React.ComponentType;
  exact: boolean;
  routes?: Route[];
}

export function getApplicationRoutes(): Route[] {
  const applicationRoutes: Route[] = [
    {
      path: '/',
      component: HomeScreen,
      exact: true,
    },
    {
      path: '/community',
      component: CommunityHome,
      exact: true,
    },
    {
      path: '/trades',
      component: Trader,
      exact: true,
    },
    {
      path: '/trades/:id',
      component: BuyerDetails,
      exact: true,
    },
    {
      path: '/login',
      component: LoginScreen,
      exact: true,
    },
    {
      path: '/userRegistration',
      component: UserRegistration,
      exact: true,
    },
    {
      path: '/otpSubmit',
      component: OtpScreen,
      exact: true,
    },
    {
      path: '/community/:id/:heading',
      component: PostDetails,
      exact: true,
    },
    {
      path: '/trades/mySauda/confirm_sauda',
      component: SaudaAcknowledgement,
      exact: true,
    },
    {
      path: '/trades/mySauda',
      component: MySauda,
      exact: true,
    },
    {
      path: '/trades/mySauda/:id/:modalName?/:modalId?',
      component: SaudaDetail,
      exact: true,
    },
    {
      path: '/trades/mySauda/createDispatch/:dispatchId?/:saudaCreationType?',
      component: CreateDispatch,
      exact: true,
    },
    {
      path: '/trades/uploadDocument',
      component: DocumentUpload,
      exact: true,
    },
    {
      path: '/rake',
      component: RakeList,
      exact: true,
    },
    {
      path: '/manualLocation',
      component: PinCode,
      exact: true,
    },
    {
      path: '/faq/questions',
      component: FaqQuestions,
      exact: true,
    },
    {
      path: '/faq/answer/:questionId',
      component: FaqAnswers,
      exact: true,
    },
    {
      path: '/rakeDetail',
      component: RakeDetail,
      exact: true,
    },
    {
      path: '/addFarmerScreen',
      component: AddFarmerScreen,
      exact: true,
    },
    {
      path: '/success',
      component: AcknowledgeMentScreen,
      exact: true,
    },
    {
      path: '/myFarmers',
      component: MyFarmers,
      exact: true,
    },
    {
      path: '/myProfile',
      component: MyProfileMain,
      exact: true,
    },
    {
      path: '/editProfile',
      component: EditProfile,
      exact: true,
    },
    {
      path: '/smsHistory',
      component: SmsHistory,
      exact: true,
    },
    {
      path: '/smsHistoryDetail/:smsId',
      component: SmsHistoryScreenDetail,
      exact: true,
    },
    {
      path: '/smsFarmers/:smsId',
      component: SmsFarmers,
      exact: true,
    },
    {
      path: '/smsSuccess',
      component: SmsAcknowledgeMentScreen,
      exact: true,
    },
    {
      path: '/shopPromotion',
      component: SMSLandingScreen,
      exact: true,
    },
    {
      path: '/smsTemplate/:smsTypeId',
      component: SMSTemplate,
      exact: true,
    },
  ];

  // push not found route at the end to handle this in router
  applicationRoutes.push(getNotFoundRoute());

  return applicationRoutes;
}

export default getApplicationRoutes();

function getNotFoundRoute(): Route {
  return {
    path: '*',
    component: CommunityHome,
    exact: false,
  };
}
