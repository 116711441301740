import React, { ReactNode, useEffect } from 'react';
import MuiTypography from '../../ComponentV3/Typography';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import Styles from './style.module.css';

interface ModalProps {
  isOpen: boolean;
  onClose?: () => void;
  children: ReactNode;
  title: string;
  isCloseButtonOption?: boolean;
  id?: string;
}

const Modal = (props: ModalProps) => {
  const { isOpen, onClose, children, title, isCloseButtonOption, id } = props;

  useEffect(() => {
    // Add or remove a class to body when the modal is open or closed
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }

    return () => {
      // Cleanup: restore overflow to auto when the component unmounts
      document.body.style.overflow = 'auto';
    };
  }, [isOpen]);

  return (
    isOpen && (
      <div className={Styles.mainContainer} id={'modalComponentVisible'}>
        <div className={Styles.modalOverlay} />
        <div className={`${Styles.modal} ${isOpen ? Styles.modalOpen : ''}`}>
          <div className={Styles.modalContent}>
            <div className={Styles.header}>
              <MuiTypography level={'title-md'}>{title}</MuiTypography>
              {isCloseButtonOption && (
                <button
                  id={`${id}CloseButton`}
                  className={Styles.closeButton}
                  onClick={onClose}
                >
                  <CloseRoundedIcon />
                </button>
              )}
            </div>

            <div className={Styles.children}>{children}</div>
          </div>
        </div>
      </div>
    )
  );
};

export default Modal;
