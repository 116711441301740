import React from 'react';
import Modal from '../../../../ComponentV2/Modal';
import Styles from './style.module.css';
import PhoneInput from '../../../../ComponentV2/MobileNumberInput';
import Button from '../../../../ComponentV2/Button';
import { PrivacyPolicy } from '../../../../constant/PrivacyPolicyUrl';

export default function Login({
  onClickLoginProceed,
  mobileNumberModalVisible,
  getMobileNumber,
  error,
  loading,
  language,
  userBlocked,
  mobileNumber,
}) {
  const renderTextWithColors = (text) => {
    const splitText = text.split(/{|}/);

    return splitText.map((item, index) => {
      if (item.startsWith('(') && item.endsWith(')')) {
        return (
          <a
            key={index}
            href={PrivacyPolicy}
            className={Styles.highlightedText}
            id={`TermsAndCondition${index}`}
          >
            {item}
          </a>
        );
      }
      return (
        <label key={index} className={Styles.termAndCondition}>
          {item}
        </label>
      );
    });
  };
  const login = (
    <div className={Styles.loginContainer}>
      <PhoneInput
        onChange={getMobileNumber}
        error={error}
        headingTitle={language?.loginHeading}
        placeholderText={language?.numerIntputTitle}
        errorMessage={language?.errorMobileNumber}
        userBlocked={userBlocked}
        blockUserMessage={language?.blockUserMessage}
        language={language}
        id="MobileNumberInput"
        defaultMobileNumber={mobileNumber}
      />
      <div className={Styles.messageText}>
        {renderTextWithColors(language?.termsAndCondtions)}
      </div>
      <div className={Styles.proceedButtonContainer}>
        <Button
          title={language?.selectLanguageButton}
          onClick={onClickLoginProceed}
          loading={loading}
          id={'MobileNumberInputModalProceedButton'}
        />
      </div>
    </div>
  );
  return (
    <Modal
      isOpen={mobileNumberModalVisible}
      onClose={() => {}}
      children={login}
      title={language?.loginText}
      id="MobileNumberInputModal"
    />
  );
}
