/* export { default as getCommunityFeed } from "./models/getCommunityFeed"; */
//use above as example

/* export * from "./types"; */
import fetchStateDistrict from '../../../apis/Trader/fetchStateDistrict';

import { StateDistrictResponse } from '../types';

interface PayloadType {
  lat?: string;
  long?: string;
}

export default async function getStateDistrict(
  payload: PayloadType,
): Promise<StateDistrictResponse> {
  const body = {
    ...payload,
  };
  try {
    const result = await fetchStateDistrict(body);
    if (!result.data.status) {
      throw result;
    }
    return result.data.data;
  } catch (error) {
    if (!error.message) {
      error.message = 'Something Went Wrong';
    }

    throw error;
  }
}
