import React from 'react';
import Styles from './style.module.css';
import CurrencyRupeeRoundedIcon from '@mui/icons-material/CurrencyRupeeRounded';
import { TradePriceBreakupData } from '../../../modal/Traders/types';
interface TradeDetailProps {
  PriceBreakup: TradePriceBreakupData;
  language?: { [key: string]: any };
}
const PaymentTerms = (props: TradeDetailProps) => {
  const { PriceBreakup, language } = props;

  const renderTextWithColors = (text: string) => {
    const splitText = text.split(/{|}/);

    return splitText.map((item, index) => {
      if (item.startsWith('(') && item.endsWith(')')) {
        return (
          <span key={'buyerDetailPaymentTermText' + index}>
            {PriceBreakup?.repayment_time?.value}{' '}
            {PriceBreakup?.repayment_time?.unit}
          </span>
        );
      }
      return <>{item}</>;
    });
  };
  return (
    <div className={Styles.wrapper}>
      <div className={Styles.titleBar}>
        <div className={Styles.iconWrapper} data-testid="rupee-icon">
          <CurrencyRupeeRoundedIcon
            sx={{ color: '#0E1E40', height: 20, width: 20 }}
          />
        </div>
        {<div className={Styles.headingText}>{language?.paymentTerms}</div>}
      </div>
      <div className={Styles.detailsWrapper}>
        {PriceBreakup?.repayment_time && (
          <div className={Styles.fulfilmentDayText}>
            {PriceBreakup?.repayment_time?.value}{' '}
            {PriceBreakup?.repayment_time?.unit}
          </div>
        )}
        <div className={Styles.disclaimerText}>
          {renderTextWithColors(language?.paymentTermsNote)}
        </div>
      </div>
    </div>
  );
};

export default PaymentTerms;
