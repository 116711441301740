import React from 'react';
import style from './style.module.css';
import PostTimeCalculation from '../../../constant/postTimeCalculation';
import VerifiedRoundedIcon from '@mui/icons-material/VerifiedRounded';

interface CardHeaderProp {
  name: string;
  state: string;
  profilepic: string;
  postedAgo: string;
  page: boolean;
  verifiedPost: boolean;
  companyProfileIcon: string;
}

const CardHeader = function (props: CardHeaderProp) {
  const {
    name,
    profilepic,
    postedAgo,
    verifiedPost,
    page,
    companyProfileIcon,
  } = props;
  return (
    <div className={style.profileContainer}>
      <div className={style.profileImageContainer}>
        <img
          src={profilepic ? profilepic : companyProfileIcon}
          alt="Profile Or CompanyPic"
          className={page ? style.pageProfileImage : style.profileImage}
          loading="lazy"
        />
      </div>
      <div className={style.profileInfoContainer}>
        <div className={style.nameHeadingContainer}>
          <div className={style.verifiedNameContiner}>
            <span className={style.nameTypography}>{name}</span>
            {verifiedPost && (
              <VerifiedRoundedIcon
                sx={{ height: 20, width: 20, fill: '#4A9C52' }}
              />
            )}
          </div>
        </div>
        <span className={style.nameSubHeadingTypograpghy}>
          {PostTimeCalculation({ utcTimestamp: postedAgo })}
        </span>
      </div>
    </div>
  );
};

export default CardHeader;
