import React from 'react';
import Modal from '../../../ComponentV2/Modal';
import Styles from './style.module.css';
import MuiButton from '../../../ComponentsV3/MuiButton';
import { Box, FormControl, FormLabel } from '@mui/joy';
import {
  ErrorProps,
  ManualModalInputProps,
} from './../../../hooks/AddFarmer/types'; /* The `interface AddFarmerManualProps` is defining the props
that the `AddFarmerModal` component expects to receive.
Here is a breakdown of each property: */

interface AddFarmerManualProps {
  language: { [key: string]: string };
  onChangeInput: (value: string, type: string) => void;
  onToggleManualModal: () => void;
  isModalVisible: boolean;
  error: boolean | ErrorProps;
  farmerDetails: ManualModalInputProps;
  saveFarmerManual: () => void;
  savingLoading: boolean;
}

export default function AddFarmerModal(props: AddFarmerManualProps) {
  /* The code snippet `const { farmerDetails, isModalVisible, language, onChangeInput,
onToggleManualModal, saveFarmerManual, savingLoading } = props;` is using object destructuring to
extract specific properties from the `props` object passed to the `AddFarmerModal` component. */
  const {
    farmerDetails,
    isModalVisible,
    language,
    onChangeInput,
    onToggleManualModal,
    saveFarmerManual,
    savingLoading,
  } = props;
  const AddFarmerInputs = (
    <>
      <Box
        display={'flex'}
        flexDirection={'column'}
        margin={'16px 16px 20px 16px'}
      >
        <Box display={'flex'} flexDirection={'column'} gap={'20px'}>
          <Box flexDirection={'column'} display={'flex'}>
            <Box display={'flex'} flex={1} flexDirection={'column'}>
              <FormControl>
                <FormLabel>{language.farmerNameInputTitle}</FormLabel>
                <Box display={'flex'} flex={1}>
                  <input
                    placeholder={language.farmerNameInputPlaceHolder}
                    className={Styles.inputBoxContainer}
                    value={farmerDetails?.farmerName}
                    onChange={(event) =>
                      onChangeInput(event?.target?.value, 'farmerName')
                    }
                  />
                </Box>
              </FormControl>
            </Box>
          </Box>
          <Box flexDirection={'column'} display={'flex'}>
            <Box display={'flex'} flex={1} flexDirection={'column'}>
              <FormControl error>
                <FormLabel>{language.farmerNumberInputTitle}</FormLabel>
                <Box display={'flex'} flex={1}>
                  <input
                    type="tel"
                    placeholder={language.farmerNumberInputPlaceHolder}
                    className={Styles.inputBoxContainer}
                    value={farmerDetails?.farmerMobile}
                    onChange={(event) =>
                      onChangeInput(event?.target?.value, 'farmerMobile')
                    }
                    maxLength={10}
                  />
                </Box>
              </FormControl>
            </Box>
          </Box>
        </Box>
        <Box display={'flex'} marginTop={'28px'}>
          <MuiButton
            variant="solid"
            size="lg"
            color="primary"
            disabled={
              farmerDetails?.farmerName.length < 1 ||
              farmerDetails?.farmerMobile.length < 10
            }
            onClick={saveFarmerManual}
            loading={savingLoading}
          >
            Proceed
          </MuiButton>
        </Box>
      </Box>
    </>
  );
  return (
    <Modal
      isOpen={isModalVisible}
      onClose={onToggleManualModal}
      // eslint-disable-next-line react/no-children-prop
      children={AddFarmerInputs}
      title={language.manualModalHeading}
      id="MobileNumberInputModal"
      isCloseButtonOption={true}
    />
  );
}
