import React, { Dispatch, SetStateAction } from 'react';
import Style from './style.module.css';
import EmptyState from './EmptyState';
import FileList from './FileList';
import FileListAlert from './FileListAlert';
import DocumentUploadHeader from './DocumentUploadHeader';
import DocumentUploadFooter from './DocumentUploadFooter';
import DocumentUploadAlert from './DocumentUploadAlert';
import DocumentViewer from '../../ComponentsV3/UploadDocument/viewer/DocumentViewer';
import { DocumentProvider } from '../../ComponentsV3/UploadDocument/DocumentContext';
import Toast from '../../ComponentV2/Toast';
import { PreviewObject } from '../../hooks/DocumentUpload/types';
import CancelUploadModal from './CancelUploadModal';
import DocumentType from '../../constant/enums/docType';
import { LoginView, useAuth } from '../../context/login';
import { SaudaDetailResp } from '../../modal/Traders/types';
import ErrorMessageToast from '../../ComponentV2/ErrorMessageToast';
import saudaTypeEnum from '../../constant/enums/saudaTypeEnum';
interface DocumentUploadProps {
  docType?: number;
  onClickBack?: () => void;
  language: { [key: string]: any };
  showDocumentUploadFooter: boolean;
  showDocumentUploadAlert: boolean;
  doneDocumentUpload: () => void;
  handleFileChange?: (
    event: React.ChangeEvent<HTMLInputElement>,
    index?: number,
  ) => void;
  handleUploadButtonClick?: (from?: string, index?: number) => void;
  fileInputRef: any;
  documents: any;
  preViewDocument: (documents: any, index?: number) => void;
  selectedDocument: PreviewObject;
  showCancelUploadModal: boolean;
  toggleCancelUploadModal: () => void;
  onCLickCancelUpload: () => void;
  removeDocument: () => void;
  onPressClosePreview: () => void;
  multiFileUpload: boolean;
  reUploadInputRef: any;
  handleReUploadChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleReUploadButtonClick: (
    from?: string,
    index?: number,
    file?: any,
  ) => void;
  disableDone?: boolean;
  deleteDocVisible?: boolean;
  documentUploadToastPopup: boolean;
  reuploadVisible: boolean;
  currentPreviewIndex?: number;
  fileLengthAlert: boolean;
  setFileLengthAlert: any;
  initialPageLoading: boolean;
  saudaDetailData: SaudaDetailResp;
  setIsError: Dispatch<SetStateAction<boolean>>;
  isError: boolean;
  fileSizeExceededToast: boolean;
  fileFormatNotSupported: boolean;
  uploadDocAssets: { [key: string]: string };
}

const DocumentUpload = ({
  docType,
  onClickBack,
  language,
  showDocumentUploadFooter,
  showDocumentUploadAlert,
  doneDocumentUpload,
  handleFileChange,
  handleUploadButtonClick,
  fileInputRef,
  documents,
  preViewDocument,
  selectedDocument,
  showCancelUploadModal,
  toggleCancelUploadModal,
  onCLickCancelUpload,
  removeDocument,
  onPressClosePreview,
  multiFileUpload,
  reUploadInputRef,
  handleReUploadChange,
  handleReUploadButtonClick,
  disableDone,
  deleteDocVisible,
  documentUploadToastPopup,
  reuploadVisible,
  currentPreviewIndex,
  fileLengthAlert,
  setFileLengthAlert,
  initialPageLoading,
  saudaDetailData,
  setIsError,
  isError,
  fileSizeExceededToast,
  fileFormatNotSupported,
  uploadDocAssets,
}: DocumentUploadProps) => {
  const authProps = useAuth();
  return (
    <div className={Style.container}>
      <FileListAlert
        language={language}
        fileLengthAlert={fileLengthAlert}
        setFileLengthAlert={setFileLengthAlert}
      />
      {selectedDocument ? (
        <DocumentProvider
          initialDocuments={[selectedDocument]}
          removeDocument={removeDocument}
          onPressClosePreview={onPressClosePreview}
          label={language[DocumentType.get(docType)]}
          deleteVisible={deleteDocVisible}
          reuploadVisible={reuploadVisible}
          reuploadLabel={language?.reUpload}
          reUploadInputRef={reUploadInputRef}
          handleReUploadChange={handleReUploadChange}
          handleReUploadButtonClick={handleReUploadButtonClick}
          currentPreviewIndex={currentPreviewIndex}
        >
          <DocumentViewer />
        </DocumentProvider>
      ) : (
        <>
          <div>
            <DocumentUploadHeader
              onClickBack={onClickBack}
              docType={docType}
              language={language}
            />
            {documents?.length > 0 ? (
              <div className={Style.content}>
                {showDocumentUploadAlert &&
                  saudaDetailData?.sauda_creation_type !==
                    saudaTypeEnum?.offline && (
                    <DocumentUploadAlert
                      text={language?.reUploadRejectedDocument}
                    />
                  )}
                <FileList
                  preViewDocument={preViewDocument}
                  documents={documents}
                  language={language}
                  fileInputRef={fileInputRef}
                  handleFileChange={handleFileChange}
                  handleUploadButtonClick={handleUploadButtonClick}
                  multiFileUpload={multiFileUpload}
                  reUploadInputRef={reUploadInputRef}
                  handleReUploadChange={handleReUploadChange}
                  handleReUploadButtonClick={handleReUploadButtonClick}
                  initialPageLoading={initialPageLoading}
                  saudaType={saudaDetailData?.sauda_creation_type}
                />
              </div>
            ) : (
              <EmptyState
                docType={docType}
                language={language}
                fileInputRef={fileInputRef}
                handleFileChange={handleFileChange}
                handleUploadButtonClick={handleUploadButtonClick}
                saudaDetailData={saudaDetailData}
                invoiceImage={uploadDocAssets.invoiceDoc}
                weightSlipImage={uploadDocAssets.weightSlip}
              />
            )}
          </div>
          {documents?.length > 0 &&
            showDocumentUploadFooter &&
            saudaDetailData?.sauda_creation_type !== saudaTypeEnum?.offline && (
              <DocumentUploadFooter
                fileInputRef={fileInputRef}
                handleFileChange={handleFileChange}
                handleUploadButtonClick={handleUploadButtonClick}
                doneDocumentUpload={doneDocumentUpload}
                language={language}
                disableDone={disableDone}
              />
            )}
          <Toast
            isError={true}
            message={language?.errorMessage}
            isOpen={documentUploadToastPopup}
          />
        </>
      )}
      <CancelUploadModal
        showCancelUploadModal={showCancelUploadModal}
        toggleCancelUploadModal={toggleCancelUploadModal}
        onCLickCancelUpload={onCLickCancelUpload}
        language={language}
        docType={docType}
      />
      <LoginView authProps={{ ...authProps }} />
      <ErrorMessageToast
        message={language?.errorMessage}
        isError={isError}
        setIsError={setIsError}
        ErrorToastType={true}
      />
      <Toast
        isError={true}
        message={language?.fileSizeExceeded}
        isOpen={fileSizeExceededToast}
      />
      <Toast
        isError={true}
        message={language?.fileFormatNotAccpetable}
        isOpen={fileFormatNotSupported}
      />
    </div>
  );
};

export default DocumentUpload;
