import HttpStatusCode from '../../apis/types/httpStatusCodesEnums.type';

/**
 * Interface for HTTP error response.
 */
interface HttpError {
  message: string;
  code: HttpStatusCode;
  path: string;
}

/**
 * Handles HTTP error response and returns an HttpError object.
 * @param errorCode HTTP error code.
 * @returns HttpError object with error message and code.
 * @throws Error if error code is not defined.
 */
export default function handleHttpError(
  errorCode: number,
  path: string = null,
): HttpError {
  let message = 'An unknown error occurred';

  if (errorCode) {
    const status = errorCode;
    switch (status) {
      case HttpStatusCode.Unauthorized:
        message = 'Unauthorized';
        break;
      case HttpStatusCode.NotFound:
        message = 'Resource not found';
        break;
      case HttpStatusCode.InternalServerError:
        message = 'Internal server error';
        break;
      default:
        message = `Request failed with status code ${status}`;
        break;
    }
  } else {
    throw new Error('Error code is not defined');
  }

  return { message, code: errorCode, path: path };
}
