import * as AppLanguages from '../constant/appLanguages';
//language will be added here
const languages = {
  [AppLanguages.appLanguagesIndex.english]: {
    heading: 'Rake Information',
    helpText: 'Help',
    changeLocationText: 'Change Location',
    locationModalHeading: 'Location Information',

    locationModalDescpSubHeading:
      'We need your location to show you the correct information, please share your location with us.',
    locationModalGpsButton: 'Allow location',
    locationModalDividerText: 'Or',
    pinCodeButtonHeading: 'Search Location',
    pinCodeButtonTitle: 'Enter District name or Pincode',
    noCurrentRake: 'No Rake in {(variable)}',
    viewNearByRake: 'See Rake in nearby district',
    currentRakeCount: '{(variable)} Rake',
    viewRake: 'See Rake',
    rakeText: 'Rake',
    noRakeAvailable: 'No Rake',
    viewMoreNearRake: 'See in your nearby district',
  },
  [AppLanguages.appLanguagesIndex.hindi]: {
    heading: 'रेक की जानकारी',
    helpText: 'सहायता',
    changeLocationText: 'स्थान बदलें',
    locationModalHeading: 'लोकेशन की जानकारी ',
    locationModalDescpSubHeading:
      'आपको सही जानकारी दिखाने के लिए हमे आपकी लोकेशन की आवश्यकता है, कृपया अपनी लोकेशन हमसे साझा करें|',
    locationModalGpsButton: 'लोकेशन की अनुमति दे',
    locationModalDividerText: 'या फिर',
    pinCodeButtonHeading: 'लोकेशन खोजें',
    pinCodeButtonTitle: 'ज़िला का नाम या पिनकोड बताएं',
    noCurrentRake: '{(variable)} जिले में कोई रेक नहीं लगी',
    viewNearByRake: 'अपने जिले के पास की रेक देखें',
    currentRakeCount: '{(variable)} रेक लगी हैं',
    viewRake: 'रेक देखें',
    rakeText: 'रेक',
    noRakeAvailable: 'कोई रेक नहीं लगी हैं',
    viewMoreNearRake: 'पास के ज़िलों में देखें',
  },
};

export default (language) => languages[language];
