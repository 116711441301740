import React, { ReactNode } from 'react';
import Textarea, { TextareaProps } from '@mui/joy/Textarea';

interface TextAreaWrapperProps extends TextareaProps {
  children?: ReactNode;
  customStyles?: string;
  // You can define additional props here if needed
}

const MuiTextarea: React.FC<TextAreaWrapperProps> = ({
  children,
  customStyles,
  ...props
}) => {
  return (
    <Textarea
      sx={{ borderRadius: '8px', ...{ customStyles } }}
      className={customStyles}
      {...props}
    >
      {children}
    </Textarea>
  );
};

export default MuiTextarea;
