import React from 'react';
import Styles from './style.module.css';
import { Box, IconButton } from '@mui/joy';
import ArrowBackRounded from '@mui/icons-material/ArrowBackRounded';
import { FAQAnswersScreenProps } from '../../../hooks/FAQAnswers/types';

// Extract only the necessary type from FAQAnswersScreenProps
interface HeaderProps {
  onClickBackFaqAnswerScreen: FAQAnswersScreenProps['onClickBackFaqAnswerScreen'];
}

// Functional component definition with destructured props

const Header = ({ onClickBackFaqAnswerScreen }: HeaderProps) => {
  return (
    <Box className={Styles.container}>
      <IconButton
        size="md"
        onClick={onClickBackFaqAnswerScreen}
        id="faqAnswerScreenOnClickNackId"
      >
        <ArrowBackRounded />
      </IconButton>
    </Box>
  );
};

export default Header; // Exporting the Header component as the default export
