import * as AppLanguages from '../constant/appLanguages';
//language will be added here
const languages = {
  [AppLanguages.appLanguagesIndex.english]: {
    createDispatch: 'Create dispatch',
    dispatchDetails: 'Dispatch Details',
    dispatchWeight: 'Dispatched Weight (Gross)',
    enterDispatchWeight: 'Enter dispatched weight',
    truckNumber: 'Truck Number',
    enterTruckNumber: 'Enter truck number',
    saudaDocument: 'Sauda documents',
    invoice: 'Invoice',
    pleaseAddTheInvoice: 'Please add the invoice',
    weightSlip: 'Weight Slip',
    addWeightSlip: 'Please add the weight slip',
    mandiTax: 'Mandi Tax',
    addMandiTax: 'Please add the mandi tax',
    infoMessage:
      'Payment will only get processed once all the documents are approved.',
    submit: 'Submit',
    documentAdded: 'Document added',
    rejected: 'Rejected',
    approved: 'Approved',
    approvePending: 'Approval pending',
    noOfBag: 'No. of bags',
    enterNoOfBag: 'Enter no. of bags',
    errorMessage: 'Please fill all input fields',
    cancelDispatchConfirmation:
      'Are you sure you want to cancel the dispatch? All entered details will get deleted.',
    cancelDispatch: 'Cancel Dispatch',
    continueDispatch: 'Continue Dispatch',
    yesCancelDispatch: 'Yes, Cancel Dispatch',
    requestFailErrorMessage:
      'Sorry for the inconvenience! Please try again later.',
    backConfirmationText: 'All entered details will get deleted.',
    mobileNumbertitle: 'Truck Driver Mobile Number',
    mobileNumberPlaceholder: 'Enter truck driver mobile number',
    okayLabel: 'Okay',
    mandiToolTipHeading: 'Mandi Tax',
    mandiToolTipMessage:
      "Fees levied at APMCs (Mandi's), paid to respective state govt that manages mandi.",
    truckerrorMessage:
      'Incorrect truck number. Please enter the correct truck number. For ex: Truck number HR19D0343',
  },
  [AppLanguages.appLanguagesIndex.hindi]: {
    createDispatch: 'डिस्पैच बनाएँ',
    dispatchDetails: 'डिस्पैच विवरण',
    dispatchWeight: 'डिस्पैच वजन (ग्रॉस /कुल )',
    enterDispatchWeight: 'डिस्पैच वजन दर्ज करें',
    truckNumber: 'ट्रक नंबर',
    enterTruckNumber: 'ट्रक नंबर दर्ज करें',
    saudaDocument: 'सौदा दस्तावेज़',
    invoice: 'इनवॉइस',
    pleaseAddTheInvoice: 'कृपया इनवॉइस  जोड़ें',
    weightSlip: 'वजन पर्ची',
    addWeightSlip: 'कृपया वजन पर्ची जोड़ें',
    mandiTax: 'मंडी टैक्स',
    addMandiTax: 'कृपया मंडी दस्तावेज़ जोड़ें',
    infoMessage: 'भुगतान केवल तभी होगा जब सभी दस्तावेज़ स्वीकृत हो जाएँगे।',
    submit: 'जमा करें',
    documentAdded: 'दस्तावेज़ जोड़ा गया',
    rejected: 'अस्वीकृत',
    approved: 'मंजूर',
    approvePending: 'मंजूरी लंबित है',
    noOfBag: 'बैग की संख्या',
    enterNoOfBag: 'बैग की संख्या दर्ज करें',
    errorMessage: 'कृपया सभी इनपुट फ़ील्ड भरें',
    cancelDispatchConfirmation:
      'क्या आप निश्चित हैं कि आप डिस्पैच को रद्द करना चाहते हैं?दर्ज किए गए सभी विवरण डिलीट हो जाएंगे।',
    cancelDispatch: 'डिस्पैच रद्द करें',
    continueDispatch: 'डिस्पैच जारी रखें',
    yesCancelDispatch: 'हाँ डिस्पैच रद्द करें',
    requestFailErrorMessage:
      'असुविधा के लिए क्षमा करें! कृपया बाद में फिर से प्रयास करें।',
    backConfirmationText: 'दर्ज किए गए सभी विवरण डिलीट हो जाएंगे।',
    mobileNumbertitle: 'ट्रक ड्राइवर का मोबाइल नंबर',
    mobileNumberPlaceholder: 'ट्रक ड्राइवर का मोबाइल नंबर दर्ज करें',
    okayLabel: 'ठीक है',
    mandiToolTipHeading: 'Mandi Tax',
    mandiToolTipMessage:
      'मंडी टैक्स, एपीएमसी (मंडी) पर लगने वाला शुल्क है, जो व्यापारी सरकार को देते हैं।',
    truckerrorMessage:
      'ट्रक नंबर गलत है। कृपया सही ट्रक नंबर दर्ज करें। उदाहरण के लिए: ट्रक नंबर HR19D0343',
  },
};

export default (language) => languages[language];
