/* This code snippet is a TypeScript React functional component named `AddFarmer`. Here is a breakdown
of what it does: */
import { Box, Chip } from '@mui/joy';
import React from 'react';
import MuiButton from '../../../ComponentsV3/MuiButton';
import Styles from './style.module.css';

/* The `interface AddFarmerProps` is defining the prop types that the `AddFarmer` functional component
expects to receive. Here is a breakdown of each property: */
interface AddFarmerProps {
  language: { [key: string]: string };
  saveFarmer: () => void;
  saving: boolean;
  selectedCount: number;
  smsBalance: number;
}
export default function AddFarmer(props: AddFarmerProps) {
  /* The line `const { saveFarmer, savingLoading, selectedCount, language } = props;` is using object
  destructuring to extract specific properties from the `props` object passed to the `AddFarmer`
  functional component. */
  const { language, saving, saveFarmer, selectedCount, smsBalance } = props;

  const disableCondition =
    smsBalance < selectedCount || selectedCount === 0 ? true : false;

  const replacePlaceholders = ({ template, values }) => {
    return template
      .split(/(<[^>]+>)/)
      .map((part, index) =>
        part.startsWith('<') && part.endsWith('>') ? (
          <span key={index}>{values[part.replace(/<|>/g, '')]}</span>
        ) : (
          <span key={index}>{part}</span>
        ),
      );
  };

  const template = language?.sendFarmerError;
  const values = {
    farmerCount: smsBalance,
  };

  return (
    <>
      <Box
        className={Styles.mainContainer}
        bottom={0}
        bgcolor={'background.body'}
        borderTop={1}
        borderColor={'neutral.outlinedBorder'}
        paddingTop={'4px'}
        flexDirection={'column'}
      >
        {smsBalance < selectedCount && (
          <Box justifyContent={'center'} display={'flex'}>
            <Chip
              variant="plain"
              color="danger"
              data-testid="smsmBalaceEceedTestId"
            >
              {replacePlaceholders({ template, values })}
            </Chip>
          </Box>
        )}
        <Box padding={'0px 16px'}>
          <MuiButton
            style={{ margin: '16px 0px' }}
            variant="solid"
            size="lg"
            color="primary"
            loading={saving}
            onClick={saveFarmer}
            disabled={disableCondition}
            id="smsSendFarmerId"
            data-testid="smsSendFarmerId"
          >
            {language.proceed}
          </MuiButton>
        </Box>
      </Box>
    </>
  );
}
