import React from 'react';
import styles from './style.module.css'; // Importing CSS module styles

interface SkeletonLoadingProps {
  className?: string; // Optional className prop for additional styling
  itemNumber?: number; // Optional itemNumber prop to specify the number of skeleton items
}

export default function SkeletonLoading(props: SkeletonLoadingProps) {
  const { className, itemNumber = 1 } = props; // Destructuring className and itemNumber from props with default value 1

  return (
    <>
      {Array.from({ length: itemNumber }).map((_, index) => (
        <div key={index} className={`${styles?.skeleton} ${className}`}></div>
      ))}
    </>
  );
}
