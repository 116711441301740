import React, { useCallback } from 'react';
import Styles from './style.module.css';
import Modal from '../../../ComponentV2/Modal';
import { Button } from '@mui/joy';
import { appLanguages } from '../../../constant/appLanguages';
import RadioButtonCheckedRoundedIcon from '@mui/icons-material/RadioButtonCheckedRounded';
import RadioButtonUncheckedRoundedIcon from '@mui/icons-material/RadioButtonUncheckedRounded';

/**
 * `LanguageParams` defines the structure for language selection parameters.
 *
 * @property {string} langCode - The language code (e.g., "en" for English).
 * @property {number} langId - The unique identifier for the language in the backend system.
 */
interface LanguageParams {
  langCode: string;
  langId: number;
}

/**
 * `PayloadType` defines the structure for the props passed to the `LanguageSelectionModal` component.
 *
 * @property {Function} handleLanguageSelect - Callback function to handle language selection.
 * @property {Function} onLanguageModalToggle - Callback function to toggle the language selection modal.
 * @property {Function} handleLanguageChange - Callback function to apply the language change.
 * @property {string} selectedLanguage - The currently selected language code.
 * @property {boolean} isLanguageModalOpen - Flag indicating if the language modal is open.
 * @property {boolean} isLanguageSelectedInProgress - Flag indicating if language selection is in progress.
 * @property {Object} language - Object containing localized strings.
 */
interface PayloadType {
  handleLanguageSelect: (props: LanguageParams) => void;
  onLanguageModalToggle: () => void;
  handleLanguageChange: () => void;
  selectedLanguage: string;
  isLanguageModalOpen: boolean;
  isLanguageSelectedInProgress: boolean;
  language: { [key: string]: any };
}

/**
 * `LanguageCard` is a functional component that renders a single language option in the language selection modal.
 *
 * @param {Object} props - The props passed to the component.
 * @param {Object} props.item - The language item data.
 * @param {string} props.selectedLanguage - The currently selected language code.
 * @param {Function} props.handleLanguageSelect - Callback to handle selection of a language.
 * @param {number} props.index - The index of the language item in the list.
 * @returns {JSX.Element} The rendered language card component.
 */
function LanguageCard({ item, selectedLanguage, handleLanguageSelect, index }) {
  // useCallback hook to memoize the handleSelect function to prevent unnecessary re-renders.
  const handleSelect = useCallback(() => {
    handleLanguageSelect({
      langCode: item?.langCode,
      langId: item?.backendLanguageCode,
    });
  }, [item?.langCode, handleLanguageSelect]);

  // Render the language card with conditional styling based on selection status.
  return (
    <div
      id={'myProfileSelectLangugeCardTestId' + index}
      onClick={handleSelect}
      className={
        item?.langCode === selectedLanguage?.langCode
          ? Styles.activeCardMainContainer
          : Styles.inActiveCardMainContainer
      }
    >
      <div className={Styles.cardDetailContainer}>
        <div className={Styles.cardTextContainer}>
          <div className={Styles.langaugeTextLg}>
            {item?.languageNameInHindi}
          </div>
          <div className={Styles.langaugeTextSm}>
            {item?.languageNameInEnglish}
          </div>
        </div>
        <div>
          {item?.langCode === selectedLanguage?.langCode ? (
            <RadioButtonCheckedRoundedIcon
              htmlColor="#008B74"
              sx={{ height: 28, width: 28 }}
            />
          ) : (
            <RadioButtonUncheckedRoundedIcon
              htmlColor="#C2C7CF"
              sx={{ height: 28, width: 28 }}
            />
          )}
        </div>
      </div>
    </div>
  );
}

/**
 * `LanguageSelectionModal` is a functional component that renders the modal for language selection.
 *
 * @param {PayloadType} props - The props passed to the component.
 * @returns {JSX.Element} The rendered modal component.
 */
const LanguageSelectionModal = ({
  handleLanguageSelect,
  onLanguageModalToggle,
  handleLanguageChange,
  selectedLanguage,
  isLanguageModalOpen,
  isLanguageSelectedInProgress,
  language,
}: PayloadType) => {
  // UI for the modal content, mapping over `appLanguages` to render language cards.
  const UiModal = (
    <div className={Styles.wrapper}>
      <div className={Styles.gridStyle}>
        {appLanguages?.map((item, index) => (
          <LanguageCard
            item={item}
            selectedLanguage={selectedLanguage}
            handleLanguageSelect={handleLanguageSelect}
            index={index}
            key={'MyProfileChangeLanguageCard' + index}
            data-testid="myProfileSelectLangugeCardTestId"
          />
        ))}
      </div>
      <div className={Styles.proceedButtonContainer}>
        <Button
          size="lg"
          style={{ borderRadius: 12 }}
          fullWidth={true}
          onClick={handleLanguageChange}
          loading={isLanguageSelectedInProgress}
          id="myProfileScreenChangeLanguageProceedTestId"
          data-testid="myProfileLanguageSelectionChangeLanguageTestId"
        >
          {language?.changeLanguage}
        </Button>
      </div>
    </div>
  );

  // Render the modal with the UI content.
  return (
    <Modal
      id="myProfileCloseModalTestId"
      onClose={onLanguageModalToggle}
      isCloseButtonOption={true}
      title={language?.selectLanguage}
      isOpen={isLanguageModalOpen}
    >
      {UiModal}
    </Modal>
  );
};

export default LanguageSelectionModal;
