import React from 'react';
import Styles from './style.module.css';
import Button from './../../../ComponentV3/MuiButton';
import MuiTypography from '../../../ComponentV3/Typography';
import { PrivacyPolicy } from '../../../constant/PrivacyPolicyUrl';

interface ActionButtonProps {
  onClickLoginProceed?: () => void;
  loading: boolean;
  userMobileNumber: string;
  onClickPrivacyPolicy: (item: string) => () => void;
  language: { [key: string]: any };
  onContactUs: () => void;
  whatsAppIconUrl: string;
}
export default function Actionbutton(props: ActionButtonProps) {
  const {
    onClickLoginProceed,
    loading,
    userMobileNumber,
    onClickPrivacyPolicy,
    language,
    onContactUs,
    whatsAppIconUrl,
  } = props;
  const renderTextWithColors = (text) => {
    const splitText = text?.split(/{|}/);

    return splitText?.map((item, index) => {
      if (item.startsWith('(') && item.endsWith(')')) {
        return (
          <a
            itemID=""
            key={index}
            href={PrivacyPolicy}
            className={Styles.highlightedText}
            id={`TermsAndCondition${index}`}
            onClick={onClickPrivacyPolicy(item)}
          >
            {item}
          </a>
        );
      }
      return (
        <MuiTypography
          level="label-sm"
          key={index}
          className={Styles.termAndCondition}
        >
          {item}
        </MuiTypography>
      );
    });
  };
  return (
    <div className={Styles?.actionContainer}>
      <div className={Styles.helpButtonContainer} onClick={onContactUs}>
        <img
          className={Styles.whatsAppIconStyle}
          src={whatsAppIconUrl}
          alt="WhatsApp Icon"
          height={16}
          width={16}
          loading="eager"
        />
        <div className={Styles.helpTextStyle}>{language?.help}</div>
      </div>
      <div className={Styles.messageText}>
        {renderTextWithColors(language?.termsAndCondtions)}
      </div>
      <div className={Styles.proceedButtonContainer}>
        <Button
          id="loginProceedtestId"
          size="lg"
          onClick={onClickLoginProceed}
          loading={loading}
          style={
            userMobileNumber.length < 10
              ? {
                  backgroundColor: '#C2C7CF',
                  color: '#FFFFFF',
                }
              : {}
          }
          disabled={userMobileNumber.length < 10 ? true : false}
        >
          {language?.proceed}
        </Button>
      </div>
    </div>
  );
}
