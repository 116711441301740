import { useState, Dispatch, SetStateAction } from 'react';
import { ActiveTrade, ExpiredTrade, PromotionalMediaList } from './types';

interface Page {
  tradeListPageNumber: number;
  setTradeListPageNumber: Dispatch<SetStateAction<number>>;
  buyersList: ActiveTrade[];
  setBuyerList: Dispatch<SetStateAction<ActiveTrade[]>>;
  totalRecords: number;
  setTotalRecords: Dispatch<SetStateAction<number>>;
  setActiveTradeList: Dispatch<SetStateAction<ActiveTrade[]>>;
  activeTradeList: ActiveTrade[];
  setExpiredTradeList: Dispatch<SetStateAction<ExpiredTrade[]>>;
  expiredTradeList: ExpiredTrade[];
  promotionalMediaList: PromotionalMediaList[];
  setPromotionalMediaList: Dispatch<SetStateAction<PromotionalMediaList[]>>;
}

export default function UseupdatePageNumber(): Page {
  const [tradeListPageNumber, setTradeListPageNumber] = useState<number>(1);
  const [buyersList, setBuyerList] = useState<ActiveTrade[]>([]);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [activeTradeList, setActiveTradeList] = useState<ActiveTrade[]>([]);
  const [expiredTradeList, setExpiredTradeList] = useState<ExpiredTrade[]>([]);
  const [promotionalMediaList, setPromotionalMediaList] = useState<
    PromotionalMediaList[]
  >([]);
  return {
    tradeListPageNumber,
    setTradeListPageNumber,
    buyersList,
    setBuyerList,
    totalRecords,
    setTotalRecords,
    setActiveTradeList,
    activeTradeList,
    setExpiredTradeList,
    expiredTradeList,
    setPromotionalMediaList,
    promotionalMediaList,
  };
}
