/* The above code is a TypeScript file that exports an object containing various event names as keys
and their corresponding values as strings. These event names seem to be related to different actions
or interactions within an application. Each key represents a specific event that can occur in the
application, such as button clicks, page transitions, form submissions, etc. These event names are
used to track and handle different user interactions within the application. */
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  beforeLocationAccess: 'beforeLocationAccess',
  onStartCropViewPage: 'onStartCropViewPage',
  onHelpButtonClick: 'onHelpButtonClick',
  onTradeDetailviewpage: 'onTradeDetailviewpage',
  onContacttoSellButtonClick: 'onContacttoSellButtonClick',
  onScrollCropViewPage: 'onScrollCropViewPage',
  onEndCropViewPage: 'onEndCropViewPage',
  onStartCropDetailPage: 'onStartCropDetailPage',
  onScrollCropDetailViewPage: 'onScrollCropDetailViewPage',
  onBackButtonClick: 'onBackButtonClick',
  onShareButtonClick: 'onShareButtonClick',
  onBrokerageButtonClick: 'onBrokerageButtonClick',
  onFincostButtonClick: 'onFincostButtonClick',
  onNetpriceButtonClick: 'onNetpriceButtonClick',
  onContactToSellButtonClick: 'onContactToSellButtonClick',
  //onEndCropViewPage: 'onEndCropViewPage'
  onSelectCrop: 'onSelectCrop',
  onCropFilterCloseButtonClick: 'onCropFilterCloseButtonClick',
  onCropFilterApplyButtonClick: 'onCropFilterApplyButtonClick',
  onSelectLocation: 'onSelectLocation',
  onSelectLocationCloseFilterButtonClick:
    'onSelectLocationCloseFilterButtonClick',
  onLocationFilterApplyButtonClick: 'onLocationFilterApplyButtonClick',
  SGN_MOBILE_SUBMITTED: 'SGN_MOBILE_SUBMITTED',
  SGN_RESEND_OTP: 'SGN_RESEND_OTP',
  SGN_CHANGE_MOBILE: 'SGN_CHANGE_MOBILE',
  SGN_OTP_SUBMITTED: 'SGN_OTP_SUBMITTED',
  SGN_PROFILE_SUBMITTED: 'SGN_PROFILE_SUBMITTED',
  onSelectVarity: 'onSelectVarity',
  onVarityFilterCloseButtonClick: 'onVarityFilterCloseButtonClick',
  onclickpricecard: 'onclickpricecard',
  ontradenonserviceablehelpbuttonclicked:
    'ontradenonserviceablehelpbuttonclicked',
  ontradenonserviceablepage: 'ontradenonserviceablepage',
  ontradeserviceableviewpage: 'ontradeserviceableviewpage',
  onPaymentBuyersTermsButtonClick: 'onPaymentBuyersTermsButtonClick',
  onPaymentGRNTermsButtonClick: 'onPaymentGRNTermsButtonClick',
  onPaymentBuyersTermsInfoButtonClick: 'onPaymentBuyersTermsInfoButtonClick',
  onPaymentGRNTermsInfoButtonClick: 'onPaymentGRNTermsInfoButtonClick',
  onCreateSaudaScreenCloseButtonClick: 'onCreateSaudaScreenCloseButtonClick',
  onPaymentTermsInfoBottomSheetCloseButtonClick:
    'onPaymentTermsInfoBottomSheetCloseButtonClick',
  onSaudaRequestScreenBrokerageInfoButtonClick:
    'onSaudaRequestScreenBrokerageInfoButtonClick',
  onSaudaRequestFincostinfoButtonClick: 'onSaudaRequestFincostinfoButtonClick',
  onSaudaRequestNetpriceInfoButtonClick:
    'onSaudaRequestNetpriceInfoButtonClick',
  onTermsSubmissionButtonClick: 'onTermsSubmissionButtonClick',
  onSaudaProceedSubmissionButtonClick: 'onSaudaProceedSubmissionButtonClick',
  onConfirmSaudaRequestSubmissionButtonClick:
    'onConfirmSaudaRequestSubmissionButtonClick',
  onOrderDetailsNetPriceInfoButtonClick:
    'onOrderDetailsNetPriceInfoButtonClick',
  onCallNowButtonClick: 'onCallNowButtonClick',
  onChatWithUsButtonClick: 'onChatWithUsButtonClick',
  onCancelSaudaRequestButtonClick: 'onCancelSaudaRequestButtonClick',
  onCancelSaudaRequestCloseButtonClick: 'onCancelSaudaRequestCloseButtonClick',
  onConfirmCancelSaudaRequestButtonClick:
    'onConfirmCancelSaudaRequestButtonClick',
  onRejectPOButtonClick: 'onRejectPOButtonClick',
  onAcceptPOButtonClick: 'onAcceptPOButtonClick',
  onCancelButtonClick: 'onCancelButtonClick',
  onAcceptCancelButtonClick: 'onAcceptCancelButtonClick',
  onAcceptPOCancelButtonClick: 'onAcceptPOCancelButtonClick',
  onMySaudasButtonClick: 'onMySaudasButtonClick',
  onOpenSaudasButtonClick: 'onOpenSaudasButtonClick',
  onClosedSaudasButtonClick: 'onClosedSaudasButtonClick',
  onMySaudasCardButtonClick: 'onMySaudasCardButtonClick',
  onMySaudasBackButtonClick: 'onMySaudasBackButtonClick',
  onSaudaStatusClicked: 'onSaudaStatusClicked',
  onSaudaStatusCloseButtonClicked: 'onSaudaStatusCloseButtonClicked',
  onSaudaStatusFilterButtonClick: 'onSaudaStatusFilterButtonClick',
  onInputQuantityBoxClick: 'onInputQuantityBoxClick',
  onRequestSaudaButtonClick: 'onRequestSaudaButtonClick',
  OnAcceptPOCheckboxClick: 'OnAcceptPOCheckboxClick',
  onSaudaDetailsScreenStart: 'onSaudaDetailsScreenStart',
  onSaudaDetailsScreenEnd: 'onSaudaDetailsScreenEnd',
  OnViewPOReceiptClick: 'OnViewPOReceiptClick',
  onStartMySaudasScreen: 'onStartMySaudasScreen',
  onEndMySaudasScreen: 'onEndMySaudasScreen',
  onPaymentDetailsGotItButtonClick: 'onPaymentDetailsGotItButtonClick',
  onPaymentDetailsTaptoCopyButtonClick: 'onPaymentDetailsTaptoCopyButtonClick',
  onPaymentDetailsCloseButtonClick: 'onPaymentDetailsCloseButtonClick',
  OnTruckDetailsViewPaymentDetailsCardClick:
    'OnTruckDetailsViewPaymentDetailsCardClick',
  onTradeListingRequestSaudaButtonClick:
    'onTradeListingRequestSaudaButtonClick',
  onLocationAccessPopup: 'onLocationAccessPopup',
  onChangeTradeLocationButton: 'onChangeTradeLocationButton',
  onDispatchedWeightInputBoxClick: 'onDispatchedWeightInputBoxClick',
  onTruckNumberInputBoxClick: 'onTruckNumberInoutBoxClick',
  onNumberOfBagInputBoxClick: 'onNumberOfBagInputBoxClick',
  onTruckDriverMobileNumberInputBoxClick:
    'onTruckDriverMobileNumberInputBoxClick',
  onUploadSaudaDocumentButtonClick: 'onUploadSaudaDocumentButtonClick',
  onCreateDispatchSubmitButtonClick: 'onCreateDispatchSubmitButtonClick',
  onDispatchTruckButtonClick: 'onDispatchTruckButtonClick',
  onTruckNumberButtonClick: 'onTruckNumberButtonClick	',
  OnTruckDetailsPaymentDetailsButtonClick:
    'OnTruckDetailsPaymentDetailsButtonClick',
  onCreateDispatchBackButton: 'onCreateDispatchBackButton',
  onContinueDispatchButtonClick: 'onContinueDispatchButtonClick',
  onCancelDispatchButtonClick: 'onCancelDispatchButtonClick',
  onDocumentUploadButtonClick: 'onDocumentUploadButtonClick',
  onUploadedDocumentButtonClick: 'onUploadedDocumentButtonClick',
  onAddMoreFilesButtonClick: 'onAddMoreFilesButtonClick',
  onDoneButtonClick: 'onDoneButtonClick',
  onContinueUploadingButtonClick: 'onContinueUploadingButtonClick',
  onCancelUploadingButtonClick: 'onCancelUploadingButtonClick',
  onDeleteButtonClick: 'onDeleteButtonClick',
  onStartCommunityHomeScreen: 'onStartCommunityHomeScreen',
  onCommunityCategoryPageButtonClick: 'onCommunityCategoryPageButtonClick',
  onCommunityHomeCardClick: 'onCommunityHomeCardClick',
  onEndCommunityHomeScreen: 'onEndCommunityHomeScreen',
  onCommunityBackButtonClick: 'onCommunityBackButtonClick',
  onStartCommunityDetailedPage: 'onStartCommunityDetailedPage',
  onClapButtonClick: 'onClapButtonClick',
  onCommentButtonClick: 'onCommentButtonClick',
  onCommunityDetailedDisclaimerButtonClick:
    'onCommunityDetailedDisclaimerButtonClick',
  onCommentClapClick: 'onCommentClapClick',
  onCommentReplyClick: 'onCommentReplyClick',
  onCommentMoreRepliesClick: 'onCommentMoreRepliesClick',
  onCommentViewLessClick: 'onCommentViewLessClick',
  onCommunityRecommendedCardClick: 'onCommunityRecommendedCardClick',
  onCommentInputBoxClick: 'onCommentInputBoxClick',
  onCommentSubmitButtonClick: 'onCommentSubmitButtonClick',
  /* App side login flow moengage events starts here*/
  onPrivacyPolicyButtonClick: 'onPrivacyPolicyButtonClick',
  onTermsConditionsButtonClick: 'onTermsConditionsButtonClick',
  onSGNOTPSubmittedButtonClick: 'onSGNOTPSubmittedButtonClick',
  onSGNBackButtonClick: 'onSGNBackButtonClick',
  onOTPResendButtonClick: 'onOTPResendButtonClick',
  onSGNProfileSubmittedButtonClick: 'onSGNProfileSubmittedButtonClick',
  onSGNMobileNumberSubmittedButtonClick:
    'onSGNMobileNumberSubmittedButtonClick',
  /* App side login flow moengage events ends here*/

  /* homescreen pwa events start */
  onWebHomeScreenChangeProfileClick: 'onWebHomeScreenChangeProfileClick',
  onWebProfileSelectionClick: 'onWebProfileSelectionClick',
  onWebProfileSelectionCloseButtonClick:
    'onWebProfileSelectionCloseButtonClick',
  onWebDistributorBottomSheetDownloadAppButtonClick:
    'onWebDistributorBottomSheetDownloadAppButtonClick',
  onWebDistributorBottomSheetNotNowButtonClick:
    'onWebDistributorBottomSheetNotNowButtonClick',
  onWebCropAdvisoryBottomSheetDownloadAppButtonClick:
    'onWebCropAdvisoryBottomSheetDownloadAppButtonClick',
  onWebCropAdvisoryBottomSheetNotNowButtonClick:
    'onWebCropAdvisoryBottomSheetNotNowButtonClick',
  onWebRakeBottomSheetDownloadAppButtonClick:
    'onWebRakeBottomSheetDownloadAppButtonClick',
  onWebRakeBottomSheetNotNowButtonClicked:
    'onWebRakeBottomSheetNotNowButtonClicked',
  onWebBahiKhataBottomSheetDownloadAppButtonClick:
    'onWebBahiKhataBottomSheetDownloadAppButtonClick',
  onWebBahiKhataBottomSheetNotNowButtonClick:
    'onWebBahiKhataBottomSheetNotNowButtonClick',
  onWebSMSBottomSheetDownloadAppButtonClick:
    'onWebSMSBottomSheetDownloadAppButtonClick',
  onWebSMSBottomSheetNotNowButtonClick: 'onWebSMSBottomSheetNotNowButtonClick',
  onWebQuizBottomSheetNotNowButtonClick:
    'onWebQuizBottomSheetNotNowButtonClick',
  onWebQuizBottomSheetDownloadAppButtonClick:
    'onWebQuizBottomSheetDownloadAppButtonClick',
  /* homescreen pwa events end */

  /* Moengage home screen native events */
  onHomeScreenSmsFeatureButtonClick: 'onHomeScreenSmsFeatureButtonClick',
  onRakeFeatureClick: 'onRakeFeatureClick',
  onHomeScreenHelpButtonClick: 'onHomeScreenHelpButtonClick',
  onMyProfileButtonClick: 'onMyProfileButtonClick',
  onAddFarmerButtonClick: 'onAddFarmerButtonClick',
  onWeatherCardShareButtonClick: 'onWeatherCardShareButtonClick',
  onQuizBannerClick: 'onQuizBannerClick',
  onStartHomeScreen: 'onStartHomeScreen',
  onBahiKhataButtonClicked: 'onBahiKhataButtonClicked',
  /* Moengage home screen native events */

  /* broker code events start */
  onBrokerCodeInputBoxButttonClick: 'onBrokerCodeInputBoxButttonClick',
  onBrokerCodeApplyButtonClick: 'onBrokerCodeApplyButtonClick',
  onBrokerCodeCloseButtonClick: 'onBrokerCodeCloseButtonClick',
  /* broker code events end */

  /* FAQ Question Screen events start */
  onStartHelpFaqScreen: 'onStartHelpFaqScreen',
  onFaqButtonClick: 'onFaqButtonClick',
  /* FAQ Question Screen events end */

  /* FAQ Answer Screen events start */
  onStartFaqDetailedScreen: 'onStartFaqDetailedScreen',
  onHelpWhatsappButtonClick: 'onHelpWhatsappButtonClick',
  /* FAQ Answer Screen events end */

  RAKE_LAUNCHED: 'RAKE_LAUNCHED',
  onRakeFeatureLaunch: 'onRakeFeatureLaunch',
  onRakeViewinNearbyDistrictButtonClick:
    'onRakeViewinNearbyDistrictButtonClick',
  RAKE_CARD_CLICKED: 'RAKE_CARD_CLICKED',
  onRakeLocationCardClick: 'onRakeLocationCardClick',

  /* rake detail screen events start */
  onDetailRakeScreenLaunch: 'onDetailRakeScreenLaunch',
  MORE_CARD_EXPLORED: 'MORE_CARD_EXPLORED',
  /* rake detail screen events end  */

  /* Event for doucment trade document upload */
  techEventFileSizeUploaded: 'techEventFileSizeUploaded',

  /* my profile events start */
  onRateAppButtonClick: 'onRateAppButtonClick',
  onLogoutButtonClick: 'onLogoutButtonClick',
  onEndLogoutConfirmationScreen: 'onEndLogoutConfirmationScreen',
  onChooseLanguageButtonClick: 'onChooseLanguageButtonClick',
  onMyProfileHelpButtonClick: 'onMyProfileHelpButtonClick',
  onMyProfileEditProfileButtonClick: 'onMyProfileEditProfileButtonClick',
  onEndMyProfileScreen: 'onEndMyProfileScreen',
  onStartMyProfileScreen: 'onStartMyProfileScreen',
  /* my profile events end */
  ADD_FARMER: 'ADD_FARMER',
  NEW_MANNUAL_ADD_FARMER_CLICKED: 'NEW_MANNUAL_ADD_FARMER_CLICKED',
  NEW_MANNUAL_FAMER_ADDED: 'NEW_MANNUAL_FAMER_ADDED',
  onAddFarmerManuallyButtonClick: 'onAddFarmerManuallyButtonClick',
  onAddFarmerSearchInputBoxClick: 'onAddFarmerSearchInputBoxClick',
  onAddFarmerSelectFarmerCheckboxClick: 'onAddFarmerSelectFarmerCheckboxClick',
  onSubmitFarmerListButtonClick: 'onSubmitFarmerListButtonClick',
  onViewMyFarmersButtonClick: 'onViewMyFarmersButtonClick',
  onMyFarmersDeleteButtonClick: 'onMyFarmersDeleteButtonClick',
  onDeleteFarmerButtonClick: 'onDeleteFarmerButtonClick',
  onDeleteFarmerCancelButtonClick: 'onDeleteFarmerCancelButtonClick',
  onDeleteFarmerCrossButtonClick: 'onDeleteFarmerCrossButtonClick',
  onAddFarmerManuallySubmitButtonClick: 'onAddFarmerManuallySubmitButtonClick',
  onAddFarmerManuallyFarmerNameInputBoxClick:
    'onAddFarmerManuallyFarmerNameInputBoxClick',
  onAddFarmerManuallyMobileNumberInputBoxClick:
    'onAddFarmerManuallyMobileNumberInputBoxClick',
  onAddFarmerManuallyCrossButtonClick: 'onAddFarmerManuallyCrossButtonClick',
  onEditProfileSubmitButtonClick: 'onEditProfileSubmitButtonClick',
  onStartEditProfileScreen: 'onStartEditProfileScreen',

  /* SMS Shop Promotion Screen Start */
  onHomeScreenSmsFeatureIButtonClick: 'onHomeScreenSmsFeatureIButtonClick',
  onHomeScreenSmsFeatureIButtonModalClosedButtonClick:
    'onHomeScreenSmsFeatureIButtonModalClosedButtonClick',
  onSmsDemoVideoClick: 'onSmsDemoVideoClick',
  onSmsDemoVideOkayButtonClick: 'onSmsDemoVideOkayButtonClick',
  onSmsChatWithUsButtonClick: 'onSmsChatWithUsButtonClick',
  onSmsTemplateSelectionClick: 'onSmsTemplateSelectionClick',
  onSmsBalanceButtonClick: 'onSmsBalanceButtonClick',

  /* SMS Shop Promotion Screen End */

  /* SMS Template Screen Start */

  onSmsTemplateBackButtonClick: 'onSmsTemplateBackButtonClick',
  onSmsTemplateEndScreen: 'onSmsTemplateEndScreen',
  onSmsBuyMachineryNotifyFarmerClick: 'onSmsBuyMachineryNotifyFarmerClick',
  onSmsCustomNotifyFarmerClick: 'onSmsCustomNotifyFarmerClick',
  onSmsDiscountNotifyFarmerClick: 'onSmsDiscountNotifyFarmerClick',
  onSmsFertilizerNotifyFarmerClick: 'onSmsFertilizerNotifyFarmerClick',
  onSmsFestivalNotifyFarmerClick: 'onSmsFestivalNotifyFarmerClick',
  onSmsPesticideNotifyFarmerClick: 'onSmsPesticideNotifyFarmerClick',
  onSmsSeedNotifyFarmerClick: 'onSmsSeedNotifyFarmerClick',
  onSmsSellCropNotifyFarmerClick: 'onSmsSellCropNotifyFarmerClick',
  onSmsShopOpenNotifyFarmerClick: 'onSmsShopOpenNotifyFarmerClick',
  onSmsShopOpenMultipleDaysNotifyFarmerClick:
    'onSmsShopOpenMultipleDaysNotifyFarmerClick',
  onSmsThankYouNotifyFarmerClick: 'onSmsThankYouNotifyFarmerClick',
  onSmsBalanceScreenBackButtonClick: 'onSmsBalanceScreenBackButtonClick',

  /* SMS Template Screen End */
};
