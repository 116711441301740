import uploadMultipleFile from '../../../apis/Trader/uploadMultipleFile';

export default async function UploadFiles(payload): Promise<any> {
  const body = payload;

  try {
    const result = await uploadMultipleFile(body);

    if (!result.data) {
      throw result;
    }

    return result.data;
  } catch (error) {
    if (!error.message) {
      error.message = 'Something Went Wrong';
    }

    throw error;
  }
}
