import React from 'react';
import { Box } from '@mui/material';
import MuiTypography from '../../../ComponentV3/Typography';

interface NoLocationProp {
  language: { [key: string]: string };
  noLocationImage: string;
}

export default function LocationList(props: NoLocationProp) {
  const { language, noLocationImage } = props;
  return (
    <Box
      display={'flex'}
      flex={1}
      alignItems={'center'}
      paddingTop={'100px'}
      flexDirection="column"
      gap={'16px'}
    >
      <img
        height={110}
        width={110}
        src={noLocationImage}
        alt="No location"
        style={{ maxWidth: 110, maxHeight: 110 }}
        loading="eager"
      />
      <MuiTypography level="title-sm" textColor={'text.primary'}>
        {language?.noPinCodeText}
      </MuiTypography>
    </Box>
  );
}
