import React from 'react';
import Styles from './style.module.css';
import { userType } from '../../../../constant/enums/userType';
import MuiTypography from '../../../../ComponentV3/Typography';

export default function personaCard({
  personaImages,
  index,
  onClickPersonaCard,
  selectedPersonaKey,
  selectedPersona,
  language,
}) {
  return (
    <button
      className={
        selectedPersona === selectedPersonaKey
          ? Styles.selectedMainContainer
          : Styles.nonSelectedMainContainer
      }
      id={'UserPersonaSelectPersona' + index}
      onClick={onClickPersonaCard(selectedPersonaKey)}
    >
      <div className={Styles.textContainer}>
        <MuiTypography level="title-md">
          {userType['retailer'] == selectedPersonaKey
            ? language?.retailer
            : userType['trader'] == selectedPersonaKey
            ? language?.distributer
            : language?.farmer}
        </MuiTypography>
        <MuiTypography level="body-xs">
          {userType['retailer'] == selectedPersonaKey
            ? language?.retailerDescp
            : userType['trader'] == selectedPersonaKey
            ? language?.distributerDescp
            : language?.farmerDescp}
        </MuiTypography>
      </div>
      <img
        className={Styles.imageContainer}
        src={personaImages}
        alt="personaImage"
        loading="eager"
      />
    </button>
  );
}
