import getConfigByKeys from '../../config/config';
import { configKeys } from '../../config/types';
import handleHttpError from '../../utils/api/httpErrorMapping';
import HttpStatusCode from '../types/httpStatusCodesEnums.type';
import UnifiedResponse from '../types/unifiedResponse.type';

import { getAuthTokenFromStorage } from '../../modal/auth/auth.model';
import { apiEndPoints } from '../../constant/apiEndPoints';

const { API_URL } = getConfigByKeys([configKeys.API_URL]);

interface ActionOnPostPayload {
  news_feed_action: string;
  news_feed_id: number;
}

export default async function ActionOnPost(
  payload: ActionOnPostPayload,
): Promise<UnifiedResponse> {
  const Auth = getAuthTokenFromStorage();

  // throwing error because at this point of time
  // the user should be logged in
  if (!Auth) throw new Error('Auth token not found');
  const path = apiEndPoints.newsFeedAction;

  const response = await fetch(`${API_URL}${path}`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      authorization: 'Bearer ' + Auth,
    },
    body: JSON.stringify(payload),
  });

  if (response.status !== HttpStatusCode.Ok) {
    throw handleHttpError(response.status, path);
  }

  const data = await response.json();
  return { data, response };
}
