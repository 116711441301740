import React from 'react';
import style from './SkeletonLoading.module.css';

interface SkeletonCardProps {
  width?: string;
  height?: string;
}

export default function SkeletonCard(props: SkeletonCardProps) {
  return (
    <div className={style.container}>
      <section>
        <div className={style.card}>
          <div className={style.top}>
            <div className={style.info}>
              <div className={style.skeletonProfilePic}></div>

              <div className={style.username}>
                <div className={style.skeletonName}></div>
                <div className={style.skeletonState}></div>
              </div>
            </div>
          </div>
          {/* Body of the card */}
          <div className={style.skeletonHeading}></div>
          <div className={style.skeletonBody}>
            <div className={style.skeletonPostPic}></div>
            <div>
              <hr className={style.hrClass}></hr>
            </div>
            {/* footer */}
            <div>
              <div className={style.gridClass}>
                <div className={style.flexClass}>
                  <div className={style.skeletonIcon}></div>
                  <div className={style.skeletonText}></div>
                </div>
                <div className={style.flexClass}>
                  <div className={style.skeletonIcon}></div>
                  <div className={style.skeletonText}></div>
                </div>
                <div className={style.flexClass}>
                  <div className={style.skeletonIcon}></div>
                  <div className={style.skeletonText}></div>
                </div>
              </div>
            </div>
          </div>
          {/* <div className={style.bottomSection}></div> */}
        </div>
      </section>
    </div>
  );
}
