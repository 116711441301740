import React from 'react';
import Styles from './style.module.css';
import { Box, IconButton } from '@mui/joy';
import Typography from '../../../ComponentV3/Typography';
import ArrowBackRounded from '@mui/icons-material/ArrowBackRounded';

// Defining the interface for the component's props
interface FAQHeaderProps {
  onClickBackFaqScreen: () => void; // Function to handle the click event for the back button
  language: { [key: string]: any }; // Object to hold language-specific text
}

// Functional component definition with destructured props
const Header = ({ onClickBackFaqScreen, language }: FAQHeaderProps) => {
  return (
    <Box className={Styles.container}>
      <Box className={Styles.headerTop}>
        <IconButton
          size="md" // Size of the IconButton
          onClick={onClickBackFaqScreen} // Handling click event to trigger the provided function
          id={'faqQuestionScreenHeaderBackButtonId'}
          data-testid={'faqQuestionScreenHeaderBackButtonTestId'} // Setting a data-testid for the IconButton
        >
          <ArrowBackRounded sx={{ color: '#FFFFFF' }} />
        </IconButton>
        <Typography level="title-md" sx={{ color: '#FFFFFF' }}>
          {language.helpAndSupport}
        </Typography>
      </Box>
      <Box className={Styles.headerBottom}>
        <Typography
          sx={{
            color: '#FFFFFF',
            fontFamily: 'NotoSans',
            fontSize: '20.11px',
            fontWeight: 600,
            lineHeight: '31.17px',
            textAlign: 'left',
          }}
        >
          {language?.frequentlyAskedQuestions}
        </Typography>
      </Box>
    </Box>
  );
};

export default Header; // Exporting the Header component as the default export
