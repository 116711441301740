/* The code snippet is defining a TypeScript constant `apiEndPoints` which is an object containing
various API endpoints as key-value pairs. Each key represents a specific API endpoint and its
corresponding value is the URL path for that endpoint. This object can be exported and used
throughout the application to easily access and manage the API endpoints. */
export const apiEndPoints = {
  guestLogin: '/v1/users/fPrxO1cd41PZmKFQ',
  newsFeedAction: '/v1/news_feed/action', //guest
  fetchAllPosts: '/v3/news_feed/merchant', //guest
  fetchBaseComments: '/v1/news_feed/base_comments', //guest
  fetchCommentReply: '/v1/news_feed/comment_replies', //guest
  fetchPages: '/v3/news_feed/page_list', //guest
  fetchPostById: '/v3/news_feed/fetch_news_feed_post_by_id', //guest
  fetchRevelantPosts: '/v3/news_feed/relevant_post_merchant', //guest
  postComments: '/v3/news_feed/comment',
  updateMerchantName: '/v4/merchant/profile',
  createSauda: '/v1/sauda/create_sauda',
  getMySaudaList: '/v1/sauda/sauda_list',
  fetchBuyerLocationList: '/v1/location/fetch_state_districts_of_buyers_ml', //guest
  fetchCropList: '/v1/crop/fetch_crop_list_ml', //guest
  fetchCropVarietyList: '/v1/crop/fetch_crop_variety_ml', //guest
  fetchMerchantProfile: '/merchant/get_merchant_profile_v4',
  fetchSaudaDetails: '/v1/sauda/details',
  fetchStateDistrictsReverseGeoCode: '/v1/location/reverse_geocode',
  fetchTradePriceBreakup: '/v1/trade/fetch_trade_details', //guest
  fetchTradeList: '/v1/trade/fetch_trade_list', //guest
  fetchTradeServiciability: '/v1/trade/fetch_trade_serviceability_details', //guest
  fetchTruckDetails: '/v1/sauda/truck_details',
  getOTP: '/v3/auth/EcMHB9bZkwy8Njhabdkanskkqpek',
  setIPLocationLogs: '/v1/location/ip_address_location',
  updateUserPersona: '/v1/merchant/merchant_occupation', //guest
  updatePOStatus: '/v1/sauda/post_supplier_po_decision',
  updateUserFeatureLocation: '/v1/home_screen/feature_location',
  validateOTP: '/v3/auth/verify_otp',
  homeScreenSectionData: '/v1/home_screen/feature_list', //guest
  getRakeStatus: '/v3/rake/history', //guest
  weatherDetail: '/v1/weather/weekly_weather_detail', //guest
  fetchCropAdvisoryList: '/v2/crop_advisory/crop_details',
  fetchBahiKhata: '/v2/passbook/fetch_credit_amount_total',
  smsBalance: '/v1/sms/sms_balance',
  updateMerchantLocation: '/v1/merchant/location',
  recentQuizDetails: '/v1/quiz/recent',
  verifyBrokerCode: '/v1/sauda/verify_broker_code',
  getPinCode: '/v3/location/search_pincode',
  getNearByRake: '/v1/location/nearby_districts',
  getFAQQuestion: '/v1/faq', //guest,
  farmaerMobileNumber: '/v3/farmer/mobile_numbers',
  fetchFarmerOfUser: '/v1/farmer/ordered_list',
  deleteFarmers: '/v1/farmer/profile',
  addFarmers: '/v3/farmer',
  getMerchantProfile: '/merchant/get_merchant_profile_v4',
  getCurrentAppVersion: '/v1/app_version/app_version_details',
  uploadImage: '/global/image_upload',
  getSMSBalance: '/v1/sms/sms_balance', //sms balance
  getSMSCategories: '/v2/sms/sms_categories', //sms categories for SMS Template list
  getSMSTemplate: '/v2/sms/sms_template', //sms Template
  smsPassBook: '/v2/sms/fetch_sms_passbook',
  smsHistoryDetail: '/v2/sms/sms_history_detail',
  sendSms: '/v4/message/send',
};
