/* The provided code snippet is a TypeScript React application that sets up the main entry point for a
React application. Here is a breakdown of what each part of the code is doing: */
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
/* The line `import reportWebVitals from "./reportWebVitals";` is importing a module or file named
`reportWebVitals` from the relative path `"./reportWebVitals"`. This import statement is used to
bring in a function or module defined in the `reportWebVitals.js` file (or any other file format
specified) for further use in the TypeScript React application. */
import reportWebVitals from './reportWebVitals';
/* The `import` statement you provided is importing specific named exports from the
`"react-router-dom"` library in a single line. Here's a breakdown of what each named export is used
for: */
import {
  BrowserRouter,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';
/* The `import { PlatformContextProvider } from "./hooks/CrossPlatformActionHandler/platformChecker";`
statement is importing the `PlatformContextProvider` component from a file located at
`"./hooks/CrossPlatformActionHandler/platformChecker"`. */
import { PlatformContextProvider } from './hooks/CrossPlatformActionHandler/platformChecker';
/* The `import { CssVarsProvider } from "@mui/joy/styles";` statement is importing the
`CssVarsProvider` component from the `@mui/joy/styles` module. This module likely contains styling
utilities or components related to managing CSS variables (CSS custom properties) within a
Material-UI (MUI) based React application. */
import { CssVarsProvider } from '@mui/joy/styles';

/* The line `import { store } from "./store";` is importing a named export `store` from a file located
at `"./store"`. This import statement is likely bringing in a Redux store instance that has been
configured and created in the `store.js` or `store.ts` file for use in the TypeScript React
application. */
import { store } from './store';
/* The `import { Provider } from "react-redux";` statement is importing the `Provider` component from
the `"react-redux"` library. */
import { Provider } from 'react-redux';
import './fonts/NotoSansRegular.ttf';
import './fonts/NotoSansDevanagari.ttf';
/* The line `import MuiTheme from "./constant/MuiTheme";` is importing a module or file named
`MuiTheme` from the relative path `"./constant/MuiTheme"`. This import statement is likely bringing
in a file that contains a predefined theme object or configuration related to the Material-UI (MUI)
theme used in the React application. */
import MuiTheme from './constant/MuiTheme';
/* The `import { AuthProvider } from "./context/login";` statement is importing the `AuthProvider`
component from a file located at `"./context/login"`. This import is likely bringing in a module or
component related to handling authentication functionality within the React application. The
`AuthProvider` component is commonly used in React applications to manage user authentication state,
provide authentication context, and handle login/logout functionality across different parts of the
application. */
import { AuthProvider } from './context/login';
/* The line `import * as Sentry from "@sentry/react";` is importing the Sentry SDK module from the
`@sentry/react` package into the TypeScript React application. */
import * as Sentry from '@sentry/react';

/* The `import { LocationProvider } from "./context/location";` statement is importing the
`LocationProvider` component from a file located at `"./context/location"`. This import is likely
bringing in a module or component related to managing location information within the React
application. */
import { LocationProvider } from './context/location';

/* `<BrowserRouter>` is a component provided by the `react-router-dom` library in React. It is used
  to wrap the application and provide routing functionality to the components within it.
The Provider component is a part of the React Redux library. It is used to
    provide the Redux store to the entire React application so that all components can access the
    store and interact with the global state managed by Redux.
The `<PlatformContextProvider>` component is part of a custom React context setup in the code
      snippet provided. It is used to provide a context for checking the platform on which the
      application is running. This context provider likely contains logic or data related to
      determining the platform (such as web, mobile, desktop, etc.) and makes this information
      available to any components that need to access it within the application.
The `<AuthProvider>` component in the provided code snippet is responsible for integrating a
       merchant login functionality using React context.
The `<CssVarsProvider>` component with the `theme` prop set to `MuiTheme` is likely
          providing a way to manage and apply custom CSS variables (CSS custom properties) within
          the React application. */

//...
Sentry.init({
  dsn: 'https://ef3c214d4b7f018751830206776ffc1e@o1094278.ingest.us.sentry.io/4507191464099840',
  integrations: [
    // See docs for support of different versions of variation of react router
    // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect: React.useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  tracesSampleRate: 0.01,
  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.01,
  replaysOnErrorSampleRate: 0.01,
  environment: import.meta.env.VITE_ENV,
});

/* The code snippet you provided is setting up the main entry point for a React application using
TypeScript. Let's break down what the code is doing: */
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <Provider store={store}>
      <PlatformContextProvider>
        <AuthProvider>
          <CssVarsProvider theme={MuiTheme}>
            <LocationProvider>
              <App />
            </LocationProvider>
          </CssVarsProvider>
        </AuthProvider>
      </PlatformContextProvider>
    </Provider>
  </BrowserRouter>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);
