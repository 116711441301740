import getMerchantProfile from '../../../apis/Trader/fetchMerchantProfile';

// Import the GetMerchantProfile type.
import { GetMerchantProfile } from '../types';

// Define an asynchronous function named getUserProfile.
// It takes two optional parameters: payload (default is an empty object) and token (default is an empty string).
export default async function getUserProfile(
  payload = {},
  token = '',
): Promise<GetMerchantProfile> {
  // Create a body object by spreading the properties of payload.
  const body = {
    ...payload,
  };

  try {
    // Await the result of calling getMerchantProfile with the body and token.
    const result = await getMerchantProfile(body, token);

    // If the status is false, throw the result as an error.
    if (!result.data.status) {
      throw result;
    }

    // Return the data
    return result.data.data;
  } catch (error) {
    // If the error object does not have a message property, set a default error message.
    if (!error.message) {
      error.message = 'Something Went Wrong';
    }

    // Rethrow the error to be handled by the calling code.
    throw error;
  }
}
