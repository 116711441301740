import React, { useState, ReactNode } from 'react';
import './Tooltip.css';

interface TooltipPropsInterface {
  contentTitle: string;
  contentDetails: string;
  children?: ReactNode;
  analyticEvents?: () => void;
  id?: string;
}

const TooltipProps = (props: TooltipPropsInterface) => {
  const {
    contentTitle,
    contentDetails,
    children,
    analyticEvents,
    id = '',
  } = props;
  const [tooltipVisible, setTooltipVisible] = useState(false);

  const handleMouseOver = () => {
    setTooltipVisible(true);
  };

  const handleMouseOut = () => {
    setTooltipVisible(false);
  };

  const handleClick = () => {
    setTooltipVisible(!tooltipVisible);
    analyticEvents();
  };
  return (
    <div
      className="tooltip"
      onMouseOver={handleMouseOver}
      onMouseOut={handleMouseOut}
      onClick={handleClick}
      id={id}
    >
      {children}
      {tooltipVisible && (
        <div className="tooltip-content">
          <div className="disclaimerTitle">{contentTitle}</div>
          <div className="disclaimerDetails">{contentDetails}</div>
        </div>
      )}
    </div>
  );
};

export default TooltipProps;
