import React, { lazy, Suspense } from 'react';
import { Header } from '../../Components/Header/Header';
import style from './style.module.css';
import { Post as PostType, Page, Post } from '../../modal/community/types';
import {
  filterSkeleton,
  postSkeleton,
} from './CommunitySkeleton/CommunitySkeleton';
import InfiniteScroll from '../../Components/InfintyScroll';
import ScreenType from '../../constant/screenEnums';
import AddToHome from './AddToHome';
import AddToHomePersistant from './AddToHomePersistant';

const Categories = lazy(() => import('../../Components/Categories/Categories'));
const Card = lazy(() => import('../../Components/Card/Card/Card'));

interface CommunityPageProps {
  posts: PostType[];
  isFetchingAllPosts: boolean;
  pages: Page[];
  isFetchingAllPages: boolean;
  onClap: (id: number, screen: number, headingText: string) => () => void;
  onComment: (id: number, screen: number, postHeading?: string) => () => void;
  onShare: (
    id: number,
    heading: string,
    screen: number,
    headingText: string,
  ) => () => void;
  onViewPost: (
    id: number,
    heading?: string,
    screen?: string,
    post?: Post,
  ) => () => void;
  onClickPage: (id: number, name?: string) => () => void;
  selectedPage: number;
  loadMorePost: () => void;
  fetchMorePages: () => void;
  handleInstallClick: () => void;
  deferredPrompt: object;
  isInstalled: boolean;
  isFirstLaunch: boolean;
  onClose: () => void;
  language: { [key: string]: any };
  communityAssets: { [key: string]: string };
}

const Community = (props: CommunityPageProps) => {
  const {
    posts,
    isFetchingAllPosts,
    onClap,
    onComment,
    onShare,
    onViewPost,
    isFetchingAllPages,
    pages,
    onClickPage,
    selectedPage,
    loadMorePost,
    fetchMorePages,
    handleInstallClick,
    isInstalled,
    isFirstLaunch,
    onClose,
    language,
    communityAssets,
  } = props;

  const postList = posts.map(function (post: PostType) {
    // doing this because we are mutating the post object
    // to set created_by to UserDeleted if the user is deleted
    // we cant mutate the object directly as it is a readonly object
    // due to redux toolkit, reduxtoolkit uses immer under the hood
    // which makes the state object readonly
    post = { ...post };

    let thumbnail = post.image_thumbnails ? post.image_thumbnails : [];

    post.created_by = post.created_by || {
      name: 'USER DELETED',
      district: '',
      state: '',
      profile_pic_url: '',
      id: '',
      page: false,
      is_verified: false,
    };

    return (
      <Suspense key={post.id} fallback={postSkeleton()}>
        <Card
          name={post.created_by.name}
          state={post.created_by.state}
          heading={post.heading_text}
          clap={post.news_feed_stats.claps}
          comment={post.news_feed_stats.comment_count}
          share={post.news_feed_stats.share_count}
          profilepic={post.created_by.profile_pic_url}
          postpic={thumbnail}
          key={post.id + 'homeScreenList'}
          id={post.id}
          onClap={onClap(
            post.id,
            ScreenType.get('homeScreen'),
            post.heading_text,
          )}
          onComment={onComment(
            post.id,
            ScreenType.get('homeScreen'),
            post.heading_text,
          )}
          onShare={onShare(
            post.id,
            post.heading_text,
            ScreenType.get('homeScreen'),
            post.heading_text,
          )}
          onViewPost={onViewPost(
            post.id,
            post.heading_text,
            ScreenType.get('homeScreen'),
            post,
          )}
          postedAgo={post.live_from}
          verifiedPost={post.created_by.is_verified}
          postTypographyData={post.formatted_long_description}
          page={post.created_by.page}
          shortDescription={post.short_description}
          isClapped={
            post.news_feed_stats.claps ||
            post.news_feed_stats.user_clap_count > 0
              ? true
              : false
          }
          onClapButtonId="homeScreenPostCardOnClapButtonId"
          onCommentButtonId="homeScreenPostCardOnCommentButtonId"
          onShareButtonId="homeScreenPostCardOnShareButtonId"
          language={language}
          companyProfileIcon={communityAssets.companyProfileIcon}
          whatsappIcon={communityAssets.whatsappIcon}
          clapIcon={communityAssets.clapIcon}
          clapDoneIcon={communityAssets.clapDoneIcon}
        />
      </Suspense>
    );
  });
  return (
    <div className={style.container}>
      <Header avatarIcon={communityAssets.avatarIcon} />
      {!isInstalled && !isFirstLaunch && (
        <AddToHomePersistant
          handleInstallClick={handleInstallClick}
          farmartLogo={communityAssets.farmartLogo}
        />
      )}
      <Suspense fallback={filterSkeleton()}>
        <Categories
          pages={pages}
          isFetchingAllPages={isFetchingAllPages}
          onClickPage={onClickPage}
          selectedPage={selectedPage}
          fetchMorePages={fetchMorePages}
        />
      </Suspense>

      <AddToHome
        isOpen={!isInstalled && isFirstLaunch}
        onClose={onClose}
        handleInstallClick={handleInstallClick}
        addToHomeImage={communityAssets.addToHomeImage}
      />
      <InfiniteScroll
        vertical={true}
        onScrollToEnd={loadMorePost}
        children={postList}
        loading={isFetchingAllPosts}
        loadingElement={postSkeleton()}
      />
    </div>
  );
};

export default Community;
