import React from 'react';
import styles from './style.module.css';

export default function QuizView({ quizImage, onClickQuiz, disableQuizClick }) {
  return (
    <img
      src={quizImage}
      className={styles?.image}
      onClick={onClickQuiz}
      id={'homeScreenQuizCard'}
      aria-disabled={disableQuizClick}
      alt="quizImage"
      loading="eager"
    />
  );
}
