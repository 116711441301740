/**
 * The AddFarmer component renders a button to add a new farmer with specified language and onClick
 * functionality.
 * @param {AddFarmerProps} props - The `props` object in the `AddFarmer` component consists of two
 * properties:
 * @returns The `AddFarmer` component is being returned. It is a functional component that renders a
 * `Box` component from Material-UI with some styling properties. Inside the `Box`, there is a
 * `MuiButton` component with a startDecorator icon and an `onClick` event handler. The text inside the
 * button is dynamically set based on the `language` prop passed to the component.
 */
import React from 'react';
/* import styles from "./style.module.css"; */
import MuiButton from '../../../ComponentV3/MuiButton';
import AddFilledIcon from '../../../assets/svgComponet/AddFilledIcon';
import { Box } from '@mui/joy';
import styles from './style.module.css';
import PlatForms from '../../../constant/platFormEnums';
import { usePlatfromData } from '../../../hooks/CrossPlatformActionHandler/platformChecker';

/* The `interface AddFarmerProps` is defining the structure of the props that the `AddFarmer` component
expects to receive. It consists of two properties: */
interface AddFarmerProps {
  language: { [key: string]: string };
  onClickAddFarmer: () => void;
}
export default function AddFarmer(props: AddFarmerProps) {
  const { language, onClickAddFarmer } = props;
  const platform = usePlatfromData();
  const bottomSpace =
    platform.platform === PlatForms?.get('application') ? 24 : 80;

  return (
    <Box className={styles.mainContainer} bottom={bottomSpace} zIndex={100}>
      <MuiButton
        sx={{
          minHeight: '40px',
          padding: '4px 16px 4px 12px',
          alignContent: 'center',
          justifyContent: 'center',
          borderRadius: '8px',
          width: 'auto', // Ensure width is set to auto
          whiteSpace: 'nowrap', // Prevents text from wrapping if it's too long
          marginRight: '16px',
        }}
        size="md"
        color="primary"
        startDecorator={<AddFilledIcon />}
        onClick={onClickAddFarmer}
        id="addFarmarPwa"
      >
        {language?.addNewFarmerButton}
      </MuiButton>
    </Box>
  );
}
