import setIpLocationLogs from '../../../apis/Trader/setIpLocationLogs';

interface PayloadType {
  lat_long: string;
}

interface Response {
  ip_address: string;
}
export default async function postIpLocationLog(
  payload: PayloadType,
): Promise<Response> {
  const body = {
    ...payload,
  };
  try {
    const result = await setIpLocationLogs(body);

    return result.data.data;
  } catch (error) {
    if (!error.message) {
      error.message = 'Something Went Wrong';
    }

    throw error;
  }
}
