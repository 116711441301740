/* This code snippet is a TypeScript React component that renders a list of SMS history data grouped by
month. Here's a breakdown of what the code is doing: */
import React from 'react';
import { smsData } from '../../../hooks/SmsHistoryHook/types';
import SmsHistoryCard from '../SmsHistoryCard/SmsHistoryCard';
import { Box } from '@mui/joy';
import MuiTypography from '../../../ComponentV3/Typography';

interface SmsListProps {
  smsHistoryData: {
    [key: string]: smsData[];
  };

  onClickSms: (smsDetail: smsData) => void;
  language: { [key: string]: string };
  fetchMoreData: () => void;
}

export default function HisoryList(props: SmsListProps) {
  const { smsHistoryData, onClickSms, language } = props;
  return Object.keys(smsHistoryData).map((month, index) => (
    <Box key={index}>
      <Box display={'flex'} margin={'0px 16px 12px 16px'}>
        <Box
          display={'flex'}
          flex={1}
          justifyContent={'center'}
          alignItems={'center'}
          gap={'8px'}
        >
          <Box style={{ flex: 1, borderBottom: '1px solid #636B744D' }} />
          <MuiTypography level="body-xxs" textColor={'text.tertiary'}>
            {month}
          </MuiTypography>
          <Box style={{ flex: 1, borderBottom: '1px solid #636B744D' }} />
        </Box>
      </Box>
      <Box>
        {smsHistoryData[month].map((item, index) => (
          <SmsHistoryCard
            key={index}
            item={item}
            onClickSms={onClickSms}
            language={language}
            index={index}
          />
        ))}
      </Box>
    </Box>
  ));
}
