export const occupation = [
  { label: 'retailer', id: 1 },
  { label: 'distributer', id: 2 },
  { label: 'farmerCooperativeSociety', id: 3 },
  { label: 'farmerProduceCompany', id: 4 },
  { label: 'farmer', id: 5 },
  { label: 'other', id: 6 },
  { label: 'notAnswered', id: 7 },
  { label: 'rozanaUser', id: 8 },
  { label: 'trader', id: 9 },
];

type OccupationLabel = (typeof occupation)[number]['label'];
const occupationIndex: Record<OccupationLabel, number> = {};

occupation.forEach(function (userType) {
  occupationIndex[userType.label] = userType.id;
});

export const userType = occupationIndex;
