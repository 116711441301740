import React from 'react';
import Styles from './style.module.css';
import CalendarIcon from '../../../assets/svgComponet/CalendarIcon';
import TodayRoundedIcon from '@mui/icons-material/TodayRounded';
import { TradePriceBreakupData } from '../../../modal/Traders/types';
interface TradeDetailProps {
  PriceBreakup: TradePriceBreakupData;
  language?: { [key: string]: any };
}
const OrderDetails = (props: TradeDetailProps) => {
  const { PriceBreakup, language } = props;

  const indianDate = new Date(
    PriceBreakup.fulfillment_time?.full_date,
  ).toLocaleString('en-IN', {
    timeZone: 'Asia/Kolkata',
    day: 'numeric',
    month: 'short',
    year: 'numeric',
  });

  const longDateIN = new Date(
    PriceBreakup.fulfillment_time?.full_date,
  ).toLocaleString('en-IN', {
    timeZone: 'Asia/Kolkata',
    day: 'numeric',
    month: 'long',
    year: 'numeric',
  });

  const renderTextWithColors = (text: string) => {
    const splitText = text.split(/{|}/);

    return splitText?.map((item, index) => {
      if (item.startsWith('(') && item.endsWith(')')) {
        return (
          <span key={'buyerDetailOrderDateText' + index}>{longDateIN}</span>
        );
      }
      return <>{item}</>;
    });
  };

  return (
    <div className={Styles.wrapper}>
      <div className={Styles.titleBar}>
        <div className={Styles.iconWrapper}>
          <TodayRoundedIcon
            htmlColor="#242725"
            style={{ height: 20, width: 20 }}
          />
        </div>
        <div className={Styles.headingText}>
          {language?.orderFulfilmentDate}
        </div>
      </div>
      <div className={Styles.detailsWrapper}>
        <div className={Styles.fulfilmentTimeText}>
          {indianDate} ({PriceBreakup?.fulfillment_time?.value}{' '}
          {PriceBreakup?.fulfillment_time?.unit})
        </div>
        <div className={Styles.disclaimerText}>
          {renderTextWithColors(language?.fulfilmentNote)}
        </div>
      </div>
    </div>
  );
};

export default OrderDetails;
