/* export { default as getCommunityFeed } from "./models/getCommunityFeed"; */
//use above as example

/* export * from "./types"; */
import fetchSaudaDetail from '../../../apis/Trader/fetchSaudaDetail';

import { SaudaDetailResp } from '../types';

interface PayloadType {
  sauda_id: number;
}

export default async function getSaudaDetails(
  payload: PayloadType,
): Promise<SaudaDetailResp> {
  const body = {
    ...payload,
  };
  try {
    const result = await fetchSaudaDetail(body);

    if (!result.data.status) {
      throw result;
    }

    return result.data.data;
  } catch (error) {
    if (!error.message) {
      error.message = 'Something Went Wrong';
    }

    throw error;
  }
}
