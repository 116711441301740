export interface PostList {
  count: number;
  rows: Post[];
}

export interface Post {
  id: number;
  news_feed_post_id: string;
  live_from: string;
  content_type_category_id: string;
  heading_text: string;
  short_description: string;
  formatted_long_description: string;
  image_thumbnails: string[];
  created_by: {
    id: string;
    name: string;
    state: string;
    district: string;
    is_verified: boolean;
    page: boolean;
    profile_pic_url: string;
  };
  news_feed_stats: {
    comment_count: number;
    view_count: number;
    share_count: number;
    claps: number;
    user_clap_count: number;
  };
}

export enum PostActionApiEnums {
  // these are the enums we need to maintain
  // for the api
  LIKE = 1,
  SHARE = 2,
  SHARE_ON_WHATS_APP = 3,
  SHARE_ON_SMS = 4,
  CLICK_ON_NEWS_FEED = 5,
  SELECT_CAT_AGRI_PRODUCTS = 6,
  SELECT_CAT_NEW_ARRIVALS = 7,
  SELECT_CAT_BEST_AGRICULTURE_PRACTICES = 8,
  SELECT_CAT_AGRI_NEWS = 9,
  SELECT_CAT_FUN_FACTS = 10,
  DISLIKE = 11,
  COMMENT = 12,
  CLAP = 13,
}

export enum PostActionControllerEnums {
  // these are the enums we need to maintain
  // for the controller, we will be using these
  // enums to map the action to the api enums
  // we are speerating these enums because we
  // want to maintain a layer of abstraction
  // between the controller and the api
  LIKE = 'LIKE',
  SHARE = 'SHARE',
  SHARE_ON_WHATS_APP = 'SHARE_ON_WHATS_APP',
  SHARE_ON_SMS = 'SHARE_ON_SMS',
  CLICK_ON_NEWS_FEED = 'CLICK_ON_NEWS_FEED',
  SELECT_CAT_AGRI_PRODUCTS = 'SELECT_CAT_AGRI_PRODUCTS',
  SELECT_CAT_NEW_ARRIVALS = 'SELECT_CAT_NEW_ARRIVALS',
  SELECT_CAT_BEST_AGRICULTURE_PRACTICES = 'SELECT_CAT_BEST_AGRICULTURE_PRACTICES',
  SELECT_CAT_AGRI_NEWS = 'SELECT_CAT_AGRI_NEWS',
  SELECT_CAT_FUN_FACTS = 'SELECT_CAT_FUN_FACTS',
  DISLIKE = 'DISLIKE',
  COMMENT = 'COMMENT',
  CLAP = 'CLAP',
}

export interface PageList {
  count: number;
  pages: Page[];
}

export interface BaseCommentList {
  comments: BaseComment[];
  count: number;
}

export interface BaseComment {
  comment_id: number;
  comment: string;
  merchant_name: string;
  merchant_profile_img: string;
  merchant_detail_id: number;
  created_at: string;
  reply_count: number;
  pinned_by_farmart: boolean;
  nested_replies?: NestedComment[];
  nestead_loading?: boolean;
  merchant_pic?: string;
}

export interface NestedCommentList {
  replies: NestedComment[];
  count: number;
}
export interface NestedComment {
  comment_id: number;
  comment: string;
  merchant_name: string;
  merchant_profile_img: string;
  merchant_detail_id: number;
  created_at: string;
  tagged_merchant_name: string;
}

export interface CommonCommentInterface {
  comment_id: number;
  comment: string;
  merchant_name: string;
  merchant_profile_img: string;
  merchant_detail_id: number;
  created_at: string;
  tagged_merchant_name?: string;
  reply_count?: number;
  pinned_by_farmart?: boolean;
  nested_replies?: NestedCommentList[];
  merchant_pic?: string;
}

export interface CommentRelyList {
  replies: Comment[];
  count: 0;
}

export interface Page {
  page_id: number;
  page_name: string;
  language_id: string;
  is_enabled: boolean;
  is_verified: boolean;
  image_url: string | null;
}

export interface PostDetailsById {
  id: number;
  created_at: string;
  updated_at: string;
  news_feed_catagory_id: number;
  news_feed_sub_catagory_id: number;
  content_type_catagory_id: number;
  is_active: number;
  merchant_detail_id: number;
  user_profile_id: number;
  heading_text: string;
  short_description: string;
  long_description: string;
  formatted_long_description: string;
  image_thumbnail: string;
  featured_image: string;
  video: string;
  video_thumbnail: string;
  s3_video_link: string;
  narrative_audio_link: string;
  no_of_likes: number;
  comment_count: number;
  liked_by_merchant: boolean;
  no_of_shares: number;
  dislike_count: number;
  disliked_by_merchant: boolean;
  view_count: number;
  is_viewed: boolean;
  total_clap_count: number;
  merchant_clap_count: number;
  locked: boolean;
  editor_type: number;
  created_by: {
    district: string | null;
    id: number;
    is_verified: boolean;
    name: string;
    page: true;
    profile_pic_url: string;
    state: string | null;
  };
  tags: string[];
}

export interface createdBy {
  district: string | null;
  id: number;
  is_verified: boolean;
  name: string;
  page: true;
  profile_pic_url: string;
  state: string | null;
}
export interface Comment {
  nestead_loading: any;
  comment_id: number;
  comment: string;
  merchant_name: string;
  merchant_profile_img: string;
  merchant_detail_id: number;
  created_at: string;
  reply_count: number;
  tagged_merchant_name: string;
  pinned_by_farmart: boolean;
  nested_replies: any;
}

export interface MerchantNameUpdate {}

export interface CommentReply {
  comment_id: number;
  comment: string;
  merchant_name: string;
  merchant_pic: string;
  created_at: string;
  is_pinned: boolean;
  merchant_detail_id: number;
}

export interface ReplyComment {
  ref_comment_id?: number;
  tagged_merchant_id?: number;
  comment_id?: number;
  comment_index?: number;
  tagged_name?: string;
}

export interface CreateComment {
  news_feed_id: number;
  comment: string;
  ref_comment_id?: number;
  tagged_merchant_id?: number;
}
