import {
  BaseComment,
  PostDetailsById,
  PostList,
  Post,
  PostActionApiEnums,
} from '../../modal/community/communityModel';

import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import ScreenType from '../../constant/screenEnums';

import {
  increamentLikeCountPost,
  increamentShareCountPost,
} from '../../modal/community/slice/community.slice';

import { Dispatch, SetStateAction } from 'react';

import { usePlatfromData } from '../../hooks/CrossPlatformActionHandler/platformChecker';
import useVisitedPostHistory from './useVisitedPostHistory';
import { trackEvent } from '../../utils/moengage';
import Events from '../../constant/MoengageEvents';

import type { RootState } from '../../store';
import getLang from '../../languages/community.js';
import { DEFAULT_LANGUAGE } from '../../constant/language';
import PlatForms from '../../constant/platFormEnums';
import downloadAppModalType from '../../constant/enums/downloadAppModalType';
import { getUserProfileFromStorage } from '../../modal/auth/auth.model';
import { createBridge } from '../../rpcBridge';
import bridgeFunctions from '../../utils/bridge';
import useAuthenticationChecker from '../../utils/authChecker';
import { apiEndPoints } from '../../constant/apiEndPoints';
import { getCommunityAssets } from '../../constant/imageUrls';

interface UsePostAction {
  onClap: (id: number, screen: number, headingText: string) => () => void;
  onComment: (id: number, screen: number, postHeading?: string) => () => void;
  onShare: (
    id: number,
    heading: string,
    screen: number,
    headingText: string,
  ) => () => void;
  onViewPost: (
    id: number,
    heading?: string,
    screen?: any,
    post?: Post,
  ) => () => void;
  language: { [key: string]: any };
  communityAssets: { [key: string]: string };
}

export default function usePostAction(
  setCommentInputVisible?: Dispatch<SetStateAction<boolean>>,
  commentInputVisible?: boolean,
  setRefCommentId?: Dispatch<SetStateAction<number>>,
  setTaggedMerchantId?: Dispatch<SetStateAction<number>>,
  setCurrentPostId?: Dispatch<SetStateAction<number>>,
  setBaseCommentPageNo?: Dispatch<React.SetStateAction<number>>,
  setBaseCommentList?: Dispatch<React.SetStateAction<BaseComment[]>>,
  setPostDetailsData?: Dispatch<React.SetStateAction<PostDetailsById>>,
  setRelevantPostList?: Dispatch<SetStateAction<PostList>>,
  relevantPostList?: PostList,
): UsePostAction {
  const platformData = usePlatfromData();
  const navigate = useNavigate();
  const { AuthenticationCheckerAndApiCaller } = useAuthenticationChecker();
  const langId = JSON.parse(localStorage.getItem('userLanguage'));
  const language = getLang(langId?.langCode || DEFAULT_LANGUAGE);
  const { getPreviousPostId, onPostClick } = useVisitedPostHistory();
  const communityAssets = getCommunityAssets(langId?.langCode);
  let userProfile = getUserProfileFromStorage() || null;
  const merchantOccupationId = userProfile?.merchant_occupation_id;
  const bridge = createBridge();

  const { pages, pageNo, selectedPage } = useSelector((state: RootState) => {
    return {
      pages: state.community.pages,
      pageNo: state.community.pages.currentPageNumber,
      selectedPage: state.community.pages.selectedPageId,
    };
  });

  const dispatch = useDispatch();
  const location = useLocation();

  let tokenExpiredCount = 0;

  function onClap(
    id: number,
    screen: number,
    headingText: string,
    postDetailsData?: PostDetailsById,
  ): () => void {
    return async function () {
      try {
        if (ScreenType.get('postDetailScreen') === screen) {
          trackEvent(Events.onClapButtonClick, {
            From: 'Post_Detail_Screen',
            Post_id: id,
            User_id: postDetailsData?.merchant_detail_id,
            Clap_count: postDetailsData?.total_clap_count,
          });
          setPostDetailsData((prevState) => ({
            ...prevState,
            liked_by_merchant: true,
            total_clap_count: prevState.total_clap_count + 1,
            merchant_clap_count: prevState.merchant_clap_count + 1,
          }));
          dispatch(increamentLikeCountPost(id));
        } else if (ScreenType.get('postDetailRelevant') === screen) {
          const index = relevantPostList.rows.findIndex(
            (post) => post.id === id,
          );
          if (index !== -1) {
            const updatedList = { ...relevantPostList };
            updatedList.rows[index].news_feed_stats.claps =
              updatedList.rows[index].news_feed_stats.claps + 1;
            updatedList.rows[index].news_feed_stats.user_clap_count =
              updatedList.rows[index].news_feed_stats.user_clap_count + 1;
            setRelevantPostList(updatedList);
          }
        } else {
          dispatch(increamentLikeCountPost(id));
        }
        await actionApi(id, PostActionApiEnums.CLAP);
      } catch (error) {
        console.log(error);
      }
    };
  }

  async function actionApi(id: number, action: number) {
    await AuthenticationCheckerAndApiCaller({
      payload: {
        news_feed_action: action,
        news_feed_id: id,
      },
      uri: apiEndPoints?.newsFeedAction,
      requestType: 'post',
    });
  }

  function onComment(
    id: number,
    screen: number,
    postHeading?: string,
    postDetailsData?: PostDetailsById,
  ): () => void {
    return async function () {
      if (screen === ScreenType.get('homeScreen')) {
        onPostClick(id);
        navigate(`/community/${id}/${postHeading}`, {
          state: {
            postId: id,
            navigateViaComment: true,
          },
        });
      } else if (screen === ScreenType.get('postDetailRelevant')) {
        trackEvent(Events.onCommentButtonClick, {
          From: 'Post_Detail_Screen',
          Post_id: id,
          User_id: postDetailsData?.merchant_detail_id,
          No_of_Comment: postDetailsData?.comment_count,
        });
        setBaseCommentList([]);
        setBaseCommentPageNo(1);
        setCurrentPostId(id);
        setRefCommentId(null);
        setTaggedMerchantId(null);
      } else {
        setCommentInputVisible(!commentInputVisible);
        setRefCommentId(null);
        setTaggedMerchantId(null);
      }
    };
  }

  function onShare(
    id: number,
    postHeading?: string,
    screen?: number,
    headingText?: string,
    postDetailsData?: PostDetailsById,
  ): () => void {
    return async function () {
      try {
        if (ScreenType.get('postDetailScreen') === screen) {
          setPostDetailsData((prevState) => ({
            ...prevState,
            no_of_shares: prevState.no_of_shares + 1,
          }));
          trackEvent(Events.onShareButtonClick, {
            From: 'Post_Detail_Screen',
            Post_id: id,
            User_id: postDetailsData?.merchant_detail_id,
            No_of_Comment: postDetailsData?.no_of_shares,
          });
          dispatch(increamentShareCountPost(id));
        } else if (ScreenType.get('postDetailRelevant') === screen) {
          const index = relevantPostList.rows.findIndex(
            (post) => post.id === id,
          );
          if (index !== -1) {
            const updatedList = { ...relevantPostList };
            updatedList.rows[index].news_feed_stats.share_count =
              updatedList.rows[index].news_feed_stats.share_count + 1;
            setRelevantPostList(updatedList);
          }
        } else {
          dispatch(increamentShareCountPost(id));
        }
        await actionApi(id, PostActionApiEnums.SHARE);
      } catch (error) {
        console.log(error);
      }
      window.open(
        encodeURI(
          `https://api.whatsapp.com/send?text=${
            import.meta.env.VITE_WEBSITE_LINK
          }/${id}/${postHeading}`,
        ),
      );
    };
  }

  function nativeAppNavigation(params: any) {
    bridge.sendRequestToNative(
      'nativeAppNavigationListner',
      params,
      (response: any) => {
        bridgeFunctions.PrintLog(response);
      },
    );
  }

  function onViewPost(
    id: number,
    postHeading?: string,
    screen?: number,
    post?: Post,
  ): () => void {
    return async function () {
      // on View
      // call api for view analytics

      try {
        await actionApi(id, PostActionApiEnums.CLICK_ON_NEWS_FEED);
      } catch (error) {
        console.log(error);
      }

      if (screen === ScreenType.get('homeScreen')) {
        onPostClick(id);
        trackEvent(Events.onCommunityHomeCardClick, {
          Page_name: '',
          Category_name: '',
          Post_id: post?.id,
          No_of_claps: post?.news_feed_stats?.claps,
          No_of_comments: post?.news_feed_stats?.comment_count,
          No_of_shares: post?.news_feed_stats?.share_count,
          Content_format: post?.content_type_category_id,
        });

        if (platformData?.platform === PlatForms?.get('website')) {
          navigate(`/community/${id}/${postHeading}`, {
            state: {
              postId: id,
              navigateViaComment: false,
            },
          });
        } else {
          if (location?.pathname === '/community') {
            navigate(`/community/${id}/${postHeading}`, {
              state: {
                postId: id,
                navigateViaComment: false,
              },
            });
          } else {
            nativeAppNavigation({
              featureId: downloadAppModalType.get('newsFeed'),
              merchantOccupationId,
              newsFeedId: id,
              newsFeedHeading: postHeading,
            });
          }
        }
      } else {
        if (screen === ScreenType.get('postDetailRelevant')) {
          trackEvent(Events?.onCommunityRecommendedCardClick, {
            Page_name: '',
            Category_name: '',
            Post_id: post?.id,
            No_of_claps: post?.news_feed_stats?.claps,
            No_of_comments: post?.news_feed_stats?.comment_count,
            No_of_shares: post?.news_feed_stats?.share_count,
            Content_format: post?.content_type_category_id,
          });
        }
        setBaseCommentList([]);
        setBaseCommentPageNo(1);
        setCurrentPostId(id);
        setRefCommentId(null);
        setTaggedMerchantId(null);
      }
    };
  }

  return {
    onClap,
    onComment,
    onShare,
    onViewPost,
    language,
    communityAssets,
  };
}
