import React, { FC } from 'react';
import Styles from './styles.module.css';

interface TabProps {
  title: string;
  content: React.ReactNode;
  active?: boolean;
  onClick: () => void;
}

const Tab: FC<TabProps> = ({ title, content, active, onClick }) => {
  return (
    <li className={active ? Styles.active : Styles.inactive} onClick={onClick}>
      <a>{title}</a>
      {active ? (
        <div className={Styles.borderBottom} />
      ) : (
        <div className={Styles.inActiveBorderBottom} />
      )}
    </li>
  );
};

const Tabs = (props: {
  active: string;
  handleChange: (id: string) => void;
  title1?: string;
  title2?: string;
}) => {
  const { active, handleChange, title1, title2 } = props;
  const [activeTab, setActiveTab] = React.useState('tab1');

  return (
    <div className={Styles.tabs}>
      <ul>
        <Tab
          title={title1}
          content={<div id="tab1">Content for Tab 1</div>}
          active={active === 'tab1'}
          onClick={() => handleChange('tab1')}
        />
        <Tab
          title={title2}
          content={<div id="tab2">Content for Tab 2</div>}
          active={active === 'tab2'}
          onClick={() => handleChange('tab2')}
        />
      </ul>
    </div>
  );
};

export default Tabs;
