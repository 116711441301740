import React from 'react';
import { DocRenderer } from '@cyntler/react-doc-viewer';
import { PDFRenderer } from '@cyntler/react-doc-viewer';
import './MyCustomPDFRenderer.css'; // Import the CSS file

const MyCustomPDFRenderer: DocRenderer = ({ mainState }) => {
  return (
    <div id="my-pdf-renderer" className="custom-pdf-container">
      <PDFRenderer mainState={mainState} />
    </div>
  );
};

export default MyCustomPDFRenderer;

MyCustomPDFRenderer.fileTypes = ['pdf', 'application/pdf'];
MyCustomPDFRenderer.weight = 0;
