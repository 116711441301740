import React from 'react';
import Styles from './style.module.css';
import Button from '../../../ComponentV2/ButtonV2';

const NoLocation = ({ language, onChangeLocation }) => {
  return (
    <div
      className={Styles.mainContainer}
      data-testid="noLocationComponentTradeList"
    >
      <div className={Styles.imageContainer}>
        <iframe
          className={Styles.animationStyles}
          src="https://rive.app/s/oLcq86THtE_ofg8QKVnWAQ/embed"
          data-testid="locationIframeIdTradeList"
        ></iframe>
      </div>
      <div className={Styles.allowLocationContainer}>
        <div className={Styles.allowLocationLabel}>
          {language?.allowLocationDisclaimer}
        </div>
        <div onClick={onChangeLocation} className={Styles.helpButtonContainer}>
          <Button title={language?.allowLocationButton} />
        </div>
      </div>
    </div>
  );
};

export default NoLocation;
