import React, { useState, useEffect } from 'react';
import { Box, Button, Checkbox, Input, FormControl, FormLabel } from '@mui/joy';
import Modal from '../../../ComponentV2/Modal';
import Typography from '../../../ComponentV3/Typography';
import smsTemplateType from '../../../constant/enums/smsTemplateType';

export default function RateModal({
  language,
  showRateModal,
  onCloseRateModal,
  smsTypeId,
  inputValues,
  setInputValues,
}) {
  const [price, setPrice] = useState(
    inputValues?.fetilizer_price || inputValues?.price || '',
  ); // Add a state for the input value
  const [isUchitMulyaChecked, setIsUchitMulyaChecked] = useState(true); // Add a state for the checkbox

  useEffect(() => {
    if (price === '') {
      setIsUchitMulyaChecked(true); // Set uchitMulyaChecked to true if input is empty
    }
  }, [price]);

  const getModalHeading = () => {
    switch (Number(smsTypeId)) {
      case smsTemplateType.get('fertilizerSmsId'):
        return language?.fertilizerRate;
      case smsTemplateType.get('pesticideSmsId'):
      case smsTemplateType.get('buyCropSmsId'):
        return language?.cropRate;
      case smsTemplateType.get('seedSmsId'):
        return language?.seedRate;
      default:
        return language?.fertilizerRate;
    }
  };

  const getPriceKey = (smsTypeID: string) => {
    switch (Number(smsTypeID)) {
      case smsTemplateType.get('fertilizerSmsId'):
        return 'fetilizer_price';
      default:
        return 'price';
    }
  };

  const handleRateChange = (event) => {
    const maxLength = 15; // Define the maximum length
    const inputValue = event?.target?.value;
    if (inputValue.length > maxLength) {
      event.target.value = inputValue.substring(0, maxLength);
    }
    const priceKey = getPriceKey(smsTypeId);
    setInputValues((prevValues) => ({
      ...prevValues,
      [priceKey]: `${event?.target?.value}`,
    }));
    setPrice(event?.target?.value);
    if (event?.target?.value === '') {
      setIsUchitMulyaChecked(true); // Set uchitMulyaChecked to true if input is empty
    } else {
      setIsUchitMulyaChecked(false); // Uncheck uchitMulya if input is not empty
    }
  };

  const handleProceedClick = () => {
    const priceKey = getPriceKey(smsTypeId);
    if (isUchitMulyaChecked) {
      setInputValues((prevValues) => ({
        ...prevValues,
        [priceKey]: 'उचित मूल्य',
      }));
      setPrice(''); // Clear the input field
    } else {
      setInputValues((prevValues) => ({
        ...prevValues,
        [priceKey]: `${price}`,
      }));
    }
    onCloseRateModal(); // Close the modal
  };

  const modalContent = (
    <Box padding={4}>
      <Box>
        <FormControl>
          <FormLabel>{language?.enterYourOwnRate}</FormLabel>
          <Input
            placeholder={language?.rateInputPlaceholder}
            value={price}
            onChange={handleRateChange}
            sx={{
              input: {
                '&:focus': {
                  border: 'none',
                },
                //placeholder text style
                '&::placeholder': {
                  opacity: 0.64,
                  color: '#9FA6AD',
                },
              },
              width: '100%',
              borderRadius: 8,
            }}
            id={'smsTemplateRateInputId'}
          />
        </FormControl>
        <Typography
          display={'flex'}
          alignContent={'center'}
          justifyContent={'center'}
          level="body-xs"
          paddingY={7}
        >
          {language?.or}
        </Typography>
        <Box paddingBottom={7}>
          <Checkbox
            label={language?.uchitMulya}
            checked={isUchitMulyaChecked}
            onChange={(event) => {
              setIsUchitMulyaChecked(event.target.checked);
              if (event.target.checked) {
                setPrice(''); // Clear the input field when checkbox is checked
              }
            }}
          />
        </Box>
      </Box>
      <Box paddingTop={4}>
        <Button
          fullWidth
          size={'lg'}
          style={{ borderRadius: 6, height: 48 }}
          onClick={handleProceedClick}
          id="smsTemplateRateModalOnClickProceed"
        >
          {language?.proceed}
        </Button>
      </Box>
    </Box>
  );
  return (
    <Modal
      isOpen={showRateModal}
      onClose={onCloseRateModal}
      children={modalContent}
      title={getModalHeading()}
      id={'rateModalSMSTemplateId'}
      isCloseButtonOption={true}
    />
  );
}
