import { useEffect, useState } from 'react';

function PWA() {
  const [deferredPrompt, setDeferredPrompt] = useState(null);
  const [isInstalled, setIsInstalled] = useState(true);
  const [isFirstLaunch, setIsFirstLaunch] = useState(false);
  useEffect(() => {
    window.addEventListener('beforeinstallprompt', (event) => {
      // Prevent the browser's default behavior
      event.preventDefault();
      // Store the event for later use
      setDeferredPrompt(event);
      setIsInstalled(false);
    });
  }, []);

  useEffect(() => {
    let first_launch = localStorage.getItem('first_launch');
    if (!first_launch) {
      setIsFirstLaunch(true);
      localStorage.setItem('first_launch', 'true');
    }
  }, [isFirstLaunch]);

  const onClose = () => {
    setIsFirstLaunch(false);
  };

  const handleInstallClick = () => {
    if (deferredPrompt) {
      // Show the install prompt
      deferredPrompt.prompt();

      // Wait for the user to respond
      deferredPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === 'accepted') {
          console.log('User accepted the A2HS prompt');
        } else {
          console.log('User dismissed the A2HS prompt');
        }

        // Clear the deferredPrompt
        setDeferredPrompt(null);
      });
    }
  };
  return {
    deferredPrompt,
    setDeferredPrompt,
    handleInstallClick,
    isInstalled,
    isFirstLaunch,
    onClose,
  };
}

export default PWA;
