import * as AppLanguages from '../constant/appLanguages';
//language will be added here
const languages = {
  [AppLanguages.appLanguagesIndex.hindi]: {
    loginText: 'लॉग इन करें',
    loginHeading: 'अपना 10 अंकों का मोबाइल नंबर दर्ज करें',
    numerIntputTitle: 'यहां नंबर दर्ज करें',
    termsAndCondtions:
      'लॉग इन से आप {(गोपनीयता नीति)}, {(नियमों और शर्तों)} को स्वीकार करते है',
    help: 'सहायता',
    selectLanguageButton: 'आगे बढ़ें',
    errorMobileNumber: 'कृपा सही मोबाइल नंबर डालें',
    otpTitle: 'ओटीपी दर्ज करें',
    otpSentOnMobile: '{(variable)} पर ओटीपी भेज दिया गया है',
    changeNumber: 'नंबर बदले',
    otpErrorMessage: 'अमान्य ओटीपी',
    didNotGetOtp: 'OTP प्राप्त नहीं हुआ ?',
    sendAgain: 'दुबारा भेजे',
    mobileNumberCopytext: 'मोबाइल नंबर को क्लिपबोर्ड पर कॉपी किया गया है।',
    locationEnableText:
      'कृपया सेटिंग्स में जाएं, लोकेशन की अनुमति दें, और फिर से प्रयास करें।',
    retailer: 'खाद, बीज, कीटनाशक बिक्री',
    retailerDescp: 'कृषि विक्रेताओं और कृषि सलाहकारों के लिए',
    distributer: 'फसल व्यापार',
    distributerDescp: 'ट्रेडर, आढ़तियों, और अन्य लोगों के लिए',
    farmer: 'खेती बाड़ी',
    farmerDescp: 'हर एक किसानों के लिए',
    chooseRole: 'अपनी व्यापार चुनें',
    whatYouDo: 'आप क्या करते हैं?',
    applicableProfession: 'जो भी आप पर लागू हो उसे चुनें',
    trade: 'व्यापार',
    connectWithUs: 'संपर्क करें',
    nearSoon: 'आपका राज्य मे उपलब्ध होने पर हम आपको सूचित करेंगे।',
    blockUserMessage:
      'क्षमा करें, आपको वर्तमान में Farmart ऐप्लिकेशन तक पहुँचने का अनुमति प्रदान नहीं की गई है। यदि आपको लगता है कि यह एक त्रुटि है या आपको और सहायता की आवश्यकता है, तो कृपया हमसे संपर्क करें - ',
    mobileNumberCopied: 'मोबाइल नंबर को क्लिपबोर्ड पर कॉपी किया गया है।',
    lastUpdate: 'आखिरी अपडेट',
    comingSoonHeading: 'आपके नजदीक कोई खरीददार नहीं है!',
    comingSoonSubHeading:
      'हम आपके गुणवत्ता युक्त  फसलों के लिए आपके क्षेत्र में अच्छे खरीददार ढूंढ़ने का प्रयास कर रहे है, जुड़े रहें!',
    contactUs: 'संपर्क करें ',
    noActiveBuyerHeading: 'कोई सक्रिय खरीददार का रेट नहीं मिला',
    noActiveBuyerSubHeading: 'कीमतें सामान्यत 1:00 बजे के अंदर अपडेट होती है',

    noExpiredBuyer: 'खरीददारों का समाप्त हो चुका रेट नहीं मिला',
    buyerPrice: 'खरीददार कीमतें',
    expiredPrice: 'समाप्त कीमतें',
    cropModalHeading: 'फसल का चयन करें',
    cropModalSearchPlaceholder: 'फसल खोजें',
    cropVarietyModalSearchPlaceholder: 'फसल की किस्में खोजें',
    cropVarietyModalHeading: 'फसल की किस्म का चयन करें',
    selectLocationHeading: 'डिलीवरी लोकेशन का चयन करें',
    selectLocationSearchPlaceholder: 'लोकेशन खोजें',
    selectLocationButton: 'आवेदन करें',
    locationModalHeading: 'लोकेशन की जानकारी',
    allowLocation: 'लोकेशन की अनुमति दे',
    allowLocationHeading:
      'आपको सही जानकारी दिखाने के लिए हमे आपकी लोकेशन की आवश्यकता है, कृपया अपनी लोकेशन हमसे साझा करें|',
    viewDetails: 'विवरण देखें',
    connectToSell: 'संपर्क करें',
    priceExpired: 'रेट समाप्त हो चुका है',
    apply: 'आवेदन करें',
    mySauda: 'मेरा सौदा',
    requestSauda: 'सौदा करें',
    suadaAlreadyRequested:
      'आपने पहले ही इस खरीददार कीमत  के लिए सौदा अनुरोध किया है।',
    saudaRequested: 'सौदा रिक्वेस्टेड',
    noLocation: 'लोकेशन नहीं मिला',
    changelocation: 'स्थान बदलें',
    allowLocationDisclaimer:
      'रेट की जानकारी देखने के लिए अपनी लोकेशन की अनुमति दें',
    allowLocationButton: 'लोकेशन की अनुमति दे',
    discountCardText: 'फारमार्ट फी पर 20% तक  की छूट पायें।',
  },
  [AppLanguages.appLanguagesIndex.english]: {
    loginText: 'Login Now',
    loginHeading: 'Enter your 10 digits mobile number',
    numerIntputTitle: 'Enter number here',
    termsAndCondtions:
      'By Login you accept our {(Privacy policy)}, {(Terms of Conditions)}.',
    help: 'Help',
    selectLanguageButton: 'Proceed',
    errorMobileNumber: 'Please enter correct mobile number',
    otpTitle: 'Enter OTP',
    otpSentOnMobile: 'OTP has been sent on {(variable)}',
    changeNumber: 'Change Number',
    otpErrorMessage: 'Invalid OTP',
    didNotGetOtp: 'OTP not received?',
    sendAgain: 'Send again',
    mobileNumberCopytext: 'Mobile number has been copied to clipboard',
    locationEnableText:
      'Please enable location permission from settings and try again!',
    retailer: 'Agri Input Retailing',
    retailerDescp: 'For Agri retailers or Agri consultants',
    distributer: 'Agri Trading',
    distributerDescp: 'For Traders, Market agents and others',
    farmer: 'Farming',
    farmerDescp: 'For each and every Farmers',
    chooseRole: 'Choose your role',
    whatYouDo: 'What you do?',
    applicableProfession: 'Choose whatever is applicable to you',
    trade: 'Trade',
    connectWithUs: 'Connect with us',
    nearSoon: 'We will notify you once your state is operational.',
    blockUserMessage:
      'Sorry, you are currently blocked from accessing the Farmart app. If you believe this is an error or need further assistance, please contact our support team - .',
    mobileNumberCopied: 'Mobile number has been copied to clipboard',
    lastUpdate: 'Last updated',
    comingSoonHeading: 'No Buyer’s near you!',
    comingSoonSubHeading:
      'We are trying to find the best buyer’s for your quality agri-commodities near you, stay tuned!',
    contactUs: 'Connect with us',
    noActiveBuyerHeading: 'No active buyer’s price found',
    noActiveBuyerSubHeading: 'Prices usually gets updated by 1:00 PM',
    noExpiredBuyer: 'No expired buyer’s price found',
    buyerPrice: "Buyer's Price",
    expiredPrice: 'Expired Price',
    cropModalHeading: 'Select Crop',
    cropModalSearchPlaceholder: 'Search Crops',
    selectLocationHeading: 'Select Delivery Location',
    selectLocationSearchPlaceholder: 'Search Location',
    selectLocationButton: 'Apply',
    viewDetails: 'View Details',
    connectToSell: 'Contact to sell',
    priceExpired: 'Price Expired',
    locationModalHeading: 'Your Location',
    allowLocation: 'Allow location access',
    allowLocationHeading:
      'To show you the correct information regarding Trade we need your location!',
    apply: 'Apply',
    cropVarietyModalSearchPlaceholder: 'Select Variety',
    cropVarietyModalHeading: 'Search Variety',
    mySauda: 'My Sauda’s',
    requestSauda: 'Request Sauda',
    suadaAlreadyRequested:
      'You have already requested for the Sauda against this buyer price',
    saudaRequested: 'Sauda Requested',
    noLocation: 'No location found',
    changelocation: 'Change location',
    allowLocationDisclaimer: 'To view rate, please allow location',
    allowLocationButton: 'Allow location',
    discountCardText: 'Get upto 20% discount on FarMart Fee',
  },
};

export default (language) => languages[language];
