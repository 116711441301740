import * as AppLanguages from '../constant/appLanguages';
//language will be added here
const languages = {
  [AppLanguages.appLanguagesIndex.hindi]: {
    congratsHeadingText: 'बधाई हो!',
    congratsSubHeadingText: 'आपका SMS  <farmerCount> किसानों को भेजा गया ',
    proceedButtonText: 'और  SMS  भेजें ',
    smsLeft: 'शेष SMS: ',
    pendingHeadingtext: 'स्वीकृति प्रतीक्षा में है।',
    pendingSubHeadingtext:
      'आपका SMS स्वीकृति के लिए भेजा गया है। टीम द्वारा स्वीकृत होने के बाद, यह आपके किसानों को भेज दिया जाएगा।',
  },
  [AppLanguages.appLanguagesIndex.english]: {
    congratsHeadingText: 'Congratulations!',
    congratsSubHeadingText: 'Your SMS has been sent to <farmerCount> farmers',
    proceedButtonText: 'Send more SMS',
    smsLeft: 'SMS Left: ',
    pendingHeadingtext: 'Waiting for approval',
    pendingSubHeadingtext:
      'Your SMS has been sent for approval, once approved by the team, it will be sent to your farmers',
  },
};

export default (language) => languages[language];
