//this is a modal component(bottom sheet) that renders ui over the web page
import React, { ReactNode, useEffect } from 'react';
import Styles from './style.module.css';

interface ModalProps {
  isOpen: boolean; // Indicates whether the modal is open or not
  onClose?: () => void; // Optional function to be called when the modal is closed
  children: ReactNode; // Content to be rendered inside the modal
  id?: string; // Optional id for the modal
}

const Modal = (props: ModalProps) => {
  const { isOpen, onClose, children, id } = props;

  // useEffect hook to handle overflow styling when modal opens or closes
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden'; // Prevent scrolling when modal is open
    } else {
      document.body.style.overflow = 'auto'; // Allow scrolling when modal is closed
    }

    // Cleanup function to reset overflow styling when component unmounts or isOpen changes
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [isOpen]);

  // Function to handle click on modal overlay to close the modal
  const handleOverlayClick = (event: React.MouseEvent<HTMLDivElement>) => {
    if (onClose && event.target === event.currentTarget) {
      onClose(); // Call onClose function if provided and click is on the overlay
    }
  };

  return (
    isOpen && ( // Render modal only if isOpen is true
      <div className={Styles.mainContainer} id={'modalComponentVisible'}>
        {/* Modal overlay to capture clicks outside the modal */}
        <div
          className={Styles.modalOverlay}
          onClick={handleOverlayClick}
          id={`modalOnClickCloseId${id}`}
        />
        {/* Modal content */}
        <div className={`${Styles.modal} ${isOpen ? Styles.modalOpen : ''}`}>
          <div className={Styles.modalContent}>
            {/* Render children inside modal */}
            <div className={Styles.children}>{children}</div>
          </div>
        </div>
      </div>
    )
  );
};

export default Modal;
