import React, { Suspense } from 'react';
import Style from './style.module.css';
import { MySauda } from '../../viewmodal/mySauda/types';
import SaudaOpenCloseEnum from '../../constant/enums/saudaOpenCloseEnum';
import { useAuth } from '../../context/login';
import MuiSkeleton from '../../MuiComponents/Skeleton';

// Lazy load components
const Header = React.lazy(() => import('../../ComponentV2/Header'));
const Tabs = React.lazy(() => import('../../ComponentV2/Tabs'));
const MySaudaList = React.lazy(() => import('./mySaudaList'));
const FilterModal = React.lazy(() => import('./MySaudaFilterModal'));
const LoginView = React.lazy(() =>
  import('../../context/login').then((module) => ({
    default: module.LoginView,
  })),
);

export default function MySaudaView(props: MySauda) {
  const {
    onCloseSaudaStatusModal,
    saudaModalVisible,
    onSelectFilterStatus,
    selectedFilterStatus,
    mySaudaList,
    fetchMoreSaudaList,
    mySaudaLoading,
    onClickOpenCloseTab,
    selectedOpenCloseTab,
    onClickAcceptPo,
    onClickBack,
    onClickSaudaOrderCard,
    language,
    isServiceAvailable, // check if trade service is available or not
    mySaudaAssets,
  } = props;

  const saudaOpenCloseEnum = SaudaOpenCloseEnum.get('openClose');
  const authProps = useAuth();

  return (
    <div className={Style.container}>
      <Suspense
        fallback={
          <MuiSkeleton
            sx={{ height: 50, width: '100%', borderRadius: 8, marginBottom: 4 }}
          />
        }
      >
        <Header
          title={language?.mySauda}
          onClickBackIcon={onClickBack}
          backButtonVisible={false}
        />
      </Suspense>
      <Suspense
        fallback={
          <MuiSkeleton
            sx={{ height: 50, width: '100%', borderRadius: 8, marginBottom: 4 }}
          />
        }
      >
        <Tabs
          onClickTab={onClickOpenCloseTab}
          selctedTab={selectedOpenCloseTab.id}
          tabData={saudaOpenCloseEnum}
        />
      </Suspense>
      <Suspense
        fallback={
          <MuiSkeleton
            itemNumber={3}
            sx={{
              height: 100,
              width: '100%',
              borderRadius: 8,
              marginBottom: 4,
            }}
          />
        }
      >
        <div className={Style.scrollableContainer}>
          <MySaudaList
            onCloseSaudaStatusModal={onCloseSaudaStatusModal}
            mySaudaList={mySaudaList}
            fetchMoreSaudaList={fetchMoreSaudaList}
            mySaudaLoading={mySaudaLoading}
            selectedOpenCloseTab={selectedOpenCloseTab}
            onClickAcceptPo={onClickAcceptPo}
            onClickBack={onClickBack}
            selectedFilterStatus={selectedFilterStatus}
            onClickSaudaOrderCard={onClickSaudaOrderCard}
            language={language}
            isServiceAvailable={isServiceAvailable}
            noSaudaImage={mySaudaAssets.noSaudaFound}
          />
        </div>
      </Suspense>
      <Suspense
        fallback={
          <MuiSkeleton
            sx={{
              height: 100,
              width: '100%',
              borderRadius: 8,
              marginBottom: 4,
            }}
          />
        }
      >
        <FilterModal
          onCloseSaudaStatusModal={onCloseSaudaStatusModal}
          saudaModalVisible={saudaModalVisible}
          onSelectFilterStatus={onSelectFilterStatus}
          selectedFilterStatus={selectedFilterStatus}
          language={language}
        />
      </Suspense>
      <Suspense
        fallback={
          <MuiSkeleton
            sx={{
              height: 100,
              width: '100%',
              borderRadius: 8,
              marginBottom: 4,
            }}
          />
        }
      >
        <LoginView authProps={{ ...authProps }} />
      </Suspense>
    </div>
  );
}
