/* This code snippet is defining a language translation object in JavaScript. It contains translations
for different phrases in two languages: English and Hindi. The translations are stored in an object
called `languages`, where the keys are language indexes from the `AppLanguages` constant and the
values are objects containing the translations for each phrase. */
import * as AppLanguages from '../constant/appLanguages';
//language will be added here
const languages = {
  [AppLanguages.appLanguagesIndex.english]: {
    mySauda: "My Sauda's",
    urgentActionPending: 'Urgent action pending',
    acceptPO: 'Accept PO',
    requestCreateOn: 'Request created on {(variable)}',
    poAcceptancePending: 'PO acceptance pending',
    noOfTrucksText: 'No of trucks in this sauda',
    saudabooked: 'Sauda Booked',
    saudaCompleted: 'Sauda completed',
    saudaInReview: 'Sauda in review',
    saudaRejected: 'Sauda rejected',
    saudaCancelled: 'Sauda cancelled',
    poRejected: 'Po rejected',
    closedSauda: 'Closed sauda',
    openSauda: 'Open Saudas',
    allSaudas: 'All Saudas',
    poAccpetancePending: 'PO acceptance pending',
    saudaBooked: 'Sauda booked',
    saudaStatus: 'Sauda status',
    'Pay at GRN': 'Pay at Unloading',
    'Pay at BUYER': 'Pay at Buyer Claim',
    noSaudasFound: 'No Sauda found',
    startRequestSauda: 'Start Requesting Sauda',
    brokerCodeApplied: 'Broker Code Applied',
    bookSaudaWithFarmart: 'Book sauda with FarMart',
    contactUs: 'Contact Us',
    poCreatedOn: 'PO created on {(variable)}',
    toBeUpdated: 'To be updated...',
  },
  [AppLanguages.appLanguagesIndex.hindi]: {
    mySauda: 'मेरे सौदे',
    urgentActionPending: 'तत्काल कार्रवाई लंबित है',
    acceptPO: 'पीओ स्वीकार करें',
    requestCreateOn: 'सौदा {(variable)} को बनाया गया',
    poAcceptancePending: 'पीओ स्वीकृति लंबित है',
    noOfTrucksText: 'इस सौदे में ट्रकों की संख्या',
    saudabooked: 'सौदा बुक हो गया',
    saudaCompleted: 'सौदा पूरा',
    saudaInReview: 'सौदा समीक्षा में है',
    saudaRejected: 'सौदा रिजेक्टेड',
    saudaCancelled: 'सौदा रद्द',
    poRejected: 'पीओ रिजेक्टेड',
    closedSauda: 'समाप्त सौदे',
    openSauda: 'सक्रिय सौदे',
    allSaudas: 'सारे सौदे',
    poAccpetancePending: 'पीओ स्वीकृति लंबित है',
    saudaBooked: 'सौदा बुक हो गया',
    saudaStatus: 'सौदा स्थिति',
    'Pay at GRN': 'अनलोडिंग पर भुगतान',
    'Pay at BUYER': 'बायर क्लेम पर भुगतान',
    noSaudasFound: 'कोई सौदा नहीं मिला',
    startRequestSauda: 'सौदा बनाना शुरू करें',
    brokerCodeApplied: 'ब्रोकर कोड लागु किया गया',
    bookSaudaWithFarmart: 'FarMart के साथ सौदा बुक करें',
    contactUs: 'संपर्क करें',
    poCreatedOn: 'पीओ {(variable)} पर बनाया गया',
    toBeUpdated: 'जल्द ही अपडेट किया जाएगा ...',
  },
};

export default (language) => languages[language];
