import React, { Suspense } from 'react';
import Styles from './style.module.css';

import Header from './Header/Header';
import ManualAddFarmer from './ManualAddFarmerButton/ManualAddFarmer';
import { AddFarmerProps } from './../../hooks/AddFarmer/types';

import ManualAddModal from './AddManualFarmer/index';
import { Box } from '@mui/joy';
import PlatForms from '../../constant/platFormEnums';
import DownLoadAppBox from './DownloadAppBox/DownLoadAppBox';
import AddFarmerButton from './AddFarmerButton/AddFarmer';
import Toast from '../../ComponentV2/Toast';
import MuiSkeleton from '../../MuiComponents/Skeleton';

const ContactList = React.lazy(() => import('./ContactList/ContactList'));

export default function AddFarmerView(props: AddFarmerProps) {
  const {
    onPressBack,
    language,
    onContactUs,
    contactList,
    contactLoading,
    contactPermission,
    currentPlatform,
    handleCheckboxChange,
    onSearch,
    contactsBackUp,
    error,
    farmerDetails,
    isModalVisible,
    onChangeInput,
    onToggleManualModal,
    saveFarmerManual,
    contactsIndex,
    savingLoading,
    saveFarmer,
    selectedCount,
    onClickOpenSetting,
    onClickDownloadApp,
    isErrorPopup,
    sortedContacts,
    pageLoading,
    currentPage,
    handleSetCurrentPage,
  } = props;

  return (
    <Box className={Styles.container} bgcolor={'background.body'}>
      <Header
        onContactUs={onContactUs}
        onPressBack={onPressBack}
        language={language}
      />

      <ManualAddFarmer
        onToggleManualModal={onToggleManualModal}
        language={language}
      />
      {contactLoading ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          paddingTop={3}
          margin={'0px 16px'}
          flexDirection={'column'}
          gap={4}
        >
          <MuiSkeleton
            sx={{ width: '100%', height: 70, borderRadius: 8 }}
            itemNumber={8}
          />
        </Box>
      ) : currentPlatform === PlatForms?.get('website') ? (
        <Box>
          <DownLoadAppBox
            language={language}
            onClickDownloadApp={onClickDownloadApp}
          />
        </Box>
      ) : (
        <>
          <Suspense
            fallback={
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                paddingTop={3}
                margin={'0px 16px'}
                flexDirection={'column'}
                gap={4}
              >
                <MuiSkeleton
                  sx={{ width: '100%', height: 70, borderRadius: 8 }}
                  itemNumber={10}
                />
              </Box>
            }
          >
            <ContactList
              language={language}
              contactList={contactList}
              contactPermission={contactPermission}
              handleCheckboxChange={handleCheckboxChange}
              onSearch={onSearch}
              contactsBackUp={contactsBackUp}
              contactsIndex={contactsIndex}
              onClickOpenSetting={onClickOpenSetting}
              sortedContacts={sortedContacts}
              pageLoading={pageLoading}
              currentPage={currentPage}
              handleSetCurrentPage={handleSetCurrentPage}
            />
          </Suspense>
          <AddFarmerButton
            selectedCount={selectedCount}
            saveFarmer={saveFarmer}
            savingLoading={savingLoading}
            language={language}
          />
        </>
      )}
      <ManualAddModal
        language={language}
        error={error}
        farmerDetails={farmerDetails}
        isModalVisible={isModalVisible}
        onChangeInput={onChangeInput}
        onToggleManualModal={onToggleManualModal}
        saveFarmerManual={saveFarmerManual}
        savingLoading={savingLoading}
      />
      <Toast
        isError={true}
        message={language?.apiError}
        isOpen={isErrorPopup}
      />
    </Box>
  );
}
