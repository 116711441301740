import fetchPostsByIdApi from '../../../apis/community/fetchPostById';

import { PostDetailsById } from '../types';

interface PayloadType {
  news_feed_id?: number;
}

export default async function getCommunityFeed(
  payload: PayloadType,
): Promise<PostDetailsById> {
  const body = {
    ...payload,
  };
  try {
    const result = await fetchPostsByIdApi(body);

    if (!result.data.status) {
      throw result;
    }

    return result.data.data;
  } catch (error) {
    if (!error.message) {
      error.message = 'Something Went Wrong';
    }

    throw error;
  }
}
