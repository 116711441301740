import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

import { BaseCommentList } from '../communityModel';

interface BaseCommentState extends BaseCommentList {
  baseCommentCurrentPageNumber: number;
  baseCommentLoading: boolean;
}

export interface CommentSlice {
  baseComment: BaseCommentState;
}

const initialState: CommentSlice = {
  baseComment: {
    count: 0,
    comments: [],
    baseCommentCurrentPageNumber: 1,
    baseCommentLoading: false,
  },
};

export const commentSlice = createSlice({
  name: 'comment',
  initialState,
  reducers: {
    setBaseCommentList: function (
      state,
      action: PayloadAction<BaseCommentList>,
    ) {
      state.baseComment.count = action.payload.count;
      state.baseComment.comments = [
        ...state.baseComment.comments,
        ...action.payload.comments,
      ];
    },
    setBaseCommentPageNumber: function (state, action: PayloadAction<number>) {
      state.baseComment.baseCommentCurrentPageNumber = action.payload;
    },
    setBaseCommentLoading: function (state, action: PayloadAction<boolean>) {
      state.baseComment.baseCommentLoading = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setBaseCommentPageNumber,
  setBaseCommentList,
  setBaseCommentLoading,
} = commentSlice.actions;

export default commentSlice.reducer;
