import * as AppLanguages from '../constant/appLanguages';
//language will be added here
const languages = {
  [AppLanguages.appLanguagesIndex.english]: {
    helpAndSupport: 'Help & Support',
    help: 'Help',
    needMoreHelp: 'Need more help?',
    frequentlyAskedQuestions: 'Frequently Asked Questions',
    noWhatsAppAccountFound: 'No WhatsApp account found',
    whatsAppMessageText:
      'Hello FarMart Please help me in getting other information',
  },
  [AppLanguages.appLanguagesIndex.hindi]: {
    helpAndSupport: 'मदद और सहायता',
    help: 'सहायता',
    needMoreHelp: 'और मदद चाहिए?',
    frequentlyAskedQuestions: 'अक्सर पूछे जाने वाले प्रश्नों',
    noWhatsAppAccountFound: 'कोई व्हाट्सएप अकाउंट नहीं मिला',
    whatsAppMessageText:
      'नमस्कार FarMart कृपया हमें अन्य जानकारी प्राप्त करने में मदद करें।',
  },
};

export default (language) => languages[language];
