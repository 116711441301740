import { Box } from '@mui/joy';
import React from 'react';

import MuiButton from '../../../ComponentsV3/MuiButton';

import { ArrowForwardRounded } from '@mui/icons-material';

interface AckProps {
  language: { [key: string]: string };
  onPressSendNewSms: () => void;
}
/**
 * Renders the acknowledgement information component.
 *
 * @param props - The component props.
 * @param props.language - The language object containing text translations.
 * @returns The rendered acknowledgement information component.
 */

export default function AcknowledgementInfo(props: AckProps) {
  const { language, onPressSendNewSms } = props;
  return (
    <Box
      marginTop={'32px'}
      display={'flex'}
      alignSelf={'stretch'}
      marginBottom={'20px'}
      marginLeft={'16px'}
      marginRight={'16px'}
    >
      <MuiButton
        size="lg"
        endDecorator={<ArrowForwardRounded />}
        id="smsAckProceedButtonId"
        data-testid="smsAckProceedButtonId"
        onClick={onPressSendNewSms}
      >
        {language?.proceedButtonText}
      </MuiButton>
    </Box>
  );
}
