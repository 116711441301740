/**
 * The SubsCribedCropCards function renders a card component displaying crop information with an image,
 * name, heading, and a right chevron icon.
 * @param item - An object containing information about a subscribed crop, such as crop_image_url, crop_name, and recent_heading.
 * @param index - The index of the subscribed crop card.
 */
import React from 'react';
import styles from './style.module.css'; // Importing CSS styles specific to this component
import ChevronRightOutlined from '@mui/icons-material/ChevronRightOutlined'; // Importing an icon component
import MuiTypography from '../../../../ComponentV3/Typography'; // Importing a custom typography component
import { Box } from '@mui/material'; // Importing Box component from MUI

export default function SubsCribedCropCards({
  item,
  index,
  onClickSubCropAdvisory,
}) {
  return (
    <Box
      className={styles.mainContainer}
      onClick={onClickSubCropAdvisory(item)}
    >
      {/* Rendering crop image */}
      <img
        className={styles.SubsCribedCropImageStyle}
        src={item?.crop_image_url}
        alt="cropImageIcon"
        id={`homescreenSubscribedCropImageId${index}`}
        loading="eager"
      />
      {/* Container for crop name, heading, and chevron icon */}
      <Box display={'flex'} gap={8} flex={1} justifyContent={'space-between'}>
        {/* Container for crop name and heading */}
        <Box display={'flex'} flexDirection={'column'}>
          {/* Crop name */}
          <MuiTypography
            level="title-md"
            textColor={'#171A1C'}
            id={`homescreenSubscribedCropNameId${index}`}
          >
            {item?.crop_name}
          </MuiTypography>
          {/* Recent heading */}
          <MuiTypography
            level="body-xs"
            textColor={'#555E68'}
            className={styles.subHeadingLabel}
            id={`homeScreenSubscribedCropLabelId${index}`}
          >
            {item?.recent_heading}
          </MuiTypography>
        </Box>
        {/* Right chevron icon */}
        <Box display={'flex'} alignItems={'center'}>
          <ChevronRightOutlined id={`homescreenSubscribedCropIconId${index}`} />
        </Box>
      </Box>
    </Box>
  );
}
