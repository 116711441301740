import React from 'react';
import Styles from './style.module.css';
import Typography from '@mui/joy/Typography';
import { Box } from '@mui/material';

import ArrowBackRounded from '@mui/icons-material/ArrowBackRounded';
import DeleteRounded from '@mui/icons-material/DeleteRounded';

// Define the interface for the Header component props
interface HeaderProps {
  onPressBack: () => void; // Function to handle the back button press
  language: { [key: string]: string }; // Language object containing localized strings
  onContactUs: () => void; // Function to handle the contact us button press

  selectedFarmers: number[]; // Array of selected farmers
  toggleDeleteModal: (type: string) => void; // Function to toggle the delete modal
}

export default function Header(props: HeaderProps) {
  const { onPressBack, language, selectedFarmers, toggleDeleteModal } = props;

  return (
    <Box
      id="myFarmersHeader"
      className={Styles.mainContainer}
      bgcolor={'background.body'}
      borderBottom={1}
      borderColor={'neutral.outlinedBorder'}
    >
      {/* Back button */}
      <Box id="myFarmersBackButton" className={Styles.locationContainer}>
        <ArrowBackRounded
          onClick={onPressBack}
          data-testid="myFarmersBackButtonTestId"
        />
        {/* Heading */}
        <Typography level="title-md" textColor={'text.primary'}>
          {language?.heading}
        </Typography>
      </Box>
      <Box>
        {/* Delete button */}
        {selectedFarmers.length > 0 && (
          <DeleteRounded
            id="myFarmersDeleteButton"
            data-testid="myFarmersDeleteButtonTestId"
            onClick={() => toggleDeleteModal('')}
            sx={{ color: '#C41C1C' }}
            color="error"
          />
        )}
      </Box>
    </Box>
  );
}
