/* eslint-disable @typescript-eslint/no-unused-vars */

// Importing language translation function
import getLang from '../../languages/marketingSmsHistory.js';
import { useNavigate } from 'react-router-dom'; // React Router hook for navigation
import { appLanguages } from '../../constant/appLanguages'; // Supported languages constants

import { SmsHisoryProps, smsData } from './types.js'; // TypeScript types for SMS History properties

import { useEffect, useState } from 'react'; // React hooks for state and lifecycle management

import useAuthenticationChecker from '../../utils/authChecker'; // Custom hook for authentication

import { apiEndPoints } from '../../constant/apiEndPoints'; // API endpoints constants
import { trackEvent } from '../../utils/moengage/index'; // Tracking functions for analytics
import Events from '../../constant/MoengageEvents'; // Tracking events constants

export default function AddFarmerHook(): SmsHisoryProps {
  const navigate = useNavigate(); // Navigation hook
  const langId = JSON.parse(localStorage.getItem('userLanguage')); // Get user language from localStorage
  const activeSystemLang = Intl.DateTimeFormat().resolvedOptions().locale; // Get system language
  const [initialLoading, setInitialLoading] = useState(true); // State for initial loading
  const [pageNumber, setPageNumber] = useState(1); // State for current page number
  const [smsBalance, setSmsBalance] = useState(0); // State for SMS balance
  const systemLanguage = activeSystemLang?.includes('en')
    ? appLanguages[1].langCode
    : appLanguages[0].langCode; // Determine system language

  const [showShopPromotionModal, setShowShopPromotionModal] = useState(false); // State for shop promotion modal visibility
  const [historyLength, setHistoryLength] = useState(true); // State for history length
  const [smsHistoryData, setSmsHistroyData] = useState<
    | {
        [key: string]: smsData[];
      }
    | undefined
  >({}); // State for SMS history data
  const [startTime, setStartTime] = useState<Date | null>(null);
  const language = getLang(langId?.langCode || systemLanguage); // Get language translations

  // Get the authentication checker and API caller functions
  const { AuthenticationCheckerAndApiCaller } = useAuthenticationChecker();

  // Function to handle back button press
  function onPressBack() {
    const currentTime = new Date().getTime();
    const timeSpent = (currentTime - startTime.getTime()) / 1000; // Divide by 1000 to convert ms to seconds
    trackEvent(Events.onSmsBalanceScreenBackButtonClick, {
      'Time spent': timeSpent,
    });
    navigate(-1); // Navigate to home
  }

  /**
   * The function `onContactUs` navigates to the "/faq/questions" page.
   */
  function onContactUs() {
    navigate('/faq/questions'); // Navigate to FAQ page
  }

  useEffect(() => {
    // Set the start time when the component mounts
    setStartTime(new Date());
  }, []);

  useEffect(() => {
    if (historyLength) {
      getSmsHistory(); // Fetch SMS history if history length is true
    }
  }, [pageNumber, historyLength]);

  useEffect(() => {
    getSmsBalance(); // Fetch SMS balance on component mount
  }, []);

  /**
   * Fetches more data by incrementing the page number.
   */
  function fetchMoreData() {
    if (historyLength) {
      setPageNumber((prevPage) => prevPage + 1); // Increment page number
    }
  }

  /**
   * Fetches the SMS balance from the API.
   */
  async function getSmsBalance() {
    try {
      const smsBalanceResponse = await AuthenticationCheckerAndApiCaller({
        payload: {}, // Empty payload
        uri: apiEndPoints.smsBalance, // API endpoint for SMS balance
        requestType: 'get', // HTTP GET request
      });

      if (smsBalanceResponse?.data?.status) {
        setSmsBalance(smsBalanceResponse.data.data?.total_sms_credit_point); // Set SMS balance
      }
    } catch (error) {
      console.log(error); // Log error
    }
  }

  /**
   * Fetches the SMS history from the API.
   */
  async function getSmsHistory() {
    if (pageNumber === 1) {
      setInitialLoading(true); // Set initial loading state to true
    }

    try {
      const fetchSmsPassbookParams = {
        page_no: pageNumber, // Current page number
        limit: 5, // Number of items per page
      };
      const smsHistoryResp = await AuthenticationCheckerAndApiCaller({
        payload: fetchSmsPassbookParams, // Request payload
        uri: apiEndPoints.smsPassBook, // API endpoint for SMS history
        requestType: 'post', // HTTP POST request
      });

      if (smsHistoryResp?.data?.status) {
        if (smsHistoryResp?.data.data.length > 0) {
          updateData(smsHistoryResp?.data.data); // Update SMS history data
        } else {
          setHistoryLength((prevPage) => false); // Set history length to false
        }
      }
    } catch (error) {
      console.log('Error in fetching My Farmers', error); // Log error
    } finally {
      setInitialLoading(false); // Set initial loading state to false
    }
  }

  /**
   * Counts the length of the SMS history.
   * @param {Object} newListData - The new list data.
   * @returns {number} - The length of the SMS history.
   */
  function countHistoryLength(newListData: { [key: string]: smsData[] }) {
    const totalObjectsReduce = Object.values(newListData).reduce(
      (acc, current) => acc + current.length,
      0,
    );
    return totalObjectsReduce % 5; // Return the length of the SMS history
  }

  /**
   * Updates the SMS history data with new data.
   * @param {Array} newData - The new data to update.
   */
  const updateData = (newData) => {
    const updatedData = { ...smsHistoryData };
    newData.forEach((item) => {
      const month = item.month;
      if (updatedData[month]) {
        updatedData[month] = [...updatedData[month], ...item.data]; // Append new data to existing data
      } else {
        updatedData[month] = item.data; // Set new data
      }
    });
    setSmsHistroyData(updatedData); // Set updated SMS history data
  };

  /**
   * Handles the click event for the info button.
   */
  function onClickInfo() {
    setShowShopPromotionModal(true); // Show shop promotion modal
    trackEvent(Events?.onHomeScreenSmsFeatureIButtonClick, {
      from: 'SMS_HISTORY_SCREEN',
    }); // Track event
  }

  /**
   * Handles the close event for the modal.
   */
  function onCloseModal() {
    setShowShopPromotionModal(false); // Hide shop promotion modal
    trackEvent(Events?.onHomeScreenSmsFeatureIButtonModalClosedButtonClick, {
      from: 'SMS_HISTORY_SCREEN',
    }); // Track event
  }

  /**
   * Handles the click event for an SMS item.
   * @param {smsData} smsDetail - The SMS detail data.
   */
  function onClickSms(smsDetail: smsData) {
    navigate(`/smsHistoryDetail/${smsDetail.id}`, {
      state: { smsHistoryDetailData: smsDetail },
    }); // Navigate to SMS history detail page
  }

  return {
    onPressBack,
    language,
    onContactUs,
    smsHistoryData,
    onClickInfo,
    onClickSms,
    smsBalance,
    initialLoading,
    onCloseModal,
    showShopPromotionModal,
    fetchMoreData,
  };
}
