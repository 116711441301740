/* This TypeScript code snippet is defining a constant `CTUserTypeEnums` as a new Map object. The Map
object is a collection of key-value pairs where each key can be of any type. In this case, the keys
are numbers and the values are strings representing different user types. */
const CTUserTypeEnums = new Map();

CTUserTypeEnums.set(1, 'RETAILING');
CTUserTypeEnums.set(9, 'TRADING');
CTUserTypeEnums.set(5, 'FARMING');
CTUserTypeEnums.set(2, 'RETAILING');
CTUserTypeEnums.set(3, 'RETAILING');
CTUserTypeEnums.set(4, 'RETAILING');
CTUserTypeEnums.set(6, 'RETAILING');
CTUserTypeEnums.set(7, 'RETAILING');
CTUserTypeEnums.set(8, 'RETAILING');

export default CTUserTypeEnums;
