/* 
  This functional component is called `CropAdvisoryPrimary` and it renders a section of a crop advisory feature on a web application. 
  It handles displaying information about subscribed crops and provides options for users to subscribe to new crops or view more details.
*/

import React from 'react';
import styles from './style.module.css'; // Importing CSS styles specific to this component
import MuiButton from '../../../ComponentsV3/MuiButton'; // Importing a custom button component
import InsightsRounded from '@mui/icons-material/InsightsRounded'; // Importing an icon component
import AddFilled from '@mui/icons-material/Add'; // Importing an icon component
import ChevronRightOutlined from '@mui/icons-material/ChevronRightOutlined'; // Importing an icon component
import MuiTypography from '../../../ComponentV3/Typography'; // Importing a custom typography component
import { Box } from '@mui/material'; // Importing Box component from MUI
import { Chip } from '@mui/joy'; // Importing Chip component from MUI
import AddedCropCard from './AddedCropCard'; // Importing a custom component for displaying added crop cards
import SkeletonLoading from '../../../ComponentsV4/SkeletonLoading'; // Importing a skeleton loading component for placeholders

export default function CropAdvisoryPrimary({
  language, // Language object containing text translations
  subscribedCropsAdvisoryList, // List of subscribed crops
  totalCropSubscirbedCount, // Total count of subscribed crops
  item, // Item data related to the component
  onClickViewCropAdvisoryPrimary, // Callback function to handle click event for viewing crop advisory
  subscribedCropAdvisoryLoading, // Flag indicating if crop advisory data is loading
  homeScreenLoading, // Flag indicating if home screen data is loading
  onClickSubCropAdvisory,
  onClickViewSubscribedCropsList,
}) {
  return (
    <Box className={styles?.mainContainer} bgcolor={'background.surface'}>
      {/* Conditional rendering of skeleton loading component if either home screen or crop advisory data is loading */}
      {homeScreenLoading || subscribedCropAdvisoryLoading ? (
        <SkeletonLoading className={styles?.loading} />
      ) : (
        // Displaying content when both home screen and crop advisory data are loaded
        <>
          {/* Container for the main content */}
          <Box className={styles?.infoContainer}>
            <Box className={styles?.contentContainer}>
              {/* Container for the heading and button */}
              <Box className={styles.featurelabelContainer}>
                {/* Heading for the crop advisory section */}
                <Box
                  alignSelf={'stretch'}
                  display={'flex'}
                  justifyContent={'space-between'}
                  alignItems={'center'}
                >
                  {/* Heading text */}
                  <MuiTypography level="title-md" textColor={'#171A1C'}>
                    {language?.cropAdvisoryPrimaryHeading}
                  </MuiTypography>
                  {/* Button for subscribing to new crops (only displayed if subscribed crop count is greater than 3) */}
                  <Box>
                    {totalCropSubscirbedCount > 3 && (
                      <MuiButton
                        color="primary"
                        size="sm"
                        variant="plain"
                        startDecorator={<AddFilled />}
                        id="homescreenCropAdvisoryAddCropSecondaryButtonId"
                      >
                        {language?.subscribeNewCrop}
                      </MuiButton>
                    )}
                  </Box>
                </Box>
                {/* Subheading for the crop advisory section (only displayed if subscribed crop count is less than 3) */}
                {totalCropSubscirbedCount < 3 && (
                  <MuiTypography
                    level="body-xxs"
                    textColor={'text.tertiary'}
                    className={styles.subHeadingLabel}
                  >
                    {language?.cropAdvisoryPrimarySubHeading}
                  </MuiTypography>
                )}
              </Box>
              {/* Chip indicating FOMO (Fear of Missing Out) message (only displayed if subscribed crop count is less than 3) */}
              {totalCropSubscirbedCount < 3 && (
                <Chip
                  color="neutral"
                  size="sm"
                  startDecorator={<InsightsRounded />}
                  variant="soft"
                  style={{
                    borderRadius: 4,
                  }}
                >
                  {language?.cropAdvisoryFomoPrimary}
                </Chip>
              )}
            </Box>
            {/* Icon image (only displayed if subscribed crop count is less than 3) */}
            {totalCropSubscirbedCount < 3 && (
              <Box>
                <img
                  className={styles.iconImageStyle}
                  src={item.features[0]?.icon}
                  alt="itemFeatureIcon"
                  loading="eager"
                />
              </Box>
            )}
          </Box>

          {/* Rendering added crop cards for each subscribed crop */}
          {subscribedCropsAdvisoryList?.map(function (item, index) {
            return (
              <AddedCropCard
                key={index}
                index={index}
                item={item}
                onClickSubCropAdvisory={onClickSubCropAdvisory}
              />
            );
          })}

          {/* Button for subscribing to new crops or viewing more details */}
          <MuiButton
            size="md"
            color="primary"
            startDecorator={totalCropSubscirbedCount <= 3 ? <AddFilled /> : ''}
            endDecorator={
              totalCropSubscirbedCount <= 3 ? '' : <ChevronRightOutlined />
            }
            variant="solid"
            id="homescreenCropAdvisoryAddCropButtonId"
            onClick={
              totalCropSubscirbedCount < 3
                ? onClickViewCropAdvisoryPrimary
                : onClickViewSubscribedCropsList
            }
          >
            {totalCropSubscirbedCount <= 3
              ? language?.subscribeNewCrop
              : language?.cropAdvisoryPrimaryButton}
          </MuiButton>
        </>
      )}
    </Box>
  );
}
