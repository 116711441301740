import React, { useEffect, useRef } from 'react';
import Style from './style.module.css';
import ButtonV2 from '../../../ComponentV2/ButtonV2';
import Modal from '../../../ComponentV2/Modal';
import PaymentTermsModal from '../PaymentTermsModal';

import Typography from '../../../ComponentV3/Typography';
import replaceVariableInString from '../../../utils/replacevariableInLanguage';
import { TradePriceBreakupData } from '../../../modal/Traders/types';
import RadioButtonCheckedRoundedIcon from '@mui/icons-material/RadioButtonCheckedRounded';
import RadioButtonUncheckedRoundedIcon from '@mui/icons-material/RadioButtonUncheckedRounded';
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';

interface requestSaudaProps {
  showRequestModal: boolean;
  priceBreakUp: TradePriceBreakupData;
  onClose: any;
  onClickRequestSauda: () => void;
  toggleRequestSaudaModal: () => void;
  quantity: number;
  handleQuantityChange: () => void;
  paymentTerms: string;
  paymentTermsType: string;
  handlePaymentTerms: () => void;
  showPaymentTermsModal: boolean;
  proceedDisabled: boolean;
  grnInfo: () => void;
  buyersInfo: () => void;
  onClosePaymentTermsModal: () => void;
  closePaymentTermsModal: () => void;
  handleBrokeragePopup: () => void;
  handleFinancingPopup: () => void;
  handleNetPopup: () => void;
  saudaRequestToastPopup: boolean;
  handleSaudaRequestToastPopup: () => void;
  language?: { [key: string]: any };
  proceedSaudaRequest: () => void;
  isFocusedSaudaRequestInput: boolean;
  handleFocusSaudaRequestInput: () => void;
  handleBlurSaudaRequestInput: () => void;
  onKeyDownSaudaRequestInput: () => void;
  discountFeeSmiley: string;
  discountCardBgBig: string;
  magicIcon: string;
}

const RequestSaudaModal = (props: requestSaudaProps) => {
  const {
    showRequestModal = true,
    priceBreakUp,
    onClose,
    quantity,
    handleQuantityChange,
    paymentTerms,
    paymentTermsType,
    handlePaymentTerms,
    showPaymentTermsModal,
    proceedDisabled,
    grnInfo,
    buyersInfo,
    onClosePaymentTermsModal,
    closePaymentTermsModal,
    handleBrokeragePopup,
    handleFinancingPopup,
    handleNetPopup,
    language,
    proceedSaudaRequest,
    saudaRequestToastPopup,
    handleSaudaRequestToastPopup,
    isFocusedSaudaRequestInput,
    handleFocusSaudaRequestInput,
    handleBlurSaudaRequestInput,
    onKeyDownSaudaRequestInput,
    discountFeeSmiley,
    discountCardBgBig,
    magicIcon,
  } = props;

  const inputRef = useRef<HTMLInputElement>(null);
  useEffect(() => {
    const handleWheel = (event: WheelEvent) => {
      if (inputRef.current && inputRef.current === document.activeElement) {
        event.preventDefault();
      }
    };
    // Add event listener for mouse wheel event
    document.addEventListener('wheel', handleWheel, { passive: false });
    // Cleanup function to remove the event listener
    return () => {
      document.removeEventListener('wheel', handleWheel);
    };
  }, []);

  const InfoIcon = () => <InfoRoundedIcon fontSize="small" />;

  const modalContent = (
    <div className={Style.modalContainer}>
      <label className={Style.sellQuantity}>{language?.quantityToSell}</label>
      <div className={Style.inputMainContainer}>
        <input
          ref={inputRef}
          id="quantityInput"
          type="number"
          value={quantity}
          onChange={handleQuantityChange}
          placeholder={language?.enterQuantity}
          className={
            isFocusedSaudaRequestInput ? Style.active : Style.inputBoxContainer
          }
          onFocus={handleFocusSaudaRequestInput}
          onBlur={handleBlurSaudaRequestInput}
          onKeyDown={onKeyDownSaudaRequestInput}
        />
        <div
          className={
            isFocusedSaudaRequestInput ? Style.quantityActive : Style.quantity
          }
        >
          <label className={Style.quantityText}>
            {priceBreakUp?.supplier_quantity_unit === null
              ? ''
              : priceBreakUp?.supplier_quantity_unit}
          </label>
        </div>
      </div>
      <div className={Style.minimumQuantity}>
        <div className={Style.info}>
          <InfoIcon />
        </div>
        <div className={Style.info}>
          <label className={Style.minimumQuantityText}>
            {`${language?.minQuantityPreferred} 250 `}
          </label>
          <label className={Style.minimumQuantityUnit}>
            {priceBreakUp?.supplier_quantity_unit === null
              ? ''
              : priceBreakUp?.supplier_quantity_unit}
          </label>
        </div>
      </div>
      <div className={Style.paymentTermsContainer}>
        <label className={Style.paymentTerms}>
          {language?.choosePaymentTerms}
        </label>
        <div
          onClick={handlePaymentTerms}
          className={
            paymentTerms === 'buyers-term'
              ? Style.cardActive
              : Style.cardInactive
          }
          id={'buyers-term'}
        >
          <div className={Style.termsTextContainer}>
            {paymentTerms === 'buyers-term' ? (
              <RadioButtonCheckedRoundedIcon
                htmlColor="#008B74"
                sx={{ height: 28, width: 28 }}
              />
            ) : (
              <RadioButtonUncheckedRoundedIcon
                htmlColor="#C2C7CF"
                sx={{ height: 28, width: 28 }}
              />
            )}
            <label className={Style.paymentTermsText}>
              {language?.atBuyersTerms}
            </label>
            <div className={Style.paymentInfo} onClick={buyersInfo}>
              <InfoIcon />
            </div>
            <PaymentTermsModal
              showPaymentTermsModal={showPaymentTermsModal}
              onClosePaymentTermsModal={onClosePaymentTermsModal}
              closePaymentTermsModal={closePaymentTermsModal}
              paymentTermsType={paymentTermsType}
              priceBreakUp={priceBreakUp}
              handleBrokeragePopup={handleBrokeragePopup}
              handleFinancingPopup={handleFinancingPopup}
              handleNetPopup={handleNetPopup}
              language={language}
              magicIcon={magicIcon}
            />
          </div>
          <div className={Style.netPriceContainer}>
            <label className={Style.netPriceText}>{language?.netPrice}</label>
            <label className={Style.netPrice}>{`${
              priceBreakUp?.price_breakup_buyer?.net_price === null
                ? 0
                : priceBreakUp?.price_breakup_buyer?.net_price
            } ${
              priceBreakUp?.price_breakup_buyer?.net_price_unit === null
                ? ''
                : priceBreakUp?.price_breakup_buyer?.net_price_unit
            }`}</label>
          </div>
        </div>

        <div>
          <div
            onClick={handlePaymentTerms}
            className={
              paymentTerms === 'grns-term'
                ? Style.cardActiveGRN
                : Style.cardInactiveGRN
            }
            id={'grns-term'}
          >
            <div className={Style.termsTextContainer}>
              {paymentTerms === 'grns-term' ? (
                <RadioButtonCheckedRoundedIcon
                  htmlColor="#008B74"
                  sx={{ height: 28, width: 28 }}
                />
              ) : (
                <RadioButtonUncheckedRoundedIcon
                  htmlColor="#C2C7CF"
                  sx={{ height: 28, width: 28 }}
                />
              )}
              <label className={Style.paymentTermsText}>
                {language?.atGRNTerms}
              </label>
              <div className={Style.paymentInfo} onClick={grnInfo}>
                <InfoIcon />
              </div>
              <PaymentTermsModal
                showPaymentTermsModal={showPaymentTermsModal}
                onClosePaymentTermsModal={onClosePaymentTermsModal}
                closePaymentTermsModal={closePaymentTermsModal}
                paymentTermsType={paymentTermsType}
                priceBreakUp={priceBreakUp}
                handleBrokeragePopup={handleBrokeragePopup}
                handleFinancingPopup={handleFinancingPopup}
                handleNetPopup={handleNetPopup}
                language={language}
                magicIcon={magicIcon}
              />
            </div>
            <div className={Style.netPriceContainer}>
              <label className={Style.netPriceText}>{language?.netPrice}</label>
              <div>
                {priceBreakUp?.price_breakup_grn?.net_price_without_discount &&
                priceBreakUp?.price_breakup_grn?.discounted_fee !== null &&
                priceBreakUp?.price_breakup_grn?.discounted_fee !== 0 ? (
                  <label className={Style.netPriceWithoutDiscount}>
                    {priceBreakUp?.price_breakup_grn?.net_price_without_discount.toFixed(
                      2,
                    )}
                  </label>
                ) : (
                  <></>
                )}
                <label className={Style.netPrice}>{`${
                  priceBreakUp?.price_breakup_grn?.net_price === null
                    ? 0
                    : priceBreakUp?.price_breakup_grn?.net_price
                } ${
                  priceBreakUp?.price_breakup_grn?.net_price_unit === null
                    ? ''
                    : priceBreakUp?.price_breakup_grn?.net_price_unit
                }`}</label>
              </div>
            </div>
          </div>
          {paymentTerms === 'grns-term' &&
            (priceBreakUp?.price_breakup_grn?.net_discount ?? 0) > 0 && (
              <div
                className={Style.overlappedDiv}
                style={{
                  backgroundImage: `url(${discountCardBgBig})`,
                  backgroundSize: 'cover',
                  backgroundRepeat: 'no-repeat',
                }}
              >
                <div className={Style.smiley}>
                  <img
                    src={discountFeeSmiley}
                    height={'100%'}
                    width={'100%'}
                    alt="Discount Fee Smiley"
                    loading="eager"
                  />
                </div>
                <div className={Style.discountCardText}>
                  <Typography level="title-sm" sx={{ color: '#B2852F' }}>
                    {replaceVariableInString(
                      priceBreakUp?.price_breakup_grn?.net_discount
                        ?.toFixed(2)
                        ?.toString(),
                      language?.amountSavedText,
                    )}
                  </Typography>
                </div>
              </div>
            )}
        </div>
      </div>
      <ButtonV2
        title={language?.proceed}
        disabled={proceedDisabled}
        onClick={proceedSaudaRequest}
      />
    </div>
  );

  return (
    <Modal
      isOpen={showRequestModal}
      children={modalContent}
      title={language?.requestSauda}
      isCloseButtonOption={true}
      onClose={onClose}
    />
  );
};

export default RequestSaudaModal;
