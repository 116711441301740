import * as AppLanguages from '../constant/appLanguages';
//language will be added here
const languages = {
  [AppLanguages.appLanguagesIndex.hindi]: {
    heading: 'आपके किसान',
    searchText: 'किसान ढूंढे',
    noSearch: '* कोई किसान नहीं मिला *',
    addNewFarmerButton: 'किसान जोड़े',
    emptyAlert:
      'ऐसा लगता है कि आपने अभी तक किसी किसान को नहीं जोड़ा है। किसानों को जोड़ने के लिए नीचे दिए गए बटन पर क्लिक करें',
    apiError: 'असुविधा के लिए क्षमा करें! कृपया बाद में फिर से प्रयास करें।',
    smsBalanceLeft: 'शेष  SMS बैलेंस:',
    selectAll: 'सभी का चयन करें',
    farmersSelected: 'किसान चयनित',
    proceed: 'अभी भेजें',
    sendFarmerError: 'कृपया आगे बढ़ने के लिए <farmerCount> किसानों तक चयन करें',
  },
  [AppLanguages.appLanguagesIndex.english]: {
    heading: 'Your farmers',
    searchText: 'Search Farmers',
    noSearch: '* No Farmer found *',
    addNewFarmerButton: 'Add Farmers',
    emptyAlert:
      'It seems that you have not added any farmer yet. Click the below button to add farmers',
    apiError: 'Sorry for the inconvenience! Please try again later.',
    smsBalanceLeft: 'SMS balance left:',
    selectAll: 'Select all',
    farmersSelected: 'farmer selected',
    proceed: 'Send Now',
    sendFarmerError: 'कृपया आगे बढ़ने के लिए <farmerCount> किसानों तक चयन करें',
  },
};

export default (language) => languages[language];
