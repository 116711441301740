import * as AppLanguages from '../constant/appLanguages';
//language will be added here
const languages = {
  [AppLanguages.appLanguagesIndex.hindi]: {
    help: 'सहायता',
    heading: 'SMS हिस्ट्री देखें',
    smsHistoryTitle: '<month> महीने के लिए आपका SMS बैलेंस',
    numberOfdFarmerGotMessage: '<numberOfFarmer> किसानों को सन्देश मिला • ',
  },
  [AppLanguages.appLanguagesIndex.english]: {
    help: 'Help',
    heading: 'SMS History',
    smsHistoryTitle: 'Your SMS balance for the month of <month>',
    numberOfdFarmerGotMessage: '<numberOfFarmer> farmers got the message • ',
  },
};

export default (language) => languages[language];
