import * as AppLanguages from '../constant/appLanguages';
//language will be added here
const languages = {
  [AppLanguages.appLanguagesIndex.english]: {
    heading: 'Search Location',
    help: 'Help',
    searchPlaceHolder: 'Enter District name or Pincode',

    noPinCodeText: 'Pincode not found',
  },
  [AppLanguages.appLanguagesIndex.hindi]: {
    heading: 'लोकेशन खोजें',
    help: 'सहायता',
    searchPlaceHolder: 'ज़िला का नाम या पिनकोड बताएं',

    noPinCodeText: 'पिनकोड नहीं मिला',
  },
};

export default (language) => languages[language];
