const WeatherImageUrls = new Map();

WeatherImageUrls.set(
  'rain>90',
  'https://d2n0idf0n5xz1f.cloudfront.net/development/WeatherProps/rainfall___90.riv',
);
WeatherImageUrls.set(
  'rain70-30',
  'https://d2n0idf0n5xz1f.cloudfront.net/development/WeatherProps/rainfall_is_30-70.riv',
);
WeatherImageUrls.set(
  'rain90-70',
  'https://d2n0idf0n5xz1f.cloudfront.net/development/WeatherProps/rainfall_70-90.riv',
);
WeatherImageUrls.set(
  'wind>25',
  'https://d2n0idf0n5xz1f.cloudfront.net/development/WeatherProps/wind_speed___25.riv',
);
WeatherImageUrls.set(
  'wind25-12',
  'https://d2n0idf0n5xz1f.cloudfront.net/development/WeatherProps/wind_speed_12.5-25.riv',
);
WeatherImageUrls.set(
  'lowTemprature',
  'https://d2n0idf0n5xz1f.cloudfront.net/development/WeatherProps/low_temperature.riv',
);
WeatherImageUrls.set(
  'highTemprature',
  'https://d2n0idf0n5xz1f.cloudfront.net/development/WeatherProps/high_temperature_(max_t___35).riv',
);
WeatherImageUrls.set(
  'noLocation',
  'https://d2n0idf0n5xz1f.cloudfront.net/development/WeatherProps/no_location.riv',
);
WeatherImageUrls.set(
  'normalWeather',
  'https://d2n0idf0n5xz1f.cloudfront.net/development/WeatherProps/normal_weather.riv',
);

export default WeatherImageUrls;
