const SmsAcknowledgementRiveUrl = new Map();

SmsAcknowledgementRiveUrl.set(
  'smsSent',
  'https://d1ioice0blp2od.cloudfront.net/fmtapp/asset/2024/9/26/fe7636d0-210b-419e-84cb-ced50e424d7a_success_tick_animation.riv',
);
SmsAcknowledgementRiveUrl.set(
  'smsPending',
  'https://d1ioice0blp2od.cloudfront.net/fmtapp/asset/2024/9/26/20e26f63-b813-4dd3-8fe3-7742c6067905_waiitingForAppproval.riv',
);
export default SmsAcknowledgementRiveUrl;
