import React from 'react';
import { Skeleton } from '@mui/joy';
import { SxProps } from '@mui/system'; // Import SxProps for type safety
interface SkeletonProps {
  variant?: 'circular' | 'rectangular' | 'text' | 'overlay';
  animation?: 'pulse' | 'wave';
  loading?: boolean;
  sx?: SxProps; // Allow passing of additional styles
  children?: React.ReactNode; // Add children prop
  itemNumber?: number;
}
const defaultSx: SxProps = {
  backgroundColor: '#F0F4F8',
};
const MuiSkeleton = ({
  variant = 'rectangular',
  animation = 'pulse',
  sx,
  children,
  itemNumber = 1,
  ...props
}: SkeletonProps) => {
  return (
    <>
      {Array.from({ length: itemNumber }).map((_, index) => (
        <Skeleton
          key={'skeletonLoading' + index}
          variant={variant}
          animation={animation}
          sx={{ ...defaultSx, ...sx }}
          {...props}
        >
          {children} {/* Render children here */}
        </Skeleton>
      ))}
    </>
  );
};
export default MuiSkeleton;
