import React from 'react';
import Styles from './style.module.css';
import Typography from '@mui/joy/Typography';
import { Box } from '@mui/material';

import ArrowBackRounded from '@mui/icons-material/ArrowBackRounded';

/* The `interface HeaderProps` is defining the props that the `Header` component expects to receive.
Here's what each property in the interface represents: */
interface HeaderProps {
  onPressBack: () => void;
  language: { [key: string]: string };
  onContactUs: () => void;
}

export default function Header(props: HeaderProps) {
  const { onPressBack, language } = props;
  return (
    <Box
      className={Styles.mainContainer}
      bgcolor={'background.body'}
      borderBottom={1}
      borderColor={'neutral.outlinedBorder'}
    >
      <Box className={Styles.locationContainer} id="RakeListScreenBackTestId">
        <ArrowBackRounded
          onClick={onPressBack}
          id="rakeListScreenOnPressBackId"
          data-testid={'addFarmerHeaderOnPressBackTestId'}
        />
        <Typography level="title-md" textColor={'text.primary'}>
          {language?.heading}
        </Typography>
      </Box>
    </Box>
  );
}
