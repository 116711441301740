import React from 'react';
import Styles from './style.module.css';
import KeyboardArrowRightRoundedIcon from '@mui/icons-material/KeyboardArrowRightRounded';

export default function index({ onClickMySaudaButton, title }) {
  return (
    <button
      className={Styles.mainContainer}
      onClick={onClickMySaudaButton}
      data-testid={'onClickMySaudaButtonId'}
    >
      <label className={Styles.titleText}>{title}</label>
      <KeyboardArrowRightRoundedIcon
        className={Styles.svg}
        sx={{ color: 'white' }}
      />
    </button>
  );
}
