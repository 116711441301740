import React, { ReactNode } from 'react';
import Button, { ButtonProps } from '@mui/joy/Button';

interface ButtonWrapperProps extends ButtonProps {
  children?: ReactNode;
  customStyles?: string;
  // You can define additional props here if needed
}

const MuiButton: React.FC<ButtonWrapperProps> = ({
  children,
  customStyles,
  ...props
}) => {
  return (
    <Button
      sx={{ borderRadius: '8px', ...{ customStyles } }}
      fullWidth
      className={customStyles}
      {...props}
    >
      {children}
    </Button>
  );
};

export default MuiButton;
