import React from 'react';
import Styles from './style.module.css';
import { RWebShare } from 'react-web-share';
import { TradePriceBreakupData } from '../../../modal/Traders/types';
import { trackEvent } from '../../../utils/moengage';
import Events from '../../../constant/MoengageEvents';
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import ShareRoundedIcon from '@mui/icons-material/ShareRounded';

interface TradeDetailProps {
  PriceBreakup: TradePriceBreakupData;
  onClickBack: () => void;
  language?: { [key: string]: any };
}
const index = (props: TradeDetailProps) => {
  const { PriceBreakup, onClickBack, language } = props;

  return (
    <div className={Styles.wrapper} data-testid="buyertDetailsHeaderId">
      <div className={Styles.leftList}>
        <div
          onClick={onClickBack}
          style={{ display: 'flex' }}
          data-testid="navigateBackHeaderBuyerDetails"
        >
          <ArrowBackRoundedIcon sx={{ color: 'black' }} />
        </div>
        <div>{language?.heading}</div>
      </div>
      <RWebShare
        data={{
          text: `Sell your crops at best prices on Farmart App ${'\n'}*Buyer Details :* ${
            PriceBreakup?.buyer_name
          }${'\n'} *Please click below link, to check for suitable prices on Farmart App Link* - `,

          title: 'Share Via',
          url: PriceBreakup?.branch_io_link,
        }}
        onClick={() => {
          trackEvent(Events.onShareButtonClick, {});
        }}
        sites={['facebook', 'whatsapp', 'telegram', 'mail']}
        data-testid="rwebshareHeaderBuyerDetailsId"
      >
        <div
          className={Styles.right}
          data-testid="shareButtonHeaderBuyerDetails"
        >
          <ShareRoundedIcon htmlColor="#293756" />
        </div>
      </RWebShare>
    </div>
  );
};

export default index;
