// Importing necessary React and MUI components.
import React from 'react';
import { Box, Card, IconButton } from '@mui/joy';
import Typography from '../../../ComponentV3/Typography';
import {
  CloseRounded, // Icon for closing modal.
  ChevronRightRounded, // Icon indicating a forward action or selection.
  CameraAltOutlined, // Icon representing camera action.
  PhotoOutlined, // Icon representing gallery selection.
} from '@mui/icons-material';
import Styles from './style.module.css'; // Importing component-specific styles.
import { EditProfileModalContentProps } from '../../../hooks/EditProfile/types'; // Importing TypeScript types for props.

// ModalContent component for the Edit Profile modal.
// This component provides UI for editing profile photo, including options to open camera or gallery.
const ModalContent = ({
  closeModal, // Function to close the modal.
  openCamera, // Function to open the camera interface.
  openGallery, // Function to open the gallery for photo selection.
  language, // Object containing localized strings.
}: EditProfileModalContentProps) => {
  return (
    <Box className={Styles.contentContainer}>
      {' '}
      {/* Main container for modal content. */}
      <Box className={Styles.modalHeader}>
        {' '}
        {/* Modal header section. */}
        <Typography level={'title-md'}>{language?.editPhoto}</Typography>{' '}
        {/* Modal title. */}
        <IconButton
          onClick={closeModal}
          id={'onClickCloseModalEditProfileId'}
          data-testid="onClickCloseModalEditProfileTestId"
        >
          {' '}
          {/* Close button. */}
          <CloseRounded />
        </IconButton>
      </Box>
      <Box className={Styles.modalBody}>
        {' '}
        {/* Modal body section. */}
        {/* Card for opening camera. */}
        <Card
          variant="soft"
          sx={{ marginBottom: '12px' }}
          onClick={openCamera}
          id={'onClickOpenCameraId'}
          data-testid="onClickOpenCameraTestId"
        >
          <Box className={Styles.cameraCard}>
            <Box className={Styles.cardText}>
              <CameraAltOutlined sx={{ color: '#171A1C' }} />
              <Typography
                level={'body-xs'}
                paddingLeft={2}
                sx={{ color: '#171A1C' }}
              >
                {language?.openCamera}
              </Typography>
            </Box>
            <ChevronRightRounded /> {/* Icon indicating action. */}
          </Box>
        </Card>
        {/* Card for opening gallery. */}
        <Card
          variant="soft"
          onClick={openGallery}
          id={'onClickOpenGaleryId'}
          data-testid="onClickOpenGaleryTestId"
        >
          <Box className={Styles.openGalleryCard}>
            <Box className={Styles.cardText}>
              <PhotoOutlined sx={{ color: '#171A1C' }} />
              <Typography
                level={'body-xs'}
                paddingLeft={2}
                sx={{ color: '#171A1C' }}
              >
                {language?.openGallery}
              </Typography>
            </Box>
            <ChevronRightRounded /> {/* Icon indicating action. */}
          </Box>
        </Card>
      </Box>
    </Box>
  );
};

export default ModalContent; // Exporting the component for use in other parts of the application.
