import * as AppLanguages from '../constant/appLanguages';
//language will be added here
const languages = {
  [AppLanguages.appLanguagesIndex.hindi]: {
    help: 'सहायता',
    heading: 'सन्देश विवरण',
    smsUsed: 'उपयोग हुए SMS:',
    smsSent: 'एसएमएस भेजने की तारीख',
    messageSentTitle: 'सन्देश भेजा गया',
    farmerListTitle: 'जिस किसानों को संदेश प्राप्त हुआ उनकी लिस्ट',
  },
  [AppLanguages.appLanguagesIndex.english]: {
    help: 'Help',
    heading: 'Message details',
    smsUsed: 'SMS Used:',
    smsSent: 'SMS sent on',
    messageSentTitle: 'Message Sent',
    farmerListTitle: 'List of farmers received the message',
  },
};

export default (language) => languages[language];
