import CreateMySaudaApi from '../../../apis/MySauda/createSauda';

import { CreateSauda, CreateSaudaPayload } from '../types';

export default async function postComment(
  payload: CreateSaudaPayload,
): Promise<CreateSauda> {
  const body = {
    ...payload,
  };
  try {
    const result = await CreateMySaudaApi(body);

    if (!result.data.status) {
      throw result;
    }

    return result.data.data;
  } catch (error) {
    if (!error.message) {
      error.message = 'Something Went Wrong';
    }

    throw error;
  }
}
