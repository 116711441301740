import updatePoStatus from '../../../apis/Trader/updatePoStatus';

import { UpdatePoStatusResp } from '../types';

interface PayloadType {
  sauda_id: number;
  sauda_status_id: number;
}
export default async function updateUserProfile(
  payload: PayloadType,
): Promise<UpdatePoStatusResp> {
  const body = {
    ...payload,
  };
  try {
    const result = await updatePoStatus(body);

    if (!result.data.status) {
      throw result;
    }

    return result.data.data;
  } catch (error) {
    if (!error.message) {
      error.message = 'Something Went Wrong';
    }

    throw error;
  }
}
