import aesjs from 'aes-js';
import { symmetricEncryptionKey } from '../../constant/symmetricEncryptionKey';
export default async function encryptAsAES(text, key = symmetricEncryptionKey) {
  if (!text) throw new Error('text is required');

  if (typeof text !== 'string') throw new Error('text must be a string');

  if (!key) throw new Error('key is required');

  //check  if key is not an array
  if (!Array.isArray(key)) throw new Error('key must be a array');

  const textBytes = aesjs.utils.utf8.toBytes(text);
  const aesCtr = new aesjs.ModeOfOperation.ctr(key, new aesjs.Counter(5));
  const encryptedBytes = await aesCtr.encrypt(textBytes);

  const encryptedHex = aesjs.utils.hex.fromBytes(encryptedBytes);

  return encryptedHex;
}
