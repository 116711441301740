import fetchCropList from '../../../apis/Trader/fetchCropList';
import { CropList } from '../types';

interface PayloadType {
  search_param: string;
  page: number;
  limit?: number;
}

export default async function getCropList(
  payload: PayloadType,
): Promise<CropList> {
  const body = { ...payload };
  try {
    const result = await fetchCropList(body);
    if (!result.data.status) {
      throw result;
    }

    return result.data.data;
  } catch (error) {
    if (!error.message) {
      error.message = 'Something Went Wrong';
    }

    throw error;
  }
}
