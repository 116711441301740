import React from 'react';
import style from './style.module.css'; // Importing CSS styles
import ClapAnimation from '../../Components/ClapAnimation'; // Importing ClapAnimation component
import CommentRoundedIcon from '@mui/icons-material/CommentRounded';

interface PostCardAction {
  onClap: () => void; // Function to handle clap action
  isClapped: boolean; // Boolean indicating if the post is clapped
  clap: number; // Number of claps on the post
  onComment: () => void; // Function to handle comment action
  comment: number; // Number of comments on the post
  onShare: () => void; // Function to handle share action
  share: number; // Number of shares of the post
  backgroundColor?: string; // Optional background color for the action container
  onClapButtonId?: string; // Optional ID for the clap button
  onCommentButtonId?: string; // Optional ID for the comment button
  onShareButtonId?: string; // Optional ID for the share button
  language?: { [key: string]: any }; // Optional object containing language strings
  whatsappIcon: string;
  clapIcon: string;
  clapDoneIcon: string;
}

/**
 * PostCardAction component
 * @param {PostCardAction} props - Props for the PostCardAction component
 * @returns {JSX.Element} - PostCardAction component
 */
export default function index(props: PostCardAction): JSX.Element {
  const {
    onClap,
    isClapped,
    clap,
    onComment,
    comment,
    onShare,
    share,
    backgroundColor,
    onClapButtonId = '',
    onCommentButtonId = '',
    onShareButtonId = '',
    language,
    whatsappIcon,
    clapIcon,
    clapDoneIcon,
  } = props;

  return (
    <div
      className={style.actionContainer}
      style={{ backgroundColor: `${backgroundColor}` }}
    >
      {/* Clap section */}
      <div className={style.clapAnimation} id={onClapButtonId} onClick={onClap}>
        <ClapAnimation
          isClapped={isClapped}
          clap={clap}
          language={language}
          clapIcon={clapIcon}
          clapDoneIcon={clapDoneIcon}
        />
      </div>

      {/* Comment section */}
      <div
        className={style.iconCumCountContainer}
        id={onCommentButtonId}
        onClick={onComment}
      >
        <div className={style.iconImage}>
          <CommentRoundedIcon sx={{ height: 20, width: 20 }} />
        </div>
        <p
          className={style.iconTextStyles}
        >{`${language?.comment} (${comment})`}</p>
      </div>

      {/* Share section */}
      <div
        className={style.iconCumCountContainer}
        id={onShareButtonId}
        onClick={onShare}
      >
        <div className={style.iconImage}>
          <img
            src={whatsappIcon}
            height={16}
            width={16}
            style={{ maxWidth: 16 }}
            loading="lazy"
          />
        </div>
        <p
          className={style.iconTextStyles}
        >{`${language?.share} (${share})`}</p>
      </div>
    </div>
  );
}
