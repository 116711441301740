import React from 'react';
import { Box, Button, Chip } from '@mui/joy';
import Styles from './style.module.css';
import { SMSTemplateScreenProps } from '../../../hooks/SMSTemplateHook/types';

export default function Footer({
  language,
  onClickSend,
  remainingSMS,
  allInputsFilled,
}: SMSTemplateScreenProps) {
  return (
    <Box
      position={'fixed'}
      bottom={0}
      left={0}
      right={0}
      zIndex={1000}
      bgcolor={'#FFFFFF'}
      className={Styles.footer}
    >
      <Box display={'flex'} justifyContent={'center'} alignItems={'center'}>
        <Chip size="md" color="success" sx={{ borderRadius: 4 }}>
          {`${language?.smsBalanceLeft} ${remainingSMS}`}
        </Chip>
      </Box>
      <Box padding={4}>
        <Button
          fullWidth
          variant="solid"
          size="lg"
          onClick={onClickSend}
          style={{ borderRadius: 6, height: 48 }}
          disabled={!allInputsFilled}
          id={'smsTemplateOnClickSendToFarmersId'}
        >
          {language?.sendToFarmers}
        </Button>
      </Box>
    </Box>
  );
}
