import * as AppLanguages from '../constant/appLanguages';
//language will be added here
const languages = {
  [AppLanguages.appLanguagesIndex.hindi]: {
    share: 'साझा करे',
    clap: 'ताली',
    comment: 'कमेंट',
  },
  [AppLanguages.appLanguagesIndex.english]: {
    share: 'Share',
    clap: 'Clap',
    comment: 'Comment',
  },
};

export default (language) => languages[language];
