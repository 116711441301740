import React, { FC, useState, useRef, useEffect } from 'react';
import Styles from './style.module.css';

interface PopupProps {
  show: boolean;
  onClose?: (event?: any) => void;
  data: {
    title: string;
    message: string;
    message2?: string;
    buttonTitle?: string;
  };
}

const Popup: FC<PopupProps> = ({ show, onClose, data }) => {
  // const backdropRef = useRef<HTMLDivElement>(null);

  // const handleClickOutside = (event: MouseEvent) => {
  //   if (
  //     backdropRef.current &&
  //     !backdropRef.current.contains(event.target as Node)
  //   ) {
  //     onClose();
  //   }
  // };

  // useEffect(() => {
  //   if (show) {
  //     document.addEventListener("mousedown", handleClickOutside);
  //   } else {
  //     document.removeEventListener("mousedown", handleClickOutside);
  //   }
  // }, [show]);

  return (
    <>
      <div className={show ? Styles.backdrop : Styles.notActive} />
      <div
        className={`${Styles.popup} ${show ? Styles.active : Styles.notActive}`}
      >
        <div className={Styles.content}>
          <div className={Styles.title}>{data.title}</div>
          <div className={Styles.data}>{data.message}</div>
          <div className={Styles.data}>{data?.message2}</div>
        </div>
        <div onClick={(e) => onClose(e)} className={Styles.btn}>
          {data.buttonTitle}
        </div>
      </div>
    </>
  );
};

export default Popup;
