const smsTemplateType = new Map();

smsTemplateType.set('customSmsId', 23);
smsTemplateType.set('festivalSmsId', 39);
smsTemplateType.set('thankyouSmsId', 48);
smsTemplateType.set('discountSmsId', 33);
smsTemplateType.set('shopOpenCloseSingleDaySmsId', 19);
smsTemplateType.set('shopOpenCloseMultiDaySmsId', 20);
smsTemplateType.set('buyCropSmsId', 38);
smsTemplateType.set('machineSmsId', 42);
smsTemplateType.set('seedSmsId', 28);
smsTemplateType.set('pesticideSmsId', 29);
smsTemplateType.set('fertilizerSmsId', 34);

export default smsTemplateType;
