import React from 'react';
import { Box, IconButton } from '@mui/joy';
import { PlayArrowRounded } from '@mui/icons-material';
import Typography from '../../../ComponentV3/Typography';

// Define the interface for the props
interface PromotionMediaCardProps {
  promotionalMedia: { thumbnail: string }[];
  onClickPromtionalMediaCard: () => void;
  language: { [key: string]: any };
}

export default function PromotionMediaCard({
  promotionalMedia,
  onClickPromtionalMediaCard,
  language,
}: PromotionMediaCardProps) {
  const hasMedia = promotionalMedia && promotionalMedia?.length > 0;

  return (
    <Box marginY={5} marginX={4}>
      <Typography
        level={'title-sm'}
        fontWeight={600}
        style={{ color: '#555E68' }}
      >
        {language?.promotionalMediaContainerHeading}
      </Typography>

      {hasMedia && (
        <Box
          height={140}
          width={'100%'}
          display={'flex'}
          justifyContent={'center'}
          alignItems={'center'}
          marginTop={2}
          borderRadius={4}
          data-testid="media-container"
          sx={{
            backgroundImage: `url(${promotionalMedia[0].thumbnail})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        >
          <IconButton
            sx={{
              height: 48,
              width: 48,
              backgroundColor: '#F0F4F8',
              borderRadius: 24,
              display: 'flex',
            }}
            onClick={onClickPromtionalMediaCard}
            id={'smsLandingOnClickMediaContainerId'}
            data-testid={'smsLandingOnClickMediaContainerId'}
          >
            <PlayArrowRounded
              sx={{ color: '#636B74', height: 30, width: 30 }}
            />
          </IconButton>
        </Box>
      )}
    </Box>
  );
}
