import { useState, useEffect } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { apiEndPoints } from '../../constant/apiEndPoints';
import { SMSTemplateScreenProps } from './types';
import smsTypeIdEnums from '../../constant/enums/smsTypeIdEnums';
import useAuthenticationChecker from '../../utils/authChecker';
import smsTemplateType from '../../constant/enums/smsTemplateType';
import { appLanguages } from '../../constant/appLanguages';
import getLang from '../../languages/smsTemplate';
import Events from '../../constant/MoengageEvents';
import { trackEvent } from '../../utils/moengage';

export default function SMSTemplateHook(): SMSTemplateScreenProps {
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  // Get the active system language from the user's browser settings
  let activeSystemLang = Intl.DateTimeFormat().resolvedOptions().locale;
  // Determine the system language code and backend language code based on whether the language is English
  let systemLanguage = activeSystemLang?.includes('en')
    ? appLanguages[1].langCode
    : appLanguages[0].langCode;

  // Retrieve user's selected language from local storage, defaulting to system language if not found
  const langId = JSON.parse(localStorage.getItem('userLanguage'));
  // Get the language translation object based on the user's selected language or default system language
  const language = getLang(
    langId?.langCode ? langId?.langCode : systemLanguage,
  );

  const { templateDetails } = location.state || {}; // Destructure templateDetails from state
  const [headerTitle, setHeaderTitle] = useState(
    templateDetails?.form_page_heading || '',
  ); //getting from state
  const [smsTypeId, setSMSTypeId] = useState(
    params?.smsTypeId || templateDetails?.sms_type_id,
  ); //getting from state
  const [smsCost, setSMSCost] = useState(null);
  const [remainingSMS, setRemainingSMS] = useState(null);
  const [smsTemplateMessage, setSMSTemplateMessage] = useState([]);
  const [inputList, setInputList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showSingleSelectModal, setShowSingleSelectModal] = useState(false);
  const [showRateModal, setShowrateModal] = useState(false);
  const { AuthenticationCheckerAndApiCaller } = useAuthenticationChecker();
  const [inputValues, setInputValues] = useState({});
  const [singleSelectData, setSingleSelectData] = useState([]);
  const [singleSelectLoading, setSingleSelectLoading] = useState(false); // New state variable
  const [allInputsFilled, setAllInputsFilled] = useState(false);
  const [inputVariables, setInputVariables] = useState([]);
  const [radioselectedValue, setRadioSelectedValue] = useState('');
  const [dateStates, setDateStates] = useState({});
  const [calendarVisibility, setCalendarVisibility] = useState({});
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedItem, setSelectedItem] = useState(null);
  // Track time spent on screen
  const [startTime, setStartTime] = useState<Date | null>(null);
  // const [singleSelectListData, setSingleSelectListData] = useState([]);
  const [page, setPage] = useState(1);
  const [apiEndPoint, setApiEndPoint] = useState('');
  const [filteredItems, setFilteredItems] = useState([]);

  // Set the start time when the component mounts
  useEffect(() => {
    const startTime = new Date(); // Start time when the component mounts
    setStartTime(startTime);

    // Cleanup function to track event when component unmounts
    return () => {
      const currentTime = new Date().getTime();
      const timeSpent = (currentTime - startTime.getTime()) / 1000; // Convert ms to seconds
      // Track the event when the screen is unmounted
      trackEvent(Events?.onSmsTemplateEndScreen, {
        'Template name': smsTemplateNameById(), // Function to get SMS template name
        'Time spent': timeSpent,
      });
    };
  }, []);

  useEffect(() => {
    // Run both API calls simultaneously
    setLoading(true);
    Promise.all([getSMSTemplate(), getSMSStats()]).finally(() => {
      setLoading(false);
    });
  }, []);

  useEffect(() => {
    if (inputList.length > 0) {
      const initialState = {};
      inputList.forEach((variable) => {
        initialState[variable.name] =
          Number(smsTypeId) === smsTypeIdEnums.get('machinSmsMessage')
            ? ''
            : variable.default_value || '';
      });
      setInputValues(initialState);
    }
  }, [inputList]);

  useEffect(() => {
    const areAllInputsFilled = Object.values(inputValues).every(
      (value) => value !== '',
    );
    setAllInputsFilled(areAllInputsFilled);
  }, [inputValues]);

  useEffect(() => {
    setFilteredItems(
      singleSelectData.filter((item) =>
        item?.label?.toLowerCase().includes(searchTerm.toLowerCase()),
      ),
    );
  }, [singleSelectData, searchTerm]);

  useEffect(() => {
    if (apiEndPoint) {
      getSingleSelectData(page, 10); // Call getSingleSelectData when component mounts or pageNumber changes
    }
  }, [page]);

  // useEffect to trigger API call when apiEndPoint is updated
  useEffect(() => {
    if (apiEndPoint) {
      getSingleSelectData(1, 10);
    }
  }, [apiEndPoint]); // This useEffect will run when apiEndPoint is updated

  function smsTemplateNameById() {
    if (Number(smsTypeId) === smsTypeIdEnums.get('fertilizerSmsId')) {
      return 'Fertilizer Sms';
    } else if (Number(smsTypeId) === smsTypeIdEnums.get('seedSmsId')) {
      return 'Seed Sms';
    } else if (Number(smsTypeId) === smsTypeIdEnums.get('pesticideSmsId')) {
      return 'Pesticide Sms';
    } else if (Number(smsTypeId) === smsTypeIdEnums.get('buyCropMessage')) {
      return 'Buy Crop Sms';
    } else if (Number(smsTypeId) === smsTypeIdEnums.get('customMessage')) {
      return 'Custom Sms';
    } else if (Number(smsTypeId) === smsTypeIdEnums.get('discountMessage')) {
      return 'Discount Sms';
    } else if (Number(smsTypeId) === smsTypeIdEnums.get('festivalMessage')) {
      return 'Festival Sms';
    } else if (Number(smsTypeId) === smsTypeIdEnums.get('machinSmsMessage')) {
      return 'Machine Purchase Sms';
    } else if (Number(smsTypeId) === smsTypeIdEnums.get('thankyouMessage')) {
      return 'Thankyou Sms';
    } else if (
      Number(smsTypeId) === smsTypeIdEnums.get('shopOpenCloseSingleDayMessage')
    ) {
      return 'Single day Shop open/close';
    } else if (
      Number(smsTypeId) === smsTypeIdEnums.get('shopOpenCloseMultiDayMessage')
    ) {
      return 'Multi day Shop open/close';
    }
  }

  const handleSelectedItem = (item) => {
    setSelectedItem(item);
    if (Number(smsTypeId) === smsTemplateType.get('fertilizerSmsId')) {
      setInputValues((prevValues) => ({
        ...prevValues,
        cover_image: item.image_url,
        fertilizer_name: item.label,
      }));
    } else {
      setInputValues((prevValues) => ({
        ...prevValues,
        crop_name: item.label,
      }));
    }
    onCloseSingleSelectModal(); // Close the modal after selecting an item
  };

  const handleModalClose = () => {
    if (
      searchTerm &&
      !filteredItems.find((item) => item.label === searchTerm)
    ) {
      if (Number(smsTypeId) === smsTemplateType.get('fertilizerSmsId')) {
        const defaultFertilizerImage = sessionStorage.getItem(
          'defaultFertilizerImage',
        );
        if (defaultFertilizerImage) {
          setInputValues((prevValues) => ({
            ...prevValues,
            cover_image: defaultFertilizerImage,
            fertilizer_name: searchTerm,
          }));
        } else {
          // Handle the case where the default fertilizer image is not set
          console.error(
            'Default fertilizer image not found in session storage',
          );
        }
      } else {
        setInputValues((prevValues) => ({
          ...prevValues,
          crop_name: searchTerm,
        }));
      }
    }
    onCloseSingleSelectModal();
  };

  const handleDateChange = (inputName, date) => {
    setDateStates((prevStates) => {
      const newState = { ...prevStates, [inputName]: date };
      return newState;
    });
    handleInputChange({ name: inputName }, date.toISOString());
    // Close the calendar after selecting a date
    setCalendarVisibility((prevVisibility) => ({
      ...prevVisibility,
      [inputName]: false,
    }));
  };

  const handleRadioButtonSelection = (event, input) => {
    setRadioSelectedValue(event.target.value);
    handleInputChange(input, event.target.value);
  };

  const handleTextareaChange = (input, event) => {
    const inputValue = event.target.value;
    const maxLength = input.char_length;
    if (inputValue.length > maxLength) {
      return inputValue.substring(0, maxLength);
    }
    return inputValue;
  };

  const handleInputChange = (input, value) => {
    if (input?.char_length) {
      const maxLength = input?.char_length;
      const truncatedValue = value.substring(0, maxLength);
      setInputValues((prevValues) => ({
        ...prevValues,
        [input.name]: truncatedValue,
      }));
    } else {
      setInputValues((prevValues) => ({ ...prevValues, [input.name]: value }));
    }
  };

  const handleInputClick = (input) => {
    if (
      input?.input_type === 'dropdown-api' ||
      input?.input_type === 'numpad-dropdown' ||
      (input?.type === 'numpad' &&
        Number(smsTypeId) === smsTemplateType.get('buyCropSmsId'))
    ) {
      if (input?.type === 'textbox') {
        // Open single select list modal
        openSingleSelectModal(input?.api_endpoint); // pass api endpoint to modal
      } else if (input?.type === 'numpad') {
        // Open number input modal
        openRateModal();
      }
    }
  };

  async function getSMSTemplate() {
    try {
      const response = await AuthenticationCheckerAndApiCaller({
        uri: apiEndPoints?.getSMSTemplate,
        payload: { sms_type_id: smsTypeId },
        requestType: 'get',
      });
      if (response?.data?.status) {
        setSMSTemplateMessage(response?.data?.data?.sms_template);
        setInputList(response?.data?.data?.variables);
        setHeaderTitle(response?.data?.data?.form_page_heading);
        setSMSCost(response?.data?.data?.cost);
        setInputVariables(response?.data?.data?.variables);
        console.log(response?.data?.data?.variables, '====');
        if (Number(smsTypeId) === smsTypeIdEnums.get('fertilizerSmsId')) {
          const defaultFertilizerImage =
            response.data.data.variables[2].default_value;
          try {
            sessionStorage.setItem(
              'defaultFertilizerImage',
              defaultFertilizerImage,
            );
          } catch (error) {
            console.error(
              'Error saving default fertilizer image to sessionStorage:',
              error,
            );
          }
        }
        return response?.data?.data;
      }
    } catch (err) {
      console.log(err);
    }
  }

  async function getSMSStats() {
    try {
      const response = await AuthenticationCheckerAndApiCaller({
        uri: apiEndPoints?.getSMSBalance,
        payload: {},
        requestType: 'get',
      });
      if (response?.data?.status) {
        setRemainingSMS(response?.data?.data?.total_sms_credit_point);
      }
    } catch (err) {
      console.log(err);
    }
  }

  function onClickBack() {
    navigate(-1);
    const currentTime = new Date().getTime();
    const timeSpent = (currentTime - startTime.getTime()) / 1000; // Divide by 1000 to convert ms to seconds
    trackEvent(Events?.onSmsTemplateBackButtonClick, {
      Time_Spent: timeSpent,
      Selected_Template_Name: smsTemplateNameById(),
    });
  }

  function onClickSend() {
    const modifiedInputValues = Object.fromEntries(
      Object.entries(inputValues).map(([key, value]) => {
        if (value !== 'उचित मूल्य') {
          if (key === 'price') {
            return [key, `₹${value}`];
          } else if (key === 'fetilizer_price' || key === 'Discount') {
            return [key, `${value} ₹`];
          } else {
            return [key, value];
          }
        } else {
          return [key, value];
        }
      }),
    );
    setInputValues((prevValues) => ({
      ...prevValues,
      smsCost: smsCost,
      smsTypeId: smsTypeId,
    }));
    navigate(`/smsFarmers/${smsTypeId}`, {
      state: {
        smsCost: smsCost,
        inputValues: modifiedInputValues,
      },
    });
    smsTemplateEvents(inputValues);
  }

  async function getSingleSelectData(page, limit) {
    setSingleSelectLoading(true); // Set loading to true when fetching data
    try {
      const response = await AuthenticationCheckerAndApiCaller({
        uri: `/${apiEndPoint}`,
        payload: { page_no: page, limit },
        requestType: 'get',
      });
      if (response?.data?.status) {
        // Handle the response data here
        const { dropdown_data } = response.data.data;
        // Concatenate the new data with the existing singleSelectData
        setSingleSelectData((prevData) => [...prevData, ...dropdown_data]);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setSingleSelectLoading(false); // Set loading to false when data is fetched or error occurs
    }
  }

  function openSingleSelectModal(apiEndPointValue) {
    setApiEndPoint(apiEndPointValue); // Set apiEndPoint first
    setShowSingleSelectModal(true); // Show modal after updating apiEndPoint
  }

  function onCloseSingleSelectModal() {
    setSingleSelectData([]);
    setShowSingleSelectModal(false);
    setPage(1);
  }

  function openRateModal() {
    setShowrateModal(true);
  }

  function onCloseRateModal() {
    setShowrateModal(false);
  }

  function smsTemplateEvents(inputValues) {
    const currentTime = new Date().getTime();
    const timeSpent = (currentTime - startTime.getTime()) / 1000;
    if (Number(smsTypeId) === smsTypeIdEnums.get('fertilizerSmsId')) {
      trackEvent(Events?.onSmsFertilizerNotifyFarmerClick, {
        Time_Spent: timeSpent,
        Fertilizer_Name: inputValues?.fertilizer_name,
        Fertilizer_Price: inputValues?.fetilizer_price,
      });
    } else if (Number(smsTypeId) === smsTypeIdEnums.get('seedSmsId')) {
      trackEvent(Events?.onSmsSeedNotifyFarmerClick, {
        Time_Spent: timeSpent,
        Seed_Name: inputValues?.product,
        Crop_Name: inputValues?.crop_name,
        Price: inputValues?.price,
      });
    } else if (Number(smsTypeId) === smsTypeIdEnums.get('pesticideSmsId')) {
      trackEvent(Events?.onSmsPesticideNotifyFarmerClick, {
        Time_Spent: timeSpent,
        Crop_Name: inputValues?.crop_name,
        Disease_Name: inputValues?.disease,
        Pesticide_Name: inputValues?.medicine,
        Price: inputValues?.price,
      });
    } else if (Number(smsTypeId) === smsTypeIdEnums.get('buyCropMessage')) {
      trackEvent(Events?.onSmsSellCropNotifyFarmerClick, {
        Timpe_Spent: timeSpent,
        Crop_Name: inputValues?.crop_name,
        Crop_Price: inputValues?.price,
      });
    } else if (Number(smsTypeId) === smsTypeIdEnums.get('customMessage')) {
      trackEvent(Events?.onSmsCustomNotifyFarmerClick, {
        Timpe_Spent: timeSpent,
        Custom_Message: inputValues?.formatted_with_commas,
      });
    } else if (Number(smsTypeId) === smsTypeIdEnums.get('discountMessage')) {
      trackEvent(Events?.onSmsDiscountNotifyFarmerClick, {
        Timpe_Spent: timeSpent,
        Product_Name: inputValues?.Product,
        Product_Price: inputValues?.Discount,
      });
    } else if (Number(smsTypeId) === smsTypeIdEnums.get('festivalMessage')) {
      trackEvent(Events?.onSmsFestivalNotifyFarmerClick, {
        Time_Spent: timeSpent,
        Festival_Name: inputValues?.festival_name,
      });
    } else if (Number(smsTypeId) === smsTypeIdEnums.get('machinSmsMessage')) {
      trackEvent(Events?.onSmsBuyMachineryNotifyFarmerClick, {
        Time_Spent: timeSpent,
        Machinery_Name: inputValues?.machine_name,
        Machinery_Price: inputValues?.price,
      });
    } else if (Number(smsTypeId) === smsTypeIdEnums.get('thankyouMessage')) {
      trackEvent(Events?.onSmsThankYouNotifyFarmerClick, {
        Time_Spent: timeSpent,
        Buying_Price: inputValues?.fetilizer_price,
      });
    } else if (
      Number(smsTypeId) === smsTypeIdEnums.get('shopOpenCloseSingleDayMessage')
    ) {
      trackEvent(Events?.onSmsShopOpenNotifyFarmerClick, {
        Time_Spent: timeSpent,
        Date_Selected: inputValues?.select_date,
        Shop_Status: inputValues?.status,
      });
    } else if (
      Number(smsTypeId) === smsTypeIdEnums.get('shopOpenCloseMultiDayMessage')
    ) {
      trackEvent(Events?.onSmsShopOpenMultipleDaysNotifyFarmerClick, {
        Time_Spent: timeSpent,
        Start_Date: inputValues?.dateRange1,
        End_Date: inputValues?.dateRange2,
        Shop_Status: inputValues?.status,
      });
    }
  }

  return {
    language,
    smsTypeId,
    loading,
    headerTitle,
    onClickBack,
    remainingSMS,
    smsTemplateMessage,
    onClickSend,
    inputList,
    inputValues,
    setInputValues,
    handleInputChange,
    handleInputClick,
    showSingleSelectModal,
    singleSelectLoading,
    showRateModal,
    onCloseRateModal,
    allInputsFilled,
    inputVariables,
    radioselectedValue,
    calendarVisibility,
    setCalendarVisibility,
    dateStates,
    handleDateChange,
    handleRadioButtonSelection,
    handleTextareaChange,
    handleSelectedItem,
    handleModalClose,
    searchTerm,
    setSearchTerm,
    filteredItems,
    setPage,
  };
}
