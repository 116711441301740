import * as AppLanguages from '../constant/appLanguages';
//language will be added here
const languages = {
  [AppLanguages.appLanguagesIndex.hindi]: {
    headerTitle: 'प्रोफ़ाइल',
    editProfileText: 'एडिट प्रोफ़ाइल',
    logout: 'लॉग आउट करें',
    changeLanguage: 'पसंदीदा भाषा चुनें',
    privacyPolicy: 'गोपनीयता नीति',
    feedback: 'ऐप को रेट करें',
    helpText: 'सहायता',
    appVersion: 'App Version',
    selectLanguage: 'भाषा चुने',
    selectLanguageButton: 'आगे बढ़ें',
    confirmLogout: 'लॉगआउट की पुष्टि करें',
    logOutDescription: 'क्या आप वाकई ऐप से लॉगआउट करना चाहते है ?',
    cancel: 'रद्द करें',
    yes: 'हाँ',
    myFarmer: 'मेरे किसान',
    requestFailErrorMessage:
      'असुविधा के लिए क्षमा करें! कृपया बाद में फिर से प्रयास करें।',
  },
  [AppLanguages.appLanguagesIndex.english]: {
    headerTitle: 'My Profile',
    editProfileText: 'Edit Profile',
    logout: 'Logout',
    changeLanguage: 'Change app language',
    privacyPolicy: 'Privacy Policy',
    feedback: 'Rate the app',
    helpText: 'Help',
    appVersion: 'App Version',
    selectLanguage: 'Select language',
    selectLanguageButton: 'Proceed',
    confirmLogout: 'Confirm Logout',
    logOutDescription: 'Are you sure you want to logout from the app ?',
    cancel: 'Cancel',
    yes: 'Yes',
    myFarmer: 'My Farmer',
    requestFailErrorMessage:
      'Sorry for the inconvenience! Please try again later.',
  },
};

export default (language) => languages[language];
