import React, { useState, useEffect } from 'react';
import style from './style.module.css';

export const Header = (props) => {
  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.pageYOffset;
      const isScrollingUp = prevScrollPos > currentScrollPos;

      setPrevScrollPos(currentScrollPos);
      setVisible(isScrollingUp || currentScrollPos < 10); // Show header if scrolling up or at the top
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [prevScrollPos]);

  const profileStringify = localStorage.getItem('userProfile');
  let userProfileData;

  if (profileStringify) {
    try {
      userProfileData = JSON.parse(profileStringify);
    } catch (error) {
      // Handle the JSON parsing error here, e.g., log it or set a default value for profile
      console.error('Error parsing JSON:', error);
    }
  }
  return (
    <div
      className={`${style.headerParent} ${
        visible ? style.visible : style.hidden
      }`}
    >
      <div className={style.profileContainer}>
        <img
          alt="profile"
          src={
            userProfileData?.profile_pic_url
              ? userProfileData?.profile_pic_url
              : props.avatarIcon
          }
          className={style.imageContainer}
          loading="eager"
        />
        <div className={style.userDetailsContainer}>
          <span id={style.spanid}>नमस्कार</span>
          <strong className={style.name}>
            {userProfileData?.merchant_name
              ? userProfileData?.merchant_name
              : 'प्रिय उपभोक्ता'}
          </strong>
        </div>
      </div>
    </div>
  );
};
