import * as AppLanguages from '../constant/appLanguages';
//language will be added here
const languages = {
  [AppLanguages.appLanguagesIndex.hindi]: {
    help: 'सहायता',
    pendingRakeMessage: 'रेक रास्ते मैं हैं',
    reachedRakeMessage: 'रेक पहुँच चुकी हैं',
    rake: 'रेक',
    headerTitle: '{(variable)} की रेक',
    noRakeAvailable: 'कोई रेक नहीं लगी हैं',
    noCurrentRake: '{(variable)} जिले में कोई रेक नहीं लगी',
    nearAreaRaketitle: 'अपने जिले के पास की रेक देखें',
    seeInMoreDistrict: 'पास के ज़िलों में देखें',
    rakeMessage1: 'समय पर जानकारी',
    rakeMessage2: 'सही जानकारी',
  },
  [AppLanguages.appLanguagesIndex.english]: {
    help: 'Help',
    pendingRakeMessage: "Rake is on the way'",
    reachedRakeMessage: 'Rake has arrived',
    rake: 'Rake',
    headerTitle: 'Rake for {(variable)}',
    noRakeAvailable: 'No Rake',
    noCurrentRake: 'No Rake in {(variable)}',
    nearAreaRaketitle: 'See Rake in nearby district',
    seeInMoreDistrict: 'See in your nearby district',
    rakeMessage1: 'Timely Information',
    rakeMessage2: 'Correct Information',
  },
};

export default (language) => languages[language];
