import * as AppLanguages from '../../../constant/appLanguages';

const languages = {
  [AppLanguages.appLanguagesIndex.hindi]: {
    second: 'सेकंड',
    minutes_ago: '1 मिनट पहले',
    hour_ago: '1 घंटे पहले',
    minutes: 'मिनट',
    hour: 'घंटे',
    ago: 'पहले',
    days: 'दिन',
    last_week: 'पिछले सप्ताह',
    yesterday: 'बीता कल',
    weeks: 'सप्ताह',
    last_month: 'पिछले महीने',
    months: 'महीना',
    last_year: 'पिछले साल',
    years: 'साल',
  },

  [AppLanguages.appLanguagesIndex.english]: {
    second: 'seconds',
    minutes_ago: '1 minute ago',
    hour_ago: '1 hour ago',
    minutes: 'minutes',
    hour: 'hour',
    ago: 'ago',
    days: 'day',
    last_week: 'Last week',
    yesterday: 'Yesterday',
    weeks: 'weeks',
    last_month: 'Last month',
    months: 'months',
    last_year: 'Last year',
    years: 'years',
  },
};
export default (language) => languages[language];
