export default function CreateTextForEncryption(text) {
  // append the date at front of the text
  // append the month at end of the text
  const date = new Date();
  const day = date.getDate();
  const month = date.getMonth();
  const revisedDateString = day < 10 ? '0' + day : day;
  const revisedMonthString = month < 10 ? '0' + month : month;
  const revisedText = revisedDateString + text + revisedMonthString;
  return revisedText;
}
