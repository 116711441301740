import React from 'react';
import Styles from './style.module.css'; // Importing CSS module for styling.
import { Box, IconButton } from '@mui/joy'; // Importing Box and IconButton components from MUI Joy for layout and interaction.
import Typography from '../../../ComponentV3/Typography'; // Importing a custom Typography component for text rendering.
import { ArrowBackRounded } from '@mui/icons-material'; // Importing the ArrowBackRounded icon for the back button.
import { EditProfileHeaderProps } from '../../../hooks/EditProfile/types'; // Importing type definitions for component props.

// Header component for the Edit Profile screen.
// It displays a back button and the screen title, both of which can be localized.
const Header = ({
  onClickBackEditProfile, // Function to handle the back button click, navigating the user back.
  language, // Object containing localized strings, used here for the screen title.
}: EditProfileHeaderProps) => {
  return (
    <Box className={Styles.wrapper}>
      {' '}
      {/* Container for the header, styled with CSS modules. */}
      <IconButton
        size="md" // Setting the size of the IconButton.
        onClick={onClickBackEditProfile} // Assigning the click handler to navigate back.
        id="editProfileOnClickBackId" // Providing an ID for potential testing purposes.
      >
        <ArrowBackRounded /> {/* Displaying the back arrow icon. */}
      </IconButton>
      <Box className={Styles.heading}>
        {' '}
        {/* Container for the screen title. */}
        <Typography level="title-md">{language?.editProfile}</Typography>{' '}
        {/* Rendering the localized screen title. */}
      </Box>
    </Box>
  );
};

export default Header; // Exporting the Header component for use in other parts of the application.
