const SaudaEnums = new Map();

SaudaEnums.set('saudaInReview', 1);
SaudaEnums.set('poAcceptancePending', 2);
SaudaEnums.set('saudaBooked', 3);
SaudaEnums.set('saudaCancelled', 4);

SaudaEnums.set('saudaRejected', 5);
SaudaEnums.set('saudaCompleted', 6);
SaudaEnums.set('poRejected', 7);

export default SaudaEnums;
