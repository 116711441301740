import { configureStore } from '@reduxjs/toolkit';
import communityReducer from './modal/community/slice/community.slice';
import CommentReducer from './modal/community/slice/comment.slice';
import personaReducer from './redux/slices/personaSlice';

export const store = configureStore({
  reducer: {
    community: communityReducer,
    comment: CommentReducer,
    persona: personaReducer, // user persona slice
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
