import { Config } from './types';
const AES_KEY = [94, 51, 9, 20, 16, 88, 25, 87, 25, 10, 84, 51, 3, 72, 14, 99];

const config = {
  AES_KEY,
  API_URL: import.meta.env.VITE_API_BASE_URL,
};

const appConfig: Config = config;

export default function getConfigByKeys(
  keysToRetrieve: string[],
): Partial<Config> {
  return keysToRetrieve.reduce((accumulator, currentKey) => {
    accumulator[currentKey] = appConfig[currentKey];
    return accumulator;
  }, {} as Partial<Config>);
}
