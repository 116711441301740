import * as AppLanguages from '../constant/appLanguages';

const languages = {
  [AppLanguages.appLanguagesIndex.hindi]: {
    invoice_docs: 'इनवॉइस दस्तावेज़',
    weight_slip: 'वजन पर्ची',
    mandi_tax: 'मंडी टैक्स',
    listOfFiles: 'फाइलों की सूची',
    add_document: 'दस्तावेज़ जोड़ें',
    bill_to_text:
      'बिल Farmart Service Pvt. Ltd.के नाम पर बनेगी और पता में <deliveryDistrict>,<deliveryState> रहेगा जो  डिलीवरी जिला और स्टेट होगा, और <farmartGST>',
    documentApprovalPending: 'मंजूरी लंबित है',
    documentApproved: 'मंजूर',
    documentRejected: 'अस्वीकृत',
    add_more_files: 'और  फाइल जोड़ें',
    done: 'पूरा हो गया',
    reUpload: 'पुनः अपलोड करें',
    reUploadRejectedDocuments:
      'कृपया अस्वीकृत दस्तावेजों को फिर से अपलोड करें।',
    maxFilesToUploadPerDocument:
      'आप प्रति दस्तावेज़ अधिकतम 10 फाइलें अपलोड कर सकते हैं।',
    cancelUpload: 'अपलोड रद्द करें',
    continueUploading: 'अपलोड जारी रखें',
    documentUploadConfirmationText:
      'क्या आप सुनिश्चित हैं कि आप <documentName> अपलोड को रद्द करना चाहते हैं? पुनः अपलोड किए गए सभी दस्तावेज़ हटा दिए जाएंगे।',
    supportedFormatsText: 'Supported formats: Image and PDF',
    reUploadRejectedDocument: 'कृपया अस्वीकृत दस्तावेजों को फिर से अपलोड करें।',
    errorMessage:
      'असुविधा के लिए क्षमा करें! कृपया बाद में फिर से प्रयास करें।',
    fileListAlert:
      'आपने 10 से अधिक फाइलें जोड़ी हैं। कृपया आगे बढ़ने के लिए प्रति दस्तावेज़ 10 फ़ाइलों तक सीमित करें।',
    backConfirmation: 'पुनः अपलोड किए गए सभी दस्तावेज़ हटा दिए जाएंगे।',
    fileSizeExceeded:
      'दस्तावेज़ बहुत बड़ा है और अपलोड नहीं किया जा सकता है। कृपया आकार को कम करें और फिर से प्रयास करें',
    fileFormatNotAccpetable:
      'गलत फाइल प्रकार चुना गया है। कृपया फोटो या PDF ही चुनें।',
  },
  [AppLanguages.appLanguagesIndex.english]: {
    invoice_docs: 'Invoice Document',
    weight_slip: 'Weight Slip',
    mandi_tax: 'Mandi Tax',
    listOfFiles: 'List of files',
    add_document: 'Add document',
    bill_to_text:
      'Bill to :Farmart Service Pvt. Ltd., and address: <deliveryDistrict>, <deliveryState> and GST: <farmartGST>',
    document_approval_pending: 'Approval Pending',
    documentApproved: 'Approved',
    documentRejected: 'Rejected',
    add_more_files: 'Add more files',
    done: 'Done',
    reUpload: 'Re-upload',
    reUploadRejectedDocuments: 'Please re-upload the rejected documents.',
    maxFilesToUploadPerDocument: 'You can upload maximum 10 files per document',
    cancelUpload: 'Cancel Upload',
    continueUploading: 'Continue Uploading',
    documentUploadConfirmationText:
      'Are you sure you want to cancel the <documentName> upload? All the re-uploaded document will get deleted.',
    supportedFormatsText: 'Supported formats: Image and PDF',
    reUploadRejectedDocument: 'Please re-upload the rejected documents.',
    errorMessage: 'Sorry for the inconvenience! Please try again later.',
    fileListAlert:
      "You've added more than 10 files. Please limit to 10 files per document to proceed.",
    backConfirmation: 'All the re-uploaded document will get deleted.',
    fileSizeExceeded:
      'The document is too large and cannot be uploaded. Please reduce the size and try again.',
    fileFormatNotAccpetable:
      'Wrong file type selected. Please choose an image or PDF.',
  },
};

export default (language) => languages[language];
