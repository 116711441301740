import React from 'react';
import { Box, Snackbar } from '@mui/joy';

// Defining the interface for the component's props
interface FAQAlertProps {
  showFaqAlert: boolean; // Boolean to control the visibility of the Snackbar
  setShowFaqAlert: (flag: boolean) => void; // Function to update the visibility state
  language: { [key: string]: any }; // Object to hold language-specific text
}

// Functional component definition with destructured props
const FAQAlert = ({
  showFaqAlert,
  setShowFaqAlert,
  language,
}: FAQAlertProps) => {
  return (
    <Box>
      <Snackbar
        open={showFaqAlert} // Controls whether the Snackbar is open
        autoHideDuration={4000} // Automatically hides the Snackbar after 4 seconds
        onClose={() => {
          setShowFaqAlert(false); // Sets showFaqAlert to false when Snackbar is closed
        }}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }} // Positions the Snackbar at the top center
        sx={{
          paddingTop: 3,
          paddingBottom: 3,
          paddingLeft: 2,
          paddingRight: 2,
          zIndex: 20000,
          borderRadius: '8px',
          width: '90%',
          marginBottom: '32px',
        }} // Custom styling for the Snackbar
        variant="soft"
        color={'danger'}
      >
        {language?.noWhatsAppAccountFound}
      </Snackbar>
    </Box>
  );
};

export default FAQAlert; // Exporting the FAQAlert component as the default export
