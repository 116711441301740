import * as AppLanguages from '../constant/appLanguages';
//language will be added here
const languages = {
  [AppLanguages.appLanguagesIndex.english]: {
    confirmRequest: 'Confirm Request',
    wantTo: 'I want to',
    payAtBuyersTerms: 'Pay at Buyer Claim',
    payAtGRNTerms: 'Pay at Unloading',
    to: 'to',
    netPriceQuantityInfo:
      'Sauda price and quantity can be changed until the Sauda is confirmed.',
    confirmSaudaRequest: 'Confirm Sauda Request',
    saleDetails: 'Sell {(variable1)} of {(variable2)} at {(variable3)}',
    help: 'Help',
    errorMessage:
      'असुविधा के लिए क्षमा करें! कृपया बाद में फिर से प्रयास करें।',
    docRequirementTitle: 'Document required at the time of Sauda confirmation',
    gstOrPan: '• GST or PAN Card',
    canceledCheque: '• Cancelled Cheque(For bank account)',
    tds: '• TDS Certificate',
    aadharCard: '• Aadhaar Card',
    doYouHaveBrokerCode: 'Do you have broker code?',
    enterBrokerCode: 'Enter broker code here',
    unverifiedBrokerCode:
      'Please verify the broker code before confirming the sauda request.',
    invalidBrokerCode: 'Invalid code, please try another code!',
    verifiedBrokerCode:
      'Code applied successfully! Your broker firm name is {(variable1)}.',
    invalidBrokerCodeAlert:
      'You have entered invalid broker code. Please enter valid code before confirming the request.',
    saudaPriceAndQuantity:
      'Sauda price and quantity can be changed until the Sauda is confirmed.',
    amountSaved: 'You saved ₹{(variable)} on this sauda',
    totalSaudaValue: 'Total Sauda Value ₹{(variable)}',
    payAtUnloading: 'Pay at Unloading',
    grossBuyerPrice: 'Gross Buyer Price',
    cashDiscount: 'Cash Discount',
    bagDeduction: 'Bag Deduction',
    unloadingCharges: 'Unloading Charges',
    netbuyerPrice: 'Net Buyer Price',
    brokerage: 'Brokerage',
    farMartFee: 'FarMart Fee',
    discountedFarMartFee: 'Discount on FarMart Fee @ {(variable)}%',
    netForPrice: 'Net FOR Price',
    popUpOkayButton: 'Okay',
    brokeragePopUpSubHeading:
      'The charges to book an order with the buyer via FarMart in ensuring you get the best prices and you are being offered reliable services every time. Brokerage: {(BrokergeUnit)}.',
    financePopUpSubHeading:
      "To help you get your payment faster before buyer's makes the payment. This will assist you in maintaining your cash flow faster. FarMart Fee: {(financeCost)}%",
    netPricePopUpSubHeading:
      'Net FORPrice = Gross Buyer Price - Bag Deduction - unloading Charges - Brokerage - FarMart Fee.',
    netPricePopUpSubHeading2:
      'The rate shown above will be available after sending the truck to the buyer. If you want to hire a truck from Farmart, you will have to pay the fare for the truck.',
  },
  [AppLanguages.appLanguagesIndex.hindi]: {
    confirmRequest: 'सौदा  मंजूर',
    wantTo: 'मैं',
    payAtBuyersTerms: 'बायर क्लेम पर भुगतान',
    payAtGRNTerms: 'अनलोडिंग पर भुगतान',
    to: 'हूँ',
    netPriceQuantityInfo:
      'सौदा के मूल्य और मात्रा को तब तक बदला जा सकता है जब तक कि सौदा की पुष्टि नहीं हो जाती।',
    confirmSaudaRequest: 'सौदा मंजूर करें ',
    saleDetails: '{(variable1)} {(variable2)} {(variable3)} पर बेचना चाहता',
    help: 'सहायता',
    errorMessage: 'Sorry for the inconvenience! Please try again later.',
    docRequirementTitle: 'सौदा बनाते समय आवश्यक दस्तावेज।',
    gstOrPan: '• GST या पैन कार्ड',
    canceledCheque: '• कैंसिल चेक (बैंक अकाउंट के लिए )',
    tds: '• TDS सर्टिफिकेट',
    aadharCard: '• आधार कार्ड',
    doYouHaveBrokerCode: 'क्या आपके पास ब्रोकर कोड है ?',
    enterBrokerCode: ' ब्रोकर कोड यहाँ  डालें ',
    unverifiedBrokerCode:
      'कृपया सौदा की पुष्टि करने से पहले ब्रोकर कोड की जाँच करें ',
    invalidBrokerCode: 'गलत कोड, कृपया सही कोड डालें ',
    verifiedBrokerCode:
      'कोड को सफलतापूर्वक लागु किया गया  है! आपके ब्रोकर फर्म का नाम {(variable1)} है।',
    invalidBrokerCodeAlert:
      'आपने गलत ब्रोकर कोड डाला है कृपया सौदा की पुष्टि करने से पहले सही कोड डालें ',
    saudaPriceAndQuantity:
      'सौदा के मूल्य और मात्रा को तब तक बदला जा सकता है जब तक कि सौदा की पुष्टि नहीं हो जाती',
    amountSaved: 'इस सौदे पर आपको ₹{(variable)} की बचत हुई',
    totalSaudaValue: 'कुल सौदा मूल्य ₹{(variable)}',
    payAtUnloading: 'अनलोडिंग पर भुगतान करें',
    grossBuyerPrice: 'ग्रॉस बायर मूल्य',
    cashDiscount: 'कैश डिस्काउंट',
    bagDeduction: 'बैग कटौती',
    unloadingCharges: 'अनलोडिंग शुल्क',
    netbuyerPrice: 'नेट बायर मूल्य',
    brokerage: 'ब्रोकरेज',
    farMartFee: 'फारमार्ट फी',
    discountedFarMartFee: 'फारमार्ट फी पर @ {(variable)}% की छूट',
    netForPrice: 'नेट FOR मूल्य',
    popUpOkayButton: 'ठीक है',
    brokeragePopUpSubHeading:
      'फारमार्ट के माध्यम से खरीददार के साथ एक सौदा बुक करने के लिए शुल्क, जिससे फारमार्ट यह सुनिश्चित करता है कि आप हर बार सर्वोत्तम मूल्य प्राप्त कर सकें और आपको हमेशा विश्वसनीय सेवाएं प्रदान की जा सके। ब्रोकरेज: {(BrokerageUnit)}',
    financePopUpSubHeading:
      'बायर के भुगतान करने से पहले तेजी से भुगतान प्राप्त करने में आपकी सहायता करेगा, यह आपके कैश फ्लो को तेज बनाए रखने में मदद करेगा। फारमार्ट फी: {(financeCost)}%',
    netPricePopUpSubHeading:
      'नेट FOR मूल्य = ग्रॉस बायर मूल्य - बैग कटौती - अनलोडिंग शुल्क - ब्रोकरेज - फारमार्ट फी।',
    netPricePopUpSubHeading2:
      'ऊपर दिख रहा रेट बायर के यहाँ गाड़ी भेजने पर मिलेगा। अगर आप फारमार्ट से गाड़ी लगाना चाहते हैं तो आपको गाड़ी का किराया देना होगा।',
  },
};

export default (language) => languages[language];
