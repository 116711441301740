import React from 'react';
import CreateDispatchView from '../../Pages/CreateDispatch/createDispatch';
import UseCreateDispatch from '../../hooks/createDispatch/useCreateDispatch';

export default function createDispatch() {
  const {
    language,
    onClickBackButton,
    dispatchDetails,
    dispatchWeight,
    truckNumber,
    noOfBags,
    onChangeDispatchWeight,
    onChangeTruckNumber,
    onChangeNoOfBags,
    dispatchDetailLoading,
    onClickInput,
    onClickUploadDocument,
    onClickSubmitButton,
    error,
    setError,
    invoiceDocument,
    weightSlipDocument,
    mandiDoument,
    showCancelDispatchModal,
    toggleCancelDispatchModal,
    onClickCancelDispatch,
    createDispatchToastPopup,
    submitLoading,
    setIsError,
    isError,
    onChangeTruckDriverNumber,
    truckDriverNumber,
    saudaDetailData,
    onClickMandiToolTip,
    mandiToolTipVisible,
    truckErrorMessage,
  } = UseCreateDispatch();
  return (
    <CreateDispatchView
      language={language}
      onClickBackButton={onClickBackButton}
      dispatchDetails={dispatchDetails}
      dispatchWeight={dispatchWeight}
      truckNumber={truckNumber}
      noOfBags={noOfBags}
      onChangeDispatchWeight={onChangeDispatchWeight}
      onChangeTruckNumber={onChangeTruckNumber}
      onChangeNoOfBags={onChangeNoOfBags}
      dispatchDetailLoading={dispatchDetailLoading}
      onClickInput={onClickInput}
      onClickUploadDocument={onClickUploadDocument}
      onClickSubmitButton={onClickSubmitButton}
      error={error}
      setError={setError}
      invoiceDocument={invoiceDocument}
      weightSlipDocument={weightSlipDocument}
      mandiDoument={mandiDoument}
      showCancelDispatchModal={showCancelDispatchModal}
      toggleCancelDispatchModal={toggleCancelDispatchModal}
      onClickCancelDispatch={onClickCancelDispatch}
      createDispatchToastPopup={createDispatchToastPopup}
      submitLoading={submitLoading}
      setIsError={setIsError}
      isError={isError}
      onChangeTruckDriverNumber={onChangeTruckDriverNumber}
      truckDriverNumber={truckDriverNumber}
      saudaDetailData={saudaDetailData}
      onClickMandiToolTip={onClickMandiToolTip}
      mandiToolTipVisible={mandiToolTipVisible}
      truckErrorMessage={truckErrorMessage}
    />
  );
}
