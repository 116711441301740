import fetchIpLocation from '../../../apis/Trader/fetchIpLocation';
import { IpLocationResp } from '../types';

export default async function getUserIpLocation(
  payload = {},
): Promise<IpLocationResp> {
  const body = {
    ...payload,
  };
  try {
    const result = await fetchIpLocation(body);

    if (!result.data) {
      throw result;
    }

    return result.data;
  } catch (error) {
    if (!error.message) {
      error.message = 'Something Went Wrong';
    }

    throw error;
  }
}
