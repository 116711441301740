// @ts-nocheck

import React from 'react';
import DocViewer from '@cyntler/react-doc-viewer';
import './DocumentViewer.css';
import { useDocumentContext } from './../DocumentContext';
import MyCustomPNGRenderer from './../renderer/MyCustomPNGRenderer';
import MyCustomPDFRenderer from './../renderer/MyCustomPDFRenderer';
import NavBar from './../header/NavBar';
import Modal from '../../../ComponentV2/Modal';
import DownloadRounded from '@mui/icons-material/DownloadRounded';
import Button from '../../../ComponentsV3/MuiButton';
import UploadRounded from '@mui/icons-material/UploadRounded';

function DownloadModal({ onDownload, documents }) {
  return (
    <div className="download-children">
      <DownloadRounded />
      <div
        onClick={() => onDownload(documents[0].uri)}
        className="download-label"
      >
        Download report
      </div>
    </div>
  );
}

export const DocumentViewer = () => {
  const {
    documents,
    removeDocument,
    onPressClosePreview,
    deleteVisible,
    label,
    downloadModal,
    onCloseDownloadModal,
    onDownload,
    downloadVisible,
    reuploadVisible,
    reuploadLabel,
    reUploadInputRef,
    handleReUploadChange,
    handleReUploadButtonClick,
    currentPreviewIndex,
  } = useDocumentContext();

  return (
    <>
      <div className="viewerContainer" createObjectURL>
        <NavBar
          removeDocument={removeDocument}
          onPressClosePreview={onPressClosePreview}
          deleteVisible={deleteVisible}
          label={label}
          downloadVisible={downloadVisible}
          onCloseDownloadModal={onCloseDownloadModal}
        />
        <div style={{}}>
          <DocViewer
            className="viewer"
            documents={documents}
            language="en"
            pluginRenderers={[MyCustomPDFRenderer, MyCustomPNGRenderer]}
            config={{
              header: {
                overrideComponent: () => {
                  return <label></label>;
                },
                disableHeader: true,
                retainURLParams: false,
              },
              csvDelimiter: ';',
              pdfZoom: {
                defaultZoom: 1,
                zoomJump: 0.1,
                disableDefaultControl: false,
              },
              pdfVerticalScrollByDefault: true,
            }}
          />
        </div>

        {reuploadVisible && (
          <div
            className="reupload-button-container"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <input
              type="file"
              accept=".pdf, .jpg, .jpeg, .png"
              multiple={false}
              onChange={handleReUploadChange}
              style={{ display: 'none' }}
              ref={(el) => (reUploadInputRef.current[currentPreviewIndex] = el)}
            />
            <Button
              startDecorator={<UploadRounded />}
              onClick={(e) => {
                e.stopPropagation();
                handleReUploadButtonClick(
                  'fileListCard',
                  currentPreviewIndex,
                  documents[0]?.fileData,
                );
              }}
              id="reUploadButtonFileListCard"
            >
              {reuploadLabel}
            </Button>
          </div>
        )}
      </div>

      <Modal
        isOpen={downloadModal}
        children={
          <DownloadModal onDownload={onDownload} documents={documents} />
        }
        title={'Deduction Report'}
        isCloseButtonOption={true}
        onClose={onCloseDownloadModal}
      />
    </>
  );
};

export default DocumentViewer;
