import React, { Suspense } from 'react';
import Styles from './style.module.css';
import Header from './Header/Header';

import { SmsFarmerProps } from '../../hooks/SmsFarmersHook/types';
import SearchBar from './SearchBar/SearchBar';
import EmptyStateContent from './EmptyStateContent/EmptyStateContent';
import Loader from '../../ComponentV2/Loading';
import InfiniteScroll from '../../Components/InfintyScroll';

import { Box } from '@mui/joy';
import AddFarmer from './AddFarmer/index';
import Toast from '../../ComponentV2/Toast';
import SmsCount from './SmsCount/SmsCount';
import MuiSkeleton from '../../MuiComponents/Skeleton';
const ContactList = React.lazy(() => import('./ContactList/ContactList'));

export default function MyFarmerView(props: SmsFarmerProps) {
  const {
    onPressBack,
    language,
    onContactUs,
    farmers,
    searchParam,
    setSearchParam,
    toggleFarmerSelection,
    debouncedSetSearchParam,
    initialLoading,
    searchLoading,
    onClickAddFarmer,
    isErrorPopup,
    loadMoreFarmers,
    setCurrentPage,
    contactsIndex,
    selectAll,
    selectAllView,
    initialFarmersCount,
    selectedCount,
    smsBalance,
    saveFarmer,
    saving,
    selectedFarmerCount,
  } = props;

  const showEmptyState =
    !initialLoading &&
    farmers.length === 0 &&
    searchParam === '' &&
    initialFarmersCount === 0;

  function renderLoader() {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100%"
        paddingTop={3}
      >
        <Loader size={30} color="grey" marginTop={20} />
      </Box>
    );
  }

  return (
    <Box className={Styles.container} bgcolor={'background.body'}>
      <Header
        onContactUs={onContactUs}
        onPressBack={onPressBack}
        language={language}
      />
      {initialLoading ? (
        // Show full-screen loader during initial load
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          paddingTop={3}
          margin={'0px 16px'}
          flexDirection={'column'}
          gap={4}
        >
          <MuiSkeleton sx={{ width: '30%', height: 24, borderRadius: 8 }} />
          <MuiSkeleton
            sx={{ width: '100%', height: 70, borderRadius: 8 }}
            itemNumber={10}
          />
        </Box>
      ) : (
        <>
          <SmsCount
            selectedCount={selectedCount}
            smsBalance={smsBalance}
            language={language}
          />
          {showEmptyState ? (
            // Show empty state content if no farmers and no search params after initial load
            <EmptyStateContent
              language={language}
              onClickAddFarmer={onClickAddFarmer}
            />
          ) : (
            <>
              <SearchBar
                language={language}
                searchParam={searchParam}
                setSearchParam={setSearchParam}
                debouncedSetSearchParam={debouncedSetSearchParam}
                setCurrentPage={setCurrentPage}
              />
              {searchLoading ? (
                // Show loader in place of list during search
                renderLoader()
              ) : (
                <InfiniteScroll
                  onScrollToEnd={loadMoreFarmers}
                  loading={searchLoading}
                  loadingElement={renderLoader()}
                >
                  <Suspense
                    fallback={
                      <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        paddingTop={3}
                        margin={'0px 16px'}
                        flexDirection={'column'}
                        gap={4}
                      >
                        <MuiSkeleton
                          sx={{ width: '100%', height: 70, borderRadius: 8 }}
                          itemNumber={10}
                        />
                      </Box>
                    }
                  >
                    <ContactList
                      language={language}
                      farmers={farmers}
                      toggleFarmerSelection={toggleFarmerSelection}
                      contactsIndex={contactsIndex}
                      selectAll={selectAll}
                      selectAllView={selectAllView}
                      selectedCount={selectedCount}
                      selectedFarmerCount={selectedFarmerCount}
                    />
                  </Suspense>
                </InfiniteScroll>
              )}
              <AddFarmer
                language={language}
                saveFarmer={saveFarmer}
                saving={saving}
                selectedCount={selectedCount}
                smsBalance={smsBalance}
              />
            </>
          )}
        </>
      )}

      <Toast
        isError={true}
        message={language?.apiError}
        isOpen={isErrorPopup}
      />
    </Box>
  );
}
