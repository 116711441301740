import React from 'react';
import Styles from './style.module.css';
import Modal from './Modal';
import './Spinner.css';

interface UpdateMerchantNameinfo {
  nameModal: boolean;
  onChangeName: (event: React.ChangeEvent<HTMLInputElement>) => void;
  merchantName: string;
  onCompleteUserInfo: () => void;
  onCloseUpdateUserInoModal: () => void;
  userNameUploadLoading: boolean;
}

function LoadingSpinner() {
  return (
    <div className="spinner-container">
      <div className="loading-spinner"></div>
    </div>
  );
}

const AddToHome = (props: UpdateMerchantNameinfo) => {
  const {
    nameModal,
    onChangeName,
    merchantName,
    onCompleteUserInfo,
    onCloseUpdateUserInoModal,
    userNameUploadLoading,
  } = props;

  function modalView() {
    return (
      <div className={Styles.modalContainer}>
        <div className={Styles.borderWidth}></div>
        <div className={Styles.inputContainer}>
          <div className={Styles.titleContainer}>अपना नाम लिखे</div>
          <input
            autoFocus
            style={{
              paddingLeft: 12,
              fontSize: 14,
              fontWeight: 400,
              lineHeight: 20,
            }}
            value={merchantName}
            onChange={onChangeName}
            className={Styles.inputStyle}
            placeholder="आपका नाम"
          />

          <div className={Styles.buttonStyle} onClick={onCompleteUserInfo}>
            {userNameUploadLoading ? <LoadingSpinner /> : 'आगे बड़े'}
          </div>
        </div>
      </div>
    );
  }
  return (
    nameModal && (
      <Modal
        isOpen={nameModal}
        onClose={onCloseUpdateUserInoModal}
        children={modalView()}
        title={'अपने नाम से पोस्ट करने के लिए कृपया नाम बतायें'}
      />
    )
  );
};

export default AddToHome;
