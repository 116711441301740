/**
 * The function `GoodFoodLogo` renders a logo component with text content based on the provided
 * language object.
 * @param  - The code you provided is a React functional component named `GoodFoodLogo`. It takes a
 * prop `language` as input. The component renders a main container with a label displaying the
 * `goodFoodEconomy` text from the `language` prop. Inside the main container, there is a description
 * container
 * @returns The `GoodFoodLogo` component is being returned. It contains a main container with a label
 * for the "goodFoodEconomy" text in the specified language, a description container with a title label
 * for the "goodFoodLogoText" in the specified language, and the `FoodChainLogo` component.
 */
import React from 'react';
import styles from './style.module.css';

export default function GoodFoodLogo({ language, homeScreenAssets }) {
  return (
    <div className={styles?.mainContainer}>
      <label className={styles?.motoContainer}>
        {language?.goodFoodEconomy}
      </label>
      <div className={styles?.descriptionContainer}>
        <label className={styles?.descriptiontitle}>
          {language?.goodFoodLogoText}
        </label>
        <img
          src={homeScreenAssets.foodChainLogo}
          height={80}
          width={65}
          style={{ maxWidth: 65 }}
          loading="eager"
        />
      </div>
    </div>
  );
}
