/**
 * The WeatherCard component in TypeScript React displays weather information with dynamic styling
 * based on weather data and location.
 * @param  - The `WeatherCard` component takes the following parameters:
 */
import React from 'react';
import styles from './style.module.css';
import MuiTypography from '../../../ComponentV3/Typography';
import { Box } from '@mui/material';
import AddFilled from '@mui/icons-material/LocationOnOutlined';
import { RiveComponent } from '../../../utils/riveComponent';
import WeatherImageUrls from '../../../constant/weatherimageUrls';
import SkeletonLoading from '../../../ComponentsV4/SkeletonLoading';
import jsonParse from '../../../utils/jsonParser';
/**
 * The `WeatherCard` component in TypeScript React displays weather information with dynamic styling
 * based on weather data and location.
 * @param {Object} props - The props for the `WeatherCard` component.
 * @param {Object} props.weatherData - Weather data object containing temperature, rainfall probability, etc.
 * @param {Object} props.language - Language object for translations.
 * @param {string} props.stateName - Name of the state for weather information.
 * @param {string} props.districtName - Name of the district for weather information.
 * @param {boolean} props.weatherLoading - Flag indicating whether weather data is loading.
 * @param {Function} props.shareOnWhatsApp - Function to share weather information on WhatsApp.
 * @param {boolean} props.homeScreenLoading - Flag indicating whether home screen data is loading.
 * @returns {JSX.Element} - The `WeatherCard` component renders weather information with dynamic styling.
 */
export default function WeatherCard({
  weatherData,
  language,
  stateName,
  districtName,
  weatherLoading,
  shareOnWhatsApp,
  homeScreenLoading,
  homeScreenAssets,
}) {
  let conditionalStyleResp = conditionalStyle();

  function conditionalStyle() {
    if (jsonParse(localStorage.getItem('stateName'))) {
      if (weatherData?.rain_prob > 90) {
        return {
          linearColor: styles.highRainProb,
          backgroundColorColor: '#D6DBFF',
          riveLink: WeatherImageUrls.get('rain>90'),
          title: language.weatherRain90,
          tileBorderColor: '#E0E4FF',
          moTitle: 'Extreme Rainfall',
        };
      } else if (weatherData?.rain_prob < 90 && weatherData?.rain_prob >= 70) {
        return {
          linearColor: styles.midRainProb,
          backgroundColorColor: '#C9DDE9',
          riveLink: WeatherImageUrls.get('rain90-70'),
          title: language?.weatherRain70,
          tileBorderColor: '#D0E2EC',
          moTitle: 'High Rainfall',
        };
      } else if (weatherData?.rain_prob < 70 && weatherData?.rain_prob >= 30) {
        return {
          linearColor: styles.lowRainProb,
          backgroundColorColor: '#CDE7EF',
          riveLink: WeatherImageUrls.get('rain70-30'),
          title: language?.weatherRain30,
          tileBorderColor: '#E6F3F7',
          moTitle: 'Light Rainfall',
        };
      } else {
        if (weatherData?.wind_spd > 25) {
          return {
            linearColor: styles.highWindProb,
            backgroundColorColor: '#D8D8F3',
            riveLink: WeatherImageUrls.get('wind>25'),
            title: language?.weatherWind25,
            tileBorderColor: '#E6E6F7',
            moTitle: 'Extreme winds',
          };
        } else if (
          weatherData?.wind_spd <= 25 &&
          weatherData?.wind_spd > 12.5
        ) {
          return {
            linearColor: styles.lowWindProb,
            backgroundColorColor: '#D4DFE8',
            riveLink: WeatherImageUrls.get('wind25-12'),
            title: language?.weatherWind12,
            tileBorderColor: '#F0F4F7',
            moTitle: 'High Winds',
          };
        } else {
          if (weatherData?.temp_max >= 35) {
            return {
              linearColor: styles.highTemprature,
              backgroundColorColor: '#FFE6CC',
              riveLink: WeatherImageUrls.get('highTemprature'),
              title: language?.weatherMaxTemp,
              tileBorderColor: '#FFF0E0',
              moTitle: 'Extreme temperature',
            };
          } else if (weatherData?.temp_min <= 15) {
            return {
              linearColor: styles.lowTemprature,
              backgroundColorColor: '#D1F0FF',
              riveLink: WeatherImageUrls.get('lowTemprature'),
              title: language?.weatherMinTemp,
              tileBorderColor: '#E6F7FF',
              moTitle: 'Low temperature',
            };
          } else {
            return {
              linearColor: styles.normalWeather,
              backgroundColorColor: '#C2D6FF',
              riveLink: WeatherImageUrls.get('normalWeather'),
              title: language?.weatherNormal,
              tileBorderColor: '#DDE8FF',
              moTitle: 'Good Weather',
            };
          }
        }
      }
    } else {
      return {
        linearColor: styles.noLocation,
        backgroundColorColor: '#F4F5F7',
        riveLink: WeatherImageUrls.get('noLocation'),
        title: language?.weatherNoLocation,
        tileBorderColor: '#DFE1E5',
        moTitle: '',
      };
    }
  }

  const Dataa = conditionalStyle();

  return (
    <Box
      border={`1px solid ${Dataa.tileBorderColor}`}
      className={styles?.mainContainer}
      onClick={() => {
        shareOnWhatsApp(
          Dataa?.title,
          `Highest Temperature:${weatherData?.temp_max}° Lowest Temperature:${weatherData?.temp_min}°`,
          Dataa?.moTitle,
        );
      }}
    >
      {homeScreenLoading || weatherLoading ? (
        <SkeletonLoading className={styles?.loading} />
      ) : (
        <>
          <Box className={Dataa.linearColor}>
            <Box className={styles?.contentContainer}>
              <MuiTypography level="title-sm" textColor={'#171A1C'}>
                {Dataa?.title}
              </MuiTypography>

              <MuiTypography
                level="label-sm"
                textColor={'#555E68'}
                className={styles.subHeadingLabel}
              >
                {jsonParse(localStorage.getItem('stateName'))
                  ? `H:${weatherData?.temp_max}° L:${weatherData?.temp_min}°`
                  : '--° --°'}
              </MuiTypography>
            </Box>
            <Box className={styles?.profileImageContainer}>
              <RiveComponent
                src={conditionalStyleResp?.riveLink}
                autoplay={true}
              />
            </Box>
          </Box>
          <Box
            className={styles?.tempratureContainer}
            style={{ background: `${Dataa.backgroundColorColor}` }}
          >
            <Box
              display={'flex'}
              flexDirection={'row'}
              justifyContent={'space-between'}
              alignItems={'center'}
              padding={'0px 8px'}
              alignSelf={'stretch'}
              flex={1}
            >
              <Box
                display={'flex'}
                gap={2}
                justifyContent={'center'}
                alignItems={'center'}
                height={18}
                padding={'0px 6px'}
                bgcolor={'background.body'}
                borderRadius={'4px'}
                id={'weatherCardLocationButtonId'}
              >
                <AddFilled
                  fontSize="small"
                  style={{
                    height: 14,
                    color: '#171A1C',
                    width: 14,
                  }}
                />
                <MuiTypography
                  level="label-md"
                  textColor={'#171A1C'}
                  id={'weatherCardLocationButtonTextId'}
                >
                  {`${jsonParse(
                    localStorage.getItem('districtName'),
                  )}, ${jsonParse(localStorage.getItem('stateName'))}`}
                </MuiTypography>
              </Box>
              <Box
                style={{
                  display: 'flex',
                  gap: '4px',
                  justifyContent: 'center',
                  alignItems: 'center',
                  padding: '0px 6px',
                }}
              >
                <img
                  className={styles.whatsAppIcon}
                  src={homeScreenAssets.whatsappIcon as unknown as string}
                  alt="Whatsapp Icon"
                  id={'weatherCardShareOnWhatsappImageId'}
                  height={16}
                  width={16}
                  loading="eager"
                />
                <MuiTypography
                  level="body-xs"
                  textColor={'#5555C9'}
                  id={'weatherCardShareOnWhatsappTextId'}
                  sx={{
                    cursor: 'pointer',
                    userSelect: 'none',
                    whiteSpace: 'nowrap',
                  }}
                >
                  {language?.share}
                </MuiTypography>
              </Box>
            </Box>
          </Box>
        </>
      )}
    </Box>
  );
}
