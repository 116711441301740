import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

import { PostList, PageList } from '../communityModel';

interface PostListState extends PostList {
  currentPageNumber: number;
}

interface PageListState extends PageList {
  currentPageNumber: number;
  selectedPageId: number;
}

export interface CommunitSlice {
  posts: PostListState;
  pages: PageListState;
}

const initialState: CommunitSlice = {
  posts: {
    count: 0,
    rows: [],
    currentPageNumber: 1,
  },
  pages: {
    count: 0,
    pages: [],
    currentPageNumber: 1,
    selectedPageId: null,
  },
};

export const communitSlice = createSlice({
  name: 'community',
  initialState,
  reducers: {
    setPosts: function (state, action: PayloadAction<PostList>) {
      state.posts.count = action.payload.count;
      state.posts.rows = [...state.posts.rows, ...action.payload.rows];
    },
    setCurrentPostPageNumber: function (state, action: PayloadAction<number>) {
      state.posts.currentPageNumber = action.payload;
    },
    increamentLikeCountPost: function (state, action: PayloadAction<number>) {
      const result = state.posts.rows.find(function (item) {
        return item.id === action.payload;
      });

      if (result) {
        result.news_feed_stats.claps = result.news_feed_stats.claps + 1;
      }
    },
    increamentCommentCountPost: function (
      state,
      action: PayloadAction<number>,
    ) {
      const result = state.posts.rows.find(function (item) {
        return item.id === action.payload;
      });

      if (result) {
        result.news_feed_stats.comment_count =
          result.news_feed_stats.comment_count + 1;
      }
    },
    increamentShareCountPost: function (state, action: PayloadAction<number>) {
      const result = state.posts.rows.find(function (item) {
        return item.id === action.payload;
      });

      if (result) {
        result.news_feed_stats.share_count =
          result.news_feed_stats.share_count + 1;
      }
    },
    setPages: function (state, action: PayloadAction<PageList>) {
      state.pages.count = action.payload.count;
      state.pages.pages = [...state.pages.pages, ...action.payload.pages];
    },
    setCurrentPageNumber: function (state, action: PayloadAction<number>) {
      state.pages.currentPageNumber = action.payload;
    },
    setSelectedPageId: function (state, action: PayloadAction<number>) {
      state.pages.selectedPageId = action.payload;
    },
    resetPostList: function (state, action: PayloadAction<PostList>) {
      state.posts.count = action.payload.count;
      state.posts.rows = action.payload.rows;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setPosts,
  increamentLikeCountPost,
  setPages,
  setCurrentPageNumber,
  setSelectedPageId,
  setCurrentPostPageNumber,
  resetPostList,
  increamentCommentCountPost,
  increamentShareCountPost,
} = communitSlice.actions;

export default communitSlice.reducer;
