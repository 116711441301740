import { v4 as uuid } from 'uuid';
import bridgeFunctions from './utils/bridge';

const createBridge = (() => {
  let instance;
  const callbacks = {};

  const createInstance = () => {
    const bridge = {};

    bridge._createRpcRequestBody = (method_name, params) => {
      const id = uuid();
      const callback_id = uuid();
      const request = {
        id,
        method_name,
        params,
        callback_id,
      };
      return request;
    };

    bridge._createRpcResponseBody = (id, params, callback_id) => {
      const response = {
        id,
        params,
        callback_id,
      };
      return response;
    };

    bridge._sendRpcMessage = (message) => {
      window?.ReactNativeWebView?.postMessage(JSON.stringify(message));
    };

    bridge.sendRequestToNative = (method_name, params, callback) => {
      const request_data = bridge._createRpcRequestBody(method_name, params);
      const { callback_id } = request_data;
      callbacks[callback_id] = callback;
      bridge._sendRpcMessage(request_data);
    };

    bridge._sendResponseToWeb = (id, response, callback_id) => {
      const response_data = bridge._createRpcResponseBody(
        id,
        response,
        callback_id,
      );
      bridge._sendRpcMessage(response_data);
    };

    bridge.listenMessagesFromNative = async (request) => {
      const parseRequest = JSON.parse(request);
      const { id, method_name, params, callback_id } = parseRequest;

      if (callbacks.hasOwnProperty(callback_id)) {
        callbacks[callback_id](params);
        delete callbacks[callback_id];
      } else {
        if (method_name) {
          const response = await bridgeFunctions[method_name](params);
          bridge._sendResponseToWeb(id, response, callback_id);
        }
      }
    };

    return bridge;
  };

  return () => {
    if (!instance) {
      instance = createInstance();
    }
    return instance;
  };
})();

export { createBridge };
