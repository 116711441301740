import { useRef } from 'react';

export enum MediaSourceEnums {
  CAMERA,
  FILE,
}

export default function useCaptureAndCompressImage() {
  const inputRef = useRef<HTMLInputElement | null>(null);

  const captureAndCompressImage = async (
    mediaSource: MediaSourceEnums,
    maxWidth: number = 1920, // Optional width parameter with default value
    maxHeight: number = 1920, // Optional height parameter with default value
  ): Promise<string> => {
    return new Promise((resolve, reject) => {
      if (!inputRef.current) {
        // Create the input element if it doesn't exist
        inputRef.current = document.createElement('input');
        inputRef.current.type = 'file';
        inputRef.current.accept = 'image/*';
        inputRef.current.style.display = 'none'; // Hide the input
        document.body.appendChild(inputRef.current);

        inputRef.current.onchange = async () => {
          const file = inputRef.current?.files
            ? inputRef.current.files[0]
            : null;
          if (!file) {
            reject('No file selected.');
            return;
          }

          try {
            const reader = new FileReader();
            reader.onload = (event) => {
              const img = new Image();
              img.onload = () => {
                const canvas = document.createElement('canvas');
                const ctx = canvas.getContext('2d');
                if (!ctx) {
                  console.error('Failed to get canvas context.');
                  reject('Failed to get canvas context.');
                  return;
                }

                // Calculate the new dimensions while maintaining the aspect ratio
                let width = img.width;
                let height = img.height;

                if (width > height) {
                  if (width > maxWidth) {
                    height *= maxWidth / width;
                    width = maxWidth;
                  }
                } else {
                  if (height > maxHeight) {
                    width *= maxHeight / height;
                    height = maxHeight;
                  }
                }

                canvas.width = width;
                canvas.height = height;

                // Draw the image on the canvas
                ctx.drawImage(img, 0, 0, width, height);

                // Convert the canvas to a base64-encoded image URL
                const base64Url = canvas.toDataURL('image/jpeg', 0.8); // 0.8 is the compression quality
                resolve(base64Url);
              };
              img.src = event.target?.result as string;
            };
            reader.readAsDataURL(file);
          } catch (error) {
            console.error('Error processing the image:', error);
            reject('An error occurred while processing the image.');
          } finally {
            if (inputRef.current) {
              inputRef.current.value = ''; // Reset the input value
            }
          }
        };
      }

      if (mediaSource === MediaSourceEnums.CAMERA) {
        inputRef.current.capture = 'environment'; // Use the camera for input
      } else {
        inputRef.current.removeAttribute('capture');
      }

      inputRef.current.click(); // Open file selector or camera
    });
  };

  return { captureAndCompressImage };
}
