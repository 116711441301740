import handleHttpError from '../../utils/api/httpErrorMapping';
import HttpStatusCode from '../types/httpStatusCodesEnums.type';

interface IpResp {
  response: Response;
  data: any;
}

export default async function updateUserLocation(payload): Promise<IpResp> {
  const response = await fetch(
    `https://www.googleapis.com/geolocation/v1/geolocate?key=AIzaSyBOLUftTKClT-uUI6Ke0jvG-8xbZ2JlMc0`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({}),
    },
  );

  if (response.status !== HttpStatusCode.Ok) {
    throw handleHttpError(response.status);
  }

  const data = await response.json();
  return { data, response };
}
