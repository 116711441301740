import getLang from '../../languages/Acknowledgement.js'; // Importing language translation function
import { useLocation, useNavigate } from 'react-router-dom';
import { appLanguages } from '../../constant/appLanguages';
import { AcknowledgementProps } from './types.js';
import { trackEvent } from '../../utils/moengage/index'; // Importing tracking functions
import Events from '../../constant/MoengageEvents'; // Importing constant events

export default function AddFarmerHook(): AcknowledgementProps {
  const { state } = useLocation();
  const langId = JSON.parse(localStorage.getItem('userLanguage'));
  const activeSystemLang = Intl.DateTimeFormat().resolvedOptions().locale;
  const systemLanguage = activeSystemLang?.includes('en')
    ? appLanguages[1].langCode
    : appLanguages[0].langCode;

  const language = getLang(langId?.langCode || systemLanguage);

  const navigate = useNavigate();

  // Function to handle back button press
  /**
   * The onPressBack function navigates to the "/myFarmers" route with state information indicating it
   * was triggered from the "addFarmer" action.
   */
  function onPressBack() {
    trackEvent(Events.onViewMyFarmersButtonClick, {});
    if (state?.fromScreen) {
      navigate(`/${state?.fromScreen}`);
    } else {
      navigate('/myFarmers', {
        state: {
          from: 'addFarmer',
        },
      });
    }
  }

  /**
   * The function `onContactUs` navigates to the "/faq/questions" page.
   */
  /**
   * The `onContactUs` function navigates to the "/faq/questions" page.
   */
  function onContactUs() {
    navigate('/faq/questions');
  }

  // Returning necessary functions and states
  return {
    onPressBack,
    language,
    onContactUs,
  };
}
