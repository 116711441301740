import React, { useState, useRef, useEffect } from 'react';
import Styles from './style.module.css';
import IvrNumber from '../../constant/enums/farmartIvrNumber';
import Toast from '../../ComponentV2/Toast';
import { usePlatfromData } from '../../hooks/CrossPlatformActionHandler/platformChecker';
import { TextField } from '@mui/material';
import { createBridge } from '../../rpcBridge.js';
import bridgeFunctions from '../../utils/bridge';

interface MobileNumberInputProps {
  onChange?: (mobileNumber: string) => void;
  error?: boolean;
  headingTitle?: string;
  placeholderText?: string;
  errorMessage?: string;
  userBlocked?: boolean;
  blockUserMessage?: boolean;
  language?: any;
  id?: string;
  defaultMobileNumber?: string;
}

const MobileNumberInput: React.FC<MobileNumberInputProps> = ({
  onChange,
  error,
  headingTitle,
  placeholderText,
  errorMessage,
  userBlocked,
  blockUserMessage,
  language,
  id,
  defaultMobileNumber,
}) => {
  const bridge = createBridge();
  useEffect(() => {
    (window as any).listenMessagesFromApp = function (request: any) {
      bridge.listenMessagesFromNative(request);
    };
  }, []);
  const webViewRef = useRef(null);
  const [mobileNumber, setMobileNumber] = useState<string>(
    defaultMobileNumber || '',
  );
  const [toastVisible, setTaostVisible] = useState<boolean>(false);
  const platformData = usePlatfromData();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    // Remove any non-digit characters (like spaces, dashes, etc.)
    let cleanedNumber = event.target.value.replace(/\D/g, ''); // Check if the number starts with the country code +91
    if (cleanedNumber.startsWith('91') && cleanedNumber.length > 10) {
      // Remove the country code
      cleanedNumber = cleanedNumber.slice(2);
    } // Get the last 10 digits
    let newNumber = cleanedNumber.slice(0, 10);

    setMobileNumber(newNumber);
    onChange(newNumber);
    // Pass the updated value to the parent component
  };

  const ivrCall = () => {
    if (window.innerWidth > 1100) {
      setTaostVisible(true);
      navigator.clipboard.writeText(IvrNumber.toString());
      setTimeout(() => {
        setTaostVisible(false);
      }, 5000);
    }

    if (platformData.platform !== 'WEB') {
      bridge.sendRequestToNative(
        'DialNumber',
        {
          number: IvrNumber,
        },
        (response) => {
          bridgeFunctions.PrintLog(response);
        },
      );
    } else window.location.href = `tel:${IvrNumber}`;
  };

  useEffect(() => {
    webViewRef.current.focus();
  }, []);

  return (
    <div className={Styles.mobileNumberInput}>
      <label className={Styles.headingText} htmlFor="mobile-number">
        {headingTitle}
      </label>
      <div className={Styles.inputMainContainer}>
        <div className={Styles.countryCode}>+91</div>
        <input
          ref={webViewRef}
          type="tel"
          id={id}
          value={mobileNumber}
          onChange={handleChange}
          placeholder={placeholderText}
          required
          className={Styles.inputBoxContainer}
        />
      </div>

      {userBlocked && (
        <label className={Styles.wrongMobileNumberText}>
          {blockUserMessage}
          <label className={Styles.blockUserNumberText} onClick={ivrCall}>
            {IvrNumber}
          </label>
        </label>
      )}
      {!userBlocked && error && (
        <label className={Styles.wrongMobileNumberText}>{errorMessage}</label>
      )}
      <Toast
        isError={false}
        message={language?.mobileNumberCopied}
        isOpen={toastVisible}
      />
    </div>
  );
};

export default MobileNumberInput;
