import React from 'react';
import Styles from './style.module.css';
import { TradePriceBreakupData } from '../../../modal/Traders/types';
import FactoryRoundedIcon from '@mui/icons-material/FactoryRounded';
interface TradeDetailProps {
  PriceBreakup: TradePriceBreakupData;
  language?: { [key: string]: any };
}
const BuyerInfo = (props: TradeDetailProps) => {
  const { PriceBreakup, language } = props;

  const HouseIcon = () => (
    <FactoryRoundedIcon htmlColor="#0E1E40" sx={{ height: 20, width: 20 }} />
  );

  return (
    <div className={Styles.wrapper} data-testid={'buyerInfoBuyerDetailsId'}>
      <div className={Styles.titleBar}>
        <div className={Styles.leftBar}>
          <div className={Styles.houseIconWrapper}>
            <HouseIcon />
          </div>
          <div className={Styles.headingText}>{language?.buyerDetails}</div>
        </div>
      </div>
      <div className={Styles.infoWrapper}>
        <div className={Styles.buyerNameText}>{PriceBreakup?.buyer_name}</div>
        <div className={Styles.addressWrapper}>
          {PriceBreakup?.buyer_address}
          <div>
            {PriceBreakup?.district_name}, {PriceBreakup?.state_name},{' '}
            {PriceBreakup?.buyer_pincode}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BuyerInfo;
