import React, { ReactNode } from 'react';
import Typography, { TypographyProps } from '@mui/joy/Typography';

interface TypographyWrapperProps extends TypographyProps {
  children?: ReactNode;
  customStyles?: string;
  // You can define additional props here if needed
}

const MuiTypography: React.FC<TypographyWrapperProps> = ({
  children,
  customStyles,
  ...props
}) => {
  return (
    <Typography className={customStyles} {...props}>
      {children}
    </Typography>
  );
};

export default MuiTypography;
