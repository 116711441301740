import { Box } from '@mui/joy';
import React from 'react';
import MuiTypography from '../../../ComponentV3/Typography';

import MuiButton from '../../../ComponentsV3/MuiButton';

interface AckProps {
  onPressBack: () => void;
  language: { [key: string]: string };
}
export default function AcknowledgementInfo(props: AckProps) {
  const { language, onPressBack } = props;
  return (
    <Box
      display={'flex'}
      flexDirection={'column'}
      alignSelf={'stretch'}
      justifyContent={'center'}
      alignItems={'center'}
      margin={'0px 16px'}
    >
      <Box display={'flex'} height={120} width={120} marginBottom={'20px'}>
        <iframe
          style={{ border: 'none', display: 'flex', alignSelf: 'center' }}
          width={120}
          height={120}
          src="https://rive.app/s/C0eOsZfXEUeZ6fdQIhJlWQ/embed"
          allow="autoplay"
        ></iframe>
      </Box>
      <Box>
        <MuiTypography level="title-lg" textAlign={'center'}>
          {language?.congratsHeadingText}
        </MuiTypography>
        <MuiTypography textAlign={'center'} level="body-xxs" marginTop={'4px'}>
          {language?.congratsSubHeadingText}
        </MuiTypography>
      </Box>
      <Box marginTop={'32px'} display={'flex'} alignSelf={'stretch'}>
        <MuiButton onClick={onPressBack} size="lg">
          {language?.proceedButtonText}
        </MuiButton>
      </Box>
    </Box>
  );
}
