import React from 'react';
import Style from './style.module.css';

interface LoaderProps {
  color?: string;
}

const Loader: React.FC<LoaderProps> = ({ color }) => {
  const loaderStyle = {
    borderTop: `2px solid ${color}`,
    borderRight: `2px solid ${color}`,
  };

  return <div className={Style.loader} style={loaderStyle}></div>;
};

export default Loader;
