import React from 'react';
import Styles from './style.module.css';
import Modal from '../../../ComponentV2/Modal';
import { Location } from '../../../modal/Traders/types';
import InfiniteScroll from '../../../Components/InfintyScroll';
import Loading from '../../../ComponentV2/Loading';

interface PayloadType {
  isOpen: boolean;
  handleBuyerLocationListModal: () => void;
  locationList: Location[];
  activeLocations: string[];
  handleLocationSelection: (locationId: string) => void;
  locationLoading: boolean;
  onClickLocationFilterApply: () => void;
  buyerLocationListSearchParam: string;
  handleLocationSearch: (event: React.ChangeEvent<HTMLInputElement>) => void;
  loadMoreLocationList: () => void;
  language: { [key: string]: any };
  searchIcon: string;
  activeSvg: string;
  inactiveSvg: string;
}

const SelectLocationModal = ({
  isOpen,
  handleBuyerLocationListModal,
  locationList,
  handleLocationSelection,
  activeLocations,
  locationLoading,
  onClickLocationFilterApply,
  buyerLocationListSearchParam,
  handleLocationSearch,
  loadMoreLocationList,
  language,
  searchIcon,
  activeSvg,
  inactiveSvg,
}: PayloadType) => {
  const locationListing = locationList?.length > 0 && (
    <div className={Styles.optionWrapper}>
      {locationList.map((item, index) => {
        return (
          <div
            key={index + 'locationList'}
            className={
              activeLocations.includes(item?.key_concat)
                ? Styles.ActiveItemContainer
                : Styles.InactiveItemContainer
            }
            onClick={() => handleLocationSelection(item.key_concat)}
          >
            <div className={Styles?.titleStyle}>{item?.value_concat}</div>
            {activeLocations.includes(item?.key_concat) ? (
              <img
                src={activeSvg}
                height={24}
                width={24}
                style={{ maxWidth: 24 }}
                loading="eager"
              />
            ) : (
              <img
                src={inactiveSvg}
                height={24}
                width={24}
                style={{ maxWidth: 24 }}
                loading="eager"
              />
            )}
          </div>
        );
      })}
    </div>
  );

  const UiModal = (
    <div className={Styles.wrapper}>
      <div className={Styles.searchWrapper}>
        <div className={Styles.searchField}>
          <div className={Styles.iconWrapper}>
            <img src={searchIcon} height={24} width={25} loading="eager" />
          </div>
          <input
            type="text"
            placeholder={language?.selectLocationSearchPlaceholder}
            onChange={handleLocationSearch}
            value={buyerLocationListSearchParam}
            className={Styles.searchInput}
          />
        </div>
      </div>
      <div className={Styles.infinityScroll}>
        <InfiniteScroll
          children={locationListing}
          vertical={true}
          onScrollToEnd={loadMoreLocationList}
          loading={locationLoading}
          loadingElement={
            <div className={Styles.loadingContainer}>
              <Loading size={20} color="grey" loaderWidth={3} />
            </div>
          }
        />
      </div>
      <div className={Styles.buttonWrapperContainer}>
        <div
          onClick={onClickLocationFilterApply}
          className={Styles.button_container}
        >
          {language?.apply}
        </div>
      </div>
    </div>
  );

  return (
    <Modal
      onClose={handleBuyerLocationListModal}
      isCloseButtonOption={true}
      title={language?.selectLocationHeading}
      isOpen={isOpen}
      children={UiModal}
    />
  );
};

export default SelectLocationModal;
