import React, { Dispatch, SetStateAction, useEffect } from 'react';
import Styles from './style.module.css';

interface ErrorMessageProps {
  message: string;
  isError: boolean;
  setIsError: Dispatch<SetStateAction<boolean>>;
  ErrorToastType: boolean;
  time?: number;
}

const ErrorMessageToast = ({
  isError,
  message,
  setIsError,
  ErrorToastType, //true stands for error tmessage toast and false for success
  time = 5000,
}: ErrorMessageProps) => {
  useEffect(() => {
    let timeoutId: NodeJS.Timeout;

    if (isError) {
      // Set timeout for 10 seconds
      timeoutId = setTimeout(() => {
        // Perform your specific action here
        setIsError(false);
      }, time); // 10000 milliseconds = 10 seconds
    }

    return () => {
      // Clear the timeout when the component unmounts or isVisible becomes false
      clearTimeout(timeoutId);
    };
  }, [isError]);
  return (
    <div
      className={`${ErrorToastType ? Styles.error : Styles.success} ${
        isError ? Styles.active : Styles.inactive
      }`}
    >
      {message}
    </div>
  );
};

export default ErrorMessageToast;
