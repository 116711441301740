import { useState, useEffect } from 'react';
import { UserRegistrationHooks, LanguageParams } from './types.js';
import getLang from '../../languages/userRegistration.js';
import { useLocation, useNavigate } from 'react-router-dom';
import UpdatePersonaApi from '../../modal/Traders/models/updatePersona.js';
import { trackEvent } from '../../utils/moengage/index';
import Events from '../../constant/MoengageEvents';
import personaEnums from '../../constant/enums/personaName';
import GetMerchantProfile from '../../modal/Traders/models/GetMerchantProfile.js';
import { createBridge } from '../../rpcBridge.js';

import bridgeFunctions from '../../utils/bridge';
import { appLanguages } from '../../constant/appLanguages';
import PostRequest from '../../utils/apiCaller/postRequest';
import PutRequest from '../../utils/apiCaller/putRequest';

import { apiEndPoints } from '../../constant/apiEndPoints';
import { usePlatfromData } from '../CrossPlatformActionHandler/platformChecker'; // Importing hook to get platform data
import PlatForm from '../../constant/platFormEnums';
import { getLoginScreenAssets } from '../../constant/imageUrls/index';

// This function provides hooks for user registration
export default function MobileSubmitHook(): UserRegistrationHooks {
  const navigate = useNavigate(); // Navigation function
  const platformData = usePlatfromData(); // Getting platform data
  const bridge = createBridge();
  useEffect(() => {
    (window as any).listenMessagesFromApp = function (request: any) {
      bridge.listenMessagesFromNative(request);
    };
  }, []);

  let activeSystemLang = Intl.DateTimeFormat().resolvedOptions().locale;
  let systemLanguage = activeSystemLang?.includes('en')
    ? appLanguages[1].langCode
    : appLanguages[0].langCode;
  let systemLanguageId = activeSystemLang?.includes('en')
    ? appLanguages[1].backendLanguageCode
    : appLanguages[0].backendLanguageCode;
  // Record the time when the component starts loading
  const startLoadTime = new Date().getTime();

  // Retrieve the language ID from localStorage
  const langId = JSON.parse(localStorage.getItem('userLanguage'));

  // Get the current route parameters
  const params = useLocation()?.state;

  // Get language and current language details
  const language = getLang(
    langId?.langCode ? langId?.langCode : systemLanguage,
  );
  const currentLanguage = langId || {
    langCode: systemLanguage,
    langId: systemLanguageId,
  };

  const userRegistrationAssets = getLoginScreenAssets(langId?.langCode);

  // State variables
  const [isLanguageModalOpen, toggleLanguageModal] = useState<boolean>(false);
  const [isLanguageSelectedInProgress, setLanguageSelectedInProgress] =
    useState<boolean>(false);
  const [selectedLanguage, setSelectedLanguage] = useState(currentLanguage);
  const [selectedPersona, setSelectedPersona] = useState<string>(null);
  const [loading, setLoading] = useState<boolean>(false);

  // Function to handle language selection
  function handleLanguageSelect(languageItem: LanguageParams) {
    setSelectedLanguage(languageItem);
  }

  // Function to handle language change
  function handleLanguageChange() {
    if (localStorage.getItem('userLanguage') !== selectedLanguage) {
      setLanguageSelectedInProgress(!isLanguageSelectedInProgress);
      localStorage.setItem('userLanguage', JSON.stringify(selectedLanguage));
      toggleLanguageModal(!isLanguageModalOpen);
      setLanguageSelectedInProgress(false);
    } else {
      toggleLanguageModal(!isLanguageModalOpen);
    }
  }

  // Function to toggle the language modal
  function onLanguageModalToggle() {
    setSelectedLanguage(
      langId || {
        langCode: systemLanguage,
        langId: systemLanguageId,
      },
    );
    toggleLanguageModal(!isLanguageModalOpen);
  }

  // Function to handle click on persona card
  function onClickPersonaCard(key: string) {
    return () => {
      setSelectedPersona(key);
    };
  }

  // Function to handle click on proceed with selected persona
  async function onClickProceedPersona() {
    setLoading(true);
    try {
      // Update persona API call
      await PutRequest({
        payload: {
          occupation_id: selectedPersona,
        },
        uri: apiEndPoints.updateUserPersona,
      });

      // Get merchant profile API call
      const getMerchantProfile = await PostRequest({
        payload: {},
        uri: apiEndPoints?.fetchMerchantProfile,
      });
      // Track moEngage event
      trackEvent(Events?.onSGNProfileSubmittedButtonClick, {
        job_to_be_done: personaEnums[parseInt(selectedPersona)],
        selection_time: new Date().getTime() - startLoadTime,
      });
      if (platformData?.platform === PlatForm?.get('application')) {
        // Post message to React Native WebView
        bridge.sendRequestToNative(
          'SetToken',
          {
            token: params?.token,
            profile: getMerchantProfile?.data.data,
          },
          (response) => {
            bridgeFunctions.PrintLog(response);
          },
        );
        bridge.sendRequestToNative(
          'setAppLanguage',
          {
            langCode: selectedLanguage?.langCode,
          },
          (response) => {
            bridgeFunctions.PrintLog(response);
          },
        );
      } else {
        localStorage.setItem(
          'profileSelected',
          getMerchantProfile?.data?.data?.merchant_occupation_id,
        );
        localStorage.setItem('loged', 'true');
        navigate('/');
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  }

  // Return the hooks and state variables
  return {
    handleLanguageSelect,
    onLanguageModalToggle,
    handleLanguageChange,
    selectedLanguage,
    isLanguageModalOpen,
    isLanguageSelectedInProgress,
    selectedPersona,
    onClickPersonaCard,
    language,
    loading,
    onClickProceedPersona,
    userRegistrationAssets,
  };
}
