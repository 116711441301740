// DocumentContext.js
// @ts-nocheck

import React, { createContext, useContext, useState, useRef } from 'react';
import './style.css';
import { PreviewObject } from './../../hooks/DocumentUpload/types';

const DocumentContext = createContext();

export const useDocumentContext = () => useContext(DocumentContext);
// create interface for this file
interface Props {
  children?: JSX.Element;
  initialDocuments?: [PreviewObject];
  removeDocument?: () => void;
  onPressClosePreview?: () => void;
  deleteVisible?: boolean;
  downloadVisible?: boolean;
  onDownload?: (link: string) => void;
  label?: string;
  downloadModal?: boolean;
  onCloseDownloadModal?: () => void;
  reuploadVisible?: boolean;
  reuploadLabel?: string;
  reUploadInputRef?: any;
  handleReUploadChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleReUploadButtonClick?: (
    from?: string,
    index?: number,
    file?: any,
  ) => void;
  currentPreviewIndex?: number;
}

export const DocumentProvider = ({
  children,
  initialDocuments = [],
  removeDocument,
  onPressClosePreview,
  label,
  deleteVisible,
  downloadVisible,
  downloadModal,
  onCloseDownloadModal,
  onDownload,
  reuploadVisible,
  reuploadLabel,
  reUploadInputRef,
  handleReUploadChange,
  handleReUploadButtonClick,
  currentPreviewIndex,
}: Props) => {
  const [documents, setDocuments] = useState(initialDocuments);

  return (
    <div className="container">
      <div className="document-container">
        <DocumentContext.Provider
          value={{
            documents,
            removeDocument,
            onPressClosePreview,
            deleteVisible,
            downloadVisible,
            label,
            downloadModal,
            onCloseDownloadModal,
            onDownload,
            reuploadVisible,
            reuploadLabel,
            reUploadInputRef,
            handleReUploadChange,
            handleReUploadButtonClick,
            currentPreviewIndex,
          }}
        >
          {children}
        </DocumentContext.Provider>
      </div>
    </div>
  );
};
