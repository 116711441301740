/* 
  This React component renders a list of secondary features. 
  It includes a helper function, SecondaryFeatureCard, 
  responsible for rendering individual secondary feature cards.
*/

import React from 'react';
import Styles from './style.module.css';
import MuiTypography from '../../../ComponentV3/Typography';
import { Box } from '@mui/material';
import SecondaryFeatureTextEnums from '../../../constant/enums/secondaryFeatureEnums';
import SkeletonLoading from '../../../ComponentsV4/SkeletonLoading';

// Helper function to render individual secondary feature cards
function SecondaryFeatureCard({
  item, // Data of individual feature
  index, // using as Unique key for React rendering
  language, // Language object for translations
  onClickSecondaryFeatureCard, // Callback function for handling click events
}) {
  return (
    <Box
      className={Styles.secondaryCardStyle}
      onClick={() => {
        // On click, trigger the callback function with feature ID
        onClickSecondaryFeatureCard(
          SecondaryFeatureTextEnums.get(item?.id),
          item?.name,
          item?.screen_name,
        );
      }}
      id={'secondaryFeaturePwaId' + index}
    >
      {/* Render feature icon */}
      <img
        id={'SecondaryFeaureCardIconId' + index}
        className={Styles.verifiedIcon}
        src={item?.icon}
        alt="verified"
        loading="eager"
      />
      {/* Render feature label */}
      <MuiTypography
        className={Styles.cardDetailContainer}
        level="title-sm"
        textColor={'#171A1C'}
        id={'SecondaryFeaureCardLabelId' + index}
      >
        {/* Translate feature label using language object */}
        {language[SecondaryFeatureTextEnums.get(item?.id)]}
      </MuiTypography>
    </Box>
  );
}

// Main SecondaryFeature component
export default function SecondaryFeature({
  item, // Data containing features list
  language, // Language object for translations
  onClickSecondaryFeatureCard, // Callback function for handling click events
  homeScreenLoading, // Flag indicating whether home screen data is loading
}) {
  return item?.features?.length ? (
    <Box className={Styles.wrapper}>
      <Box className={Styles.gridStyle}>
        {/* Render skeleton loading if data is loading */}
        {homeScreenLoading ? (
          <>
            <SkeletonLoading className={Styles?.loading} />
            <SkeletonLoading className={Styles?.loading} />
            <SkeletonLoading className={Styles?.loading} />
            <SkeletonLoading className={Styles?.loading} />
          </>
        ) : (
          // Map through features and render SecondaryFeatureCard for each
          item?.features?.map(function (item, index) {
            return (
              <SecondaryFeatureCard
                key={'languageCard' + index}
                item={item}
                language={language}
                onClickSecondaryFeatureCard={onClickSecondaryFeatureCard}
                index={index}
              />
            );
          })
        )}
      </Box>
    </Box>
  ) : (
    <></>
  );
}
