// @ts-nocheck - may need to be at the start of file
import { TradePriceBreakup } from './types';
import GetTradePriceBreakup from '../../modal/Traders/models/TradePriceBreakup';
import { useEffect, useState } from 'react';
import { TradePriceBreakupData } from '../../modal/Traders/types';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { usePlatfromData } from '../../hooks/CrossPlatformActionHandler/platformChecker';
import { trackEvent } from '../../utils/moengage';
import Events from '../../constant/MoengageEvents';

import getLang from '../../languages/tradePriceBreakupLanguage.js';
import { useAuth } from '../../context/login';
import { decodeParams } from '../../utils/handleUrlParams';
import { getBuyerDetailAssets } from '../../constant/imageUrls';

export default function UseTradeDetailList(): TradePriceBreakup {
  const auth = useAuth();
  const userLogedIn = localStorage.getItem('loged');
  const state = useLocation()?.state;
  const platformData = usePlatfromData();
  const utm = getUtm();
  const startLoadTime = new Date().getTime();
  const [numberCopyToast, setNumberCopyToast] = useState(false);
  const [PriceBreakup, setPriceBreakup] = useState<TradePriceBreakupData>(null);
  const [activeTab, setActive] = useState('tab2');
  const [quantity, setQuantity] = useState(
    state?.quantity ? state?.quantity : '',
  );
  const [paymentTerms, setPaymentTerms] = useState(
    state?.paymentTerms ? state?.paymentTerms : '',
  );
  const [proceedDisabled, setProceedDisabled] = useState(true);
  const [showPaymentTermsModal, setShowPaymentTermsModal] = useState(false);
  const [paymentTermsType, setPaymentTermsType] = useState('');
  const { id } = useParams();
  const [showRequestSauda, setShowRequestSauda] = useState(
    state?.requestSauda ? state?.requestSauda : false,
  );
  const [saudaRequestToastPopup, setSaudaRequestToastPopup] =
    useState<boolean>(false);
  const [isFocusedSaudaRequestInput, setIsFocusedSaudaRequestInput] =
    useState(false);

  const navigate = useNavigate();

  const handleFocusSaudaRequestInput = () => {
    trackEvent(Events.onInputQuantityBoxClick, {});
    setIsFocusedSaudaRequestInput(true);
  };

  const handleBlurSaudaRequestInput = () => {
    setIsFocusedSaudaRequestInput(false);
  };

  const [brokeragePopup, setBrokeragePopup] = useState(false);
  const handleBrokeragePopup = (type: string) => {
    if (type) {
      if (paymentTermsType === 'buyer') {
        trackEvent(Events.onSaudaRequestScreenBrokerageInfoButtonClick, {
          Bottom_sheet_name: ' At Buyers Term',
        });
      } else {
        trackEvent(Events.onSaudaRequestScreenBrokerageInfoButtonClick, {
          Bottom_sheet_name: ' At GRN term',
        });
      }
    } else {
      if (!brokeragePopup) {
        trackEvent(Events.onBrokerageButtonClick, {});
      }
    }
    setBrokeragePopup(!brokeragePopup);
  };
  const langId = JSON.parse(localStorage.getItem('userLanguage'));
  const language = getLang(langId?.langCode || 'HI');
  const currentLanguageId = langId?.langCode || 'HI';

  const buyerDetailAssets = getBuyerDetailAssets(langId?.langCode);

  const [financingPopup, setFinancingPopup] = useState(false);
  const handleFinancingPopup = (type: string) => {
    if (type) {
      if (paymentTermsType === 'buyer') {
        trackEvent(Events.onSaudaRequestFincostinfoButtonClick, {
          Bottom_sheet_name: ' At Buyers Term',
        });
      } else {
        trackEvent(Events.onSaudaRequestFincostinfoButtonClick, {
          Bottom_sheet_name: ' At GRN term',
        });
      }
    } else {
      if (!financingPopup) {
        trackEvent(Events.onFincostButtonClick, {});
      }
    }

    setFinancingPopup(!financingPopup);
  };

  const [netPopup, setNetPopup] = useState(false);
  const handleNetPopup = (type: string) => {
    if (type) {
      if (paymentTermsType === 'buyer') {
        trackEvent(Events.onSaudaRequestNetpriceInfoButtonClick, {
          Bottom_sheet_name: ' At Buyers Term',
        });
      } else {
        trackEvent(Events.onSaudaRequestNetpriceInfoButtonClick, {
          Bottom_sheet_name: ' At GRN term',
        });
      }
    } else {
      if (!netPopup) {
        trackEvent(Events.onNetpriceButtonClick, {});
      }
    }
    setNetPopup(!netPopup);
  };

  const handleChangeTab = (id: string) => {
    setActive(id);
  };
  const handleSaudaRequestToastPopup = () => {
    setSaudaRequestToastPopup(true);
    setTimeout(() => {
      setSaudaRequestToastPopup(false);
    }, 5000);
  };

  function getUtm() {
    if (platformData.platform === 'WEB') {
      const urlSearchParams = new URLSearchParams(window?.location?.search);
      const paramsString = Object.fromEntries(urlSearchParams.entries());
      return paramsString?.utm_source;
    } else {
      const params = decodeParams(window?.location?.search);
      return params?.utm;
    }
  }

  /**
   * The function `handleNumberCopyToast` copies a phone number to the clipboard and displays a toast
   * message if the window width is greater than 1100 pixels, otherwise it initiates a phone call using
   * the phone number.
   */
  const handleNumberCopyToast = () => {
    trackEvent(Events.onContactToSellButtonClick, {});

    /* tslint:disable */

    if (window?.innerWidth > 1100) {
      setNumberCopyToast(true);
      navigator.clipboard.writeText(PriceBreakup?.customer_support_number);
      setTimeout(() => {
        setNumberCopyToast(false);
      }, 5000);
    } else {
      if (platformData.platform !== 'WEB') {
        (window as any)?.ReactNativeWebView?.postMessage(
          JSON.stringify({
            type: 'call',
            mobileNumber: PriceBreakup?.customer_support_number,
          }),
        );
      } else
        window.location.href = `tel:${PriceBreakup?.customer_support_number}`;
    }
  };

  /**
   * The function fetchTradePriceBreakup fetches trade price breakup data based on the state and district
   * IDs stored in localStorage, and then sets the fetched data to the priceBreakup state variable.
   */

  async function fetchTradePriceBreakup() {
    try {
      const stateId = localStorage.getItem('stateId');
      const districtId = localStorage.getItem('districtId');

      const serviceResponse = await GetTradePriceBreakup({
        supplier_district_id: JSON.parse(districtId),
        supplier_state_id: JSON.parse(stateId),
        trade_price_id: id,
      });
      let cropDetailEventAttribute = {
        Loading_time: (new Date().getTime() - startLoadTime) / 1000,
        Channel: utm,
      };

      trackEvent(Events.onStartCropDetailPage, cropDetailEventAttribute);

      if (serviceResponse) {
        setPriceBreakup(serviceResponse);
      }
    } catch (error) {
      if (error?.code === 401 || error?.code === 426) {
        if (platformData.platform === 'WEB') {
          if (unVerifiedRoutes?.guestLogin?.includes(error?.path)) {
            await loginAsGuest();
          } else {
            auth.setMobileNumberModalVisible(true);
          }
        }
      }
    }
  }

  useEffect(() => {
    const stateId = localStorage.getItem('stateId');
    const districtId = localStorage.getItem('districtId');
    if (stateId !== null && districtId !== null) {
      fetchTradePriceBreakup();
    } else {
      navigate('/trades');
    }
  }, [userLogedIn]);

  useEffect(function () {
    window.addEventListener('popstate', onClickBack);

    return () => {
      window.removeEventListener('popstate', onClickBack);
    };
  }, []);

  function onClickBack(): void {
    let backEventAttribute = {
      Previous_screen: 'TradeScreen',
    };
    trackEvent(Events.onBackButtonClick, backEventAttribute);
    if (platformData.platform === 'WEB') {
      auth.setMobileNumberModalVisible(false);
    }
    navigate('/trades');
  }

  const onClickRequestSauda = () => {
    trackEvent(Events.onRequestSaudaButtonClick, {
      Terms: activeTab === 'tab1' ? 'Buyer' : 'GRN',
    });
    if (PriceBreakup?.is_sauda_booked === 1) {
      handleSaudaRequestToastPopup();
    } else {
      if (platformData.platform === 'WEB' && !userLogedIn) {
        auth.setMobileNumberModalVisible(true);
        setShowRequestSauda(true);
      } else {
        setShowRequestSauda(true);
      }
    }
  };

  const toggleRequestSaudaModal = () => {
    showRequestSauda &&
      trackEvent(Events?.onCreateSaudaScreenCloseButtonClick, {
        From: 'Cross',
      });
    setShowRequestSauda(!showRequestSauda);
  };

  const handleQuantityChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event?.target?.value === '') {
      setQuantity(null);
    } else {
      setQuantity(Number(event?.target?.value));
    }
  };

  const onKeyDownSaudaRequestInput = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    ['e', 'E', '+', '-'].includes(event.key) && event.preventDefault();
  };

  const handlePaymentTerms = (event: any) => {
    const id = event?.currentTarget?.id;
    trackEvent(
      id === 'grns-term'
        ? Events?.onPaymentGRNTermsButtonClick
        : Events?.onPaymentBuyersTermsButtonClick,
      {
        Crop_name: PriceBreakup?.crop_name,
        Buyer_name: PriceBreakup?.buyer_name,
      },
    );
    setPaymentTerms(id);
  };

  useEffect(() => {
    if (paymentTerms && quantity > 0) {
      setProceedDisabled(false);
    } else {
      setProceedDisabled(true);
    }
  }, [paymentTerms, quantity]);

  const grnInfo = (event: any) => {
    event.stopPropagation();
    trackEvent(Events?.onPaymentGRNTermsInfoButtonClick, {});
    setShowPaymentTermsModal(true);
    setPaymentTermsType('grn');
  };

  const buyersInfo = (event: any) => {
    event.stopPropagation();
    trackEvent(Events?.onPaymentBuyersTermsInfoButtonClick, {});
    setShowPaymentTermsModal(true);
    setPaymentTermsType('buyer');
  };

  const onClosePaymentTermsModal = (event: any) => {
    event.stopPropagation();
    setShowPaymentTermsModal(false);
    if (paymentTermsType === 'buyer') {
      trackEvent(Events?.onPaymentTermsInfoBottomSheetCloseButtonClick, {
        From: 'Cross',
        Bottom_sheet_name: 'At Buyers Term',
      });
    } else {
      trackEvent(Events?.onPaymentTermsInfoBottomSheetCloseButtonClick, {
        From: 'Cross',
        Bottom_sheet_name: 'At GRN term',
      });
    }
  };

  const closePaymentTermsModal = (event: any) => {
    event.stopPropagation();
    setShowPaymentTermsModal(false);
    if (paymentTermsType === 'buyer') {
      trackEvent(Events?.onTermsSubmissionButtonClick, {
        Bottom_sheet_name: 'At Buyers Term',
      });
    } else {
      trackEvent(Events?.onTermsSubmissionButtonClick, {
        Bottom_sheet_name: 'At GRN term',
      });
    }
  };

  const proceedSaudaRequest = () => {
    // trackEvent(Events?.onCreateSaudaScreenCloseButtonClick, {
    //   From: "Proceed_button",
    // });
    trackEvent(Events?.onSaudaProceedSubmissionButtonClick, {
      Crop_name: PriceBreakup?.crop_name,
      Buyer_name: PriceBreakup?.buyer_name,
    });
    if (PriceBreakup?.is_sauda_booked === 1) {
      handleSaudaRequestToastPopup();
    } else {
      navigate(`/trades/mySauda/confirm_sauda`, {
        state: {
          quantity: quantity,
          quantity_unit: PriceBreakup.supplier_quantity_unit,
          priceBreakUp: PriceBreakup,
          paymentTerms: paymentTerms,
          paymentTermsType: paymentTermsType,
        },
      });
    }
  };

  useEffect(function () {
    platformData.platform === 'WEB' &&
      !userLogedIn &&
      state?.requestSauda &&
      auth.setMobileNumberModalVisible(true);
  }, []);

  return {
    PriceBreakup,
    handleChangeTab,
    activeTab,
    brokeragePopup,
    handleBrokeragePopup,
    financingPopup,
    handleFinancingPopup,
    netPopup,
    handleNetPopup,
    onClickBack,
    numberCopyToast,
    handleNumberCopyToast,
    language,
    currentLanguageId,
    showRequestSauda,
    onClickRequestSauda,
    toggleRequestSaudaModal,
    quantity,
    handleQuantityChange,
    paymentTerms,
    paymentTermsType,
    handlePaymentTerms,
    showPaymentTermsModal,
    proceedDisabled,
    grnInfo,
    buyersInfo,
    onClosePaymentTermsModal,
    closePaymentTermsModal,
    saudaRequestToastPopup,
    handleSaudaRequestToastPopup,
    proceedSaudaRequest,
    onKeyDownSaudaRequestInput,
    isFocusedSaudaRequestInput,
    handleFocusSaudaRequestInput,
    handleBlurSaudaRequestInput,
    buyerDetailAssets,
  };
}
