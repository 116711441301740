import React from 'react';
import Styles from './style.module.css';

import Header from './Header/Header';

import { SmsHisoryProps } from '../../hooks/SmsHistoryHookDetailHook/types';
import { Box } from '@mui/joy';
import SmsDetailCard from './SmsDetailCard/SmsDetailCard';
import MessageCard from './MessageCard/MessageCard';
import FarmersList from './FarmersList/FarmersList';
import ShopPromotionModal from '../../ComponentV2/ShopPromotionModal';
import MuiSkeleton from '../../MuiComponents/Skeleton';

export default function AddFarmerView(props: SmsHisoryProps) {
  const {
    onPressBack,
    language,
    onContactUs,
    onClickInfo,
    initialLoading,
    smsHistoryData,
    smsHistoryDetailData,
    onCloseModal,
    showShopPromotionModal,
  } = props;

  return (
    <Box className={Styles.container} bgcolor={'background.surface'}>
      <Header
        onContactUs={onContactUs}
        onPressBack={onPressBack}
        language={language}
        onClickInfo={onClickInfo}
      />

      {initialLoading ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          paddingTop={3}
          margin={'0px 16px'}
          flexDirection={'column'}
          gap={4}
        >
          <MuiSkeleton
            sx={{ width: '100%', height: 70, borderRadius: 8 }}
            itemNumber={7}
          />
        </Box>
      ) : (
        <>
          <SmsDetailCard
            smsHistoryData={smsHistoryData}
            smsHistoryDetailData={smsHistoryDetailData}
            language={language}
          />
          <ShopPromotionModal
            onClose={onCloseModal}
            openModal={showShopPromotionModal}
          />
          <MessageCard smsHistoryData={smsHistoryData} language={language} />
          <FarmersList smsHistoryData={smsHistoryData} language={language} />
        </>
      )}
    </Box>
  );
}
