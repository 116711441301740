/* export { default as getCommunityFeed } from "./models/getCommunityFeed"; */
//use above as example

/* export * from "./types"; */
import fetchTradeServiciability from '../../../apis/Trader/fetchTradeServiciability';

import { TradeServiciabilityResponse } from '../types';

interface PayloadType {
  supplier_state_id?: number;
  supplier_district_id?: number;
}

export default async function getTradeServiceability(
  payload: PayloadType,
): Promise<TradeServiciabilityResponse> {
  const body = {
    ...payload,
  };
  try {
    const result = await fetchTradeServiciability(body);
    if (!result.data.status) {
      throw result;
    }
    return result.data.data;
  } catch (error) {
    if (!error.message) {
      error.message = 'Something Went Wrong';
    }
    return error;
  }
}
