import React from 'react';
import Style from './style.module.css';
import DocumentTypeEnum from '../../../constant/enums/docType';
import NavigateBack from '../../../assets/svgComponet/navigateBack';

interface DocumentUploadHeaderProps {
  docType: number;
  onClickBack: () => void;
  language: { [key: string]: any };
}

const DocumentUploadHeader = ({
  docType,
  onClickBack,
  language,
}: DocumentUploadHeaderProps) => {
  return (
    <div className={Style.header}>
      <div onClick={onClickBack} id="DocumentUploadBackButton">
        <NavigateBack />
      </div>
      <div className={Style.headerText}>
        {language[DocumentTypeEnum.get(docType)]}
      </div>
    </div>
  );
};

export default DocumentUploadHeader;
