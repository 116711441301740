import { appLanguagesIndex, defaultLanguageCode } from '../appLanguages';

const homeScreenAssets = new Map([
  [
    appLanguagesIndex['hindi'],
    {
      farmartLogo2:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/1fcbcf09-5619-4dd0-a6db-58f469883ae3_FarMartLogo2.svg',
      distributorImage:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/69e52505-899e-49fe-ab74-d2be1407de17_distributors.png',
      bahiKhataImage:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/b9a48776-a447-44fd-850f-b471964c3939_bahi%20khata.png',
      cropAdvisoryImage:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/2ed4cd3d-1f93-49a8-9ddb-aa596f217f3f_CropAdvisory.svg',
      dukaanPrachaarImage:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/999487a9-54be-49e7-9386-d6262deed263_DukaanPrachar.svg',
      rakeImage:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/db717c74-37d9-4a4c-a9ae-051ba933ee3e_Rake.svg',
      quizImage:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/73f30f27-ad19-4abf-9d8b-4f4a5bbfb663_quiz.png',
      backgroundImage:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/fab2daba-6012-42f2-a00e-668064aa5e4f_Background.png',
      distributorCardImage:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/20ae20d0-418a-4e5d-948c-38abce2007e3_DistribuitorsCard.svg',
      bahiKhataCardImage:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/e325503f-3794-4d01-a17f-cc60370a0aa7_BahiKhataCardImage.svg',
      cropAdvisoryCardImage:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/9f1ec0ec-30c2-408e-9e98-fe9ca7945ac4_CropAdvisoryCard.svg',
      dukaanPrachaarCardImage:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/7216d660-2ed1-49c0-845f-2722762183a6_DukaanPracharCard.svg',
      rakeCardImage:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/7e440c2d-8616-4f96-94f7-b2df019a4011_RakeCard.svg',
      quizCardImage:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/ca3a5462-c42f-443a-b0db-7e6601547a1a_QuizCard.svg',
      foodChainLogo:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/2ba5a0f4-fd2e-4f0d-9fa2-36cf5d650e34_FoodChainLogo.svg',
      startDecorationLine:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/835344ed-ea04-40fe-9567-72d9d5748c4f_StartDecorationLine.svg',
      endDecorationLine:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/059aaca0-89e0-4e1a-8e49-a095038d4428_EndDecorationLine.svg',
      whatsappIcon:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/179ec8d4-fbb4-4288-a368-f0d7d5ad7d8f_Whatsapp.svg',
      addFilledIcon:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/c4ce4bd8-78c9-4f39-8428-8960aa4cb53f_AddFilledIcon.svg',
      locationIcon:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/e731f021-e9ba-4332-ba89-8b7217de73df_LocationSvg.svg',
      companyProfileIcon:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/e33f7117-a108-42ff-8d40-74681a8dea9c_companyProfile.svg',
      clapIcon:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/11/87c163ec-9a21-4e50-b7e0-51d9fb98171c_clap.png',
      clapDoneIcon:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/11/d3d5f692-0eeb-495a-b5d5-da85f4aaac4b_clapDone.png',
    },
  ],
  [
    appLanguagesIndex['english'],
    {
      farmartLogo2:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/1fcbcf09-5619-4dd0-a6db-58f469883ae3_FarMartLogo2.svg',
      distributorImage:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/69e52505-899e-49fe-ab74-d2be1407de17_distributors.png',
      bahiKhataImage:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/b9a48776-a447-44fd-850f-b471964c3939_bahi%20khata.png',
      cropAdvisoryImage:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/2ed4cd3d-1f93-49a8-9ddb-aa596f217f3f_CropAdvisory.svg',
      dukaanPrachaarImage:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/999487a9-54be-49e7-9386-d6262deed263_DukaanPrachar.svg',
      rakeImage:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/db717c74-37d9-4a4c-a9ae-051ba933ee3e_Rake.svg',
      quizImage:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/73f30f27-ad19-4abf-9d8b-4f4a5bbfb663_quiz.png',
      backgroundImage:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/fab2daba-6012-42f2-a00e-668064aa5e4f_Background.png',
      distributorCardImage:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/20ae20d0-418a-4e5d-948c-38abce2007e3_DistribuitorsCard.svg',
      bahiKhataCardImage:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/e325503f-3794-4d01-a17f-cc60370a0aa7_BahiKhataCardImage.svg',
      cropAdvisoryCardImage:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/9f1ec0ec-30c2-408e-9e98-fe9ca7945ac4_CropAdvisoryCard.svg',
      dukaanPrachaarCardImage:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/7216d660-2ed1-49c0-845f-2722762183a6_DukaanPracharCard.svg',
      rakeCardImage:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/7e440c2d-8616-4f96-94f7-b2df019a4011_RakeCard.svg',
      quizCardImage:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/ca3a5462-c42f-443a-b0db-7e6601547a1a_QuizCard.svg',
      foodChainLogo:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/2ba5a0f4-fd2e-4f0d-9fa2-36cf5d650e34_FoodChainLogo.svg',
      startDecorationLine:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/835344ed-ea04-40fe-9567-72d9d5748c4f_StartDecorationLine.svg',
      endDecorationLine:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/059aaca0-89e0-4e1a-8e49-a095038d4428_EndDecorationLine.svg',
      whatsappIcon:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/179ec8d4-fbb4-4288-a368-f0d7d5ad7d8f_Whatsapp.svg',
      addFilledIcon:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/c4ce4bd8-78c9-4f39-8428-8960aa4cb53f_AddFilledIcon.svg',
      locationIcon:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/e731f021-e9ba-4332-ba89-8b7217de73df_LocationSvg.svg',
      companyProfileIcon:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/e33f7117-a108-42ff-8d40-74681a8dea9c_companyProfile.svg',
      clapIcon:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/11/87c163ec-9a21-4e50-b7e0-51d9fb98171c_clap.png',
      clapDoneIcon:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/11/d3d5f692-0eeb-495a-b5d5-da85f4aaac4b_clapDone.png',
    },
  ],
]);

const loginScreenAssets = new Map([
  [
    appLanguagesIndex['hindi'],
    {
      whatsappIcon:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/179ec8d4-fbb4-4288-a368-f0d7d5ad7d8f_Whatsapp.svg',
      farmartLogo:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/11/440fd623-6f6a-45a8-b4ce-06093cb4a741_fmtLogo.png',
      languageSelection:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/9278acc5-3bbc-487a-8acb-2a427320a149_LanguageSelectIcon.svg',
      backIcon:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/53bbe6c3-4431-4e2b-8aa1-9245b51b0745_Back.svg',
    },
  ],
  [
    appLanguagesIndex['english'],
    {
      whatsappIcon:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/179ec8d4-fbb4-4288-a368-f0d7d5ad7d8f_Whatsapp.svg',
      farmartLogo:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/11/440fd623-6f6a-45a8-b4ce-06093cb4a741_fmtLogo.png',
      languageSelection:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/9278acc5-3bbc-487a-8acb-2a427320a149_LanguageSelectIcon.svg',
      backIcon:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/53bbe6c3-4431-4e2b-8aa1-9245b51b0745_Back.svg',
    },
  ],
]);

const tradeScreenAssets = new Map([
  [
    appLanguagesIndex['hindi'],
    {
      whatsappIcon:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/179ec8d4-fbb4-4288-a368-f0d7d5ad7d8f_Whatsapp.svg',
      farmartLogo:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/11/440fd623-6f6a-45a8-b4ce-06093cb4a741_fmtLogo.png',
      languageSelection:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/9278acc5-3bbc-487a-8acb-2a427320a149_LanguageSelectIcon.svg',
      backIcon:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/53bbe6c3-4431-4e2b-8aa1-9245b51b0745_Back.svg',
      activeSvg:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/bb3499cb-06c1-4b07-a100-9a2f92ceffe2_CheckboxActive.svg',
      inactiveSvg:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/f9ed0f34-8f2b-471a-9bf3-5450faec68fe_CheckboxInactive.svg',
      searchIcon:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/b9c58c60-444c-4bbb-b60b-ae920b35a337_searchIcon.svg',
      tradeComingSoon:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/5c399665-07be-4629-9a8c-bf0a8aefe79f_TradeComingSoonHI.svg',
      noBuyerList:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/3dd776d9-7f6c-4af6-a50c-1fddbf555f08_NoBuyerList.svg',
      noExpiredList:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/fc3e8dac-f52e-45f4-842d-5c705a8be53e_NoExpiredList.svg',
      locationIcon:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/e731f021-e9ba-4332-ba89-8b7217de73df_LocationSvg.svg',
      discountCardBgSmall:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/fd95a4cf-c2c1-4621-b03c-e2b263dc5340_DiscountCardBackgroundSmall.svg',
    },
  ],
  [
    appLanguagesIndex['english'],
    {
      whatsappIcon:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/179ec8d4-fbb4-4288-a368-f0d7d5ad7d8f_Whatsapp.svg',
      farmartLogo:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/11/440fd623-6f6a-45a8-b4ce-06093cb4a741_fmtLogo.png',
      languageSelection:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/9278acc5-3bbc-487a-8acb-2a427320a149_LanguageSelectIcon.svg',
      backIcon:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/53bbe6c3-4431-4e2b-8aa1-9245b51b0745_Back.svg',
      activeSvg:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/bb3499cb-06c1-4b07-a100-9a2f92ceffe2_CheckboxActive.svg',
      inactiveSvg:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/f9ed0f34-8f2b-471a-9bf3-5450faec68fe_CheckboxInactive.svg',
      searchIcon:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/b9c58c60-444c-4bbb-b60b-ae920b35a337_searchIcon.svg',
      tradeComingSoon:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/51eb38cc-6c01-4bb7-857d-662f6800dcb7_TradeComingSoonEN.svg',
      noBuyerList:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/3dd776d9-7f6c-4af6-a50c-1fddbf555f08_NoBuyerList.svg',
      noExpiredList:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/fc3e8dac-f52e-45f4-842d-5c705a8be53e_NoExpiredList.svg',
      locationIcon:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/e731f021-e9ba-4332-ba89-8b7217de73df_LocationSvg.svg',
      discountCardBgSmall:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/fd95a4cf-c2c1-4621-b03c-e2b263dc5340_DiscountCardBackgroundSmall.svg',
    },
  ],
]);

const buyerDetailAssets = new Map([
  [
    appLanguagesIndex['hindi'],
    {
      discountCardBgBig:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/ec83dcf5-1ff7-417a-9e61-c9db7bec343f_DiscountCardBackgroundBig.svg',
      discountFeeSmiley:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/b7f39a12-1676-44ae-b5a1-4f447ee67ab7_discountFeeSmiley.svg',
      magicIcon:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/8701c2ff-6b80-42a9-9763-6f0b5b8560e1_magicIcon.svg',
    },
  ],
  [
    appLanguagesIndex['english'],
    {
      discountCardBgBig:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/ec83dcf5-1ff7-417a-9e61-c9db7bec343f_DiscountCardBackgroundBig.svg',
      discountFeeSmiley:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/b7f39a12-1676-44ae-b5a1-4f447ee67ab7_discountFeeSmiley.svg',
      magicIcon:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/8701c2ff-6b80-42a9-9763-6f0b5b8560e1_magicIcon.svg',
    },
  ],
]);

const mySaudaAssets = new Map([
  [
    appLanguagesIndex['hindi'],
    {
      noSaudaFound:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/3dd776d9-7f6c-4af6-a50c-1fddbf555f08_NoBuyerList.svg',
    },
  ],
  [
    appLanguagesIndex['english'],
    {
      noSaudaFound:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/3dd776d9-7f6c-4af6-a50c-1fddbf555f08_NoBuyerList.svg',
    },
  ],
]);

const faqAssets = new Map([
  [
    appLanguagesIndex['hindi'],
    {
      whatsappIcon:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/179ec8d4-fbb4-4288-a368-f0d7d5ad7d8f_Whatsapp.svg',
    },
  ],
  [
    appLanguagesIndex['english'],
    {
      whatsappIcon:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/179ec8d4-fbb4-4288-a368-f0d7d5ad7d8f_Whatsapp.svg',
    },
  ],
]);

const rakeDetailAssets = new Map([
  [
    appLanguagesIndex['hindi'],
    {
      noRake:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/afec88ce-6ad6-4acb-b3a0-7a3c7073df8a_no%20rake.png',
    },
  ],
  [
    appLanguagesIndex['english'],
    {
      noRake:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/afec88ce-6ad6-4acb-b3a0-7a3c7073df8a_no%20rake.png',
    },
  ],
]);

const uploadDocAssets = new Map([
  [
    appLanguagesIndex['hindi'],
    {
      invoiceDoc:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/dca5e032-be4d-4464-946b-93deb55389d5_Invoice%20Doc%20Empty%20State.png',
      weightSlip:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/c02b9910-8585-4516-9bb1-9789b16e4737_Weight%20Slip%20Empty%20State.png',
    },
  ],
  [
    appLanguagesIndex['english'],
    {
      invoiceDoc:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/dca5e032-be4d-4464-946b-93deb55389d5_Invoice%20Doc%20Empty%20State.png',
      weightSlip:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/c02b9910-8585-4516-9bb1-9789b16e4737_Weight%20Slip%20Empty%20State.png',
    },
  ],
]);

const saudaDetailsAssets = new Map([
  [
    appLanguagesIndex['hindi'],
    {
      saudaDetailsImage:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/bf66ee29-9da6-44d7-800b-0172ff5deb7f_saudaDetailImage.png',
      whatsappIcon:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/179ec8d4-fbb4-4288-a368-f0d7d5ad7d8f_Whatsapp.svg',
      activeSvg:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/bb3499cb-06c1-4b07-a100-9a2f92ceffe2_CheckboxActive.svg',
      inactiveSvg:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/f9ed0f34-8f2b-471a-9bf3-5450faec68fe_CheckboxInactive.svg',
      onTimeIcon:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/a59624a2-558e-4818-8808-e518cfab115f_onTimeIcon2.svg',
      magicIcon:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/8701c2ff-6b80-42a9-9763-6f0b5b8560e1_magicIcon.svg',
    },
  ],
  [
    appLanguagesIndex['english'],
    {
      saudaDetailsImage:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/bf66ee29-9da6-44d7-800b-0172ff5deb7f_saudaDetailImage.png',
      whatsappIcon:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/179ec8d4-fbb4-4288-a368-f0d7d5ad7d8f_Whatsapp.svg',
      activeSvg:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/bb3499cb-06c1-4b07-a100-9a2f92ceffe2_CheckboxActive.svg',
      inactiveSvg:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/f9ed0f34-8f2b-471a-9bf3-5450faec68fe_CheckboxInactive.svg',
      onTimeIcon:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/a59624a2-558e-4818-8808-e518cfab115f_onTimeIcon2.svg',
      magicIcon:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/8701c2ff-6b80-42a9-9763-6f0b5b8560e1_magicIcon.svg',
    },
  ],
]);

const communityAssets = new Map([
  [
    appLanguagesIndex['hindi'],
    {
      avatarIcon:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/01c36823-20de-46bf-b9a9-59e28fc07bc4_avatar.svg',
      addToHomeImage:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/1dd5b8df-5f7a-42c2-8be8-a192faf00061_farmartAddHome.png',
      farmartLogo:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/12c699c5-2924-43ed-845b-775fb44f041d_farmart.svg',
      companyProfileIcon:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/e33f7117-a108-42ff-8d40-74681a8dea9c_companyProfile.svg',
      userProfileIcon:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/b8226359-73bf-4d21-85ab-b65a877d37a7_userProfile.svg',
      whatsappIcon:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/11/d5fad9b3-4f2b-49fe-bd06-a4dd75128cea_whatsapp.png',
      clapIcon:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/11/87c163ec-9a21-4e50-b7e0-51d9fb98171c_clap.png',
      clapDoneIcon:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/11/d3d5f692-0eeb-495a-b5d5-da85f4aaac4b_clapDone.png',
    },
  ],
  [
    appLanguagesIndex['english'],
    {
      avatarIcon:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/01c36823-20de-46bf-b9a9-59e28fc07bc4_avatar.svg',
      addToHomeImage:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/1dd5b8df-5f7a-42c2-8be8-a192faf00061_farmartAddHome.png',
      farmartLogo:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/12c699c5-2924-43ed-845b-775fb44f041d_farmart.svg',
      companyProfileIcon:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/e33f7117-a108-42ff-8d40-74681a8dea9c_companyProfile.svg',
      userProfileIcon:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/b8226359-73bf-4d21-85ab-b65a877d37a7_userProfile.svg',
      whatsappIcon:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/11/d5fad9b3-4f2b-49fe-bd06-a4dd75128cea_whatsapp.png',
      clapIcon:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/11/87c163ec-9a21-4e50-b7e0-51d9fb98171c_clap.png',
      clapDoneIcon:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/11/d3d5f692-0eeb-495a-b5d5-da85f4aaac4b_clapDone.png',
    },
  ],
]);
const myProfileAssets = new Map([
  [
    appLanguagesIndex['hindi'],
    {
      languageToggleIcon:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/e42e8a0d-ec10-4d59-9b72-934c99c9d360_languageSelection.svg',
    },
  ],
  [
    appLanguagesIndex['english'],
    {
      languageToggleIcon:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/7/e42e8a0d-ec10-4d59-9b72-934c99c9d360_languageSelection.svg',
    },
  ],
]);
const pincodeAssets = new Map([
  [
    appLanguagesIndex['hindi'],
    {
      noLocationImage:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/8/07bf655f-5ef5-4aef-a98b-798e90745143_noLocationFound.png',
    },
  ],
  [
    appLanguagesIndex['english'],
    {
      noLocationImage:
        'https://d2n0idf0n5xz1f.cloudfront.net/fmtapp/asset/2024/10/8/07bf655f-5ef5-4aef-a98b-798e90745143_noLocationFound.png',
    },
  ],
]);

export {
  pincodeAssets,
  myProfileAssets,
  communityAssets,
  saudaDetailsAssets,
  uploadDocAssets,
  rakeDetailAssets,
  faqAssets,
  mySaudaAssets,
  buyerDetailAssets,
  tradeScreenAssets,
  loginScreenAssets,
  homeScreenAssets,
  defaultLanguageCode,
};
