/**
 * The above TypeScript code defines functions for guest login, storing and retrieving authentication
 * information from local storage.
 * @returns The code snippet provided contains several functions related to handling authentication
 * information in a web application. Here is a summary of what each function does:
 */
import { v4 as uuid } from 'uuid';
import loginAsGuestApi from '../../../apis/auth/guestLogin';
import getConfigByKeys from '../../../config/config';
import { configKeys } from '../../../config/types';
import encryptAsAES from '../../../utils/encryption/aes';
import dateToObject from '../../../utils/date/dateToObject';

import { AuthInfoToStorage } from '../types';
import { Profile } from '../../../hooks/auth/types';

let loginAsGuestPromise: Promise<AuthInfoToStorage> | null = null;

export async function loginAsGuest(): Promise<AuthInfoToStorage> {
  if (loginAsGuestPromise) {
    return loginAsGuestPromise; // If there's already a promise, return it
  }

  loginAsGuestPromise = (async () => {
    const { AES_KEY } = getConfigByKeys([configKeys.AES_KEY]);

    /* `const currentDate = dateToObject(new Date());` is creating a new Date object representing the
    current date and time, and then converting it into an object format using the `dateToObject`
    function. This allows for easier manipulation and extraction of specific date components such as
    day and month for further use in the code. */
    const currentDate = dateToObject(new Date());
    const uuidString = uuid();
    const uniqueId = currentDate.day + uuidString + currentDate.month;
    const token = await encryptAsAES(uniqueId, AES_KEY);

    try {
      const result = await loginAsGuestApi({ uuid: token });
      storeAuthInfoToStorage(result.data.data);
      localStorage.removeItem('profileSelected');
      return result.data.data;
    } catch (error) {
      console.log(error);
      throw error; // Ensure errors are propagated correctly
    } finally {
      loginAsGuestPromise = null; // Reset the promise after it's done
    }
  })();

  return loginAsGuestPromise;
}

export function storeAuthInfoToStorage(data: AuthInfoToStorage) {
  localStorage.setItem('authToken', JSON.stringify(data?.token || null));
  localStorage.setItem('userProfile', JSON.stringify(data?.profile || null));
  localStorage.setItem('userLocation', JSON.stringify(data?.location || null));
  localStorage.setItem('userLanguage', JSON.stringify(data?.language || null));
  localStorage.setItem(
    'appLocationPermission',
    JSON.stringify(data?.appLocationPermission || null),
  );
  localStorage.setItem(
    'appRoutesData',
    JSON.stringify(data?.appLocationPermission || null),
  );
}

export function getAuthTokenFromStorage(): string {
  const token = localStorage.getItem('authToken');

  return JSON.parse(token) || '';
}

export function getUserProfileFromStorage(): AuthInfoToStorage['profile'] {
  const userProfile = localStorage.getItem('userProfile');

  return userProfile !== undefined && userProfile !== null
    ? JSON.parse(userProfile)
    : '';
}

export function getUserLocationFromStorage(): AuthInfoToStorage['location'] {
  const userLocation = localStorage.getItem('userLocation');

  return userLocation !== undefined ? JSON.parse(userLocation) : '';
}

export function getUserLanguageFromStorage(): AuthInfoToStorage['language'] {
  const userLanguage = localStorage.getItem('userLanguage');

  return userLanguage !== undefined ? JSON.parse(userLanguage) : '';
}

export function setAuthTokenToStorage(token: string) {
  localStorage.setItem(
    'authToken',
    JSON.stringify(token || getAuthTokenFromStorage()),
  );
}

export function setProfileToStorage(profile: Profile) {
  localStorage.setItem(
    'userProfile',
    JSON.stringify(profile || getUserProfileFromStorage()),
  );
}
