import { useState, useEffect, useRef } from 'react';

interface CommentHookProps {
  onSubmit: (value: string) => void;
  taggedName: string;
  removeTaggedName: () => void;
  focus: boolean;
}

const CommentInputHook = (props: CommentHookProps) => {
  const { onSubmit, taggedName, removeTaggedName, focus } = props;
  const [inputValue, setInputValue] = useState('');
  const inputRef = useRef<HTMLTextAreaElement | null>(null);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [focus]);

  useEffect(() => {
    if (taggedName) {
      setInputValue('');
      setInputValue((prevInputValue) => `@${taggedName} ${prevInputValue}`);
    }
  }, [taggedName]);

  const handleInputChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    //ts-ingnorec
    let value = event.target.value;
    setInputValue(value);
    const textarea = inputRef.current;
    if (textarea) {
      textarea.style.height = 'auto';
      textarea.style.height = `${Math.min(34, textarea.scrollHeight)}px`;
    }
  };

  const handleSubmit = () => {
    if (inputValue.trim() !== '') {
      let cleanedValue = inputValue;
      if (cleanedValue.startsWith(`@${taggedName} `)) {
        cleanedValue = cleanedValue.slice(taggedName.length + 1).trim();
      }
      onSubmit(cleanedValue);
      removeTaggedName();
      setInputValue('');
    }
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === 'Enter' && event.shiftKey) {
      // Insert a new line in the textarea when Shift+Enter is pressed
      setInputValue((prevValue) => prevValue + '\n');
    } else if (event.key === 'Enter') {
      // Submit the form when Enter key is pressed without Shift
      event.preventDefault(); // Prevents the default Enter key behavior
      handleSubmit();
    } else if (event.key === 'Backspace') {
      if (inputValue.startsWith(`@${taggedName} `)) {
        setInputValue('');
        removeTaggedName();
      }
    }
  };

  return {
    handleKeyPress,
    handleSubmit,
    handleInputChange,
    inputValue,
    inputRef,
  };
};

export default CommentInputHook;
