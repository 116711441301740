import React from 'react';
import { Alert, Snackbar } from '@mui/joy';
import Style from './style.module.css';
import { Warning } from '@mui/icons-material';

interface AcknowledgeAlertProps {
  language: { [key: string]: any }; // Language settings for localization
  showInvalidBrokeCodeAlert?: boolean; // Flag to show invalid broker code alert
  setShowInvalidBrokerCodeAlert?: (flag: boolean) => void; // Function to set invalid broker code alert
}

export default function AlertInfo({
  language,
  showInvalidBrokeCodeAlert,
  setShowInvalidBrokerCodeAlert,
}: AcknowledgeAlertProps) {
  return (
    <div className={Style.footer}>
      <Snackbar
        open={showInvalidBrokeCodeAlert}
        autoHideDuration={4000}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        sx={{
          padding: 0,
          zIndex: 20000,
          borderRadius: '8px',
          width: '90%',
          marginBottom: '32px',
        }}
        variant="soft"
        color={'danger'}
        onClose={() => {
          setShowInvalidBrokerCodeAlert(false);
        }}
      >
        {
          <Alert
            variant="soft"
            color={'danger'}
            startDecorator={<Warning color="error" />}
            className={Style.alert}
          >
            {language?.invalidBrokerCodeAlert}
          </Alert>
        }
      </Snackbar>
    </div>
  );
}
