import * as AppLanguages from '../constant/appLanguages';
//language will be added here
const languages = {
  [AppLanguages.appLanguagesIndex.english]: {
    submit: 'Submit',
    userName: 'Name',
    userNamePlaceholder: 'Enter name',
    occupationName: 'Shop Name',
    businessName: 'Business Name',
    shopName: 'Shop Name',
    editProfile: 'Edit Profile',
    editPhoto: 'Edit photo',
    openCamera: 'Open Camera',
    openGallery: 'Open Gallery',
    notEnterUserName: 'Enter your name',
    notEnterShopName: 'Enter your shop name',
    notbothShopAndUserName: 'Enter your name and shop name',
    requestFailErrorMessage:
      'Sorry for the inconvenience! Please try again later.',
  },
  [AppLanguages.appLanguagesIndex.hindi]: {
    submit: 'सबमिट करें',
    userName: 'नाम',
    userNamePlaceholder: 'नाम दर्ज करें',
    occupationName: 'दुकान का नाम',
    businessName: 'बिज़नेस नाम',
    shopNamePlaceholder: 'अपनी दुकान का नाम भरें',
    shopName: 'दुकान का नाम',
    editProfile: 'प्रोफाइल एडिट करें',
    editPhoto: 'फोटो बदलें',
    openCamera: 'फोटो लें',
    openGallery: 'गैलेरी खोलें',
    notEnterUserName: 'अपना नाम भरें',
    notEnterShopName: 'अपनी दुकान का नाम भरें',
    notbothShopAndUserName: 'अपना नाम और अपनी दुकान का नाम भरें',
    requestFailErrorMessage:
      'असुविधा के लिए क्षमा करें! कृपया बाद में फिर से प्रयास करें।',
  },
};

export default (language) => languages[language];
