// Import Box component from @mui/joy for layout and styling purposes.
import { Box } from '@mui/joy';
// Import React for component creation.
import React from 'react';
// Import Styles from the module CSS for custom styling.
import Styles from './style.module.css';
// Import MyProfileHeaderProps type for type checking of props.
import { MyProfileHeaderProps } from '../../../hooks/MyProfile/types';
// Import MuiTypography component for text rendering with Material UI styling.
import MuiTypography from '../../../ComponentV3/Typography';
// Import MuiButton component for button rendering with Material UI styling.
import MuiButton from '../../../ComponentsV3/MuiButton';
// Import EditRounded and ArrowBackRounded icons from @mui/icons-material for use in buttons.
import { EditRounded, ArrowBackRounded } from '@mui/icons-material';
import SkeletonLoading from '../../../ComponentsV4/SkeletonLoading';

/**
 * The index function component renders the header section of the MyProfile page.
 * It displays a back button, the page title, and an edit profile button.
 *
 * Props:
 * - language: Object containing localized text strings.
 * - onClickMyProfile: Function to be called when the edit profile button is clicked.
 * - onClickBack: Function to be called when the back button is clicked.
 *
 * @param {MyProfileHeaderProps} props The props passed to the component.
 * @returns {JSX.Element} The JSX code for the MyProfile header component.
 */
export default function index(props: MyProfileHeaderProps) {
  // Destructure props for easier access to individual properties.
  const { language, onClickMyProfile, onClickBack, loading } = props;

  // Render the component.
  return loading ? (
    <SkeletonLoading className={Styles?.skeleton} /> // Main container for the header, styled with background color and custom CSS.
  ) : (
    <Box bgcolor={'background.body'} className={Styles?.mainContainer}>
      {/* Container for the back button and page title, aligned in a row. */}
      <Box display={'flex'} alignItems={'center'} gap={'var(--3, 12px)'}>
        {/* // Back button with an onClick event handler to trigger navigation. */}
        <ArrowBackRounded
          onClick={onClickBack}
          id="myProfileHeaderBackButtonId"
          data-testid={'myProfileHeaderBackButtonTestId'}
        />
        {/* // Page title, styled with primary text color and medium title level. */}
        <MuiTypography
          textColor={'text.primary'}
          level="title-md"
          data-testid={'myProfileHeaderTitleTestId'}
        >
          {language?.headerTitle}
        </MuiTypography>
      </Box>
      {/*   // Container for the edit profile button, styled and aligned centrally. */}
      <Box
        display={'flex'}
        minHeight={'32px'}
        padding={'2px 0px'}
        justifyContent={'center'}
        alignItems={'center'}
        gap={'6px'}
        borderRadius={'var(--radius-md, 8px)'}
        onClick={() => {
          onClickMyProfile('onClickMyProfile');
        }}
        id="myProfileHeaderEditButtonId"
      >
        {/* // Edit profile button with an icon, styled as a small, primary-colored plain button. */}
        <MuiButton
          size="sm"
          color="primary"
          startDecorator={<EditRounded />}
          variant="plain"
          className={Styles?.editButton}
          data-testid={'myProfileHeaderEditButtonTestId'}
        >
          {language?.editProfileText}
        </MuiButton>
      </Box>
    </Box>
  );
}
