import { appLanguagesIndex, defaultLanguageCode } from './appLanguages';

const quizImagesData = new Map([
  [
    appLanguagesIndex['hindi'],
    'https://d2n0idf0n5xz1f.cloudfront.net/metadata/quiz_image/quiz_hi_v2.png',
  ],
  [
    appLanguagesIndex['english'],
    'https://d2n0idf0n5xz1f.cloudfront.net/metadata/quiz_image/quiz_en_v2.png',
  ],
]);

export function getQuizImage(langCode) {
  if (!langCode) {
    langCode = defaultLanguageCode;
  }

  const quizImage = quizImagesData.get(langCode);

  return quizImage || quizImagesData.get(defaultLanguageCode);
}
