/**
 * Compare two objects and return true if they are equal, excluding a specific key.
 *
 * @param {Object} obj1 The first object to compare.
 * @param {Object} obj2 The second object to compare.
 * @param {string} [excludeKey] The key to exclude from the comparison.
 * @returns {boolean} True if the objects are equal, false otherwise.
 */
export default function compareObjects(obj1, obj2, excludeKey) {
  if (excludeKey) {
    obj1 = Object.fromEntries(
      Object.entries(obj1).filter(([key]) => key !== excludeKey),
    );
    obj2 = Object.fromEntries(
      Object.entries(obj2).filter(([key]) => key !== excludeKey),
    );
  }

  return JSON.stringify(obj1) === JSON.stringify(obj2);
}
