import * as AppLanguages from '../constant/appLanguages';
//language will be added here
const languages = {
  [AppLanguages.appLanguagesIndex.hindi]: {
    headerTitle: 'दुकान प्रचार',
    help: 'सहायता',
    viewSMSHistory: 'भेजे हुए  SMS  देखें',
    selectSMSTemplate: 'SMS टेम्पलेट चुनें',
    promotionalMediaContainerHeading:
      'दुकान प्रचार कृषि विक्रेताओं की कैसे मदद करता है?',
    okay: 'ठीक है',
    needSupport: 'सहायता की जरुरत है ?',
    contactUs: 'संपर्क करें',
    reachOutForHelp: 'सहायता के लिए कृपया हमसे संपर्क करें!',
    smsBalanceForCurrentMonth: '<currentMonth> महीने के लिए आपका SMS बैलेंस',
    smsSentInLastFewDays:
      '<noOfSMSSent> SMS पिछले  <noOfDays> दिनों में भेजे गए हैं।',
    whatsAppMessageText:
      'नमस्कार FarMart कृपया हमें अन्य जानकारी प्राप्त करने में मदद करें।',
    noWhatsAppAccountFound: 'कोई व्हाट्सएप अकाउंट नहीं मिला',
    progressiveSignUpModalHeading: 'आगे बढ़ने के लिए, कृपया विवरण जोड़ें',
    businessNameInputTitle: 'दूकान का नाम',
    nameInputTitle: 'आपका नाम',
    businessNameInputPlaceholder: 'जैसे. सिंह ट्रेडर्स',
    nameInputTitlePlaceholder: 'जैसे. अरविन्द सिंह',
    progressiveModalProceed: 'आगे बड़े',
    shopNameInputTitle: 'दूकान का नाम',
    shopNameInputPlaceholder: 'जैसे. सिंह बीज भंडार',
  },
  [AppLanguages.appLanguagesIndex.english]: {
    headerTitle: 'Shop Promotion',
    help: 'Help',
    viewSMSHistory: 'View SMS history',
    selectSMSTemplate: 'Select SMS Template',
    promotionalMediaContainerHeading: 'How SMS marketing helped the retailers?',
    okay: 'Okay',
    needSupport: 'Need Support?',
    contactUs: 'Contact us',
    reachOutForHelp: 'Reach out to us for any help!',
    smsBalanceForCurrentMonth:
      'Your SMS balance for the month of <currentMonth>',
    smsSentInLastFewDays: '<noOfSMSSent> SMS SENT IN LAST <noOfDays> DAYS',
    whatsAppMessageText:
      'Hello FarMart Please help me in getting other information',
    noWhatsAppAccountFound: 'No WhatsApp account found',
    progressiveSignUpModalHeading: 'To Proceed, Please add the details',
    businessNameInputTitle: 'Business Name',
    nameInputTitle: 'Your Name',
    businessNameInputPlaceholder: 'Eg. Singh Traders',
    nameInputTitlePlaceholder: 'Eg. Arvind Singh',
    progressiveModalProceed: 'Proceed',
    shopNameInputTitle: 'Shop Name',
    shopNameInputPlaceholder: 'Eg. Singh Beej Bhandar',
  },
};

export default (language) => languages[language];
