import * as AppLanguages from '../constant/appLanguages';
//language will be added here
const languages = {
  [AppLanguages.appLanguagesIndex.hindi]: {
    saudaIdLabel: 'सौदा ID',
    quantityLabel: 'मात्रा',
    netPriceLabel: 'नेट FOR मूल्य',
    grossPriceLabel: 'ग्रॉस बायर मूल्य',
    cancelSaudaRequest: 'सौदा रद्द करें',
    cancelSaudaModalheading: 'क्या आप सौदा रद्द करना चाहते हैं ?',
    acceptPoLabel: 'पीओ स्वीकार करें',
    viewPoReciept: 'पीओ रसीद देखें',
    tncAccept1: 'आप हमारी ',
    tncAccept2: 'नियम एवं शर्तें',
    tncAccept3: 'स्वीकार करते हैं',
    cancelButton: 'रद्द करें',
    acceptPoButton: 'पीओ स्वीकार करें',
    rejectpoLabel: 'रिजेक्ट पीओ',
    rejectPoButton: 'रिजेक्ट पीओ',
    acceptpoNote:
      'फारमार्ट आपकी पीओ स्वीकृति के लिए प्रतीक्षा कर रहा है, कृपया सुनिश्चित करें कि आपने सभी नियम और शर्तें पढ़ी हैं।',
    acceptPoCardHeading: 'फाइनल सौदा के लिए अपडेटेड मूल्य और मात्रा',
    acceptPoCardSubHeading:
      '<quantity> <cropName> नेट FOR मूल्य <netRate> में।',

    truckModalHeading: 'भुगतान विवरण',
    grossWeightLabel: 'कुल वजन',
    truckRejectedLabel: 'ट्रक रिजेक्टेड',
    paidLabel: 'भुगतान हुआ',
    pendingLabel: 'शेष भुगतान',
    grossPaybleLabel: 'कुल भुगतान राशि',
    deductionLabel: 'कटौती',
    payableLabel: 'नेट भुगतान राशि',
    okayLabel: 'ठीक है',

    truckModalInfoHeading: 'आपका भुगतान अगले कुछ घंटों में कर दिया जाएगा.',
    buyerQualityRepot: 'खरीददार की गुणवत्ता रिपोर्ट',
    truckModalPaymentDue: 'भुगतान देय तिथि {(Date)} है',
    truckModalTransDetailHeading: 'लेनदेन का विवरण',
    utrNumber: 'UTR  नंबर',
    tapToCopy: 'कॉपी करने के लिए टैप करें',

    truckUnload: 'ट्रक अनलोडेड',
    truckDispached: 'ट्रक भेजा गया',
    saudaInReviewHeading: 'सौदा अनुरोध सफलतापूर्वक बनाया गया',
    saudaInReviewSubHeading:
      'सौदा के लिए टीम आपसे जल्द ही संपर्क करेगी और आपके स्वीकृति के लिए पीओ भेजेगी।',
    saudaBookCardHeading: 'सौदा सफलतापूर्वक बुक हो गया',
    saudaBookCardSubHeading:
      'पीओ बनने के दिन ही ट्रक को डिस्पैच करें, क्योंकि <n> दिनों के भीतर डिस्पैच न करने पर ऑर्डर रद्द कर दिया जाएगा।',
    saudaBookCardSubHeadingOffline:
      'पीओ बनने के दिन ही ट्रक को डिस्पैच करें, क्योंकि <n> दिनों के भीतर डिलीवर न करने पर ऑर्डर रद्द कर दिया जाएगा।',
    poRejectedCardHeading: 'आपके द्वारा पीओ रिजेक्ट किया गया',
    poRejectedCardSubHeading: '<date> को रिजेक्ट किया गया',
    saudaCanceledCardHeading: 'सौदा आपके द्वारा रद्द किया  गया',
    saudaCanceledCardSubHeading: '<date> को रद्द किया गया',
    saudaRejectCardHeading: 'फारमार्ट  द्वारा सौदा  रिजेक्ट किया  गया',
    saudaRejectCardSubHeading: '<date> को रिजेक्ट  किया गया',
    saudaCompleteCardHeading: 'सौदा पूरा हुआ',
    saudaCompleteSubCardHeading: '<date> को पूरा हुआ',
    truckDetailHeading: 'ट्रक की जानकारी',
    viewPayment: 'भुगतान की जानकारी देखें',
    buyerDetails: 'खरीददार और डिलीवरी विवरण',
    qualityParaMeters: 'गुणवत्ता पैरामीटर',
    orderFulfilmentDate: 'आर्डर पूर्ति की तारीख',
    fulfilmentNote:
      'नोट: आर्डर बुकिंग के दिन से बुक की गई मात्रा को {(fulfilmentDate)} तक पहुँचाया जाना चाहिए।',
    grossPrice: 'ग्रॉस बायर मूल्य',
    cashDiscount: 'कैश डिस्काउंट',
    bagDeduction: 'बैग कटौती',
    unloadingCharges: 'अनलोडिंग शुल्क',
    brokerage: 'ब्रोकरेज',
    financingCost: 'फारमार्ट फी',
    buyerTermToolTip:
      'अनलोडिंग के {(paymertDays)} दिन बाद भुगतान प्राप्त करें, फारमार्ट {*brokerage*} के दर से ब्रोकरेज शुल्क लेगा।',
    // "भुगतान खरीददार द्वारा GRN (गुड्स रिसीप्ट नोट) बनाने के {(paymertDays)} बाद होगा। फारमार्ट ब्रोकरेज शुल्क {*brokerage*} के दर से लागत लेगा",

    // grnTermToolTip:
    // "GRN (गुड्स रिसीप्ट नोट) पर भुगतान के लिए फारमार्ट ब्रोकरेज शुल्क {(brokerage)} और बिल मूल्य का {*financingCost*} फाइनेंसिंग लागत लेगा।",
    grnTermToolTip:
      'अनलोडिंग पर भुगतान पायें।  फारमार्ट {(brokerage)} ब्रोकरेज और बिल की गई राशि के {*financingCost*} की दर से फारमार्ट फी लेगा।',
    DiscountedGrnTermToolTip:
      'अनलोडिंग पर भुगतान पायें।  फारमार्ट {(brokerage)} ब्रोकरेज और बिल की गई राशि के {*financingCost*} की दर से डिस्काउंटेड फारमार्ट फी लेगा।',
    saudaCanceledHeadingLabel: 'सौदा रद्द',
    saudaReviewHeadingLabel: ' सौदा समीक्षा में है',
    saudaRejectedHeadingLabel: 'सौदा रिजेक्टेड',
    saudaBookededHeadingLabel: 'सौदा बुक हो गया',
    saudaPendingHeadingLabel: 'पीओ स्वीकृति लंबित है',
    saudaPoRejectedHeadingLabel: 'पीओ रद्द',
    saudaComepletedHeadingLabel: 'सौदा पूरा',
    orderDetailHeading: 'आर्डर विवरण',
    orderDetailNote:
      'सौदा के मूल्य और मात्रा को तब तक बदला जा सकता है जब तक कि सौदा की पुष्टि नहीं हो जाती।',
    needHelpHeading: 'सहायता चाहिए ?',
    needHelpSubHeading:
      'सौदा करने में किसी भी सहायता की जरुरत हो तो कृपया हमसे संपर्क करें।',
    chatWithus: 'बात करें',
    callNow: 'कॉल करें',
    brokeragePopUpTitle: 'ब्रोकरेज',
    brokeragePopUpSubHeading:
      'फारमार्ट के माध्यम से खरीददार के साथ एक सौदा बुक करने के लिए शुल्क, जिससे फारमार्ट यह सुनिश्चित करता है कि आप हर बार सर्वोत्तम मूल्य प्राप्त कर सकें और आपको हमेशा विश्वसनीय सेवाएं प्रदान की जा सके। ब्रोकरेज: {(BrokerageUnit)}',
    popUpOkayButton: 'ठीक है',

    financePopUpTitle: 'फारमार्ट फी',
    financePopUpSubHeading:
      'बायर के भुगतान करने से पहले तेजी से भुगतान प्राप्त करने में आपकी सहायता करेगा, यह आपके कैश फ्लो को तेज बनाए रखने में मदद करेगा। फारमार्ट फी: {(financeCost)}%',

    netPricePopUpTitle: 'नेट FOR मूल्य',
    netPricePopUpSubHeading:
      'नेट FOR मूल्य = ग्रॉस बायर मूल्य - बैग कटौती - अनलोडिंग शुल्क - ब्रोकरेज - फाइनेंसिंग लागत।',
    heading: 'सौदा विवरण',
    utrCopyPopup: 'UTR नंबर कॉपी हो गया',
    mobileNumberCopytext: 'मोबाइल नंबर को क्लिपबोर्ड पर कॉपी किया गया है।',
    atGrn: 'अनलोडिंग पर भुगतान',
    atBuyers: 'बायर क्लेम पर भुगतान',
    payAtBuyer: 'बायर क्लेम पर भुगतान',
    payAtGrn: 'अनलोडिंग पर भुगतान',
    dispatchedWeight: 'भेजा गया वजन: ',
    deliveredWeight: 'वितरित किया गया वजन: ',
    errorMessage:
      'असुविधा के लिए क्षमा करें! कृपया बाद में फिर से प्रयास करें।',
    createDispatch: 'डिस्पैच  बनाएँ',
    viewReport: 'कटौती रिपोर्ट देखें',
    truckDetailText:
      'सौदा के साथ आगे बढ़ने के लिए डिस्पैच बनाना शुरू करें। समय पर भुगतान के लिए भेजे गए प्रत्येक ट्रक का डिस्पैच दस्तावेज को साझा करें।',
    documentApprovalPending: 'दस्तावेज़ की मंजूरी लंबित है',
    documentRejected: 'दस्तावेज़ अस्वीकृत',
    documentRejectedInfo:
      'दस्तावेज़ अस्वीकृत, आगे बढ़ने के लिए इसे फिर से अपलोड करें',
    reUploadNow: 'पुनः अपलोड करें',
    payable: 'कुल भुगतान राशि',
    waitingForApproval: 'दस्तावेज़ मंजूरी की प्रतीक्षा करें',
    paymentDetails: 'भुगतान विवरण',
    brokerCode: 'ब्रोकर कोड',
    buyerNetPrice: 'नेट बायर मूल्य',
    discountFee: 'फारमार्ट फी पर @ {(discount_in_percent)}% की छूट',
    'Pay at GRN': 'अनलोडिंग पर भुगतान',
    'Pay at BUYER': 'बायर क्लेम पर भुगतान',
    toBeUpdated: 'जल्द ही अपडेट किया जाएगा ...',
    priceBreakup: 'मूल्य विभाजन',
    tdsLabel: 'टीडीएस',
    poIdLabel: 'पीओ ID',
  },
  [AppLanguages.appLanguagesIndex.english]: {
    saudaIdLabel: 'Sauda ID',
    quantityLabel: 'Quantity',
    netPriceLabel: 'Net Price (FOR)',
    grossPriceLabel: 'Gross Buyer Price',
    cancelSaudaRequest: 'Cancel Sauda Request',
    cancelSaudaModalheading:
      'Are you sure, you want to cancel the Sauda request?',
    acceptPoLabel: 'Accept PO',
    viewPoReciept: 'View PO receipt',
    tncAccept1: 'By accepting the PO you accept to our',
    tncAccept2: 'Terms and Conditions',
    tncAccept3: '',
    cancelButton: 'Cancel',
    acceptPoButton: 'Accept PO',
    rejectpoLabel: 'Reject PO',
    rejectPoButton: 'Reject PO',
    acceptpoNote:
      'Farmart is waiting for you to approve the PO, kindly ensure you have read all the TnC',
    acceptPoCardHeading: 'The updated price and quantity for final Sauda is',
    acceptPoCardSubHeading:
      '<quantity> of <cropName> at Net FOR Price of <netRate>',
    truckModalHeading: 'Payment details',
    grossWeightLabel: 'Gross weight',
    truckRejectedLabel: 'Truck rejected',
    paidLabel: 'Paid',
    pendingLabel: 'Pending',
    grossPaybleLabel: 'Gross Payable',
    deductionLabel: 'Deduction',
    payableLabel: 'Net Payable',
    okayLabel: 'Okay',
    truckModalInfoHeading:
      'Your payment will be processed in the next few hours.',
    buyerQualityRepot: 'Buyer’s quality report',
    truckModalPaymentDue: 'Payment due date is {(Date)}',
    truckModalTransDetailHeading: 'Transaction details',
    utrNumber: 'UTR Number',
    tapToCopy: 'Tap to copy',
    truckUnload: 'Truck unloaded',
    truckDispached: 'Truck dispatched',
    saudaInReviewHeading: 'Sauda request created successfully',
    saudaInReviewSubHeading:
      'Team will reach out to you shortly for the Sauda request and will share the PO for your approval.',
    saudaBookCardHeading: 'Sauda booked successfully',
    saudaBookCardSubHeading:
      'Dispatch the truck on the same day of PO creation, as failure to dispatch within <n> days will result in order cancellation.',
    saudaBookCardSubHeadingOffline:
      'Dispatch the truck on the same day of PO creation, as failure to deliver within <n> days will result in order cancellation.',
    poRejectedCardHeading: 'PO rejected by you',
    poRejectedCardSubHeading: 'Rejected on <date>',
    saudaCanceledCardHeading: 'Sauda request cancelled by you',
    saudaCanceledCardSubHeading: 'Cancelled on <date>',
    saudaRejectCardHeading: 'Sauda request rejected by FarMart',
    saudaRejectCardSubHeading: 'Rejected on <date>',
    saudaCompleteCardHeading: 'Sauda Completed',
    saudaCompleteSubCardHeading: 'Completed on <date>',
    truckDetailHeading: 'Truck Details',
    viewPayment: 'View payment details',
    buyerDetails: 'Buyer’s & Delivery Details',
    saudaCanceledHeadingLabel: 'Sauda Cancelled',
    saudaReviewHeadingLabel: 'Sauda in Review',
    saudaRejectedHeadingLabel: 'Sauda Rejected',
    saudaBookededHeadingLabel: 'Sauda Booked',
    saudaPendingHeadingLabel: 'PO acceptance Pending',
    saudaPoRejectedHeadingLabel: 'PO Rejected',
    saudaComepletedHeadingLabel: 'Sauda Completed',
    qualityParaMeters: 'Quality Parameters',
    orderFulfilmentDate: 'Order fullfilment date',
    fulfilmentNote:
      'Note: From the day of the order booking, the booked quantity should be delivered by {(fulfilmentDate)}.',
    grossPrice: 'Gross Buyer Price',
    cashDiscount: 'Cash Discount',
    bagDeduction: 'Bag Deduction',
    unloadingCharges: 'Unloading Charges',
    brokerage: 'Brokerage',
    financingCost: 'FarMart Fee',
    buyerTermToolTip:
      'Get paid, {(paymertDays)} post unloading. FarMart will charge brokerage at {*brokerage*}',
    // "Get paid, {(paymertDays)} post GRN. FarMart will charge brokerage at {*brokerage*}.",
    grnTermToolTip:
      'Get paid at Unloading. FarMart will charge brokerage at {(brokerage)} and FarMart Fee at {*financingCost*} of the billed value.',
    DiscountedGrnTermToolTip:
      'Get paid at Unloading. FarMart will charge brokerage at {(brokerage)} and Discounted FarMart Fee at {*financingCost*} of the billed value.',
    // grnTermToolTip:
    //   "Get paid on GRN. FarMart will charge brokerage at {(brokerage)} and FarMart Fee at {*financingCost*} of the billed value.",
    orderDetailHeading: 'Order Details',
    orderDetailNote:
      'Sauda price and quantity can be changed until the Sauda is confirmed.',
    needHelpHeading: 'Need Help',
    needHelpSubHeading:
      'Need any help with the Sauda , please reach out to us.',
    chatWithus: 'Chat with us',
    callNow: 'Call Now',

    brokeragePopUpSubHeading:
      'The charges to book an order with the buyer via FarMart in ensuring you get the best prices and you are being offered reliable services every time. Brokerage: {(BrokergeUnit)}.',
    popUpOkayButton: 'Okay',
    financePopUpTitle: 'FarMart Fee',
    netPricePopUpSubHeading:
      'Net FOR Price = Gross Buyer Price - Bag Deduction - unloading Charges - Brokerage - FarMart Fee.',

    netPricePopUpTitle: 'Net FOR Price',
    financePopUpSubHeading:
      "To help you get your payment faster before buyer's makes the payment. This will assist you in maintaining your cash flow faster. FarMart Fee: {(financeCost)}%",
    heading: 'Sauda Details',
    utrCopyPopup: 'UTR Number copied successfully',
    mobileNumberCopytext: 'Mobile number has been copied to clipboard',
    atGrn: 'Pay at Unloading',
    atBuyers: 'Pay at Buyer Claim',
    payAtBuyer: 'Pay at Buyer Claim',
    payAtGrn: 'Pay at Unloading',
    dispatchedWeight: 'Dispatched Weight: ',
    deliveredWeight: 'Delivered Weight: ',
    errorMessage: 'Sorry for the inconvenience! Please try again later.',
    createDispatch: 'Create Dispatch',
    viewReport: 'View deduction report',
    truckDetailText:
      'Start creating dispatch to proceed with the Sauda. Share dispatch documents against each trucks dispatched for timely payments. ',
    documentApprovalPending: 'Document approval pending',
    documentRejected: 'Document Rejected',
    documentRejectedInfo: 'document rejected, reupload it to proceed',
    reUploadNow: 'Re-upload now',
    payable: 'Gross Payable',
    waitingForApproval: 'Waiting for document approval',
    paymentDetails: 'Payment Details',
    brokerCode: 'BROKER CODE',
    buyerNetPrice: 'Net Buyer Price',
    discountFee: 'Discount on FarMart Fee @ {(discount_in_percent)}%',
    'Pay at GRN': 'Pay at Unloading',
    'Pay at BUYER': 'Pay at Buyer Claim',
    toBeUpdated: 'To be updated...',
    priceBreakup: 'Price Breakup',
    tdsLabel: 'TDS',
    poIdLabel: 'PO ID',
  },
};

export default (language) => languages[language];
