import React, { ReactNode } from 'react';
import './modal.css';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  children: ReactNode;
  title: string;
}

const BottomToTopModal = (props: ModalProps) => {
  const { isOpen, onClose, children, title } = props;
  return (
    <div>
      <div className={`modal-overlay`} />
      <div className={`modal ${isOpen ? 'open' : ''}`}>
        <div className="modal-content">
          <div className="header">
            {title}
            <button className="close-button" onClick={onClose}>
              <CloseRoundedIcon />
            </button>
          </div>
          <div className="children">{children}</div>
        </div>
      </div>
    </div>
  );
};

export default BottomToTopModal;
