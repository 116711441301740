import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface PersonaState {
  userSelectedPersona: string | null; // Type can be changed based on persona data structure
}

const initialState: PersonaState = {
  userSelectedPersona: null,
};

const personaSlice = createSlice({
  name: 'persona',
  initialState,
  reducers: {
    setUserPersona: (state, action: PayloadAction<string>) => {
      state.userSelectedPersona = action.payload;
    },
  },
});

export const { setUserPersona } = personaSlice.actions;

export default personaSlice.reducer;
