import { useEffect, useState } from 'react';
/* The line `import getLang from "../../languages/rakeDetailList.js";` is importing a function named
`getLang` from a file located at "../../languages/rakeDetailList.js". This function is likely used
to retrieve language-related data or translations for the application. */
import getLang from '../../languages/rakeDetailList.js';
/* The line `import { trackEvent } from "../../utils/moengage";` is importing a function named
`trackEvent` from a file located at "../../utils/moengage". This function is likely used for
tracking events or interactions within the application using Moengage, which is a marketing
automation platform. The `trackEvent` function is commonly used to send analytics data or track user
behavior for marketing and engagement purposes. */
import { trackEvent } from '../../utils/moengage';
/* The line `import GetRequest from "../../utils/apiCaller/getRequest";` is importing a function named
`GetRequest` from a file located at "../../utils/apiCaller/getRequest". This function is likely used
to make HTTP requests to an API endpoint. The `GetRequest` function is commonly used to handle GET
requests and fetch data from a server. It is a part of the utility functions related to API calling
in the application. */
import GetRequest from '../../utils/apiCaller/getRequest';
/* The line `import { apiEndPoints } from "../../constant/apiEndPoints";` is importing a constant named
`apiEndPoints` from a file located at "../../constant/apiEndPoints". This constant likely contains
endpoint URLs or configurations related to API calls in the application. It is used to store and
manage the API endpoints centrally, making it easier to maintain and update them across the
application. This import allows access to the `apiEndPoints` constant for making API requests and
handling API-related logic within the code. */
import { apiEndPoints } from '../../constant/apiEndPoints';
/* The line `import { DEFAULT_LANGUAGE } from "../../constant/language";` is importing a constant named
`DEFAULT_LANGUAGE` from a file located at "../../constant/language". This constant likely holds a
default language setting or identifier used within the application. By importing `DEFAULT_LANGUAGE`,
the code gains access to this predefined language value, which can be utilized for language-related
functionalities or configurations throughout the codebase. This import allows the code to use the
`DEFAULT_LANGUAGE` constant to establish a default language setting or handle language-specific
logic within the application. */
import { DEFAULT_LANGUAGE } from '../../constant/language';
/* The line `import { useNavigate, useLocation } from "react-router-dom";` is importing two hooks,
`useNavigate` and `useLocation`, from the `react-router-dom` library. */
import { useNavigate, useLocation } from 'react-router-dom';
/* The line `import Events from '../../constant/MoengageEvents'` is importing a module or file named
`MoengageEvents` from the `constant` directory located at the specified path. This import statement
allows the code to access and use the contents of the `MoengageEvents` module within the current
file. */
import Events from '../../constant/MoengageEvents';
/* The line `import { NearRakeDataProps, CurrentRakeDataProps, RakeDetailProps } from './types'` is
importing specific types or interfaces from a file named 'types' located in the same directory as
the current file. */
import {
  NearRakeDataProps,
  CurrentRakeDataProps,
  RakeDetailProps,
} from './types';
/* The above code is written in TypeScript and it is importing the `appLanguages` constant from a file
located at "../../constant/appLanguages". The code snippet also includes a placeholder " */
import { appLanguages } from '../../constant/appLanguages';
import { getRakeDetailAssets } from '../../constant/imageUrls/index.js';
export function useRakeDetailHook(): RakeDetailProps {
  /* The above code snippet is written in TypeScript and it seems to be a part of a React component.
  Here is a breakdown of what the code is doing: */
  const navigate = useNavigate();
  const locationParams = useLocation();
  const activeSystemLang = Intl.DateTimeFormat().resolvedOptions().locale;
  const systemLanguage = activeSystemLang?.includes('en')
    ? appLanguages[1].langCode
    : appLanguages[0].langCode;
  const langId = JSON.parse(localStorage.getItem('userLanguage'));
  const language = getLang(
    langId?.langCode || systemLanguage || DEFAULT_LANGUAGE,
  );
  const rakeDetailAssets = getRakeDetailAssets(langId?.langCode);
  const localStorageLocation = {
    master_district_id: JSON.parse(localStorage.getItem('districtId')) || 0,
    master_district_name:
      JSON.parse(localStorage.getItem('districtName')) || '',
    master_state_id: JSON.parse(localStorage.getItem('stateId')) || 0,
    master_state_name: JSON.parse(localStorage.getItem('stateName')) || '',
    rake_count: 0,
  };
  const [nearRakeLoading, setNearRakeLoading] = useState<boolean>(true);
  const [currentRakeLoading, setCurretRakeLoading] = useState<boolean>(true);
  const [nearbyRakePage, setNearbyRakePage] = useState<number>(1);
  const [nearRakeData, setNearRakeData] = useState<NearRakeDataProps[]>([
    {
      master_district_id: 0,
      master_district_name: '',
      master_state_id: 0,
      master_state_name: '',
      rake_count: 0,
    },
    {
      master_district_id: 0,
      master_district_name: '',
      master_state_id: 0,
      master_state_name: '',
      rake_count: 0,
    },
    {
      master_district_id: 0,
      master_district_name: '',
      master_state_id: 0,
      master_state_name: '',
      rake_count: 0,
    },
    {
      master_district_id: 0,
      master_district_name: '',
      master_state_id: 0,
      master_state_name: '',
      rake_count: 0,
    },
  ]);
  const [loadMoreRakeLoading, setLoadMoreRakeLoading] =
    useState<boolean>(false);
  const [currentRakeData, setCurrentRakeData] = useState<
    CurrentRakeDataProps[]
  >([
    {
      brand_alias_code: '',
      brand_name: '',
      demand_date_info: '',
      from_station: '',
      fullfiled_on_date_info: '',
      id: 0,
      is_matured: 0,
      to_name: '',
    },
    {
      brand_alias_code: '',
      brand_name: '',
      demand_date_info: '',
      from_station: '',
      fullfiled_on_date_info: '',
      id: 0,
      is_matured: 0,
      to_name: '',
    },
  ]);
  const [currentRakeCount, setCurretRakeCount] = useState<number>(0);
  const [nearRakeCount, setNearRakeCount] = useState<number>(0);
  const [uniqueKey, setUniqueKey] = useState<number>(0);
  const [currentLocation, setCurrentLocation] = useState<NearRakeDataProps>(
    locationParams?.state || localStorageLocation,
  );
  const [masterStateId, setMasterStateId] = useState<number>(
    currentLocation?.master_state_id ||
      JSON.parse(localStorage?.getItem('stateId')),
  );
  const [masterDistrictId, setMasterDistrictId] = useState<number>(
    currentLocation?.master_district_id ||
      JSON.parse(localStorage?.getItem('districtId')),
  );

  /**
   * The function fetchRakeStatus fetches current and nearby rake details and updates the state
   * accordingly.
   */
  async function fetchRakeStatus() {
    setNearRakeLoading(true);
    setCurretRakeLoading(true);

    Promise.all([
      getCurrentRakeDetail({ masterStateId, masterDistrictId }),
      getNearRakeStatus({ masterStateId, masterDistrictId, page: 1 }),
    ])
      .then(([currentRakeResp, nearRakeResp]) => {
        if (currentRakeResp?.data?.status) {
          trackEvent(Events?.onDetailRakeScreenLaunch, {
            rake_count: currentRakeResp.data?.data?.count,
          });
          setCurretRakeCount(currentRakeResp.data?.data?.count);
          setCurrentRakeData(currentRakeResp.data?.data?.rows);
        }
        if (nearRakeResp?.data?.status) {
          setNearbyRakePage(2);
          setNearRakeData(nearRakeResp?.data?.data?.rows);
          setNearRakeCount(nearRakeResp.data?.data?.count);
        }
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      })
      .finally(() => {
        setCurretRakeLoading(false);
        setNearRakeLoading(false);
      });
  }

  /**
   * The function `onLoadMoreNearRake` is an asynchronous function that loads more data related to
   * nearby rakes and updates the state accordingly.
   */
  async function onLoadMoreNearRake() {
    setLoadMoreRakeLoading(true);
    trackEvent(Events?.MORE_CARD_EXPLORED, {});
    const nearRakeResp = await getNearRakeStatus({
      masterStateId,
      masterDistrictId,
      page: nearbyRakePage,
    });

    if (
      nearRakeResp?.data?.status &&
      nearRakeResp?.data?.data?.rows?.length !== 0
    ) {
      setNearRakeData([...nearRakeData, ...nearRakeResp?.data?.data?.rows]);

      setNearbyRakePage(nearbyRakePage + 1);
    }
    setLoadMoreRakeLoading(false);
  }

  /**
   * The function onClickRakeDetails updates various state variables based on the selected location
   * data.
   * @param {NearRakeDataProps} selectedLocation - The `selectedLocation` parameter is of type
   * `NearRakeDataProps`, which likely contains data related to a specific location or rake. This
   * function `onClickRakeDetails` is designed to be called when a user clicks on details related to a
   * specific location or rake. Within the function, it
   * @returns A function is being returned that updates various state variables based on the
   * `selectedLocation` object passed as an argument.
   */
  function onClickRakeDetails(selectedLocation: NearRakeDataProps) {
    return () => {
      setCurrentLocation(selectedLocation);
      setUniqueKey((prevKey) => prevKey + 1);
      setMasterStateId(selectedLocation?.master_state_id);
      setMasterDistrictId(selectedLocation?.master_district_id);
    };
  }

  /**
   * The `onClickHelp` function navigates to the "/faq/questions" page when called.
   */
  function onClickHelp() {
    navigate('/faq/questions');
  }
  /**
   * The onPressBack function navigates back one step.
   */

  function onPressBack() {
    navigate(-1);
  }

  /* The `useEffect` hook in the provided code snippet is responsible for triggering a side effect,
  which in this case is the `fetchRakeStatus` function, based on the dependency array
  `[masterDistrictId]`. Here's what it does: */
  useEffect(() => {
    if (masterDistrictId) {
      fetchRakeStatus();
    }
  }, [masterDistrictId]);

  return {
    /* The variables and functions listed below are part of a custom hook named `useRakeDetailHook` in
    a React component. Here's a breakdown of what each of them is doing: */
    nearRakeLoading,
    nearRakeData,
    nearRakeCount,
    currentRakeData,
    currentRakeCount,
    currentLocation,
    onLoadMoreNearRake,
    loadMoreRakeLoading,
    currentRakeLoading,
    onClickRakeDetails,
    onPressBack,
    onClickHelp,
    language,
    uniqueKey,
    rakeDetailAssets,
  };
}

/**
 * The function `getNearRakeStatus` makes an asynchronous request to retrieve near rake status based on
 * the provided master state and district IDs and page number.
 * @param  - The `getNearRakeStatus` function is an asynchronous function that takes three parameters:
 * `masterStateId`, `masterDistrictId`, and `page`. These parameters are used to make a GET request to
 * retrieve the status of nearby rakes based on the provided state and district IDs, and the
 * @returns The `getNearRakeStatus` function returns the response from the API call made to
 * `apiEndPoints.getNearByRake` with the specified request payload containing the `masterStateId`,
 * `masterDistrictId`, limit, and page number. If there is an error during the API call, the function
 * catches the error and logs it to the console.
 */
async function getNearRakeStatus({ masterStateId, masterDistrictId, page }) {
  try {
    let nearRakeRequestBody = {
      master_state_id: masterStateId,
      master_district_id: masterDistrictId,
      limit: 6,
      page_no: page,
    };

    const nearRakeStatusResp = await GetRequest({
      uri: apiEndPoints?.getNearByRake,
      payload: nearRakeRequestBody,
    });

    return nearRakeStatusResp;
  } catch (error) {
    console.log(error);
  }
}

/**
 * This TypeScript function retrieves the current rake detail based on the provided master state and
 * district IDs.
 * @param  - The `getCurrentRakeDetail` function is an asynchronous function that takes two parameters:
 * `masterStateId` and `masterDistrictId`. These parameters are used to fetch the current rake detail
 * information from an API endpoint. The function constructs a request body object with the provided
 * state and district IDs, sets
 * @returns The function `getCurrentRakeDetail` returns the response from the API call to get the
 * current rake status based on the provided `masterStateId` and `masterDistrictId`.
 */
async function getCurrentRakeDetail({ masterStateId, masterDistrictId }) {
  try {
    let currentRakeRequestBody = {
      master_state_id: masterStateId,
      master_district_id: masterDistrictId,
      limit: 30,
      page: 1,
    };

    const currentRakeStatusResp = await GetRequest({
      uri: apiEndPoints?.getRakeStatus,
      payload: currentRakeRequestBody,
    });
    return currentRakeStatusResp;
  } catch (error) {
    console.log(error);
  }
}
