import * as AppLanguages from '../constant/appLanguages';
//language will be added here
const languages = {
  [AppLanguages.appLanguagesIndex.english]: {
    trade: 'Trade',
    community: 'Community',
    home: 'Home',
    mySauda: 'My Sauda',
    sms: 'SMS',
  },
  [AppLanguages.appLanguagesIndex.hindi]: {
    trade: 'ट्रेड',
    community: 'समुदाय',
    home: 'होम',
    mySauda: 'मेरा सौदा',
    sms: 'एसएमएस',
  },
};

export default (language) => languages[language];
