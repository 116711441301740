import * as AppLanguages from '../constant/appLanguages';
//language will be added here
const languages = {
  [AppLanguages.appLanguagesIndex.hindi]: {
    sendToFarmers: 'किसानो को सूचित करें',
    enterYourOwnRate: 'अपनी रेट दर्ज करें',
    rateInputPlaceholder: 'उदा. 100/किग्रा',
    or: 'या',
    uchitMulya: 'उचित मूल्य',
    proceed: 'आगे बढ़ें',
    fertilizerRate: 'खाद रेट',
    cropRate: 'फसल रेट',
    seedRate: 'बीज रेट',
    cropName: 'फसल का नाम',
    searchCrop: 'फसल खोजें...',
    fertilizerName: 'खाद का नाम',
    searchFertilizer: 'खाद खोजें...',
    smsBalanceLeft: 'शेष SMS बैलेंस:',
  },
  [AppLanguages.appLanguagesIndex.english]: {
    sendToFarmers: 'Send to farmers',
    enterYourOwnRate: 'Enter your own rate',
    rateInputPlaceholder: 'Eg. 100/Kg',
    or: 'OR',
    uchitMulya: 'उचित मूल्य',
    proceed: 'Proceed',
    fertilizerRate: 'Fertilizer Rate',
    cropRate: 'Crop Rate',
    seedRate: 'Seed Rate',
    cropName: 'Crop Name',
    searchCrop: 'Search crop...',
    fertilizerName: 'Fertilizer Name',
    searchFertilizer: 'Search fertilizer...',
    smsBalanceLeft: 'SMS balance left:',
  },
};

export default (language) => languages[language];
