import React from 'react';
import style from './style.module.css';

interface CardContentProp {
  shortDescription: string;
  heading: string;
  language?: { [key: string]: any };
}

const CardContent = function (props: CardContentProp) {
  const { shortDescription, heading, language } = props;
  return (
    <div className={style.postContentContainer}>
      <div className={`${style.headerContainer} ${style.overflowHeader}`}>
        <span className={style.headingText}>{heading}</span>
      </div>

      <div className={`${style.headerDesc} ${style.overflowDesc}`}>
        <p className={style.subHeadingText}>
          {shortDescription}{' '}
          <span className={style.subHeadingTextSeeMore}>
            {language?.seeMore}
          </span>
        </p>
      </div>
    </div>
  );
};

export default CardContent;
