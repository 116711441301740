import React, { useState, useEffect } from 'react';
import Styles from './style.module.css';
import TradeListCard from '../TradeListCard';
import {
  ActiveTrade,
  ExpiredTrade,
  PromotionalMediaList,
} from '../../../modal/Traders/types';
import InfiniteScroll from '../../../Components/InfintyScroll';
import Loading from '../../../ComponentV2/Loading';
import promotionalTradeBannerenum from '../../../constant/enums/promotionalTradeBannerenum';

interface TradeListProps {
  buyersList: ActiveTrade[];
  totalRecords: number;
  getMoreTradeListData: () => void;
  buyersListLoading: boolean;
  language: { [key: string]: any };
  currentLanguageId: string;
  onCLickViewDetail: (
    id: string,
    cropName: string,
    buyerName: string,
    requestSauda: boolean,
  ) => void;
  handleNumberCopyToast: (
    phoneNumber: number,
    cropName: string,
    buyerName: string,
  ) => void;
  activeTradeList: ActiveTrade[];
  expiredTradeList: ExpiredTrade[];
  serviciability: boolean;
  tradeListPageNumber: number;
  saudaRequestToastPopup: boolean;
  handleSaudaRequestToastPopup: () => void;
  promotionalMediaList: PromotionalMediaList[];
  noBuyerListImage: string;
  noExpiredListImage: string;
  discountCardBgSmall: string;
}

const TradeList = (props: TradeListProps) => {
  const {
    buyersList,
    totalRecords,
    getMoreTradeListData,
    buyersListLoading,
    language,
    currentLanguageId,
    onCLickViewDetail,
    handleNumberCopyToast,
    activeTradeList,
    expiredTradeList,
    serviciability,
    tradeListPageNumber,
    saudaRequestToastPopup,
    handleSaudaRequestToastPopup,
    promotionalMediaList,
    noBuyerListImage,
    noExpiredListImage,
    discountCardBgSmall,
  } = props;
  const [firstExpiredItemIndex, setFirstExpiredItemIndex] = useState<
    number | null
  >(null);

  useEffect(() => {
    // Find the index of the first expired item
    const expiredIndex = buyersList.findIndex((item) => item.is_expire === 1);
    setFirstExpiredItemIndex(expiredIndex !== -1 ? expiredIndex : null);
  }, [buyersList]);

  const buyers = (
    <div className={Styles?.tradeListContainer}>
      {buyersList?.length !== 0 && activeTradeList?.length === 0 && (
        <div className={Styles.noDataContainer}>
          <div className={Styles.noDataImageWrapper}>
            <img
              className={Styles.whatsAppIcon}
              src={noBuyerListImage}
              alt="NoBuyer List"
              height={54}
              width={54}
              loading="eager"
            />
          </div>
          <div>
            <div className={Styles.noImageHeadingText}>
              {language?.noActiveBuyerHeading}
            </div>
            <div className={Styles.noImageSubHeadingText}>
              {language?.noActiveBuyerSubHeading}
            </div>
          </div>
        </div>
      )}
      {buyersList?.length > 0
        ? buyersList?.map((items, index) => {
            const isExpired = items?.is_expire;

            // Show the label only for the first expired item
            const renderLabel = isExpired && index === firstExpiredItemIndex;
            return (
              <React.Fragment key={index}>
                {renderLabel ? (
                  <div className={Styles.headerTextStyle}>
                    {language?.expiredPrice}
                  </div>
                ) : null}
                {isExpired === 0 ? (
                  <>
                    {items?.show_banner ===
                      promotionalTradeBannerenum?.activeBanner &&
                      promotionalMediaList?.length > 0 &&
                      promotionalMediaList?.map((item, index) => {
                        return (
                          <img
                            src={item?.media_url}
                            className={Styles?.banner}
                            key={'promotionaTradeListBanner' + index}
                            loading="lazy"
                          />
                        );
                      })}
                    <TradeListCard
                      onCLickViewDetail={onCLickViewDetail}
                      key={index + 'ActiveTradeListCard'}
                      items={items}
                      language={language}
                      currentLanguageId={currentLanguageId}
                      handleNumberCopyToast={handleNumberCopyToast}
                      saudaRequestToastPopup={saudaRequestToastPopup}
                      handleSaudaRequestToastPopup={
                        handleSaudaRequestToastPopup
                      }
                      discountCardBgSmall={discountCardBgSmall}
                    />
                  </>
                ) : (
                  <TradeListCard
                    onCLickViewDetail={onCLickViewDetail}
                    key={index + 'ExpiredTradeListCard'}
                    items={items}
                    language={language}
                    currentLanguageId={currentLanguageId}
                    handleNumberCopyToast={handleNumberCopyToast}
                    saudaRequestToastPopup={saudaRequestToastPopup}
                    handleSaudaRequestToastPopup={handleSaudaRequestToastPopup}
                    discountCardBgSmall={discountCardBgSmall}
                  />
                )}
              </React.Fragment>
            );
          })
        : !buyersListLoading && (
            <div className={Styles.noDataImageContainer}>
              <div className={Styles.noDataContainer}>
                <div className={Styles.noDataImageWrapper}>
                  <img
                    className={Styles.whatsAppIcon}
                    src={noBuyerListImage as unknown as string}
                    alt="No Buyer List"
                    loading="eager"
                  />
                </div>
                <div>
                  <div className={Styles.noImageHeadingText}>
                    {language?.noActiveBuyerHeading}
                  </div>
                  <div className={Styles.noImageSubHeadingText}>
                    {language?.noActiveBuyerSubHeading}
                  </div>
                </div>
              </div>
              <div className={Styles.headerTextStyle}>
                {language?.expiredPrice}
              </div>

              <div className={Styles.noDataContainer}>
                <div className={Styles.noDataImageWrapper}>
                  <img
                    className={Styles.whatsAppIcon}
                    src={noExpiredListImage as unknown as string}
                    alt=""
                    loading="eager"
                  />
                </div>
                <div className={Styles.noImageHeadingText}>
                  {language?.noExpiredBuyer}
                </div>
              </div>
            </div>
          )}
      {totalRecords === buyersList?.length &&
        buyersList?.length !== 0 &&
        expiredTradeList?.length === 0 && (
          <>
            <div className={Styles.headerTextStyle}>
              {language?.expiredPrice}
            </div>

            <div className={Styles.noDataContainer}>
              <div className={Styles.noDataImageWrapper}>
                <img
                  className={Styles.whatsAppIcon}
                  src={noExpiredListImage}
                  alt="NoExpired List"
                  height={54}
                  width={54}
                  loading="eager"
                />
              </div>
              <div className={Styles.noImageHeadingText}>
                {language?.noExpiredBuyer}
              </div>
            </div>
          </>
        )}
    </div>
  );
  return (
    <InfiniteScroll
      children={buyers}
      vertical={true}
      onScrollToEnd={getMoreTradeListData}
      loading={buyersListLoading}
      loadingElement={
        <div
          className={
            tradeListPageNumber > 1
              ? Styles.loadingContainer
              : Styles.centerLoadingContainer
          }
          data-testid={'loadingElementTradeList'}
        >
          <Loading size={30} color="grey" marginTop={20} />
        </div>
      }
    />
  );
};

export default TradeList;
