/* This code snippet is defining language translations for a multilingual application. It contains
translations for different text elements in both English and Hindi languages. The `import * as
AppLanguages from "../constant/appLanguages";` statement is importing a module that likely contains
language constants or indexes. */
import * as AppLanguages from '../constant/appLanguages';
//language will be added here
const languages = {
  [AppLanguages.appLanguagesIndex.hindi]: {
    whatYouDo: 'आप क्या करते हैं?',
    applicableProfession: 'जो भी आप पर लागू हो उसे चुनें',
    retailer: 'खाद, बीज, कीटनाशक बिक्री',
    retailerDescp: 'कृषि विक्रेताओं और कृषि सलाहकारों के लिए',
    distributer: 'फसल व्यापार',
    distributerDescp: 'ट्रेडर, आढ़तियों, और अन्य लोगों के लिए',
    farmer: 'खेती बाड़ी',
    farmerDescp: 'हर एक किसानों के लिए',
    downloadAppNow: 'अभी ऐप डाउनलोड करें',
    notNow: 'अभी नहीं',
    getTheApp: 'FarMart ऐप से जुड़ें',
    rake: 'रेक',
    bahiKhata: 'बही खाता',
    distributor: 'डिस्ट्रीब्यूटर्स',
    dukaanPrachar: 'दुकान प्रचार',
    cropAdvisory: 'फसल सलाह',
    rakeCardText: 'अपने नजदीक लगी रेक की जानकारी देखें',
    bahiKhataCardText: 'अपना हिसाब किताब करें डिजिटल',
    distributorCardText: 'देखें अपने नजदीकी डिस्ट्रीब्यूटर्स की लिस्ट',
    dukaanPracharCardText:
      'ग्राहकों को 1000 फ्री SMS भेजकर करें अपनी दुकान का प्रचार',
    cropAdvisoryCardText: 'फसल सलाह लें और बढ़ायें फसलों की पैदावार',
    chooseRole: 'अपनी प्रोफ़ाइल चुनें',
    changeRole: 'अपना प्रोफाइल बदलें',
    smsFeature: 'दुकान प्रचार',
    sellCropPrimaryHeading: 'फसल बेचें',
    expenseManagerPrimaryHeading: 'फसल लागत कैलकुलेटर',
    mandiRatePrimaryHeading: 'मंडी रेट',
    distributorList: 'डिस्ट्रीब्यूटर्स',
    cropAdvisoryPrimaryHeading: 'फसल सलाह',
    cropAdvisoryPrimarySubHeading:
      'अपने फसल की देख रेख करें और पैदावार बढ़ायें',
    cropAdvisoryFomoPrimary: 'प्रतिदिन 1000 से अधिक फसल सलाह ली जाती है',
    cropAdvisoryPrimaryButton: 'सभी फसलों को देखें',
    subscribeNewCrop: 'फसल जोड़े',
    smsPrimaryHeading: 'दुकान प्रचार',
    smsPrimarySubHeading: 'दुकान प्रचार करेगा ग्राहकों से रिश्ता मजबूत',
    smsFomoPrimary: 'प्रतिदिन 10000+ संदेश भेजे जाते हैं',
    smsBalance: 'SMS बैलेंस :',
    smsPrimaryButton: 'दुकान का प्रचार करें',
    bahiKhataPrimarySubHeading: 'उधार का रिकॉर्ड हुआ आसान',
    bahiKhataFomoPrimary: 'प्रतिदिन 10000+ संदेश भेजे जाते हैं',
    bahiKhataCreditText: 'आपको देने हैं: ',
    bahiKhataDebitText: 'आपको मिलेगा:',
    bahiKhataPrimaryButton: 'उधार खाता जोड़े',
    rakePrimarySubHeading: 'अपने आस पास लगने वाली रेक देखें',
    rakeFomoPrimary: 'प्रतिदिन भारत में 600 से अधिक रेक लगती हैं',
    nerByrakePrimaryButton: 'आस पास मैं लगी हुई रेक देखें',
    rakePrimaryButton: 'रेक देखें',
    rakeUnavailable: '<districtName> में कोई रेक नहीं लगी हैं|',
    rakeAvailableHeading:
      'राष्ट्रीय रसायन एवं उर्वरक साइडिंग थाल वैशेत, पेन (आरसीएफ उज्ज्वला) एवं उर्वरक साइडिंग',
    rakeLocationUnavailableText: 'रेक की जानकारी के लिए लोकेशन की अनुमति दें|',
    rakeStatus: 'रेक रास्ते मैं हैं',
    rakeReached: 'रेक पहुँच चुकी हैं',
    weatherRain90: 'आज बारिश होने की संभावना काफी ज्यादा है।',
    weatherRain70: 'आज बारिश होने की संभावना ज्यादा है।',
    weatherRain30: 'आज बारिश होने की संभावना है।',
    weatherWind25: 'आज तेज हवा चलने की संभावना है।',
    weatherWind12: 'आज सामान्य गति से हवा चलने के आसार हैं।',
    weatherMaxTemp: 'आज काफी गर्मी पड़ सकती है।',
    weatherMinTemp: 'आज काफी ठंड पड़ सकती है।',
    weatherNoLocation: 'मौसम देखने के लिए अपने जिला दर्ज करें।',
    weatherNormal: 'आज का मौसम अच्छा रहेगा।',
    share: 'साझा करे',
    goodFoodEconomy: '#Good Food Economy',
    goodFoodLogoText:
      'Designed exclusively for 🇮🇳, crafted with ❤️ in Gurugram, India.',
    community: 'समुदाय',
    viewAllNewsFeed: 'और पोस्ट देखे',
    seeMore: '  ...और देखें',
    clap: 'ताली',
    comment: 'कमेंट',
    jan: 'जनवरी',
    feb: 'फ़रवरी',
    march: 'मार्च',
    april: 'अप्रैल',
    may: 'मई',
    june: 'जून',
    july: 'जुलाई',
    august: 'अगस्त',
    september: 'सितंबर',
    october: 'अक्टूबर',
    november: 'नवंबर',
    december: 'दिसंबर',
    changeLocationText: 'स्थान बदलें',
    weatherGreeting: 'नमस्कार',
    weatherWhatsappLink: 'मौसम की जानकारी देखें 👉',
    quiz: 'क्विज़',
    quizCardText: 'फारमार्ट पर क्विज़ खेलें और जीतें इनाम !',
    addFarmer: 'किसान जोड़ें',
    help: 'सहायता',
    gkQuizHeaderText: 'GK प्रतियोगिता',
    gkQuizMessageText: 'आपने जीके क्विज़ प्रतियोगिता सफलतापूर्वक पूरी कर ली है',
    gkQuizSubMessageText:
      'आपको अपना क्विज़ परिणाम जल्द ही व्हाट्सएप पर प्राप्त होगा',
    whatsappInstall: 'कृपया व्हाट्सएप इंस्टॉल करें',
    locationEnableText:
      'कृपया सेटिंग्स में जाएं, लोकेशन की अनुमति दें, और फिर से प्रयास करें।',
    refreshPageMessage: 'कृपया वेब साइट को पुनः ताज़ा करें',
  },
  [AppLanguages.appLanguagesIndex.english]: {
    whatYouDo: 'What you do?',
    applicableProfession: 'Choose whatever is applicable to you',
    retailer: 'Agri Input Retailing',
    retailerDescp: 'For Agri retailers or Agri consultants',
    distributer: 'Agri Trading',
    distributerDescp: 'For Traders, Market agents and others',
    farmer: 'Farming',
    farmerDescp: 'For each and every Farmers',
    downloadAppNow: 'Download the app now',
    notNow: 'Not now',
    getTheApp: 'Get the FarMart app for',
    rake: 'Rake',
    bahiKhata: 'Bahi Khata',
    distributor: 'Distributor',
    dukaanPrachar: 'Dukaan Prachar',
    cropAdvisory: 'Crop Advisory',
    rakeCardText: 'See the RAKE near your location',
    bahiKhataCardText: 'Maintaining your Bahi Kahta',
    distributorCardText: 'See the Distributor’s List near your location',
    dukaanPracharCardText:
      'Advertise your dukaan by sending free 1000 messages.',
    cropAdvisoryCardText: "Crop Advisory to improve your crop's yield",
    chooseRole: 'Choose your profile',
    changeRole: 'Change your role',
    smsFeature: 'Shop Promotion',
    sellCropPrimaryHeading: 'Sell Crop',
    expenseManagerPrimaryHeading: 'Crop Expense Calculator',
    mandiRatePrimaryHeading: 'Mandi Rate',
    distributorList: 'Distributors',
    cropAdvisoryPrimaryHeading: 'Crop Advisory',
    cropAdvisoryPrimarySubHeading: 'For better yield take care of your crop',
    cropAdvisoryFomoPrimary: 'Daily more than 1000-ton crops are sold',
    cropAdvisoryPrimaryButton: 'View all Crops',
    subscribeNewCrop: 'Add Crop',
    smsPrimaryHeading: 'Shop Promotion',
    smsPrimarySubHeading:
      'Shop Promotion will build your relation with the customer',
    smsFomoPrimary: '10000+ messages are sent daily',
    smsBalance: 'SMS Balance',
    smsPrimaryButton: 'Promote your shop',
    bahiKhataPrimarySubHeading: 'Credit Records are now easier',
    bahiKhataFomoPrimary: '10000+ messages are sent daily',
    bahiKhataCreditText: 'You have to pay:',
    bahiKhataDebitText: 'You will receive:',
    bahiKhataPrimaryButton: 'Add Credit',
    rakePrimarySubHeading: 'Find nearby rakes',
    rakeFomoPrimary: 'Daily 600+ rakes are transported',
    rakePrimaryButton: 'See Rake',
    nerByrakePrimaryButton: 'See nearby Rakes',
    rakeUnavailable: 'No rake in <districtName>',
    rakeAvailableHeading:
      'National Chemical & Fertilizer Siding, Pen (RCF Ujjwala) & Fertilizer Siding',
    rakeLocationUnavailableText: 'Allow location for rake information',
    rakeReached: 'Rake Reached',
    rakeStatus: 'Rake is on the way',
    weatherRain90: 'Chance of rainfall is extreme today',
    weatherRain70: 'Chance of rainfall is very high today',
    weatherRain30: 'Chance of rainfall is moderate today',
    weatherWind25: 'Wind speed might be high today',
    weatherWind12: 'Wind speed might be moderately high today',
    weatherMaxTemp: 'Temperature will be high today',
    weatherMinTemp: 'Temperature will be low today',
    weatherNoLocation: 'Give location access to see the weather',
    weatherNormal: 'Weather conditions will be good today',
    share: 'Share',
    goodFoodEconomy: '#Good Food Economy',
    goodFoodLogoText:
      'Designed exclusively for 🇮🇳, crafted with ❤️ in Gurugram, India.',
    community: 'Community',
    viewAllNewsFeed: 'View more posts',
    seeMore: '  ...See more',
    clap: 'Clap',
    comment: 'Comment',
    jan: 'January',
    feb: 'February',
    march: 'March',
    april: 'April',
    may: 'May',
    june: 'June',
    july: 'July',
    august: 'August',
    september: 'September',
    october: 'October',
    november: 'November',
    december: 'December',
    changeLocationText: 'Change Location',
    weatherGreeting: 'Namaste',
    weatherWhatsappLink: 'Check Weather Now 👉',
    quiz: 'Quiz',
    quizCardText: 'Play quiz with FarMart and win exciting rewards.',
    addFarmer: 'Add Farmer',
    help: 'Help',
    gkQuizHeaderText: 'GK Participation',
    gkQuizMessageText: 'You have successfully finished the GK Quiz competition',
    gkQuizSubMessageText: 'You will soon receive your quiz result on whatsapp',
    whatsappInstall: 'Please Install Whatsapp',
    locationEnableText:
      'Please enable location permission from settings and try again!',
    refreshPageMessage: 'Please refresh Web site again',
  },
};

export default (language) => languages[language];
