/* This TypeScript code snippet is creating a Map called `PlatForms` and populating it with key-value
pairs. The keys are strings representing different platforms ("application" and "website"), and the
values are corresponding abbreviations ("APP" and "WEB"). Finally, the `PlatForms` Map is being
exported as the default export from this module. */
const PlatForms = new Map();

PlatForms.set('application', 'APP');
PlatForms.set('website', 'WEB');

export default PlatForms;
