import React from 'react';
import Styles from './style.module.css';

import { SmsAcknowledgementProps } from '../../hooks/SmsAcknowledgementHooks/types';

import { Box } from '@mui/joy';
import AcknowledgementInfo from './AcknowledgementLabel/AcknowledgementInfo';
import ProceedButton from './ProceedButton/ProceedButton';

/**
 * Renders the AddFarmerView component.
 *
 * @param {AcknowledgementProps} props - The props for the AddFarmerView component.
 * @returns {JSX.Element} The rendered AddFarmerView component.
 */
export default function AddFarmerView(props: SmsAcknowledgementProps) {
  const { onPressSendNewSms, language, smsBalance, smsSent, smsId } = props;

  return (
    <Box className={Styles.container} bgcolor={'background.body'}>
      <AcknowledgementInfo
        language={language}
        smsBalance={smsBalance}
        smsSent={smsSent}
        smsId={smsId}
      />
      <ProceedButton
        language={language}
        onPressSendNewSms={onPressSendNewSms}
      />
    </Box>
  );
}
