import React from 'react';
import Styles from './style.module.css';
import Typography from '@mui/joy/Typography';
import { Box } from '@mui/material';

import ArrowBackRounded from '@mui/icons-material/ArrowBackRounded';
import MuiButton from '../../../ComponentsV3/MuiButton';
import { IconButton } from '@mui/joy';
import { InfoRounded } from '@mui/icons-material';

/* The `interface HeaderProps` is defining the props that the `Header` component expects to receive.
Here's what each property in the interface represents: */
interface HeaderProps {
  onPressBack: () => void;
  language: { [key: string]: string };
  onContactUs: () => void;
  onClickInfo: () => void;
}

export default function Header(props: HeaderProps) {
  const { onPressBack, language, onClickInfo, onContactUs } = props;
  return (
    <Box
      className={Styles.mainContainer}
      bgcolor={'background.body'}
      borderBottom={1}
      borderColor={'neutral.outlinedBorder'}
    >
      <Box className={Styles.locationContainer}>
        <ArrowBackRounded
          onClick={onPressBack}
          htmlColor="#171A1C"
          id="smsHistoryDetailHeaderBackId"
          data-testid="smsHistoryDetailHeaderBackTestId"
        />
        <Typography level="title-md" textColor={'text.primary'}>
          {language?.heading}
        </Typography>
      </Box>
      <Box display={'flex'} gap={'8px'}>
        <IconButton
          color="neutral"
          size="sm"
          variant="outlined"
          style={{ borderRadius: '8px' }}
          onClick={onClickInfo}
          id="smsHistoryDetailHeaderInfoId"
          data-testid="smsHistoryDetailHeaderInfoTestId"
        >
          <InfoRounded htmlColor="#32383E" />
        </IconButton>
        <MuiButton
          variant="soft"
          size="sm"
          color="warning"
          onClick={onContactUs}
          id="smsHistoryDetailHeaderHelpId"
          data-testid="smsHistoryDetailHeaderHelpTestId"
        >
          {language?.help}
        </MuiButton>
      </Box>
    </Box>
  );
}
