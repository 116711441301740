import React from 'react';
import MyFarmerView from '../../Pages/MyFarmers/MyFarmerView';

import MyFarmersHook from '../../hooks/MyFarmersHook/myFarmersHook';

export default function otpScreen() {
  const {
    onPressBack,
    language,
    onContactUs,
    farmers,
    searchParam,
    setSearchParam,
    toggleFarmerSelection,
    selectedFarmers,
    debouncedSetSearchParam,
    initialLoading,
    searchLoading,
    onPressDelete,
    onClickAddFarmer,
    isDeleteModalVisible,
    toggleDeleteModal,
    isErrorPopup,
    currentPage,
    hasMore,
    loadMoreFarmers,
    setCurrentPage,
  } = MyFarmersHook();
  return (
    <MyFarmerView
      onPressBack={onPressBack}
      language={language}
      onContactUs={onContactUs}
      farmers={farmers}
      searchParam={searchParam}
      setSearchParam={setSearchParam}
      toggleFarmerSelection={toggleFarmerSelection}
      selectedFarmers={selectedFarmers}
      debouncedSetSearchParam={debouncedSetSearchParam}
      initialLoading={initialLoading}
      searchLoading={searchLoading}
      onPressDelete={onPressDelete}
      onClickAddFarmer={onClickAddFarmer}
      toggleDeleteModal={toggleDeleteModal}
      isDeleteModalVisible={isDeleteModalVisible}
      isErrorPopup={isErrorPopup}
      currentPage={currentPage}
      hasMore={hasMore}
      loadMoreFarmers={loadMoreFarmers}
      setCurrentPage={setCurrentPage}
    />
  );
}
