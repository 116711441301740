// Spinner.tsx

import React from 'react';
import Styles from './style.module.css'; // Add your styling here or use a library for spinners

interface SpinnerProps {
  color?: string;
  size?: number;
  marginTop?: number;
  loaderWidth?: number;
}

const Loading: React.FC<SpinnerProps> = ({
  color = '#3498db',
  size = 50,
  marginTop = 0,
  loaderWidth = 4,
}) => {
  const spinnerStyle: React.CSSProperties = {
    border: `${loaderWidth}px solid ${color}`,
    borderTop: `${loaderWidth}px solid transparent`,
    borderRadius: '50%',
    width: `${size}px`,
    height: `${size}px`,
    marginTop: marginTop,
    alignSelf: 'center',
  };

  return <div className={Styles.spinner} style={spinnerStyle} />;
};

export default Loading;
