import React from 'react';
import Styles from './style.module.css';

const Header = ({
  language,
  onClickHelp,
  masterStateDistrict,
  onChangeLocation,
  whatsappIcon,
}) => {
  return (
    <div
      className={Styles.mainContainer}
      data-testid="tradeListHeaderContainer"
    >
      <div className={Styles.locationContainer} id="tradeListChangeLocation">
        <div className={Styles.loactionLabelContainer}>
          <div className={Styles.locationLabel}>{language?.trade}</div>
        </div>
      </div>

      <div
        onClick={onClickHelp}
        className={Styles.helpButtonContainer}
        data-testid="help-button-container"
      >
        <img
          className={Styles.whatsAppIcon}
          src={whatsappIcon}
          alt="Whatsapp Icon"
          height={16}
          width={16}
          loading="eager"
        />

        <label className={Styles.helpTextStyle}>{language?.help}</label>
      </div>
    </div>
  );
};

export default Header;
