/**
 * The `SmsCount` component displays the remaining SMS balance after deducting the selected count,
 * using localized strings for language support.
 * @param {SmsCountProps} props - The `SmsCount` component takes in three props:
 * @returns The `SmsCount` component is being returned. It displays a `Chip` component inside a `Box`
 * component. The `Chip` component shows the remaining SMS balance after deducting the selected count.
 */
import { Box } from '@mui/joy';
import { Chip } from '@mui/joy';
import React from 'react';

interface SmsCountProps {
  selectedCount: number;
  smsBalance: number;
  language: { [key: string]: string }; // Language object containing localized strings
}

export default function SmsCount(props: SmsCountProps) {
  const { selectedCount, smsBalance, language } = props;
  return (
    <Box
      display="flex"
      justifyContent={'center'}
      alignItems={'center'}
      marginTop={'16px'}
    >
      <Chip
        size="md"
        variant="soft"
        color="success"
        sx={{
          borderRadius: 4,
        }}
      >
        {language.smsBalanceLeft} {smsBalance - selectedCount}
      </Chip>
    </Box>
  );
}
