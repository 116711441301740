import React from 'react';
import style from './style.module.css';
import CardHeader from './../CardHeader/index';
import CardContent from '../CardContent';
import ImageGrid from '../../ImageGrid';
import Action from '../../Action';

interface CardProps {
  name: string;
  state: string;
  heading: string;
  clap: number;
  isClapped: boolean;
  comment: number;
  share: number;
  profilepic: string;
  postpic: string[];
  id: number;
  onClap: () => void;
  onComment: () => void;
  onShare: () => void;
  onViewPost;
  postedAgo: string;
  verifiedPost: boolean;
  postTypographyData: string;
  page: boolean;
  shortDescription: string;
  backgroundColor?: string;
  onClapButtonId?: string;
  onCommentButtonId?: string;
  onShareButtonId?: string;
  className?: string;
  language?: { [key: string]: any };
  companyProfileIcon: string;
  whatsappIcon: string;
  clapIcon: string;
  clapDoneIcon: string;
}

const Card = function (props: CardProps) {
  const {
    clap,
    comment,
    heading,
    name,
    postpic,
    profilepic,
    share,
    state,
    onClap,
    onComment,
    onShare,
    onViewPost,
    postedAgo,
    verifiedPost,
    page,
    shortDescription,
    isClapped,
    backgroundColor,
    id,
    onClapButtonId = '',
    onCommentButtonId = '',
    onShareButtonId = '',
    className,
    language,
    companyProfileIcon,
    whatsappIcon,
    clapIcon,
    clapDoneIcon,
  } = props;
  return (
    <div
      className={`${style.card} ${className}`}
      style={{ backgroundColor: `${backgroundColor}` }}
      id={`post-${id}`}
    >
      <div
        className={style.contentContainerCard}
        onClick={onViewPost}
        id={`contentContainerCard-${id}`}
      >
        <CardHeader
          name={name}
          profilepic={profilepic}
          state={state}
          postedAgo={postedAgo}
          verifiedPost={verifiedPost}
          page={page}
          companyProfileIcon={companyProfileIcon}
        />
        <CardContent
          shortDescription={shortDescription}
          heading={heading}
          language={language}
        />
        <ImageGrid images={[postpic[0]]} />
      </div>
      <div className={style.emptyLine}></div>
      <Action
        onClap={onClap}
        isClapped={isClapped}
        clap={clap}
        onComment={onComment}
        comment={comment}
        onShare={onShare}
        share={share}
        backgroundColor={backgroundColor}
        onClapButtonId={onClapButtonId}
        onCommentButtonId={onCommentButtonId}
        onShareButtonId={onShareButtonId}
        language={language}
        whatsappIcon={whatsappIcon}
        clapIcon={clapIcon}
        clapDoneIcon={clapDoneIcon}
      />
    </div>
  );
};

export default Card;
