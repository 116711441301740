import React from 'react';
import { Box } from '@mui/material';
import { PinCodeDataObj } from '../../../hooks/PinCode/types';
import NoLocation from './../NoLocation/NoLocation';
import LocationCard from '../LocationCard/LocationCard';
import MuiSkeleton from '../../../MuiComponents/Skeleton';

interface LocationListProps {
  pinCodeList?: PinCodeDataObj[];
  totalPinCodeCounts?: number;
  loading?: boolean;
  onClickLocation?: (selectedLocation: PinCodeDataObj) => void;
  language: { [key: string]: string };
  pinCodeSearchValue: string;
  noLocationImage: string;
}

export default function LocationList(props: LocationListProps) {
  const { loading, pinCodeList, onClickLocation, language, noLocationImage } =
    props;
  return (
    <Box>
      {loading ? (
        <>
          <Box
            display="flex" // Use flexbox for alignment
            justifyContent="center" // Center horizontally
            alignItems="center" // Center vertically
            flexDirection="column"
            gap={'2px'}
          >
            <MuiSkeleton
              itemNumber={10}
              variant="rectangular"
              sx={{
                height: '70px',
                width: '100%', // This will take full width of the Box
              }}
            />
          </Box>
        </>
      ) : pinCodeList?.length === 0 ? (
        <NoLocation language={language} noLocationImage={noLocationImage} />
      ) : (
        <>
          {pinCodeList?.map((item, index) => {
            return (
              <LocationCard
                item={item}
                key={index}
                index={index}
                onClickLocation={onClickLocation}
              />
            );
          })}
        </>
      )}
    </Box>
  );
}
