import React, { useEffect, useState } from 'react';
import { Crop, CropVariety, Location } from '../../modal/Traders/types';
import getCropList from '../../modal/Traders/models/CropList';
import getCropVarietyList from '../../modal/Traders/models/CropVarietyList';
import getBuyerLocationList from '../../modal/Traders/models/BuyerLocationList';
import { FilterType } from './types';
import { trackEvent } from '../../utils/moengage';
import Events from '../../constant/MoengageEvents';
import { usePlatfromData } from '../CrossPlatformActionHandler/platformChecker';
import { unVerifiedRoutes } from '../../constant/unVerifiedRoutes';
import { loginAsGuest } from '../../modal/auth/auth.model';
import { useAuth } from '../../context/login';

export default function UseFilterValues(
  setTradeListPageNumber,
  setBuyerList,
  setActiveTradeList,
  setExpiredTradeList,
): FilterType {
  const [guestLoginAttempts, setGuestLoginAttempts] = useState<number>(0);
  const platformData = usePlatfromData();
  const auth = useAuth();
  const [cropList, setCropList] = useState<Crop[]>([
    {
      id: null,
      crop_name: 'Crops',
    },
  ]);

  const [cropVarietyList, setCropVarietyList] = useState<CropVariety[]>([
    {
      id: null,
      crop_variety_name: 'Variety',
    },
  ]);

  const [locationList, setLocationList] = useState<Location[]>([
    {
      state_name: 'Locations',
      key_concat: null,
      value_concat: 'Locations',
    },
  ]);

  const [cropListSearchParam, setCropListSearchParam] = useState<string>('');
  const [cropListPage, setCropListPage] = useState<number>(1);
  const [cropListLimit, setCropListLimit] = useState<number>(10);

  const [cropVarietyListCropId, setCropVarietyListCropId] =
    useState<number>(null);
  const [cropVarietyListSearchParam, setCropVarietyListSearchParam] =
    useState<string>('');
  const [cropVarietyListPage, setVarietyCropListPage] = useState<number>(1);
  const [cropVarietyListLimit, setCropVarietyListLimit] = useState<number>(10);

  const [buyerLocationListSearchParam, setBuyerLocationListSearchParam] =
    useState<string>('');
  const [buyerLocationListPage, setBuyerLocationListPage] = useState<number>(1);
  const [buyerLocationListLimit, setBuyerLocationListLimit] =
    useState<number>(10);

  const [cropListModal, setCropListModal] = useState<boolean>(false);
  const [activeVariety, setActiveVariety] = useState<CropVariety>({
    id: null,
    crop_variety_name: 'Variety',
  });
  const [activeCrop, setActiveCrop] = useState<Crop>({
    id: null,
    crop_name: 'Crops',
  });
  const [activeLocations, setActiveLocations] = useState<string[]>([null]);
  const [cropLoading, setCropLoading] = useState<boolean>(false);
  const [cropVarietyLoading, setCropVarietyLoading] = useState<boolean>(false);
  const [locationLoading, setLocationLoading] = useState<boolean>(false);
  const [cropVarietyListModal, setCropVarietyListModal] =
    useState<boolean>(false);
  const [buyerLocationListModal, setBuyerLocationListModal] =
    useState<boolean>(false);
  const [applyLocationCheck, setApplyLocationCheck] = useState<boolean>(false);
  const [locationListCount, setLocationListCount] = useState<number>(0);

  const handleCropListModal = () => {
    if (!cropListModal) {
      getCropFilterValues();
    } else {
      trackEvent(Events.onCropFilterCloseButtonClick, {});
    }
    setCropListModal(!cropListModal);
    setCropList([
      {
        id: null,
        crop_name: 'Crops',
      },
    ]);
    setCropListSearchParam('');
  };

  const handleCropVarietyListModal = () => {
    if (!cropVarietyListModal) {
      getCropVarietyValues();
    } else {
      trackEvent(Events.onVarityFilterCloseButtonClick, {});
    }

    setCropVarietyListModal(!cropVarietyListModal);
    setCropVarietyList([
      {
        id: null,
        crop_variety_name: 'Variety',
      },
    ]);
    setCropVarietyListSearchParam('');
  };

  const handleBuyerLocationListModal = () => {
    if (buyerLocationListModal) {
      trackEvent(Events.onSelectLocationCloseFilterButtonClick, {});
    }
    setLocationList([
      {
        state_name: 'Locations',
        key_concat: null,
        value_concat: 'Locations',
      },
    ]);
    setBuyerLocationListPage(1);
    setBuyerLocationListModal(!buyerLocationListModal);
    setBuyerLocationListSearchParam('');
    setLocationListCount(0);
  };

  const handleCropSelection = (cropId: Crop) => {
    if (cropId?.id === null || cropId.id !== activeCrop?.id) {
      setActiveVariety({
        id: null,
        crop_variety_name: 'Variety',
      });
    }
    trackEvent(Events.onSelectCrop, {});

    setTradeListPageNumber(1);
    setBuyerList([]);
    setExpiredTradeList([]);
    setActiveTradeList([]);
    setActiveCrop(cropId);
    setCropListModal(false);
    setCropList([
      {
        id: null,
        crop_name: 'Crops',
      },
    ]);
    setCropListSearchParam('');
  };

  const handleVarietySelection = (cropVarietyId: CropVariety) => {
    trackEvent(Events.onSelectVarity, {});

    setTradeListPageNumber(1);
    setBuyerList([]);
    setExpiredTradeList([]);
    setActiveTradeList([]);
    setActiveVariety(cropVarietyId);
    setCropVarietyListModal(false);
    setCropVarietyList([
      {
        id: null,
        crop_variety_name: 'Variety',
      },
    ]);
    setCropVarietyListSearchParam('');
  };

  const handleLocationSelection = (locationId: string) => {
    trackEvent(Events.onSelectLocation, {});

    if (locationId) {
      if (activeLocations.includes(null)) {
        setActiveLocations(
          activeLocations
            .filter((element) => element !== null)
            .concat(locationId),
        );
      } else {
        setActiveLocations(
          activeLocations.includes(locationId)
            ? activeLocations?.length === 1
              ? activeLocations
                  .filter((element) => element !== locationId)
                  .concat(null)
              : activeLocations.filter((element) => element !== locationId)
            : [...activeLocations, locationId],
        );
      }
    } else {
      setActiveLocations([null]);
    }
  };

  const handleCropSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value: string = event.target.value;
    if (value) {
      setCropList([]);
      setCropListSearchParam(value);
    } else {
      setCropList([
        {
          id: null,
          crop_name: 'Crops',
        },
      ]);
      setCropListSearchParam(value);
    }
  };

  const handleCropVarietySearch = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const value: string = event.target.value;
    if (value) {
      setCropVarietyList([]);
      setCropVarietyListSearchParam(value);
    } else {
      setCropVarietyList([
        {
          id: null,
          crop_variety_name: 'Variety',
        },
      ]);
      setCropVarietyListSearchParam(value);
    }
  };

  const handleLocationSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value: string = event.target.value;
    if (value) {
      setLocationList([]);
      setLocationListCount(0);
      setBuyerLocationListPage(1);
      setBuyerLocationListSearchParam(value);
    } else {
      setLocationList([
        {
          state_name: 'Locations',
          key_concat: null,
          value_concat: 'Locations',
        },
      ]);
      setLocationListCount(0);
      setBuyerLocationListPage(1);
      setBuyerLocationListSearchParam(value);
    }
  };

  const getCropFilterValues = async () => {
    setCropLoading(true);
    try {
      const cropListResponse = await getCropList({
        search_param: cropListSearchParam,
        page: cropListPage,
      });
      setCropList([...cropList, ...cropListResponse?.crops]);
      setCropLoading(false);
      setGuestLoginAttempts(0);
    } catch (error) {
      setCropLoading(false);
      if (error?.code === 401 || error?.code === 426) {
        if (platformData.platform === 'WEB') {
          if (unVerifiedRoutes?.guestLogin?.includes(error?.path)) {
            if (guestLoginAttempts < 3) {
              await loginAsGuest();
              setGuestLoginAttempts((lastValue) => {
                return lastValue + 1;
              });
            }
          } else {
            auth.setMobileNumberModalVisible(true);
          }
        }
      }
    }
  };

  const getCropVarietyValues = async () => {
    setCropVarietyLoading(true);
    try {
      const cropVerietyResponse = await getCropVarietyList({
        crop_id: activeCrop.id,
        search_param: cropVarietyListSearchParam,
        page: cropVarietyListPage,
      });
      setGuestLoginAttempts(0);
      setCropVarietyList([
        ...cropVarietyList,
        ...cropVerietyResponse?.crop_varieties,
      ]);
      setCropVarietyLoading(false);
    } catch (error) {
      setCropVarietyLoading(false);
      if (error?.code === 401 || error?.code === 426) {
        if (platformData.platform === 'WEB') {
          if (unVerifiedRoutes?.guestLogin?.includes(error?.path)) {
            if (guestLoginAttempts < 3) {
              await loginAsGuest();
              setGuestLoginAttempts((lastValue) => {
                return lastValue + 1;
              });
            }
          } else {
            auth.setMobileNumberModalVisible(true);
          }
        }
      }
      console.log(error);
    }
  };

  const getBuyerLocationValues = async () => {
    setLocationLoading(true);
    try {
      const buyerLocationResponse = await getBuyerLocationList({
        page_no: buyerLocationListPage,
        limit: buyerLocationListLimit,
        search_param: buyerLocationListSearchParam,
      });
      setGuestLoginAttempts(0);
      setLocationList([
        ...locationList,
        ...buyerLocationResponse?.location_list,
      ]);
      setLocationListCount(buyerLocationResponse?.total_records);
      setLocationLoading(false);
    } catch (error) {
      setLocationLoading(false);
      if (error?.code === 401 || error?.code === 426) {
        if (platformData.platform === 'WEB') {
          if (unVerifiedRoutes?.guestLogin?.includes(error?.path)) {
            if (guestLoginAttempts < 3) {
              await loginAsGuest();
              setGuestLoginAttempts((lastValue) => {
                return lastValue + 1;
              });
            }
          } else {
            auth.setMobileNumberModalVisible(true);
          }
        }
      }
    }
  };

  function onClickLocationFilterApply() {
    setBuyerLocationListPage(1);
    setTradeListPageNumber(1);
    setBuyerList([]);
    setExpiredTradeList([]);
    setActiveTradeList([]);
    setBuyerLocationListModal(false);
    trackEvent(Events.onLocationFilterApplyButtonClick, {});

    setApplyLocationCheck(!applyLocationCheck);
  }

  const loadMoreLocationList = () => {
    if (locationListCount > (locationList?.length ?? 0)) {
      setBuyerLocationListPage((lastValue) => {
        return lastValue + 1;
      });
    }
  };

  useEffect(
    function () {
      cropListModal && getCropFilterValues();
    },
    [cropListSearchParam, guestLoginAttempts],
  );

  useEffect(
    function () {
      cropVarietyListModal && activeCrop?.id && getCropVarietyValues();
    },
    [cropVarietyListSearchParam, guestLoginAttempts],
  );

  useEffect(
    function () {
      buyerLocationListModal && getBuyerLocationValues();
    },
    [
      buyerLocationListSearchParam,
      buyerLocationListPage,
      buyerLocationListModal,
      guestLoginAttempts,
    ],
  );

  return {
    cropList,
    cropVarietyList,
    locationList,
    cropListSearchParam,
    setCropListSearchParam,
    cropListPage,
    setCropListPage,
    cropListLimit,
    setCropListLimit,
    cropVarietyListCropId,
    setCropVarietyListCropId,
    cropVarietyListSearchParam,
    setCropVarietyListSearchParam,
    cropVarietyListPage,
    setVarietyCropListPage,
    cropVarietyListLimit,
    setCropVarietyListLimit,
    buyerLocationListSearchParam,
    setBuyerLocationListSearchParam,
    buyerLocationListPage,
    setBuyerLocationListPage,
    buyerLocationListLimit,
    setBuyerLocationListLimit,
    cropListModal,
    handleCropListModal,
    cropVarietyListModal,
    handleCropVarietyListModal,
    buyerLocationListModal,
    handleBuyerLocationListModal,
    activeVariety,
    activeCrop,
    handleCropSelection,
    handleVarietySelection,
    cropLoading,
    cropVarietyLoading,
    activeLocations,
    handleLocationSelection,
    locationLoading,
    handleCropSearch,
    onClickLocationFilterApply,
    handleCropVarietySearch,
    handleLocationSearch,
    applyLocationCheck,
    loadMoreLocationList,
  };
}
