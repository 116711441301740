import validateOtp from '../../../apis/Trader/validateOtp';

import { ValidateOtp } from '../types';

export default async function ValidationOtp(
  payload = {},
): Promise<ValidateOtp> {
  const body = {
    ...payload,
  };
  try {
    const result = await validateOtp(body);

    if (!result.data.status) {
      throw result;
    }

    return result.data.data;
  } catch (error) {
    if (!error.message) {
      error.message = 'Something Went Wrong';
    }

    throw error;
  }
}
