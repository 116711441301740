import React from 'react';
import { Box } from '@mui/material';
import MuiButton from '../../../ComponentsV3/MuiButton';
import Add from '@mui/icons-material/Add';

/* The `interface ManualModalButtonProps` is defining the structure of the props that the
`ManualAddFarmer` component expects to receive. It specifies that the component should receive two
props: */
interface ManualModalButtonProps {
  onToggleManualModal: () => void;
  language: { [key: string]: string };
}
export default function ManualAddFarmer(props: ManualModalButtonProps) {
  /* `const { onToggleManualModal, language } = props;` is a destructuring assignment in JavaScript. In
  this case, it is extracting the `onToggleManualModal` and `language` properties from the `props`
  object passed to the `ManualAddFarmer` component. */
  const { onToggleManualModal, language } = props;
  return (
    <Box
      display={'flex'}
      margin={'16px 16px 0px 16px'}
      onClick={onToggleManualModal}
    >
      <MuiButton
        variant="outlined"
        size="lg"
        color="primary"
        startDecorator={<Add data-testid={'addIconTestId'} />}
      >
        {language?.manualFarmerButton}
      </MuiButton>
    </Box>
  );
}
