import React from 'react';
import Styles from './style.module.css';

interface AddToHomePersistantProp {
  handleInstallClick: () => void;
  farmartLogo: string;
}
const AddToHomePersistant = (props: AddToHomePersistantProp) => {
  const { handleInstallClick, farmartLogo } = props;
  return (
    <div
      id="pwaInstallWithDescriptionButtonId"
      onClick={handleInstallClick}
      className={Styles.AddToHomePersistantContainer}
    >
      <div className={Styles.farMartContainer}>
        <img
          className={Styles.fmtImage}
          src={farmartLogo}
          alt="farmartImage"
          loading="eager"
        />
        <div className={Styles.farMartText}>
          <span className={Styles.farMartTextHeading}>FarMart समुदाय</span>
          <span className={Styles.farMartTextSubHeading}>
            Building the good food economy
          </span>
        </div>
      </div>
      <div>
        <button className={Styles.addToHomePersistantContainer}>
          ADD TO HOME
        </button>
      </div>
    </div>
  );
};
export default AddToHomePersistant;
