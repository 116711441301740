import React from 'react';
import SmsHistoryView from '../../Pages/MarketingSmsHistory/SmsHistoryView';

import SmsHistoryHook from '../../hooks/SmsHistoryHook/smsHistoryHook';

export default function AddFarmerScreen() {
  const {
    onPressBack,
    language,
    onContactUs,
    smsHistoryData,
    onClickInfo,
    onClickSms,
    smsBalance,
    initialLoading,
    onCloseModal,
    showShopPromotionModal,
    fetchMoreData,
  } = SmsHistoryHook();
  return (
    <SmsHistoryView
      onPressBack={onPressBack}
      language={language}
      onContactUs={onContactUs}
      smsHistoryData={smsHistoryData}
      onClickInfo={onClickInfo}
      onClickSms={onClickSms}
      smsBalance={smsBalance}
      initialLoading={initialLoading}
      onCloseModal={onCloseModal}
      showShopPromotionModal={showShopPromotionModal}
      fetchMoreData={fetchMoreData}
    />
  );
}
