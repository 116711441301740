import React from 'react';
import Login from './Login';
import Otp from './Otp';

export default function TradeLogin({ authProps }) {
  return (
    <>
      <Login
        onClickLoginProceed={authProps.onClickLoginProceed}
        mobileNumberModalVisible={authProps.mobileNumberModalVisible}
        getMobileNumber={authProps.getMobileNumber}
        error={authProps.error}
        loading={authProps.loading}
        language={authProps?.language}
        userBlocked={authProps.userBlocked}
        mobileNumber={authProps.mobileNumber}
      />
      <Otp
        otpModalVisible={authProps.otpModalVisible}
        getUserOtpFromInput={authProps.getUserOtpFromInput}
        userOtp={authProps.userOtp}
        onClickValidateOtpProceed={authProps.onClickValidateOtpProceed}
        changeMobileNumber={authProps.changeMobileNumber}
        resendOtp={authProps.resendOtp}
        error={authProps.error}
        loading={authProps.loading}
        userMobileNumber={authProps.userMobileNumber}
        language={authProps?.language}
        disableResend={authProps.disableResend}
        setDisableResend={authProps.setDisableResend}
      />
    </>
  );
}
