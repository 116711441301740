import React from 'react';
import Styles from './style.module.css';

import Modal from '../../../ComponentV2/Modal';
import Button from '../../../ComponentV2/Button';

export interface LocationProps {
  onClickAllowLocationModal: () => void;
  isLocationModal: boolean;
  locationModalLoading: boolean;
  language: { [key: string]: any };
  locationImage: string;
}

const Header = (props: LocationProps) => {
  const {
    onClickAllowLocationModal,
    isLocationModal,
    locationModalLoading,
    language,
    locationImage,
  } = props;
  return (
    <Modal
      isOpen={isLocationModal}
      children={
        <div className={Styles.mainContainer}>
          <div className={Styles.imageContainer}>
            <img
              className={Styles.locationImage}
              src={locationImage}
              alt="Location Image"
              loading="eager"
            />
          </div>
          <div className={Styles.labelContainer}>
            <label className={Styles.locationModalHeading}>
              {language?.allowLocation}
            </label>
            <label className={Styles.locationModalSubHeading}>
              {language?.allowLocationHeading}
            </label>
          </div>
          <div className={Styles.buttonWrapper}>
            <Button
              title={language?.allowLocation}
              onClick={onClickAllowLocationModal}
              loading={locationModalLoading}
            />
          </div>
        </div>
      }
      title={language?.locationModalHeading}
    />
  );
};

export default Header;
