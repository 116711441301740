import ScreenType from '../../constant/screenEnums';
import createStack from '../../utils/dataStructure/stack';
import { useEffect } from 'react';
/* this stack will contain id of clicked page 
    from the home screen and post detail screen
    right now only home screen is implemented
    in future post detail screen will also be implemented
    in this stack
    we are importing and creating the stack in global scope to
    impleament singleton pattern
*/
const visitedPageIdHistory = createStack<number>();

interface Props {
  screen?: number;
}

export default function useVisitedPostHistory(props?: Props) {
  const { screen } = props || {};

  useEffect(function () {
    try {
      if (screen && ScreenType.get('homeScreen')) {
        const postId = getPreviousPostId();

        if (postId) {
          document.getElementById(`post-${postId}`)?.scrollIntoView();
        }
      }
    } catch (error) {
      console.log(error);
    }
  }, []);

  function onPostClick(id: number): void {
    visitedPageIdHistory.push(id);
  }

  function getPreviousPostId(): number {
    return visitedPageIdHistory.pop();
  }

  return {
    onPostClick,
    getPreviousPostId,
  };
}
