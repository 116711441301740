import React, { useEffect, useState, lazy, Suspense } from 'react';
import { useDispatch } from 'react-redux';
import { RootState } from '../../store';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import getLang from '../../languages/navBar';
import { apiEndPoints } from '../../constant/apiEndPoints';
import useAuthenticationChecker from '../../utils/authChecker';
import { userType } from '../../constant/enums/userType';
import { appLanguages } from '../../constant/appLanguages';
import { setUserPersona } from '../../redux/slices/personaSlice';
import MuiSkeleton from '../../MuiComponents/Skeleton';

// Use React lazy for code splitting and lazy loading
const TradeIcon = lazy(() => import('../../assets/svgComponet/TradeIcon'));
const TradeIconActive = lazy(
  () => import('../../assets/svgComponet/TradeIconActive'),
);
const CommunityIcon = lazy(
  () => import('../../assets/svgComponet/CommunityIcon'),
);
const CommunityIconActive = lazy(
  () => import('../../assets/svgComponet/CommunityIconActive'),
);
const SMSIcon = lazy(() => import('../../assets/svgComponet/SMSIcon'));
const SMSIconActive = lazy(
  () => import('../../assets/svgComponet/SMSIconActive'),
);
const HomeScreenActiveIcon = lazy(
  () => import('../../assets/svgComponet/HomeScreenActiveIcon'),
);
const HomeScreenInActiveIcon = lazy(
  () => import('../../assets/svgComponet/homeScreenInActive'),
);
const ActiveTruckIcon = lazy(
  () => import('../../assets/svgComponet/ActiveTruckIcon'),
);
const InActiveTruckIcon = lazy(
  () => import('../../assets/svgComponet/InActiveTruckIcon'),
);

interface BottomNavigationBarProps {
  isRetailer: boolean;
}

export default function BottomNavigationBar({
  isRetailer,
}: BottomNavigationBarProps) {
  const location = useLocation();
  // const dispatch = useDispatch();
  const [currentPath, setCurrentPath] = useState(location.pathname);
  const [width, setWindowWidth] = useState(window.innerWidth);
  const langId = JSON.parse(localStorage.getItem('userLanguage'));
  // const loggedIn = JSON.parse(localStorage.getItem("loged"));
  let activeSystemLang = Intl.DateTimeFormat().resolvedOptions().locale;
  // Determine the system language code and backend language code based on whether the language is English
  let systemLanguage = activeSystemLang?.includes('en')
    ? appLanguages[1].langCode
    : appLanguages[0].langCode;

  const selectedLanguage = langId?.langCode ? langId?.langCode : systemLanguage;
  const language = getLang(selectedLanguage || 'HI');
  // const { AuthenticationCheckerAndApiCaller } = useAuthenticationChecker();
  // const [isRetailer, setIsRetailer] = useState(false);
  // const [userSelectedPersona, setUserSelectedPersona] = useState(
  //   useSelector((state: RootState) => state.persona.userSelectedPersona)
  // );

  // useEffect(() => {
  //   if (loggedIn) {
  //     fetchMyProfile();
  //   }
  //   setIsRetailer(Number(userSelectedPersona) === userType["retailer"]);
  // }, [userSelectedPersona]);

  useEffect(() => {
    setCurrentPath(location?.pathname);
  }, [location?.pathname]);

  useEffect(() => {
    // Function to update window width state
    const handleResize = () => {
      setWindowWidth(window?.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [width]);

  // const handlePersonaChange = (newPersona: string) => {
  //   dispatch(setUserPersona(newPersona));
  // };

  // async function fetchMyProfile() {
  //   try {
  //     const queryParameter = {};
  //     const response = await AuthenticationCheckerAndApiCaller({
  //       uri: apiEndPoints?.getMerchantProfile,
  //       requestType: "post",
  //       payload: queryParameter,
  //     });
  //     if (response?.data?.status) {
  //       setIsRetailer(
  //         response?.data?.data?.merchant_occupation_id === userType["retailer"]
  //       );
  //       handlePersonaChange(response?.data?.data?.merchant_occupation_id);
  //     }
  //   } catch (error) {
  //     console.error(error);
  //   }
  // }

  const calculatedWidth = () => {
    if (width <= 480) {
      return {
        // Styles for max-width: 480px
        width: '100vw',
      };
    } else if (width >= 481 && width <= 767) {
      return {
        // Styles for min-width: 481px and max-width: 767px
        width: '100vw',
      };
    } else if (width >= 768 && width <= 1024) {
      return {
        // Styles for min-width: 768px and max-width: 1024px
        width: '100vw',
      };
    } else if (width >= 1025 && width <= 1280) {
      return {
        // Styles for min-width: 1025px and max-width: 1280px
        width: '30vw',
        margin: '0vw 35vw',
      };
    } else {
      return {
        // Styles for min-width: 1281px
        width: '30vw',
        margin: '0vw 35vw',
      };
    }
  };

  const activeIconStyle = () => {
    return {
      backgroundColor: '#008B74',
      borderRadius: '16px',
      background: 'var(--Primary-primaryGreen, #008B74)',
    };
  };

  const activeLabelStyle = () => {
    return {
      color: '#008B74',
    };
  };

  const skeleton = () => (
    <MuiSkeleton
      sx={{
        borderRadius: '16px',
        height: '32px',
        width: '64px',
      }}
    />
  );

  return (
    <nav
      className="bottom-navigation-bar"
      style={{
        ...calculatedWidth(),
      }}
    >
      <div className="bottom-navigation-bar-container">
        <Link className="link-wrapper" to="/">
          <div
            className="icon-container"
            style={{
              ...(currentPath === '/' ? activeIconStyle() : {}),
            }}
          >
            <Suspense fallback={skeleton()}>
              {currentPath === '/' ? (
                <HomeScreenActiveIcon />
              ) : (
                <HomeScreenInActiveIcon />
              )}
            </Suspense>
          </div>
          <label
            className="labelInactive"
            style={{
              ...(currentPath === '/' ? activeLabelStyle() : {}),
            }}
          >
            {language?.home}
          </label>
        </Link>
        {isRetailer && (
          <Link className="link-wrapper" to="/shopPromotion">
            <div
              className="icon-container"
              style={{
                ...(currentPath === '/shopPromotion' ? activeIconStyle() : {}),
              }}
            >
              <Suspense fallback={skeleton()}>
                {currentPath === '/shopPromotion' ? (
                  <SMSIconActive />
                ) : (
                  <SMSIcon />
                )}
              </Suspense>
            </div>
            <label
              className="labelInactive"
              style={{
                ...(currentPath === '/shopPromotion' ? activeLabelStyle() : {}),
              }}
            >
              {language?.sms}
            </label>
          </Link>
        )}
        <Link className="link-wrapper" to="/community">
          <div
            className="icon-container"
            style={{
              ...(currentPath === '/community' ? activeIconStyle() : {}),
            }}
          >
            <Suspense fallback={skeleton()}>
              {currentPath === '/community' ? (
                <CommunityIconActive />
              ) : (
                <CommunityIcon />
              )}
            </Suspense>
          </div>
          <label
            className="labelInactive"
            style={{
              ...(currentPath === '/community' ? activeLabelStyle() : {}),
            }}
          >
            {language?.community}
          </label>
        </Link>

        <Link className="link-wrapper " to="/trades">
          <div
            className="icon-container"
            style={{
              ...(currentPath === '/trades' ? activeIconStyle() : {}),
            }}
          >
            <Suspense fallback={skeleton()}>
              {currentPath === '/trades' ? <TradeIconActive /> : <TradeIcon />}
            </Suspense>
          </div>
          <label
            className="labelInactive"
            style={{
              ...(currentPath === '/trades' ? activeLabelStyle() : {}),
            }}
          >
            {language?.trade}
          </label>
        </Link>
        <Link className="link-wrapper " to="/trades/mySauda">
          <div
            className="icon-container"
            style={{
              ...(currentPath === '/trades/mySauda' ? activeIconStyle() : {}),
            }}
          >
            <Suspense fallback={skeleton()}>
              {currentPath === '/trades/mySauda' ? (
                <ActiveTruckIcon />
              ) : (
                <InActiveTruckIcon />
              )}
            </Suspense>
          </div>
          <label
            className="labelInactive"
            style={{
              ...(currentPath === '/trades/mySauda' ? activeLabelStyle() : {}),
            }}
          >
            {language?.mySauda}
          </label>
        </Link>
        {/* <div className="app-link" onClick={onCLickDownloadLink}>
          {selectedLanguage === appLanguages[0].langCode ? (
            <BottomTabDownloadLinkHindi />
          ) : (
            <BottomTabDownloadLink />
          )}
        </div> */}
        {/* Add more navigation items as needed */}
      </div>
    </nav>
  );
}
