import React from 'react';
import SMSTemplateScreen from '../../Pages/SMSTemplateScreen/SMSTemplateScreen';
import SMSTemplateScreenHook from '../../hooks/SMSTemplateHook/SMSTemplateHook';

export default function SMSTemplate() {
  const {
    language,
    smsTypeId,
    loading,
    headerTitle,
    onClickBack,
    smsTemplateMessage,
    remainingSMS,
    onClickSend,
    inputList,
    inputValues,
    setInputValues,
    showRateModal,
    showSingleSelectModal,
    singleSelectLoading,
    onCloseRateModal,
    handleInputChange,
    handleInputClick,
    allInputsFilled,
    inputVariables,
    handleDateChange,
    handleRadioButtonSelection,
    handleTextareaChange,
    radioselectedValue,
    calendarVisibility,
    setCalendarVisibility,
    dateStates,
    handleSelectedItem,
    handleModalClose,
    searchTerm,
    setSearchTerm,
    filteredItems,
    setPage,
  } = SMSTemplateScreenHook();
  return (
    <SMSTemplateScreen
      language={language}
      smsTypeId={smsTypeId}
      loading={loading}
      headerTitle={headerTitle}
      onClickBack={onClickBack}
      smsTemplateMessage={smsTemplateMessage}
      remainingSMS={remainingSMS}
      onClickSend={onClickSend}
      inputList={inputList}
      inputValues={inputValues}
      setInputValues={setInputValues}
      showRateModal={showRateModal}
      showSingleSelectModal={showSingleSelectModal}
      singleSelectLoading={singleSelectLoading}
      onCloseRateModal={onCloseRateModal}
      handleInputChange={handleInputChange}
      handleInputClick={handleInputClick}
      allInputsFilled={allInputsFilled}
      inputVariables={inputVariables}
      handleDateChange={handleDateChange}
      handleRadioButtonSelection={handleRadioButtonSelection}
      handleTextareaChange={handleTextareaChange}
      dateStates={dateStates}
      radioselectedValue={radioselectedValue}
      calendarVisibility={calendarVisibility}
      setCalendarVisibility={setCalendarVisibility}
      handleModalClose={handleModalClose}
      handleSelectedItem={handleSelectedItem}
      searchTerm={searchTerm}
      setSearchTerm={setSearchTerm}
      filteredItems={filteredItems}
      setPage={setPage}
    />
  );
}
