import React from 'react';
import { Box, Button } from '@mui/joy';
import Modal from '../../../ComponentV2/Modal';

// Define the interface for the props
interface PromotionalMediaModalProps {
  promotionalMedia: { media_url: string }[];
  showMediaModal: boolean;
  onCloseMediaModal: () => void;
  language: { [key: string]: any };
}

export default function PromotionalMediaModal({
  promotionalMedia,
  showMediaModal,
  onCloseMediaModal,
  language,
}: PromotionalMediaModalProps) {
  const hasMedia = promotionalMedia && promotionalMedia?.length > 0;
  const videoUrl = hasMedia ? promotionalMedia[0]?.media_url : null;

  const modalContent = (
    <Box marginY={5} marginX={4}>
      <Box marginBottom={5} width="100%">
        <video
          src={videoUrl}
          controls
          autoPlay
          style={{ width: '100%', borderRadius: 4 }}
          data-testid="promotional-video"
        />
      </Box>
      <Button
        fullWidth
        sx={{ borderRadius: 6, height: 40 }}
        onClick={onCloseMediaModal}
        id={'smsLandingPromotionalModalOnClickOk'}
      >
        {language?.okay}
      </Button>
    </Box>
  );
  return (
    <Modal
      isOpen={showMediaModal}
      title={language?.promotionalMediaContainerHeading}
      onClose={onCloseMediaModal}
      id={'smsLandingModalId'}
    >
      {modalContent}
    </Modal>
  );
}
