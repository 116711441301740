import * as AppLanguages from '../constant/appLanguages';
//language will be added here
const languages = {
  [AppLanguages.appLanguagesIndex.hindi]: {
    heading: 'आपके किसान',
    searchText: 'किसान ढूंढे',
    delete: 'डिलीट',
    noSearch: '* कोई किसान नहीं मिला *',
    addNewFarmerButton: 'किसान जोड़े',
    deleteButtonText: 'हटाएं',
    close: 'बंद करे',
    deleteHeading: 'क्या आप चुने हुए किसानों को डिलीट करना चाहते हैं?',

    emptyAlert:
      'ऐसा लगता है कि आपने अभी तक किसी किसान को नहीं जोड़ा है। किसानों को जोड़ने के लिए नीचे दिए गए बटन पर क्लिक करें',
    deleteModal: 'किसानों को हटाएं',
    apiError: 'असुविधा के लिए क्षमा करें! कृपया बाद में फिर से प्रयास करें।',
  },
  [AppLanguages.appLanguagesIndex.english]: {
    heading: 'Your farmers',
    searchText: 'Search Farmers',
    delete: 'Delete farmer',
    noSearch: '* No Farmer found *',
    addNewFarmerButton: 'Add Farmers',
    deleteButtonText: 'Delete',
    close: 'Cancel',
    deleteHeading: 'Do you want to delete selected farmers?',

    emptyAlert:
      'It seems that you have not added any farmer yet. Click the below button to add farmers',
    deleteModal: 'Delete Farmers',
    apiError: 'Sorry for the inconvenience! Please try again later.',
  },
};

export default (language) => languages[language];
