// Import the updatePersona function from the specified API module
import updatePersona from '../../../apis/Trader/updatePersona';

// Import the UpdatePersona type definition
import { UpdatePersona } from '../types';

// Define an asynchronous function UpdatePersonaApi
// It takes an optional payload object and an optional token string as arguments
// The function returns a Promise that resolves to an UpdatePersona object
export default async function UpdatePersonaApi(
  payload = {},
  token = '',
): Promise<UpdatePersona> {
  // Create a body object by spreading the payload
  const body = {
    ...payload,
  };

  try {
    // Call the updatePersona function with the body and token
    const result = await updatePersona(body, token);

    // Check if the result's data status is false
    // If so, throw the result as an error
    if (!result.data.status) {
      throw result;
    }

    // Return the data part of the result
    return result.data.data;
  } catch (error) {
    // If there is an error and it doesn't have a message property,
    // Set a default error message
    if (!error.message) {
      error.message = 'Something Went Wrong';
    }

    // Rethrow the error to be handled by the calling function
    throw error;
  }
}
