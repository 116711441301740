import * as AppLanguages from '../constant/appLanguages';
//language will be added here
const languages = {
  [AppLanguages.appLanguagesIndex.hindi]: {
    congratsHeadingText: 'बधाई हो!',
    congratsSubHeadingText: 'आपका  किसान सफलतापूर्वक जोड़ा गया है।',
    proceedButtonText: 'अपने जुड़ें हुए किसान देखें',
  },
  [AppLanguages.appLanguagesIndex.english]: {
    congratsHeadingText: 'Congratulations!',
    congratsSubHeadingText: 'Your farmer has been added successfully',
    proceedButtonText: 'View my farmers',
  },
};

export default (language) => languages[language];
