import React, { ReactNode } from 'react';
import Alert, { AlertProps } from '@mui/joy/Alert';

interface AlertWrapperProps extends AlertProps {
  children?: ReactNode;
  customStyles?: string;
}

const MuiAlert: React.FC<AlertWrapperProps> = ({
  children,
  customStyles,
  ...props
}) => {
  return <Alert {...props}>{children}</Alert>;
};

export default MuiAlert;
