export default function extractDigitsFromDate(date: Date): {
  year: string;
  month: string;
  day: string;
} {
  /**
   *
   * this function will provide an object which will be having
   * date, month and year as keys and their values as string
   * date and month will be two digits and year will be four digits
   *
   */

  const day = date
    .getDate()
    .toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false });
  const month = date.getMonth().toLocaleString('en-US', {
    minimumIntegerDigits: 2,
    useGrouping: false,
  });
  const year = date.getFullYear().toString();

  return { year, month, day };
}
