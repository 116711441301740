import React from 'react';
import './style.css';

interface ImageGridProps {
  images: string[]; // Array of image URLs
}

const ImageGrid = (props: ImageGridProps) => {
  const { images = [] } = props;
  let length = images?.length;

  if (length === 0) {
    return noImage();
  } else if (length === 1) {
    return generateOneImage(images);
  } else if (length === 2) {
    return generateTwoImage(images);
  } else if (length === 3) {
    return generateThreeImage(images);
  } else if (length === 4) {
    return generateFourImage(images);
  } else if (length > 4) {
    return generateFiveImage(images);
  }
};

export default ImageGrid;

const generateOneImage = (images) => {
  return (
    <div className={'grid-container-1'}>
      <div className={'relative-container padding-top-48-05 width-100'}>
        <div className={'image-container'}>
          <img
            src={images[0]}
            alt={'ImagePhoto'}
            className="image-style"
            loading="lazy"
          />
        </div>
      </div>
    </div>
  );
};

const generateTwoImage = (images) => {
  return (
    <div className={'grid-container-1 flex-direction-row'}>
      <div className={'relative-container padding-top-48-05 width-70'}>
        <div className={'image-container'}>
          <img
            src={images[0]}
            alt={'ImagePhoto'}
            className="image-style"
            loading="lazy"
          />
        </div>
      </div>
      <div className={'relative-container padding-top-48-05 width-30'}>
        <div className={'image-container'}>
          <img
            src={images[1]}
            alt={'ImagePhoto'}
            className="image-style"
            loading="lazy"
          />
        </div>
      </div>
    </div>
  );
};

const generateThreeImage = (images) => {
  return (
    <div className={'grid-container-1 flex-direction-row'}>
      <div className={'relative-container padding-top-48-05 width-70'}>
        <div className={'image-container'}>
          <img
            src={images[0]}
            alt={'ImagePhoto'}
            className="image-style"
            loading="lazy"
          />
        </div>
      </div>
      <div className={'relative-container padding-top-48-05 width-30'}>
        <div className="grid-container-nested flex-direction-column width-100">
          <div className={'image-container-right-3'}>
            <img
              src={images[1]}
              alt={'ImagePhoto'}
              className="image-style"
              loading="lazy"
            />
          </div>
          <div className={'image-container-right-3'}>
            <img
              src={images[2]}
              alt={'ImagePhoto'}
              className="image-style"
              loading="lazy"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const generateFourImage = (images) => {
  return (
    <div className={'grid-container-1 flex-direction-column'}>
      <div className={'relative-container padding-top-45-45 width-100'}>
        <div className={'image-container'}>
          <img
            src={images[0]}
            alt={'ImagePhoto'}
            className="image-style"
            loading="lazy"
          />
        </div>
      </div>
      <div className={'relative-container width-100 padding-top-22-07'}>
        <div className={'grid-container-nested'}>
          {images.slice(1, 4).map((image, index) => (
            <div key={index} className={'width-one-third'}>
              <img
                src={image}
                alt={'ImagePhoto'}
                className="image-style"
                loading="lazy"
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

const generateFiveImage = (images) => {
  return (
    <div className={'flex-direction-column grid-container-1'}>
      <div className={'relative-container padding-top-45-45 width-100'}>
        <div className={'grid-container-nested flex-direction-row'}>
          {images.slice(0, 2).map((image, index) => (
            <div key={index} className={'width-50'}>
              <img
                src={image}
                alt={'ImagePhoto'}
                className="image-style"
                loading="lazy"
              />
            </div>
          ))}
        </div>
      </div>
      <div className={'relative-container width-100 padding-top-22-07'}>
        <div className={'grid-container-nested'}>
          {images.slice(2, 5).map((image, index) => (
            <div key={index} className={'width-one-third'}>
              <img
                src={image}
                alt={'ImagePhoto'}
                className="image-style"
                loading="lazy"
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

const noImage = () => {
  return <></>;
};
