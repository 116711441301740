import React from 'react';
import Style from './style.module.css';
import Modal from '../../../ComponentV2/Modal';
import MuiButton from '../../../ComponentsV3/MuiButton';
import DocumentType from '../../../constant/enums/docType';

interface CancelUploadModalProps {
  showCancelUploadModal: boolean;
  toggleCancelUploadModal: () => void;
  language: { [key: string]: any };
  onCLickCancelUpload: () => void;
  docType: number;
}

const CancelUploadModal = ({
  showCancelUploadModal,
  toggleCancelUploadModal,
  language,
  onCLickCancelUpload,
  docType,
}: CancelUploadModalProps) => {
  const template = language?.documentUploadConfirmationText;
  const values = {
    documentName: language[DocumentType.get(docType)],
  };
  const replacePlaceholders = ({ template, values }) => {
    return template
      .split(/(<[^>]+>)/)
      .map((part, index) =>
        part.startsWith('<') && part.endsWith('>') ? (
          <span key={index}>{values[part.replace(/<|>/g, '')]}</span>
        ) : (
          <span key={index}>{part}</span>
        ),
      );
  };

  const modalContent = (
    <div className={Style.modalContainer}>
      <div className={Style.textContainer}>
        <label className={Style.cancelConfirmationText}>
          {replacePlaceholders({ template, values })}
        </label>
      </div>
      <div className={Style.buttonContainer}>
        <div className={Style.uploading}>
          <MuiButton
            variant={'outlined'}
            color={'neutral'}
            size={'md'}
            onClick={toggleCancelUploadModal}
            id={'onClickContinueUploadButton'}
          >
            {language?.continueUploading}
          </MuiButton>
        </div>
        <MuiButton
          size={'md'}
          color={'danger'}
          onClick={onCLickCancelUpload}
          id="onClickCancelUploadButton"
        >
          {language?.cancelUpload}
        </MuiButton>
      </div>
    </div>
  );
  return (
    <Modal
      isOpen={showCancelUploadModal}
      children={modalContent}
      title={`${language?.cancelUpload}?`}
    />
  );
};

export default CancelUploadModal;
