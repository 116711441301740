import React from 'react';
import Styles from './Style.module.css'; // Import your CSS file for styling
import Send from '../../Images/send.svg';
import Avatar from './../../Images/avatar.svg';
import CommentInputHook from './index.hook';

interface CommentInputProps {
  onSubmit: (value: string) => void;
  profileImage: any;
  taggedName: string;
  removeTaggedName: () => void;
  focus: boolean;
  textAreaId?: string;
}

const CommentInput = (props: CommentInputProps) => {
  const { profileImage, textAreaId = '' } = props;

  const {
    handleKeyPress,
    handleSubmit,
    handleInputChange,
    inputValue,
    inputRef,
  } = CommentInputHook(props);

  return (
    <div className={Styles.container}>
      <div className={Styles.profileImageContainer}>
        <img
          className={Styles.profileImageStyle}
          src={profileImage ? profileImage : (Avatar as unknown as string)}
          alt="comment_profile"
          loading="eager"
        />
      </div>

      <div className={Styles.inputContainer}>
        <textarea
          ref={inputRef}
          className={Styles.inputElement}
          onKeyDown={handleKeyPress}
          value={inputValue}
          onChange={handleInputChange}
          id={textAreaId}
        />
      </div>

      <div className={Styles.buttonContainer} onClick={handleSubmit}>
        <img
          className={Styles.profileImageStyle}
          src={Send as unknown as string}
          alt="send"
          loading="eager"
        />
      </div>
    </div>
  );
};

export default CommentInput;
