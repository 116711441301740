import * as AppLanguages from '../constant/appLanguages';
//language will be added here
const languages = {
  [AppLanguages.appLanguagesIndex.english]: {
    needMoreHelp: 'Need more help?',
    noWhatsAppAccountFound: 'No WhatsApp account found',
    whatsAppMessageText:
      'Hello FarMart Please help me in getting other information',
  },
  [AppLanguages.appLanguagesIndex.hindi]: {
    needMoreHelp: 'और मदद चाहिए?',
    noWhatsAppAccountFound: 'कोई व्हाट्सएप अकाउंट नहीं मिला',
    whatsAppMessageText:
      'नमस्कार FarMart कृपया हमें अन्य जानकारी प्राप्त करने में मदद करें।',
  },
};

export default (language) => languages[language];
