import React from 'react';
import FAQQuestions from '../../Pages/FAQQuestions/FAQQuestions';
import UseFaqQuestions from '../../hooks/FAQQuestions/FAQQuestions';

/**
 * Functional component that uses the UseFaqQuestions hook to fetch and manage FAQ questions data,
 * and passes this data and relevant handlers as props to the FAQQuestions component for rendering.
 */
export default function faqQuestions({}) {
  // Destructure the values returned by the UseFaqQuestions hook.
  const {
    faqQuestionList, // List of FAQ questions.
    onClickBackFaqScreen, // Handler for navigating back to the previous screen.
    loadingQuestion, // Loading state for FAQ questions.
    onClickFaqQuestionItem, // Handler for clicking an FAQ question item.
    onClickNeedMoreHelp, // Handler for clicking the "Need More Help" button.
    showFaqAlert, // State to show or hide the FAQ alert.
    setShowFaqAlert, // Setter function for showFaqAlert state.
    language, // Language data for the FAQ screen.
    faqAssets, // Assets for the FAQ screen.
  } = UseFaqQuestions(); // Call the custom hook to get the required data and handlers.

  return (
    <FAQQuestions
      faqQuestionList={faqQuestionList} // Pass FAQ questions list to the FAQQuestions component.
      onClickBackFaqScreen={onClickBackFaqScreen} // Pass handler for back button click.
      loadingQuestion={loadingQuestion} // Pass loading state.
      onClickFaqQuestionItem={onClickFaqQuestionItem} // Pass handler for FAQ question item click.
      onClickNeedMoreHelp={onClickNeedMoreHelp} // Pass handler for "Need More Help" button click.
      showFaqAlert={showFaqAlert} // Pass state for showing FAQ alert.
      setShowFaqAlert={setShowFaqAlert} // Pass setter function for FAQ alert state.
      language={language} // Pass language data.
      faqAssets={faqAssets} // Pass assets for the FAQ screen
    />
  );
}
