import { useState, useEffect } from 'react';
import {
  MySaudaListScreen,
  FilterStatus,
  OpenCloseTab,
  SaudaOrderCard,
} from './types';
import SaudaStatusEnum from '../../constant/enums/saudaStatusEnum';
import { MySaudaCard } from '../../modal/mySauda/types';
import SaudaOpenCloseEnum from '../../constant/enums/saudaOpenCloseEnum';
import { useNavigate } from 'react-router-dom';
import { trackEvent } from '../../utils/moengage';
import Events from '../../constant/MoengageEvents';
import getLang from '../../languages/mySaudaScreen.js';
import { useAuth } from '../../context/login';
import { usePlatfromData } from '../CrossPlatformActionHandler/platformChecker';
import { unVerifiedRoutes } from '../../constant/unVerifiedRoutes';
import { loginAsGuest } from '../../modal/auth/auth.model';
import useAuthenticationCheckerHook from '../../utils/authChecker';
import { apiEndPoints } from '../../constant/apiEndPoints';
import { getMySaudaAssets } from '../../constant/imageUrls';

export default function GetMySaudaList(): MySaudaListScreen {
  const userLogedIn = localStorage.getItem('loged');
  const platformData = usePlatfromData();
  const authProps = useAuth();
  const navigate = useNavigate();
  const saudaStatusEnum = SaudaStatusEnum.get('status');
  const saudaOpenCloseEnum = SaudaOpenCloseEnum.get('openClose');
  const langId = JSON.parse(localStorage.getItem('userLanguage'));
  const language = getLang(langId?.langCode || 'HI');
  const [saudaModalVisible, setSaudaModalVisible] = useState<boolean>(false);
  const [mySaudaList, setMySaudaList] = useState<MySaudaCard[]>([]);
  const [mySaudaTotalRecords, setMySaudaTotalRecords] = useState<number>(0);
  const [mySaudaPageNumber, setMySaudaPageNumber] = useState<number>(1);
  const [selectedFilterStatus, setSelectedFilterStatus] =
    useState<FilterStatus>(saudaStatusEnum[0]);
  const [mySaudaLoading, setMySaudaLoading] = useState<boolean>(false);
  const [selectedOpenCloseTab, setSelectedOpenCloseTab] =
    useState<OpenCloseTab>(saudaOpenCloseEnum[0]);
  const startLoadTime = new Date().getTime();
  const stateId = JSON.parse(localStorage.getItem('stateId')) || null; // get state id from local storage
  const districtId = JSON.parse(localStorage.getItem('districtId')) || null; // get district id from local storage
  const [isServiceAvailable, setIsServiceAvailable] = useState<boolean>(false); // check if trade service is available or not
  const { AuthenticationCheckerAndApiCaller } = useAuthenticationCheckerHook();
  const mySaudaAssets = getMySaudaAssets(langId?.langCode);

  function onCloseSaudaStatusModal() {
    saudaModalVisible
      ? trackEvent(Events?.onSaudaStatusCloseButtonClicked, {})
      : trackEvent(Events?.onSaudaStatusFilterButtonClick, {});
    setSaudaModalVisible(!saudaModalVisible);
  }

  function onSelectFilterStatus(value: FilterStatus) {
    return () => {
      trackEvent(Events?.onSaudaStatusClicked, {
        Filter_selected:
          value?.id === '1'
            ? 'Sauda in review'
            : value?.id === '2'
            ? 'PO acceptance pending'
            : value?.id === '3'
            ? 'Sauda booked'
            : 'All Saudas',
      });
      if (selectedFilterStatus?.id !== value?.id) {
        setMySaudaTotalRecords(0);
        setMySaudaList([]);
        setMySaudaPageNumber(1);
        setSelectedFilterStatus(value);
      }
      setSaudaModalVisible(!saudaModalVisible);
    };
  }

  /**
   * The function `fetchMySaudaList` fetches a list of sauda items based on specified parameters and
   * handles loading states, error handling, and event tracking.
   */
  async function fetchMySaudaList() {
    setMySaudaLoading(true);
    try {
      const getMySaudaListResponse = await AuthenticationCheckerAndApiCaller({
        payload: {
          is_open: selectedOpenCloseTab?.id,
          limit: 10,
          page_no: mySaudaPageNumber,
          sauda_status_id: selectedFilterStatus?.id,
          supplier_state_id: stateId,
          supplier_district_id: districtId,
        },
        uri: apiEndPoints.getMySaudaList,
        requestType: 'get',
        loginMethod: 'modal',
      });
      setMySaudaList([
        ...mySaudaList,
        ...getMySaudaListResponse?.data?.data?.sauda_list,
      ]);
      setMySaudaTotalRecords(getMySaudaListResponse?.data?.data?.total_records);
      setMySaudaPageNumber((lastValue) => {
        return lastValue + 1;
      });
      setIsServiceAvailable(
        getMySaudaListResponse?.data?.data?.is_trade_serviceable,
      ); // set service available or not
      let mySaudaEventAttribute = {
        LoadingTime: (new Date().getTime() - startLoadTime) / 1000,
        Tab: selectedOpenCloseTab.id === 1 ? 'Open' : 'Close',
      };
      trackEvent(Events.onStartMySaudasScreen, mySaudaEventAttribute);
      setMySaudaLoading(false);
    } catch (error) {
      if (error?.code === 401 || error?.code === 426) {
        if (platformData?.platform !== 'APP') {
          if (unVerifiedRoutes?.guestLogin?.includes(error?.path)) {
            await loginAsGuest();
          } else {
            authProps.setMobileNumberModalVisible(true);
          }
        }
      }
      console.log(error);
    }
  }

  const fetchMoreSaudaList = () => {
    if (mySaudaTotalRecords > mySaudaList?.length) {
      fetchMySaudaList();
    }
  };

  function onClickOpenCloseTab(value: OpenCloseTab) {
    return () => {
      trackEvent(
        value?.id === saudaOpenCloseEnum[0]?.id
          ? Events?.onOpenSaudasButtonClick
          : Events?.onClosedSaudasButtonClick,
        {},
      );
      if (selectedOpenCloseTab?.id !== value?.id) {
        setMySaudaList([]);
        setMySaudaTotalRecords(0);
        setMySaudaPageNumber(1);
        setSelectedFilterStatus(saudaStatusEnum[0]);
        setSelectedOpenCloseTab(value);
      }
    };
  }

  function onClickAcceptPo(id: number) {
    return () => {
      navigate(`/trades/mySauda/${id}`);
    };
  }

  function onClickSaudaOrderCard(value: SaudaOrderCard) {
    return () => {
      trackEvent(Events?.onMySaudasCardButtonClick, {
        Buyer_name: value?.buyerName,
        Crop_name: value?.cropName,
        Quantity: value?.quantity,
        Payment_Term: value?.paymentTerm,
        'PO Status': value?.poStatus,
        'Number of Trucks': value?.numberOfTrucks,
      });
      navigate(`/trades/mySauda/${value?.id}`, { state: 'my_sauda_list' });
    };
  }

  function onClickBack() {
    trackEvent(Events?.onMySaudasBackButtonClick, {});
    if (platformData.platform === 'WEB') {
      authProps.setMobileNumberModalVisible(false);
    }
    navigate('/trades');
  }

  useEffect(function () {
    window.addEventListener('popstate', onClickBack);

    return () => {
      window.removeEventListener('popstate', onClickBack);
    };
  }, []);

  useEffect(
    function () {
      fetchMySaudaList();
    },
    [selectedFilterStatus?.id, selectedOpenCloseTab?.id, userLogedIn],
  );

  const trackMySaudaScreenEnd = () => {
    let closedMySaudaScreenAttributes = {
      TimeSpent: (new Date().getTime() - startLoadTime) / 1000,
    };
    trackEvent(Events.onEndMySaudasScreen, closedMySaudaScreenAttributes);
  };

  useEffect(() => {
    return trackMySaudaScreenEnd;
  }, []);

  return {
    onCloseSaudaStatusModal,
    saudaModalVisible,
    onSelectFilterStatus,
    selectedFilterStatus,
    mySaudaList,
    fetchMoreSaudaList,
    mySaudaLoading,
    onClickOpenCloseTab,
    selectedOpenCloseTab,
    onClickAcceptPo,
    onClickBack,
    onClickSaudaOrderCard,
    language,
    isServiceAvailable, // check if trade service is available or not
    mySaudaAssets,
  };
}
