export default function replaceVariableInString(
  valueToReplace: string = '',
  languageString: string = '',
) {
  // If either valueToReplace or languageString is empty or undefined, return an empty string
  if (!valueToReplace || !languageString) {
    return '';
  }

  // Replace occurrences of {\(variable\)} with valueToReplace in languageString
  return languageString.replace(/\{\(variable\)\}/g, valueToReplace);
}
