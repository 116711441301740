import * as AppLanguages from '../constant/appLanguages';
//language will be added here
const languages = {
  [AppLanguages.appLanguagesIndex.hindi]: {
    shopPromotionModalTitle: 'दुकान प्रचार के लिए ',
    moreAboutBalance: 'SMS बैलेंस के बारे में',
    freeSMSCreditTitle: 'मुफ्त SMS क्रेडिट',
    freeSMSCreditText:
      'आपके उपयोग के लिए हर महीने के 1 तारीख को हम मुफ्त में 1000 SMS क्रेडिट करते हैं।',
    smsExpiryTitle: 'SMS समाप्त',
    smsExpiryText:
      'महीने के अंत में बचे हुए SMS का उपयोग नहीं करने पर वो समाप्त हो जाएगा।',
  },
  [AppLanguages.appLanguagesIndex.english]: {
    shopPromotionModalTitle: 'About Shop Promotion',
    moreAboutBalance: 'More about SMS balance',
    freeSMSCreditTitle: 'Free SMS Credit',
    freeSMSCreditText:
      'We credit free 1000 SMS on 1st of the every month for your to uses.',
    smsExpiryTitle: 'SMS Expiry',
    smsExpiryText:
      'Remaining SMS at the end of the month will get expired if not used.',
  },
};

export default (language) => languages[language];
