import React from 'react';
import Styles from './style.module.css';
import Button from '../../../ComponentV2/Button';
import SecondaryButton from '../../../ComponentV2/SecondaryButton';
import { ActiveTrade } from '../../../modal/Traders/types';
import timeAgo from '../../../utils/timeAgo/date/time_ago.js';
import Typography from '../../../ComponentV3/Typography';
export interface TradeCardProps {
  items?: ActiveTrade;
  language: { [key: string]: any };
  currentLanguageId: string;
  handleNumberCopyToast?: (
    phoneNumber: number,
    cropName: string,
    buyerName: string,
  ) => void;
  onCLickViewDetail: (
    id: string,
    cropName: string,
    buyerName: string,
    requestSauda: boolean,
  ) => void;
  saudaRequestToastPopup: boolean;
  handleSaudaRequestToastPopup: () => void;
  discountCardBgSmall: string;
}

const TradeListCard = (props: TradeCardProps) => {
  const {
    items,
    currentLanguageId,
    onCLickViewDetail,
    handleNumberCopyToast,
    language,
    saudaRequestToastPopup,
    handleSaudaRequestToastPopup,
    discountCardBgSmall,
  } = props;
  const disabled = items?.is_expire === 1 ? true : false;
  return (
    <div
      className={Styles.wrapper}
      onClick={() => {
        onCLickViewDetail(
          items?.trade_price_id,

          items?.crop_name,

          items?.buyer_name,
          false,
        );
      }}
      data-testid={
        items?.is_expire === 0 ? 'activeTradeCard' : 'inActiveTradeCard'
      }
    >
      <div className={Styles.cropDetails}>
        <div className={Styles.imageWrapper}>
          <img
            src={items?.crop_variety_image}
            alt="Image"
            className={Styles.cropImage}
            loading="lazy"
          />
        </div>
        <div className={Styles.divider} />
        <div className={Styles.infoWrapper}>
          <div className={Styles.grossPriceText}>
            {items?.gross_price} {items?.gross_price_unit}
          </div>
          <div className={Styles.cropText}>
            {items?.crop_name}
            <div className={Styles.cropDetailsDividerDot}>●</div>
            {items?.variety_name}
          </div>
          <div className={Styles.lastUpdatedContainer}>
            <div>
              {language?.lastUpdate}{' '}
              {timeAgo(items?.updated_at, currentLanguageId)}
            </div>

            {disabled ? (
              <div className={Styles.expiredLabelWrapper}>
                {language?.priceExpired}
              </div>
            ) : items?.is_sauda_booked ? (
              <div className={Styles.requestedLabelWrapper}>
                {language?.saudaRequested}
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>

      <div className={Styles.companyDetails}>
        <div className={Styles.companyName}>{items?.buyer_name}</div>
        <div className={Styles.companyLocation}>
          {items?.district_name}, {items?.state_name}
        </div>
      </div>
      {items?.discounted_fee &&
        items?.discounted_fee !== 0 &&
        items?.discounted_fee !== null &&
        items?.is_expire === 0 && (
          <div
            className={Styles.discountCard}
            style={{
              backgroundImage: `url(${discountCardBgSmall})`,
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
            }}
          >
            <div className={Styles.discountCardText}>
              <Typography level="title-sm" sx={{ color: '#B2852F' }}>
                {language?.discountCardText}
              </Typography>
            </div>
          </div>
        )}

      <div className={Styles.btnContainer}>
        <div
          onClick={() =>
            onCLickViewDetail(
              items?.trade_price_id,

              items?.crop_name,

              items?.buyer_name,
              false,
            )
          }
          data-testid={'onClickViewDetailID'}
          className={Styles.btnWrapper}
        >
          <SecondaryButton title={language?.viewDetails} />
        </div>
        <div
          className={Styles.btnWrapper}
          onClick={(event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
            event.stopPropagation();
          }}
        >
          <Button
            onClick={() => {
              if (items?.is_sauda_booked) {
                handleSaudaRequestToastPopup();
              } else {
                onCLickViewDetail(
                  items?.trade_price_id,
                  items?.crop_name,
                  items?.buyer_name,
                  true,
                );
              }
            }}
            disabled={disabled}
            title={language?.requestSauda}
          />
        </div>
      </div>
    </div>
  );
};

export default TradeListCard;
