export default {
  shop_promotion: 1,
  sell_crop: 2,
  bahi_khata: 3,
  crop_calendar: 4,
  rake: 5,
  expense_manager: 6,
  mandi_rate: 7,
  distributer_list: 8,
  news_feed: 9,
  add_farmer: 10,
  quiz: 11,
  weather: 12,
  trade: 13,
};
