import React from 'react';
import Login from '../../Pages/Login/Login';
import MobileSubmitHooks from '../../hooks/Login/mobileSubmitHook';

export default function trader({}) {
  const {
    error,
    getMobileNumber,
    userBlocked,
    userMobileNumber,
    onClickLoginProceed,
    loading,
    handleLanguageSelect,
    onLanguageModalToggle,
    handleLanguageChange,
    selectedLanguage,
    isLanguageModalOpen,
    isLanguageSelectedInProgress,
    onClickPrivacyPolicy,
    language,
    onContactUs,
    loginScreenAssets,
  } = MobileSubmitHooks();
  return (
    <Login
      error={error}
      getMobileNumber={getMobileNumber}
      userBlocked={userBlocked}
      userMobileNumber={userMobileNumber}
      onClickLoginProceed={onClickLoginProceed}
      loading={loading}
      handleLanguageSelect={handleLanguageSelect}
      onLanguageModalToggle={onLanguageModalToggle}
      handleLanguageChange={handleLanguageChange}
      selectedLanguage={selectedLanguage}
      isLanguageModalOpen={isLanguageModalOpen}
      isLanguageSelectedInProgress={isLanguageSelectedInProgress}
      onClickPrivacyPolicy={onClickPrivacyPolicy}
      language={language}
      onContactUs={onContactUs}
      loginScreenAssets={loginScreenAssets}
    />
  );
}
