import React from 'react';
import { DocRenderer } from '@cyntler/react-doc-viewer';
import './MyCustomPNGRenderer.css'; // Import the CSS file
const MyCustomPNGRenderer: DocRenderer = ({
  mainState: { currentDocument },
}) => {
  if (!currentDocument) return null;

  return (
    <div id="my-png-renderer" className="custom-png-container">
      {/* <PNGControls /> */}
      <img
        id="png-img"
        src={currentDocument.fileData as string}
        alt="currentDocumentImage"
        loading="lazy"
      />
    </div>
  );
};

MyCustomPNGRenderer.fileTypes = [
  'png',
  'image/png',
  'jpeg',
  'jpg',
  'image/jpg',
  'image/jpeg',
];
MyCustomPNGRenderer.weight = 1;

export default MyCustomPNGRenderer;
