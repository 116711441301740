export default function createStack<T>() {
  let items: T[] = [];

  function push(item: T): void {
    items = [...items, item];
  }

  function pop(): T {
    if (isEmpty()) {
      throw new Error('Stack is empty');
    }
    const [lastItem, ...restItems] = items.reverse();
    items = restItems.reverse();
    return lastItem;
  }

  function peek(): T | undefined {
    return items[items.length - 1];
  }

  function isEmpty(): boolean {
    return items.length === 0;
  }

  function size(): number {
    return items.length;
  }

  function clear(): void {
    items = [];
  }

  return {
    push,
    pop,
    peek,
    isEmpty,
    size,
    clear,
  };
}
