import React, { useState } from 'react';
import './ClapAnimation.css'; // Import CSS file for styling
interface ClapProps {
  isClapped: boolean; // Indicates if the post is clapped
  clap: number; // Number of claps on the post
  language?: { [key: string]: any }; // Optional object containing language strings
  clapIcon: string;
  clapDoneIcon: string;
}

/**
 * ClapAnimation component displays a clap animation with count.
 * @param {ClapProps} props - Props for the ClapAnimation component
 * @returns {JSX.Element} - ClapAnimation component
 */
const ClapAnimation = (props: ClapProps): JSX.Element => {
  const [clapCount, setClapCount] = useState(0);
  const [firstClapCompleted, setFirstClapCompleted] = useState(true);
  const [riseAnimationRunning, setRiseAnimationRunning] = useState(false);
  const [shrinkAnimationRunning, setShrinkAnimationRunning] = useState(false);

  /**
   * Handles the clap action.
   */
  const handleClap = () => {
    setClapCount(clapCount + 1);

    if (!firstClapCompleted) {
      setFirstClapCompleted(true);
    }

    if (!riseAnimationRunning && !shrinkAnimationRunning) {
      setRiseAnimationRunning(true);

      // Execute the rise animation
      setTimeout(() => {
        setRiseAnimationRunning(false);

        if (!riseAnimationRunning && !shrinkAnimationRunning) {
          setShrinkAnimationRunning(true);

          // Execute the shrink animation
          setTimeout(() => {
            setShrinkAnimationRunning(false);
          }, 1000);
        }
      }, 100);
    }
  };

  return (
    <div className="mainContainer" onClick={handleClap}>
      {/* Clap count display */}
      <div
        className={`clap-count-layout ${
          riseAnimationRunning || shrinkAnimationRunning
            ? 'animate'
            : 'display-none'
        }`}
      >
        <span className={'clap-count-text'}>+{clapCount}</span>
      </div>
      {/* Clap or Done icon */}
      <div
        className={`test ${
          riseAnimationRunning || shrinkAnimationRunning ? 'animate' : 'nrml'
        }`}
      >
        {props.isClapped ? (
          <img
            src={props.clapDoneIcon}
            width={'100%'}
            height={'100%'}
            loading="lazy"
          />
        ) : (
          <img
            src={props.clapIcon}
            height={'100%'}
            width={'100%'}
            loading="lazy"
          />
        )}
      </div>
      {/* Clap text with count */}
      <p
        className={'iconTextStyles'}
      >{`${props?.language?.clap} (${props.clap})`}</p>
    </div>
  );
};

export default ClapAnimation;
