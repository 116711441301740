import getConfigByKeys from '../../config/config';
import { configKeys } from '../../config/types';
import handleHttpError from '../../utils/api/httpErrorMapping';
import HttpStatusCode from '../types/httpStatusCodesEnums.type';
import UnifiedResponse from '../types/axiosResponse.types';
import axios from 'axios';
import { DEFAULT_LANGUAGE_ID } from '../../constant/language';

import {
  getAuthTokenFromStorage,
  getUserLanguageFromStorage,
} from '../../modal/auth/auth.model';

const { API_URL } = getConfigByKeys([configKeys.API_URL]);

export default async function postMultipleFile(
  payload,
): Promise<UnifiedResponse> {
  const Auth = getAuthTokenFromStorage();
  let userLanguage = getUserLanguageFromStorage()?.langId || null;
  // thrwong error because at this point of time
  // the user should be logged in
  if (!Auth) throw new Error('Auth token not found');

  if (!userLanguage) {
    userLanguage = DEFAULT_LANGUAGE_ID;
  }

  const response = await axios.post(
    `${API_URL}/v1/dispatch/upload_documents`,
    payload,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
        language: userLanguage.toString(),
        authorization: 'Bearer ' + Auth,
      },
    },
  );

  if (response.status !== HttpStatusCode.Ok) {
    throw handleHttpError(response.status);
  }

  const data = await response.data;
  const status = response.status;
  return {
    data,
    status,
  };
}
