import { useEffect, useState } from 'react';
import { PostDetails } from './types';
import {
  getCommunityFeedById,
  PostDetailsById,
} from '../../modal/community/communityModel';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../store';
import { setSelectedPageId } from '../../modal/community/slice/community.slice';
import { useNavigate, useParams } from 'react-router-dom';
import AnalyticEvents from '../../constant/analyticEvents';

import HttpStatusCode from '../../apis/types/httpStatusCodesEnums.type';
import { loginAsGuest } from '../../modal/auth/auth.model';
import { usePlatfromData } from '../../hooks/CrossPlatformActionHandler/platformChecker';
import { trackEvent } from '../../utils/moengage';
import Events from '../../constant/MoengageEvents';
export default function UsePostDetail(): PostDetails {
  const navigate = useNavigate();
  const platformData = usePlatfromData();
  const params = useParams();

  const [postDetailsData, setPostDetailsData] = useState<PostDetailsById>(null);
  const [PostDetailLoading, setPostDetailLoading] = useState<boolean>(false);
  const [currentPostId, setCurrentPostId] = useState<number>(+params.id);
  const dispatch = useDispatch();
  const startLoadTime = new Date().getTime();

  const { posts, selectedPageId } = useSelector((state: RootState) => {
    return {
      posts: state.community.posts,
      selectedPageId: state.community.pages.selectedPageId,
    };
  });

  const [startTime, setStartTime] = useState<number>(Date.now());
  let tokenExpiredCount = 0;

  async function fetchPostDetailsById() {
    setPostDetailLoading(true);
    try {
      const postsResult = await getCommunityFeedById({
        news_feed_id: currentPostId,
      });

      trackEvent(Events.onStartCommunityDetailedPage, {
        LoadingTime: (new Date().getTime() - startLoadTime) / 1000,
      });
      setPostDetailsData(postsResult);
    } catch (error) {
      // somethign went wrong
      if (error.code === HttpStatusCode.TokenExpired) {
        if (tokenExpiredCount < 2) {
          // Set your desired count limit here
          localStorage.setItem('authToken', null);
          await loginAsGuest(); // Wait for loginAsGuest() to complete
          tokenExpiredCount++; // Increment the counter
          fetchPostDetailsById(); // Call fetchAllPost() again
        } else {
          // You have reached the count limit for token expiration errors
          console.log('Token expired too many times');
        }
      } else {
        // Handle other errors
        console.log(error);
      }
      setPostDetailLoading(false);
    } finally {
      setPostDetailLoading(false);
    }
  }

  function onClickBack(): void {
    trackEvent(Events.onCommunityBackButtonClick, {
      From: 'Community_detail_screen',
    });

    const endTime = Date.now();
    const totalTime = endTime - startTime;

    navigate('/community');
    dispatch(setSelectedPageId(selectedPageId));
  }

  function onClickDisclaimer() {
    trackEvent(Events?.onCommunityDetailedDisclaimerButtonClick, {
      From: 'Post_Detail_Screen',
      Post_id: currentPostId,
    });
  }

  useEffect(
    function () {
      fetchPostDetailsById();
    },
    [currentPostId],
  );

  return {
    postDetailsData,
    PostDetailLoading,
    setCurrentPostId,
    currentPostId,
    setPostDetailsData,
    onClickBack,
    onClickDisclaimer,
  };
}
