import * as AppLanguages from '../constant/appLanguages';
//language will be added here
const languages = {
  [AppLanguages.appLanguagesIndex.hindi]: {
    otpTitle: 'ओटीपी दर्ज करें',
    otpSentOnMobile: '{(variable)} पर ओटीपी भेज दिया गया है',
    changeNumber: 'नंबर बदले',
    otpErrorMessage: 'अमान्य ओटीपी',
    didNotGetOtp: 'OTP प्राप्त नहीं हुआ ?',
    sendAgain: 'दुबारा भेजे',
    mobileNumberCopytext: 'मोबाइल नंबर को क्लिपबोर्ड पर कॉपी किया गया है।',
    proceed: 'आगे बढ़ें',
    changeLanguage: 'भाषा बदलें',
    wrongOtp: 'गलत ओटीपी, कृपया पुनः प्रयास करें',
    selectLanguage: 'भाषा चुने',
    help: 'सहायता',
  },
  [AppLanguages.appLanguagesIndex.english]: {
    otpTitle: 'Enter OTP',
    otpSentOnMobile: 'OTP has been sent on {(variable)}',
    changeNumber: 'Change Number',
    otpErrorMessage: 'Invalid OTP',
    didNotGetOtp: 'OTP not received?',
    sendAgain: 'Send again',
    mobileNumberCopytext: 'Mobile number has been copied to clipboard',
    proceed: 'Proceed',
    changeLanguage: 'Change Language',
    wrongOtp: 'Wrong OTP, please try again',
    selectLanguage: 'Select language',
    help: 'Help',
  },
};

export default (language) => languages[language];
