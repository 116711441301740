import React from 'react';
import { Box, Button } from '@mui/joy';
import Style from './style.module.css';

// Interface for props received by the component

interface FAQFooterProps {
  onClickNeedMoreHelp: () => void; // Function to handle onClick event for needing more help
  language: { [key: string]: any }; // Language object containing text strings
  whatsappIcon: string; // WhatsApp icon image
}

// Functional component definition with destructured props
const Footer = ({
  onClickNeedMoreHelp,
  language,
  whatsappIcon,
}: FAQFooterProps) => {
  const startDecorator = (
    <img src={whatsappIcon} alt="Whatsapp Icon" loading="eager" />
  ); //whatsapp Image for Button

  return (
    <Box className={Style.footerContainer}>
      <Button
        variant="outlined"
        startDecorator={startDecorator}
        className={Style.whatsappButton}
        sx={{ height: 40, borderRadius: 8 }}
        onClick={onClickNeedMoreHelp}
        id={'faqAnswerScreenOnClickNeedMoreHelpButtonId'}
      >
        {language?.needMoreHelp}
      </Button>
    </Box>
  );
};

export default Footer; // Exporting the Footer component as the default export
