import React from 'react';
import Style from './style.module.css';
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';

interface Header {
  HeaderRightSideContent?: React.ReactNode;
  HeaderRightSideContentStyles?: string;
  title: string;
  onClickBackIcon: () => void;
  id?: string;
  backButtonVisible?: boolean;
}

export default function index(props: Header) {
  const {
    HeaderRightSideContent,
    HeaderRightSideContentStyles,
    title,
    onClickBackIcon,
    id,
    backButtonVisible = true,
  } = props;
  return (
    <div className={Style.container}>
      <div className={Style.iconCumTitle}>
        {backButtonVisible ? (
          <ArrowBackRoundedIcon
            onClick={onClickBackIcon}
            id={id}
            sx={{ color: '#293756' }}
          />
        ) : (
          <></>
        )}
        <label className={Style.titleTextStyle}>{title}</label>
      </div>
      <div className={HeaderRightSideContentStyles}>
        {HeaderRightSideContent}
      </div>
    </div>
  );
}
