import React, { useRef, useEffect } from 'react';

interface InfiniteScrollProps {
  children: React.ReactNode;
  onScrollToEnd: () => void;
  horizontal?: boolean;
  vertical?: boolean;
  className?: string;
  loading?: boolean;
  loadingElement?: React.ReactNode;
  style?: React.CSSProperties; // Updated type for style
}

function InfiniteScroll({
  children,
  onScrollToEnd,
  horizontal,
  vertical,
  className,
  loading = false,
  loadingElement = <div>Loading...</div>,
  style,
}: InfiniteScrollProps) {
  const sentinelRef = useRef<HTMLDivElement | null>(null);

  const handleIntersection = (entries: IntersectionObserverEntry[]) => {
    // Check if the intersection ratio is greater than 0 (sentinel is visible)
    if (entries[0].isIntersecting && entries[0].intersectionRatio > 0) {
      onScrollToEnd();
    }
  };

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: '10px',
      threshold: 0.1, // Adjust the threshold as needed
    };

    const observer = new IntersectionObserver(handleIntersection, options);

    const sentinel = sentinelRef.current;
    if (sentinel && !loading) {
      observer.observe(sentinel);
    }

    return () => {
      if (sentinel) {
        observer.unobserve(sentinel);
      }
    };
  }, [loading]);

  const containerStyle: React.CSSProperties = {
    display: horizontal ? 'flex' : 'block',
    flexDirection: horizontal ? 'row' : 'column',
    overflowX: horizontal ? 'auto' : 'hidden',
    overflowY: vertical ? 'auto' : 'hidden',
  };

  return (
    <div className={className} style={{ ...containerStyle, ...style }}>
      {children}
      <div ref={sentinelRef}></div>
      {loading && loadingElement}
    </div>
  );
}

export default InfiniteScroll;
