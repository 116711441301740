import * as AppLanguages from '../constant/appLanguages';
//language will be added here
const languages = {
  [AppLanguages.appLanguagesIndex.hindi]: {
    selectLocationHeading: 'डिलीवरी लोकेशन का चयन करें',
    selectLocationSearchPlaceholder: 'लोकेशन खोजें',
    selectLocationButton: 'आवेदन करें',
    locationModalHeading: 'लोकेशन की जानकारी',
    allowLocation: 'लोकेशन की अनुमति दे',
    allowLocationHeading:
      'आपको सही जानकारी दिखाने के लिए हमे आपकी लोकेशन की आवश्यकता है, कृपया अपनी लोकेशन हमसे साझा करें|',
  },
  [AppLanguages.appLanguagesIndex.english]: {
    selectLocationHeading: 'Select Delivery Location',
    selectLocationSearchPlaceholder: 'Search Location',
    selectLocationButton: 'Apply',
    locationModalHeading: 'Your Location',
    allowLocation: 'Allow location access',
    allowLocationHeading:
      'To show you the correct information regarding Trade we need your location!',
  },
};

export default (language) => languages[language];
