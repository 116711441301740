import React, { useRef, useState, useEffect } from 'react';
import Style from './style.module.css';
import Button from '../../../ComponentsV3/MuiButton';
import UploadFileRounded from '@mui/icons-material/UploadFileRounded';
import ChevronRightRounded from '@mui/icons-material//ChevronRightRounded';
import UploadRounded from '@mui/icons-material/UploadRounded';
import DocumentStatus from '../../../constant/enums/documentStatus';
import documentStatusEnums from '../../../constant/docStatusEnums';
import saudaTypeEnum from '../../../constant/enums/saudaTypeEnum';

interface FileListCardProps {
  title?: string;
  onClickCard?: () => void;
  documentStatus?: number;
  onClickReUploadDocumentUpload?: (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => void;
  language: { [key: string]: any };
  comment?: string;
  handleFileChange?: (
    event: React.ChangeEvent<HTMLInputElement>,
    index?: number,
  ) => void;
  handleUploadButtonClick?: (from: string, index?: number) => void;
  fileInputRef: any;
  file: any;
  preViewDocument: (documents: any, index?: number) => void;
  multiFileUpload: boolean;
  index: number;
  reUploadInputRef: any;
  handleReUploadChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleReUploadButtonClick: (
    from?: string,
    index?: number,
    file?: any,
  ) => void;
  saudaType: number;
}

const FileListCard = ({
  title,
  documentStatus,
  language,
  comment,
  handleFileChange,
  handleUploadButtonClick,
  fileInputRef,
  file,
  preViewDocument,
  multiFileUpload,
  index,
  reUploadInputRef,
  handleReUploadChange,
  handleReUploadButtonClick,
  saudaType,
}: FileListCardProps) => {
  const [containerWidth, setContainerWidth] = useState<number | null>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const updateContainerWidth = () => {
      if (containerRef?.current) {
        setContainerWidth(containerRef?.current?.offsetWidth);
      }
    };

    // Call the updateContainerWidth function once when the component mounts
    updateContainerWidth();

    // Add event listener for window resize
    window.addEventListener('resize', updateContainerWidth);

    // Remove event listener on component unmount
    return () => {
      window.removeEventListener('resize', updateContainerWidth);
    };
  }, []);

  const getStatusColors = (documentStatus: number) => {
    switch (documentStatus) {
      case documentStatusEnums.get('documentApprovalPending'):
        return {
          backgroundColor: '#F0F4F8',
          textColor: '#32383E',
        };
      case documentStatusEnums.get('documentApproved'):
        return {
          backgroundColor: '#e3fbe3',
          textColor: '#0a470a',
        };
      case documentStatusEnums.get('documentRejected'):
        return {
          backgroundColor: '#FCE4E4',
          textColor: '#7D1212',
        };
      default:
        return { backgroundColor: '', textColor: '' };
    }
  };
  return (
    <div
      className={Style.fileListCard}
      onClick={() => preViewDocument(file, index)}
      id="onClickFileListCard"
    >
      <div className={Style.container} ref={containerRef}>
        <div className={Style.content}>
          <div className={Style.icon}>
            <UploadFileRounded style={{ color: '#636B74' }} />
          </div>
          <div className={Style.fileDetails}>
            <label
              className={Style.title}
              style={{
                maxWidth: containerWidth ? containerWidth - 72 : 'none',
              }}
            >
              {file?.fileName || file?.file_name}
            </label>
            <div className={Style.details}>
              <label className={Style.type}>
                {file?.ext === 'pdf' ? 'PDF' : 'Image'}
              </label>
              {file?.status_id && (
                <div
                  className={Style.statusLabel}
                  style={{
                    backgroundColor: getStatusColors(file?.status_id)
                      .backgroundColor,
                    color: getStatusColors(file?.status_id).textColor,
                  }}
                >
                  <label className={Style.status}>
                    {language[DocumentStatus.get(file?.status_id)]}
                  </label>
                </div>
              )}
            </div>
          </div>
        </div>

        <ChevronRightRounded style={{ color: '#636B74' }} />
      </div>
      {file?.status_id === documentStatusEnums.get('documentRejected') &&
        file?.comment &&
        saudaType !== saudaTypeEnum?.offline && (
          <div className={Style.rejectionComment}>
            <label className={Style.rejectionCommentText}>
              {file?.comment}
            </label>
          </div>
        )}
      {file?.status_id === documentStatusEnums.get('documentRejected') &&
        saudaType !== saudaTypeEnum?.offline && (
          <div
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <input
              type="file"
              accept=".pdf, .jpg, .jpeg, .png"
              multiple={false}
              onChange={handleReUploadChange}
              style={{ display: 'none' }}
              ref={(el) => (reUploadInputRef.current[index] = el)}
            />
            <Button
              startDecorator={<UploadRounded />}
              onClick={(e) => {
                e.stopPropagation();
                handleReUploadButtonClick('fileListCard', index, file);
              }}
              id="reUploadButtonFileListCard"
            >
              {language?.reUpload}
            </Button>
          </div>
        )}
    </div>
  );
};

export default FileListCard;
