import React, { ReactNode, useEffect, useRef } from 'react';
import Style from './style.module.css';
import MuiTypography from '../Typography';

interface InputProps {
  placeholder?: string;
  title?: string;
  value: string;
  onchange: (event: any) => void;
  unit: string | ReactNode;
  inputType?: string;
  onClickInput?: () => void;
  id?: string;
  disabled?: boolean;
  startDecorator?: boolean;
  endDecorator?: boolean;
  textLimit?: number;
}

export default function Input(props: InputProps) {
  const {
    placeholder,
    title,
    value,
    onchange,
    unit,
    inputType,
    onClickInput,
    id,
    disabled,
    startDecorator,
    endDecorator,
    textLimit,
  } = props;

  const inputRef = useRef<HTMLInputElement>(null);
  useEffect(() => {
    const handleWheel = (event: WheelEvent) => {
      if (inputRef.current && inputRef.current === document.activeElement) {
        event.preventDefault();
      }
    };
    // Add event listener for mouse wheel event
    document.addEventListener('wheel', handleWheel, { passive: false });
    // Cleanup function to remove the event listener
    return () => {
      document.removeEventListener('wheel', handleWheel);
    };
  }, []);
  return (
    <div className={Style?.mainContainer}>
      {title && <div className={Style?.titleContainer}>{title}</div>}
      <div className={Style?.inputContainer}>
        {startDecorator && (
          <MuiTypography level="body-md">{unit}</MuiTypography>
        )}
        <input
          className={Style?.input}
          placeholder={placeholder}
          value={value}
          onChange={onchange}
          type={inputType}
          onClick={onClickInput}
          id={id}
          disabled={disabled}
          ref={inputRef}
          maxLength={textLimit}
        />
        {endDecorator && <MuiTypography level="body-md">{unit}</MuiTypography>}
      </div>
    </div>
  );
}
