import React from 'react';
import Modal from '../../../ComponentV2/Modal';
import { Box } from '@mui/joy';
import MuiButton from '../../../ComponentsV3/MuiButton';
import MuiTypography from '../../../ComponentV3/Typography';

interface DeleteModalProps {
  onPressDelete: () => void; // Function to handle delete button press
  language: { [key: string]: string }; // Language object containing localized strings
  toggleDeleteModal: (type: string) => void; // Function to toggle delete modal visibility
  isDeleteModalVisible: boolean; // Flag to determine if delete modal is visible
}

const DeleteModal = (props: DeleteModalProps) => {
  const { language, onPressDelete, isDeleteModalVisible, toggleDeleteModal } =
    props;

  // Content of the delete modal
  const modalContent = (
    <Box padding={'16px 16px 24px 16px'}>
      <MuiTypography level="body-sm" textColor={'text.primary'}>
        {language?.deleteHeading} {/* Display delete heading */}
      </MuiTypography>

      <Box display={'flex'} marginTop={'28px'} gap={'12px'}>
        <MuiButton
          onClick={() => toggleDeleteModal('Cancel')} // Handle close button click
          variant="outlined"
          color="neutral"
          size="md"
        >
          {language?.close} {/* Display close button text */}
        </MuiButton>
        <MuiButton
          onClick={onPressDelete} // Handle delete button click
          variant="solid"
          color="danger"
          size="md"
        >
          {language?.deleteButtonText} {/* Display delete button text */}
        </MuiButton>
      </Box>
    </Box>
  );

  return (
    <Modal
      isOpen={isDeleteModalVisible} // Flag to determine if delete modal is open
      onClose={() => toggleDeleteModal('Cross')} // Handle modal close
      // eslint-disable-next-line react/no-children-prop
      children={modalContent} // Pass modal content as children
      title={language?.deleteModal} // Display delete modal title
      isCloseButtonOption={true} // Enable close button option
    />
  );
};

export default DeleteModal;
