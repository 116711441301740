import React, { Suspense, lazy } from 'react';
import Styles from './style.module.css';
import Header from '../../ComponentV2/Header';
import Input from '../../ComponentV3/inputWithIconCumTitle';
import { CreateCumDetailsDispatchProps } from '../../viewmodal/createDispatch/types';
import LocalShippingOutlined from '@mui/icons-material/LocalShippingOutlined';
import ShoppingBagRounded from '@mui/icons-material/ShoppingBagRounded';
import MuiTypography from '../../ComponentV3/Typography';
import Toast from '../../ComponentV2/Toast';
import { useAuth } from '../../context/login';
import ErrorMessageToast from '../../ComponentV2/ErrorMessageToast';
import Typography from '../../ComponentV3/Typography';
import DocumentStatus from '../../constant/docStatusEnums';
import saudaTypeEnum from '../../constant/enums/saudaTypeEnum';
import MuiSkeleton from '../../MuiComponents/Skeleton';

// Lazy load components
const DocContainer = lazy(() => import('./DocContainer'));
const ActionContainer = lazy(() => import('./ActionContainer'));
const CancelDispatchModal = lazy(() => import('./CancelDispatchModal'));
const LoginView = React.lazy(() =>
  import('../../context/login').then((module) => ({
    default: module.LoginView,
  })),
);

export default function CreateDispatchView(
  props: CreateCumDetailsDispatchProps,
) {
  const {
    language,
    onClickBackButton,
    dispatchDetails,
    dispatchWeight,
    truckNumber,
    noOfBags,
    onChangeDispatchWeight,
    onChangeTruckNumber,
    onChangeNoOfBags,
    dispatchDetailLoading,
    onClickInput,
    onClickUploadDocument,
    onClickSubmitButton,
    error,
    invoiceDocument,
    weightSlipDocument,
    mandiDoument,
    showCancelDispatchModal,
    toggleCancelDispatchModal,
    onClickCancelDispatch,
    createDispatchToastPopup,
    submitLoading,
    setIsError,
    isError,
    onChangeTruckDriverNumber,
    truckDriverNumber,
    saudaDetailData,
    onClickMandiToolTip,
    mandiToolTipVisible,
    truckErrorMessage,
  } = props;
  const authProps = useAuth();
  function checkDocStatus() {
    if (
      invoiceDocument?.folder_status_id ===
        DocumentStatus?.get('documentRejected') ||
      weightSlipDocument?.folder_status_id ===
        DocumentStatus?.get('documentRejected') ||
      mandiDoument?.folder_status_id ===
        DocumentStatus?.get('documentRejected') ||
      dispatchDetails === null
    ) {
      return false;
    } else {
      return true;
    }
  }
  return (
    <div className={Styles?.mainContainer}>
      <Suspense
        fallback={
          <MuiSkeleton sx={{ height: 100, width: '100%', marginBottom: 8 }} />
        }
      >
        <Header
          title={
            dispatchDetails?.dispatch_id
              ? language?.dispatchDetails
              : language?.createDispatch
          }
          onClickBackIcon={onClickBackButton}
          id="dispatchDetailBackButton"
        />
      </Suspense>
      <Suspense
        fallback={
          <MuiSkeleton
            sx={{
              height: 100,
              width: 'calc(100% - 32px)',
              borderRadius: 8,
              marginBottom: 8,
            }}
          />
        }
      >
        <Toast
          isError={true}
          message={language?.requestFailErrorMessage}
          isOpen={createDispatchToastPopup}
        />
      </Suspense>
      {dispatchDetailLoading ? (
        <div className={Styles?.loadingContainer}>
          <MuiSkeleton
            sx={{
              height: 100,
              width: 'calc(100% - 32px)',
              borderRadius: 8,
              marginBottom: 8,
            }}
            itemNumber={4}
          />
        </div>
      ) : (
        <>
          <div className={Styles?.restOfBodyContainer}>
            <Input
              placeholder={language?.enterTruckNumber}
              title={language?.truckNumber}
              value={truckNumber}
              onchange={onChangeTruckNumber}
              unit={<LocalShippingOutlined />}
              onClickInput={() => {
                onClickInput('truckNumber');
              }}
              id="truckNumberInput"
              disabled={dispatchDetails?.vehicle_number ? true : false}
              endDecorator={true}
            />
            <Input
              placeholder={language?.enterDispatchWeight}
              title={language?.dispatchWeight}
              value={dispatchWeight}
              onchange={onChangeDispatchWeight}
              unit={<MuiTypography level="body-md">Kg</MuiTypography>}
              inputType="tel"
              onClickInput={() => {
                onClickInput('dispatchWeight');
              }}
              id="dispatchWeightInput"
              disabled={dispatchDetails?.dispatched_weight ? true : false}
              endDecorator={true}
            />
            <Input
              placeholder={language?.enterNoOfBag}
              title={language?.noOfBag}
              value={noOfBags}
              onchange={onChangeNoOfBags}
              unit={<ShoppingBagRounded />}
              inputType="tel"
              onClickInput={() => {
                onClickInput('numberOfBag');
              }}
              id="numberOfBagInput"
              disabled={dispatchDetails?.number_of_bags ? true : false}
              endDecorator={true}
            />
            <Input
              placeholder={language?.mobileNumberPlaceholder}
              title={language?.mobileNumbertitle}
              value={truckDriverNumber}
              onchange={onChangeTruckDriverNumber}
              unit={<Typography level="body-md">+91 - </Typography>}
              inputType="tel"
              onClickInput={() => {
                onClickInput('truckDriverMobileNumber');
              }}
              id="truckDriverMobileNumber"
              disabled={
                dispatchDetails?.truck_driver_mobile_number ? true : false
              }
              startDecorator={true}
              textLimit={10}
            />

            {dispatchDetails?.sauda_creation_type === saudaTypeEnum?.offline &&
            (invoiceDocument?.docs === null ||
              invoiceDocument?.docs?.length === 0) &&
            (weightSlipDocument?.docs === null ||
              weightSlipDocument?.docs?.length === 0) &&
            (mandiDoument?.docs === null ||
              mandiDoument?.docs?.length === 0) ? (
              <></>
            ) : (
              <Suspense
                fallback={
                  <MuiSkeleton
                    sx={{
                      height: 100,
                      width: 'calc(100% - 32px)',
                      borderRadius: 8,
                      marginBottom: 8,
                    }}
                  />
                }
              >
                <DocContainer
                  language={language}
                  onClickUploadDocument={onClickUploadDocument}
                  invoiceDocument={invoiceDocument}
                  weightSlipDocument={weightSlipDocument}
                  mandiDoument={mandiDoument}
                  saudaDetailData={saudaDetailData}
                  mandiToolTipVisible={mandiToolTipVisible}
                  onClickMandiToolTip={onClickMandiToolTip}
                  saudaType={dispatchDetails?.sauda_creation_type}
                  dispatchId={dispatchDetails?.dispatch_id}
                />
              </Suspense>
            )}
          </div>
          <Suspense
            fallback={
              <MuiSkeleton
                sx={{
                  height: 100,
                  width: 'calc(100% - 32px)',
                  borderRadius: 8,
                  marginBottom: 8,
                }}
              />
            }
          >
            <ActionContainer
              language={language}
              onClickSubmitButton={onClickSubmitButton}
              error={error}
              submitLoading={submitLoading}
              actionButtonDisable={checkDocStatus()}
              saudaType={dispatchDetails?.sauda_creation_type}
            />
          </Suspense>
          <Suspense
            fallback={
              <MuiSkeleton
                sx={{
                  height: 100,
                  width: 'calc(100% - 32px)',
                  borderRadius: 8,
                  marginBottom: 8,
                }}
              />
            }
          >
            <CancelDispatchModal
              language={language}
              showCancelDispatchModal={showCancelDispatchModal}
              toggleCancelDispatchModal={toggleCancelDispatchModal}
              onClickCancelDispatch={onClickCancelDispatch}
            />
          </Suspense>
        </>
      )}
      <Suspense
        fallback={
          <MuiSkeleton
            sx={{
              height: 100,
              width: 'calc(100% - 32px)',
              borderRadius: 8,
              marginBottom: 8,
            }}
          />
        }
      >
        <LoginView authProps={{ ...authProps }} />
      </Suspense>
      <Suspense
        fallback={
          <MuiSkeleton
            sx={{
              height: 100,
              width: 'calc(100% - 32px)',
              borderRadius: 8,
              marginBottom: 8,
            }}
          />
        }
      >
        <ErrorMessageToast
          message={
            truckErrorMessage
              ? language?.truckerrorMessage
              : language?.requestFailErrorMessage
          }
          isError={isError}
          setIsError={setIsError}
          ErrorToastType={true}
        />
      </Suspense>
    </div>
  );
}
