import fetchBuyerLocationList from '../../../apis/Trader/fetchBuyerLocationList';
import { LocationList } from '../types';

interface PayloadType {
  page_no: number;
  limit: number;
  search_param: string;
}

export default async function getBuyerLocationList(
  payload: PayloadType,
): Promise<LocationList> {
  const body = { ...payload };

  try {
    const result = await fetchBuyerLocationList(body);
    if (!result.data.status) {
      throw result;
    }
    return result.data.data;
  } catch (error) {
    if (!error.message) {
      error.message = 'Something Went Wrong';
    }

    throw error;
  }
}
