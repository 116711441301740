import React from 'react';
import Styles from './style.module.css';
import Typography from '@mui/joy/Typography';
import { Box } from '@mui/material';

import ArrowBackRounded from '@mui/icons-material/ArrowBackRounded';

// Define the interface for the Header component props
interface HeaderProps {
  onPressBack: () => void; // Function to handle the back button press
  language: { [key: string]: string }; // Language object containing localized strings
  onContactUs: () => void; // Function to handle the contact us button press
}

export default function Header(props: HeaderProps) {
  const { onPressBack, language } = props;

  return (
    <Box
      id="smsFarmersHeader"
      className={Styles.mainContainer}
      bgcolor={'background.body'}
      borderBottom={1}
      borderColor={'neutral.outlinedBorder'}
    >
      <Box id="smsFarmersBackButton" className={Styles.locationContainer}>
        <ArrowBackRounded
          onClick={onPressBack}
          data-testid="smsFarmersBackButtonTestId"
          id="smsFarmersBackButtonId"
        />

        <Typography level="title-md" textColor={'text.primary'}>
          {language?.heading}
        </Typography>
      </Box>
      <Box></Box>
    </Box>
  );
}
