import React from 'react';
import styles from './style.module.css';
import MuiButton from '../../../ComponentV3/MuiButton';
import AddFilledIcon from '../../../assets/svgComponet/AddFilledIcon';
import { Box } from '@mui/joy';
import PlatForms from '../../../constant/platFormEnums';
import { usePlatfromData } from '../../../hooks/CrossPlatformActionHandler/platformChecker';
export default function AddFarmer({
  language,
  onClickAddFarmer,
  homeScreenAssets,
}) {
  const platform = usePlatfromData();

  const bottomSpace =
    platform.platform === PlatForms?.get('application') ? 24 : 80;
  return (
    <Box className={styles.mainContainer} bottom={bottomSpace}>
      <MuiButton
        sx={{
          minHeight: '40px',
          padding: '4px 16px 4px 12px',
          alignContent: 'center',
          justifyContent: 'center',
          borderRadius: '8px',
          width: 'auto', // Ensure width is set to auto
          whiteSpace: 'nowrap', // Prevents text from wrapping if it's too long
          marginRight: '16px',
        }}
        size="md"
        color="primary"
        startDecorator={
          <img
            src={homeScreenAssets.addFilledIcon}
            height={24}
            width={24}
            loading="eager"
          />
        }
        onClick={onClickAddFarmer}
        id="addFarmarPwa"
      >
        {language?.addFarmer}
      </MuiButton>
    </Box>
  );
}
